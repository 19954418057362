import React from 'react';
import { string } from "prop-types";

const Pagination = ({listPerPage, totalList, paginate, currentPage}) => {
    const pageNumber = [];
    for (let index = 1; index <= Math.ceil(totalList/listPerPage); index++) {
        pageNumber.push(index);        
    }
    return(
      <nav>
        <ul className="pagination">
          {pageNumber.map(number => (
            <li key={number} className={"cursor-pointer page-item " + (currentPage == number ? 'active': '')}>
              <a onClick={() => paginate(number)}  className="page-link" >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      )
}
Pagination.propTypes = {
  listPerPage: string.isRequired,
  totalList: string.isRequired,
  paginate: string.isRequired,
  currentPage: string.isRequired
}

export default Pagination;