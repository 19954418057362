let a = { lat: "", lng: "" };
const showPosition = position => {
  a.lat = parseFloat(position.coords.latitude);
  a.lng = parseFloat(position.coords.longitude);
};
navigator.geolocation.getCurrentPosition(showPosition);

export const Plant = (
  id = "",
  plant_name = "",
  email = "",
  countryCode = "",
  phone = "",
  organization = "",
  address = "",
  city = "",
  state = "",
  country = "",
  zip = "",
  contactPerson = "",
  lat = a.lat,
  lng = a.lng,
  capacity = ""
) => {
  return {
    id,
    plant_name,
    email,
    countryCode,
    phone,
    organization,
    address,
    city,
    state,
    country,
    zip,
    contactPerson,
    lat,
    lng,
    capacity
  };
};

export const PlantValidation = {
  plant_name: {},
  email: {},
  countryCode: {},
  phone: {},
  organization: {},
  address: {},
  city: {},
  state: {},
  country: {},
  zip: {},
  contactPerson: {},
  capacity: {}
};

let plants = ["Plant one", "Plant two", "Plant three"];

export default plants.map(p => {
  return Plant(
    p.toLowerCase(),
    p,
    "admin@" + p.toLowerCase().replace(" ", "") + ".com",
    "+91",
    "9999999999",
    "organization one",
    "address",
    "city",
    "state",
    "India",
    "123456",
    "Plant Admin",
    a.lat,
    a.lng,
    0
  );
});
