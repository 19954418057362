
export const KKBRInlet = (
    ph = "",
    orp = ""
  ) => {
    return {
      ph,
      orp
    };
  };
  
  
  export default KKBRInlet(
    "10",
    "20",
    "30"
  );
  