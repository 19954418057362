import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTooltip from 'react-tooltip'
import {
  navbarActions,
  saveEditSession,
  displayResponseMessage,
  deleteEditSessionDailyProcess
} from "../../../../actions/AppStateActions";
import ResponseMessage from "../../../common/ResponseMessage";
import * as dailyProcessService from "../../../../services/DailyProcessService";
import Pagination from "../../../common/Pagination";
import * as constant from '../../../../constants/actionTypes/AppStateActionTypes';
import { bindActionCreators } from "redux";
import logo from '../../../assets/Spinner-1s-200px.gif';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  ModalBody,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  CardHeader,

} from "shards-react";

import PageTitle from "../../../common/PageTitle";
import MaterialIcon from "material-icons-react";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faFlask, faVial, faList, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { string, object, func } from "prop-types";

class Manager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entryDataProcess: [],
      entryList: [],
      open: false,
      entry: null,
      tooltipForInHouse: false,
      tooltipForExternal: false,
      tooltipForList: false,
      isDataLoaded: false,
      opencomment: false,
      s: "",
      search_key: "",
      errorMessage: "",
      currentPage: 1,
      setCurrentPage: 1,
      listPerPage: constant.ITEM_PER_PAGE,
      setListPerPage: 1,

    };
    this.toggleModal = this.toggleModal.bind(this);
    this.delete_entry = this.delete_entry.bind(this);
    this.toggleTooltipForInHouse = this.toggleTooltipForInHouse.bind(this);
    this.toggleTooltipForExternal = this.toggleTooltipForExternal.bind(this);
    this.toggleTooltipForList = this.toggleTooltipForList.bind(this);
    this.toogleComment = this.toogleComment.bind(this);
    this.on_search_function = this.on_search_function.bind(this);
  }

  toggleTooltipForInHouse() {


    this.setState({
      tooltipForInHouse: !this.state.tooltipForInHouse
    });
  }

  toggleTooltipForExternal() {
    this.setState({
      tooltipForExternal: !this.state.tooltipForExternal
    });
  }
  toggleTooltipForList() {
    this.setState({
      tooltipForList: !this.state.tooltipForList
    });
  }
  modal() {
    return (
      <Modal
        size="lg"
        style={{ marginTop: "55%" }}
        open={this.state.opencomment} 
        toggle={this.toogleComment}
        className="h-75 d-flex align-items-center justify-content-center">
        {/* <ModalHeader>Header</ModalHeader> */}
        <div>
          <span className="float-right close" id={"toggler"} style={{ float: "right", cursor: "pointer", margin: "10px 10px -5px 10px" }} onClick={this.toogleComment}>
            <i className="material-icons">close</i>
          </span>

        </div>

        <ModalBody
          style={{ paddingTop: "0px" }}>{this.state.s}</ModalBody>
      </Modal>
    );

  }
  on_search_function(e) {
    this.setState({
      search_key: e.target.value
    })
  }
  toogleComment(entry) {
    if (entry != undefined) {
      this.setState({
        s: entry.observations == undefined ? "" : entry.observations
      })
    }

    this.setState({
      opencomment: !this.state.opencomment
    })
  }


  componentDidMount() {
    this.setState({
      isDataLoaded: !this.state.isDataLoaded
    })
    const plant_id = localStorage.getItem("plant_id");
    const listFunc = dailyProcessService.getDailyProcess;
    listFunc(plant_id).then(entryList => {
      entryList = entryList ? entryList : [];
      if (entryList.length > 0) {
        entryList.sort(function (a, b) {
          var c = new Date(a.date);
          var d = new Date(b.date);
          return c - d;
        });
        let lastdate = entryList[entryList.length - 1].date;
        entryList.reverse();
        localStorage.setItem("current_daily_process_date", lastdate);

      } else {
        this.setState({
          errorMessage: 'Not Added Any Daily Process Data'
        })
      }
      this.setState({ entryList });
      this.setState({
        isDataLoaded: !this.state.isDataLoaded
      })

    });
    this.props.actions.navbarActions("search_bar_state_visible", false);
    this.setState({
      entryDataProcess: this.props.current_user
    });
  }

  componentWillUnmount() {
    this.props.actions.navbarActions("search_bar_state_visible", false);
  }

  edit_entry(entry) {
    // console.log(entry)
    const process_detail = entry;
    if ("undefined" === typeof (process_detail["septages"])) {
      process_detail['septages'] = [];
    }

    this.props.actions.saveEditSession(process_detail);
    this.props.history.push("edit-daily-process-data");
  }

  save_id_inhouse(item) {
    let id = item._id.$oid;
    localStorage.setItem("currentProcessId", id);
    localStorage.setItem("currentProcesssDate", item.date);
    localStorage.setItem("currentDailyProcess", JSON.stringify(item));
    this.props.history.push("In-house");
  }
  save_id_external(item) {
    let id = item._id.$oid;
    localStorage.setItem("currentProcessId", id);
    localStorage.setItem("currentProcesssDate", item.date)
    this.props.history.push("external");
  }
  render_list_lab_analysis(item) {
    let id = item._id.$oid;
    localStorage.setItem("currentProcessId", id);
    localStorage.setItem("currentProcesssDate", item.date)
    localStorage.setItem("currentDailyProcess", JSON.stringify(item));
    this.props.history.push("list-lab-analysis")
  }

  toggleModal(entry) {
    this.setState({ entry }, () => {
      this.setState({ open: !this.state.open });
    });
  }
  delete_entry(id) {
    const deleteFunc = dailyProcessService.deleteDailyProcess;
    deleteFunc(id).then(
      () => {
        this.setState(
          { entryList: this.state.entryList.filter(e => e._id.$oid != id) },
          () => {
            this.toggleModal()
          }
        );
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "Entry deleted successfully"
        );
      },
      () => {
        this.toggleModal();
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong"
        );
      }
    );
    this.props.history.push("list-daily-process");

  }
  showExclamation(item) {
    if (item.status) {
      return;
    } else if (!item.status) {
      return (
        // <div style={{color: "red"}}>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="1x"
        />
      )
    }

  }

  render() {
    const m = this.modal();
    const isDataLoaded = this.state.isDataLoaded
      ? <div className="text-center" >
        <img style={{ width: "90px" }} className="rounded mx-auto d-block" src={logo}></img>
      </div>
      : null;
    let indexOfLastList = Math.ceil(this.state.currentPage) * Math.ceil(this.state.listPerPage);
    let indexOfFirstList = Math.ceil(indexOfLastList) - Math.ceil(this.state.listPerPage);
    const renderItem = this.state.entryList.slice(indexOfFirstList, indexOfLastList);
    const listItemsOne = this.setup_searchOne(renderItem);
    const paginate = pageNumber => {
      this.setState({
        currentPage: pageNumber
      })
    }
    const modalWindow = (
      <Modal
        size="lg"
        open={this.state.open}
        style={{ marginTop: "55%" }}
        toggle={() => this.toggleModal(null)}
        className="h-75 d-flex align-items-center justify-content-center"
      >
        <h4 className="text-center p-5 w-100">
          Are you sure you want to delete
        </h4>

        <div className="d-flex w-75 mx-auto justify-content-center">
          <Button
            theme="danger"
            onClick={() => this.toggleModal(null)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.delete_entry(this.state.entry._id.$oid)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Yes
          </Button>
        </div>
      </Modal>
    );

    return (

      <div className=" mh-85" onClick={this.props.navToggle}>
        {/* {isDataLoaded} */}
        <ResponseMessage />
        <Container fluid className="main-content-container px-4">
          <Breadcrumb className="mt-3">
            <BreadcrumbItem>
              <a onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/overview/" + localStorage.getItem('plant_id'))} style={{ color: "#007bff", cursor: "pointer" }}> {new String(this.props.match.params.plant_name)} Overview</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              List Daily Process Data
          </BreadcrumbItem>
          </Breadcrumb>
          <Row
            noGutters
            className="page-header py-4 align-items-center justify-content-between"
          >
            <Col className="col-md-9">
              <PageTitle
                sm="4"
                title={new String(this.props.match.params.plant_name) + " Plant"}
                subtitle={this.props.page_sub_title}
                className="text-sm-left"
              />
            </Col>
          </Row>
          {modalWindow}
          {m}
          <Row>
            <Col>
              <Card small className="mb-6">
                <CardHeader >
                  {this.props.permission.edit == "YES" ? (
                    <Button className="float-right" style={{ marginLeft: "15px" }}
                      onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/dailyprocess/")}
                    >
                    Add Daily Process Data
                    </Button>) : (
                      ""
                    )}
                  <Form
                    className="float-right"

                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <InputGroup seamless className="ml-6">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        value={this.state.search_key}
                        onChange={this.on_search_function}
                        className="navbar-search"
                        placeholder="Search daily process"
                      />
                    </InputGroup>
                  </Form>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr className="text-center">
                        <th scope="col" className="border-0">
                        </th>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Location
                        </th>
                        <th scope="col" className="border-0">
                          Date
                        </th>
                        <th width="450px" scope="col" className="border-0">
                          Observations
                        </th>
                        <th scope="col" className="border-0" style={{ width: "30%" }}>
                          Actions
                          </th>
                      </tr>
                    </thead>
                    <tbody>{listItemsOne}</tbody>
                  </table>
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <Pagination listPerPage={this.state.listPerPage}
                      totalList={this.state.entryList.length}
                      paginate={paginate}
                      currentPage={this.state.currentPage}
                    /></Col>
                  </Row>
                  {isDataLoaded}
                  <div style={{ color: "Red" }} className="text-center" >
                    {this.state.errorMessage}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  setup_searchOne(renderItem) {
    let tableRowStartsFrom = 0;
    tableRowStartsFrom = (this.state.currentPage - 1) * this.state.listPerPage + 1;
    let rearrangedEntryList = [];
    rearrangedEntryList.push(...renderItem);
    let search_test = {};
    return rearrangedEntryList.map((item, i) => {
      // console.log(tableRowStartsFrom)
      //console.log(this.state.listPerPage)
      search_test[item._id.$oid] = JSON.stringify(item);
      if (
        this.state.search_key == "" ||
        search_test[item._id.$oid].search(this.state.search_key) > 0
      ) {
        return (
          <tr className="text-center" key={i} id={item.id}>
            <td style={{ verticalAlign: "middle" }}>
              <div style={{ color: "red" }} data-tip="Please fill all the field">
                {this.showExclamation(item)}
              </div>
              <ReactTooltip />
            </td>
            <td style={{ verticalAlign: "middle" }}>
              {tableRowStartsFrom  + i}
            </td>
            <td style={{ verticalAlign: "middle" }}> {item.name}</td>
            <td style={{ verticalAlign: "middle" }}>{item.location}</td>
            <td style={{ verticalAlign: "middle" }}>{item.date}
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <div style={{ cursor: "pointer" }} onClick={() => this.toogleComment(item)}>
                {this.truncate(item.observations)}
              </div>
            </td>
            <td>
              {this.props.permission.edit == "YES" ? (
                <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none"
                  }}
                  className="hover-blue"
                  onClick={() => this.toggleModal(item)}
                >
                  <MaterialIcon icon="delete_outline" />
                </Button>
              ) : (
                  ""
                )}

              {this.props.permission.edit == "YES" ? (
                <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none"
                  }}
                  onClick={() => this.edit_entry(item)}
                  className="hover-blue ml-2"
                >
                <MaterialIcon id={item.id} icon="edit" />
                </Button>) : (
                  ""
                )}
              {this.props.permission.edit == "YES" ? (
                <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color: "#bcc5d3"
                  }}
                  onClick={() => this.save_id_inhouse(item)}
                  // onClick={() => this.props.history.push("In-house")}
                  className="hover-blue ml-2"
                  // id={"a"+i}
                  data-tip="InHouse Lab Analysis"
                >
                  <FontAwesomeIcon icon={faVial} size="2x" />
                </Button>) : (
                  ""
                )}
              <ReactTooltip />
              {this.props.permission.edit == "YES" ? (
                <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color: "#bcc5d3"
                  }}
                  // save_id_external
                  onClick={() => this.save_id_external(item)}
                  // onClick={() => this.props.history.push("external")}
                  className="hover-blue ml-2"
                  data-tip="External Lab Analysis"
                // id="TooltipExternalLab"
                // toggleTooltipForExternal
                >
                  <FontAwesomeIcon
                    icon={faFlask}
                    size="2x"

                  />
                </Button>) : (
                  ""
                )}
              <ReactTooltip />
              {/* <Tooltip
                open={this.state.tooltipForExternal}
                placement="left"
                target="#TooltipExternalLab"
                toggle={this.toggleTooltipForExternal}
                >
                External Lab Analysis
                </Tooltip> */}
              <Button
                style={{
                  borderRadius: "50px",
                  backgroundColor: "white",
                  border: "none",
                  color: "#bcc5d3"
                }}
                onClick={() => this.render_list_lab_analysis(item)}
                //onClick={() => this.props.history.push("list-lab-analysis")}
                className="hover-blue ml-2"
                // id="TooltipInhouse"
                // toggleTooltipForList
                data-tip="List Lab Analysis"
              >
                <FontAwesomeIcon
                  icon={faList}
                  size="2x"
                  navbarActions
                />
              </Button>
              <ReactTooltip />
              {/* <Tooltip
                open={this.state.tooltipForList}
                placement="left"
                target="#TooltipInhouse"
                toggle={this.toggleTooltipForList}
                 >
                 List Lab Analysis
                 </Tooltip> */}
            </td>
          </tr>
        )
      } else {
        return (
          <tr key={i} />
        )
      }
    });




    // return( 

    //     <tr className="text-center">
    //     <td>1</td>
    //     <td> {rearrangedEntryList.name} </td>
    //     <td>{rearrangedEntryList.location}</td>
    //     <td> {
    //             new Intl.DateTimeFormat('en-GB',{
    //                   year:'numeric',
    //                   month: 'long',
    //                   day: '2-digit'
    //                 }).format(rearrangedEntryList.todaysDate)
    //           } </td>
    //     <td>20</td>
    //     <td>
    //     <Button
    //       style={{
    //         borderRadius: "50px",
    //         backgroundColor: "white",
    //         border: "none"
    //       }}
    //       className="hover-blue"


    //     >
    //       <MaterialIcon icon="delete_outline" />
    //     </Button>

    //     <Button
    //       style={{
    //         borderRadius: "50px",
    //         backgroundColor: "white",
    //         border: "none"
    //       }}
    //       onClick={() => this.props.history.push("edit-daily-process-data")}


    //       className="hover-blue ml-2"
    //     >
    //       <MaterialIcon  icon="edit" />
    //     </Button>
    //           <Button
    //             style={{
    //               borderRadius: "50px",
    //               backgroundColor: "white",
    //               border: "none",
    //               color:"#bcc5d3"

    //             }}
    //             onClick={() => this.props.history.push("In-house")}
    //             className="hover-blue ml-2"
    //             id="TooltipExample"
    //           >
    //             <FontAwesomeIcon icon={faVial} size="2x" />
    //           </Button>
    //           <Tooltip
    //             open={this.state.tooltipForInHouse}
    //             placement="left"
    //             target="#TooltipExample"
    //             toggle={this.toggleTooltipForInHouse}
    //             >
    //             InHouse Lab Analysis
    //             </Tooltip>
    //           <Button
    //             style={{
    //               borderRadius: "50px",
    //               backgroundColor: "white",
    //               border: "none",
    //               color:"#bcc5d3"
    //             }}
    //             onClick={() => this.props.history.push("external")}
    //             className="hover-blue ml-2"
    //             id="TooltipExternalLab"
    //             toggleTooltipForExternal
    //           >
    //            <FontAwesomeIcon 
    //            icon={faFlask}
    //            size="2x"

    //            />
    //           </Button>
    //           <Tooltip
    //             open={this.state.tooltipForExternal}
    //             placement="left"
    //             target="#TooltipExternalLab"
    //             toggle={this.toggleTooltipForExternal}
    //             >
    //             External Lab Analysis
    //             </Tooltip>
    //             <Button
    //             style={{
    //               borderRadius: "50px",
    //               backgroundColor: "white",
    //               border: "none",
    //               color:"#bcc5d3"
    //             }}
    //             onClick={() => this.props.history.push("list-lab-analysis")}
    //             className="hover-blue ml-2"
    //             id="TooltipExternalLab"
    //             toggleTooltipExternalLab
    //           >
    //            <FontAwesomeIcon 
    //            icon={faList}
    //            size="2x"

    //            />
    //           </Button>
    //           <Tooltip
    //             open={this.state.tooltipForExternal}
    //             placement="left"
    //             target="#TooltipExternalLab"
    //             toggle={this.toggleTooltipForExternal}
    //             >
    //             External Lab Analysis
    //             </Tooltip>
    //         </td>
    //                 </tr>


    // );



  }

  // setup_search(toolTips) {
  //   let search_test = {};
  //   let rearrangedEntryList = [];
  //   if (this.props.collection_name == "users") {
  //     this.state.entryList.forEach(entry => {
  //       if (entry.designation == "Admin") rearrangedEntryList.unshift(entry);
  //       else rearrangedEntryList.push(entry);
  //     });
  //   } else {
  //     rearrangedEntryList.push(...this.state.entryList);
  //   }
  //   return rearrangedEntryList.map((item, i) => {
  //     search_test[item.id] = JSON.stringify(item);
  //     if (
  //       this.props.search_key == "" ||
  //       search_test[item.id].search(this.props.search_key) > 0
  //     ) {
  //       return (
  //         <tr className="text-center" key={i} id={item.id}>
  //           <td>{i + 1}</td>
  //           <td>
  //             {item[this.props.colDef[Object.keys(this.props.colDef)[0]]]}
  //           </td>
  //           <td>
  //             {item[this.props.colDef[Object.keys(this.props.colDef)[1]]]}
  //           </td>
  //           <td>
  //             {item[this.props.colDef[Object.keys(this.props.colDef)[2]]]}
  //           </td>
  //           {this.props.collection_name == "users" ? (
  //             <td
  //               data-tip
  //               data-for={item.first_name + i}
  //               className="cursor-pointer"
  //             >
  //               <ReactTooltip id={item.first_name + i} type="info">
  //                 {toolTips[item.id]}
  //               </ReactTooltip>
  //               {
  //                 item[this.props.colDef[Object.keys(this.props.colDef)[3]]]
  //                   .length
  //               }
  //             </td>
  //           ) : (
  //             <td>
  //               {item[this.props.colDef[Object.keys(this.props.colDef)[3]]]}
  //             </td>
  //           )}
  //           <td>
  //             <Button
  //               style={{
  //                 borderRadius: "50px",
  //                 backgroundColor: "white",
  //                 border: "none"
  //               }}
  //               className="hover-blue"
  //               onClick={() => this.toggleModal(item)}
  //               disabled={item.designation == "Admin"}
  //             >
  //               <MaterialIcon icon="delete_outline" />
  //             </Button>

  //             <Button
  //               style={{
  //                 borderRadius: "50px",
  //                 backgroundColor: "white",
  //                 border: "none"
  //               }}
  //               onClick={() => this.edit_entry(item)}
  //               className="hover-blue ml-2"
  //             >
  //               <MaterialIcon id={item.id} icon="edit" />
  //             </Button>
  //             <Button
  //               style={{
  //                 borderRadius: "50px",
  //                 backgroundColor: "white",
  //                 border: "none",
  //                 color:"#bcc5d3"

  //               }}
  //               onClick={() => this.edit_entry(item)}
  //               className="hover-blue ml-2"
  //               id="TooltipExample"
  //             >
  //               <FontAwesomeIcon id={item.id} icon={faVial} size="2x" />
  //             </Button>
  //             <Tooltip
  //               open={this.state.tooltipForInHouse}
  //               placement="left"
  //               target="#TooltipExample"
  //               toggle={this.toggleTooltipForInHouse}
  //               >
  //               InHouse Lab Analysis
  //               </Tooltip>
  //             <Button
  //               style={{
  //                 borderRadius: "50px",
  //                 backgroundColor: "white",
  //                 border: "none",
  //                 color:"#bcc5d3"
  //               }}
  //               onClick={() => this.edit_entry(item)}
  //               className="hover-blue ml-2"
  //               id="TooltipExternalLab"
  //               toggleTooltipForExternal
  //             >
  //              <FontAwesomeIcon 
  //              icon={faFlask}
  //              size="2x"

  //              />
  //             </Button>
  //             <Tooltip
  //               open={this.state.tooltipForExternal}
  //               placement="left"
  //               target="#TooltipExternalLab"
  //               toggle={this.toggleTooltipForExternal}
  //               >
  //               External Lab Analysis
  //               </Tooltip>
  //           </td>
  //         </tr>
  //       );
  //     } else {
  //       return <tr key={i} />;
  //     }
  //   });
  // }
  truncate(s) {
    let l = s.length;
    if (l == 0) {
      return '--'
    }
    if (l < 50) {
      return s;
    } else {
      const l =
        <div className="d-flex" style={{}}>

          <div>
            <label>
              {s.slice(0, 50)}
            </label>

          </div>
          <div style={{ textDecoration: "underline", color: "#007bff", marginLeft: "2px" }}>
            ...more
      </div>
        </div>

      return l;
    }


  }
}

function mapStateToProps(state) {
  return {
    search_key: state.AppState.search_key,
    current_user: state.AppState.dailyProcessess,
    permission: state.AppState.permission,
  };
}



function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteEditSessionDailyProcess, navbarActions, saveEditSession, displayResponseMessage },
      dispatch
    )
  };
}

Manager.propTypes = {
  permission: object.isRequired,
  page_title: string.isRequired,
  page_sub_title: string.isRequired,
  edit_entry_link: string.isRequired,
  add_entry_link: string.isRequired,
  colDef: object.isRequired,
  entryList: object,
  collection_name: string.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
  delete_action_type: string.isRequired,
  navToggle: func.isRequired,
  search_key: string.isRequired,
  current_user: object.isRequired,
  match: object.isRequired

};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Manager)
);
