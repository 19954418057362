import React, { Component } from "react";
import {
  Organization,
  OrganizationValidation
} from "../../../models/Organization";
import {
  saveEditSession,
  displayResponseMessage
} from "../../../actions/AppStateActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormSelect,
  FormInput,
  Button,
  FormFeedback,
  Modal,
  InputGroup
} from "shards-react";
import * as types from "../../../constants/actionTypes/PlatformActionTypes";
import { validate } from "../validations/OrganizationValidation";
import * as organizationService from "../../../services/OrganizationService";
import ResponseMessage from "../../common/ResponseMessage";

class OrganizationFormTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: this.props.edit_session
        ? { ...this.props.edit_session }
        : Organization(),
      validation: OrganizationValidation,
      submitted: false,
      open: false,
      isSubmitted: false,
    };
    this.submit_form = this.submit_form.bind(this);
    this.on_change = this.on_change.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.field_validate = this.field_validate.bind(this);
    this.form_validate = this.form_validate.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.saveEditSession(null);
  }

  form_validate() {
    let validation = { ...this.state.validation };
    let failed = [];
    Object.keys(this.state.validation).forEach(v => {
      let validation_results = { ...validate(v, this.state.organization[v]) };
      validation_results["valid"] =
        this.state.submitted && validation_results.valid;
      validation_results["invalid"] =
        this.state.submitted && validation_results.invalid;
      validation[v] = { ...validation[v], ...validation_results };
      if (validation[v].invalid) {
        failed.push(v);
      }
    });
    this.setState({ validation });
    return failed;
  }

  field_validate(e) {
    if (this.state.submitted) {
      const validation = {
        ...this.state.validation,
        [e.target.name]: {
          ...this.state.validation[e.target.name],
          ...validate(e.target.name, e.target.value)
        }
      };
      this.setState({ validation });
    }
  }

  submit_form(e) {
    e.preventDefault();
    this.setState({
      isSubmitted: true,
    })
    const submit_func =
      this.props.action == types.ADD_ORGANIZATION
        ? organizationService.addOrganization
        : organizationService.editOrganization;
    
    // submit_func(this.state.organization, "organizations", this.props.action);
    let organization_detail = this.state.organization;
    if (!this.props.edit_session) {
      delete organization_detail["id"];
    }
    submit_func({ organization_detail }).then(
      () => {
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "Organization " +
            (this.props.edit_session ? "edited" : "added") +
            " successful."
        );
        this.props.history.push("organization-list");
        this.setState({
          isSubmitted: false,
        })
      },
      () => {
        this.toggleModal();
        this.setState({
          isSubmitted: false,
        })
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong."
        );
      }
    );
  }

  on_change(e) {
    const organization = {
      ...this.state.organization,
      [e.target.name]: e.target.value
    };
    this.setState({ organization });
    this.field_validate(e);
  }

  toggleModal() {
    this.setState({ submitted: true }, () => {
      if (this.form_validate().length > 0) {
        this.setState({
          isSubmitted: false,
        })
        return;
      }
      this.setState({ open: !this.state.open });
    });
  }

  render() {
    return (
      <div className="mh-85" onClick={this.props.navToggle}>
        <ResponseMessage />
        <div className="text-dark my-3 px-5">
          <small>User Operations</small>
          <h4>{this.props.form_title} </h4>
        </div>

        <Modal
          size="lg"
          open={this.state.open}
          style={{ marginTop: "55%" }}
          toggle={this.toggleModal}
          className="h-75 d-flex align-items-center justify-content-center"
        >
          <h4 className="text-center p-5 w-100">
            {"Are You sure you want to " + this.props.button_title + "?"}{" "}
          </h4>

          <div className="d-flex w-75 mx-auto justify-content-center">
            <Button
              theme="danger"
              onClick={this.toggleModal}
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.isSubmitted == true}
              form="organization_form"
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Yes
            </Button>
          </div>
        </Modal>

        <div className="d-flex flex-wrap h-75 mt-2 px-5 col-12 align-items-center justify-content-center">
          <Card className="mb-5 p-0">
            <CardHeader className="bg-white border-bottom  p-3">
              <h5 className="text-dark">{this.props.form_sub_title}</h5>

              <div className="mt-2 p-0 col-12">
                <small> Fields marked * are mandatory </small>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <Form
                id="organization_form"
                className="d-flex flex-wrap justify-content-between"
                onSubmit={this.submit_form}
              >
                <div className="col-lg-6 mt-3">
                  <label>Name *</label>
                  <FormInput
                    name="organization_name"
                    type="text"
                    value={this.state.organization.organization_name}
                    onChange={this.on_change}
                    {...this.state.validation.organization_name}
                  />
                  <FormFeedback {...this.state.validation.organization_name}>
                    {this.state.validation.organization_name.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>E-mail *</label>
                  <FormInput
                    name="email"
                    type="email"
                    value={this.state.organization.email}
                    onChange={this.on_change}
                    {...this.state.validation.email}
                  />{" "}
                  <FormFeedback {...this.state.validation.email}>
                    {" "}
                    {this.state.validation.email.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Phone Number *</label>
                  <InputGroup className="d-flex w-100 m-0">
                    <div className="col-3 p-0">
                      <FormInput
                        maxLength={4}
                        type="text"
                        name="countryCode"
                        placeholder="ex : +91"
                        value={this.state.organization.countryCode}
                        onChange={this.on_change}
                        {...this.state.validation.countryCode}
                      />
                      <FormFeedback {...this.state.validation.countryCode}>
                        {this.state.validation.countryCode.message}
                      </FormFeedback>{" "}
                    </div>
                    <div className="col-9 p-0">
                      <FormInput
                        maxLength={10}
                        name="phone"
                        type="tel"
                        value={this.state.organization.phone}
                        onChange={this.on_change}
                        {...this.state.validation.phone}
                      />
                      <FormFeedback {...this.state.validation.phone}>
                        {" "}
                        {this.state.validation.phone.message}{" "}
                      </FormFeedback>
                    </div>
                  </InputGroup>
                </div>

                {/* <div className="col-lg-6 mt-3">
                  <label>Phone Number *</label>
                  <FormInput
                    name="phone"
                    type="text"
                    pattern="[0-9]{10}"
                    value={this.state.organization.phone}
                    onChange={this.on_change}
                    {...this.state.validation.phone}
                  />{" "}
                  <FormFeedback {...this.state.validation.phone}>
                    {" "}
                    {this.state.validation.phone.message}{" "}
                  </FormFeedback>
                </div> */}

                <div className="col-lg-6 mt-3">
                  <label>Type *</label>
                  <FormSelect
                    name="organization_type"
                    value={this.state.organization.organization_type}
                    onChange={this.on_change}
                    {...this.state.validation.organization_type}
                  >
                    <option value="" disabled>
                      Select Type
                    </option>
                    <option value="private">Private</option>
                    <option value="govt">Govt.</option>
                    <option value="ngo">NGO</option>
                    <option value="academia">Academia</option>
                    <option value="others">Others</option>
                  </FormSelect>
                  <FormFeedback {...this.state.validation.organization_type}>
                    {" "}
                    {this.state.validation.organization_type.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Domain *</label>
                  <FormSelect
                    name="domain"
                    value={this.state.organization.domain}
                    onChange={this.on_change}
                    {...this.state.validation.domain}
                  >
                    <option value="" disabled>
                      Select Domain
                    </option>
                    <option value="sanitation">Sanitation</option>
                    <option value="r&d">R&D</option>
                    <option value="wasteManagement">Waste Management</option>
                    <option value="industry">Industry</option>
                    <option value="consulting">Consulting</option>
                    <option value="others">Others</option>
                  </FormSelect>
                  <FormFeedback {...this.state.validation.domain}>
                    {" "}
                    {this.state.validation.domain.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Relation *</label>
                  <FormSelect
                    name="relation"
                    value={this.state.organization.relation}
                    onChange={this.on_change}
                    {...this.state.validation.relation}
                  >
                    <option value="" disabled>
                      Select Relation
                    </option>
                    <option value="self">Self</option>
                    <option value="customer">Customer</option>
                    <option value="partner">Partner</option>
                    <option value="regulator">Regulator</option>
                  </FormSelect>
                  <FormFeedback {...this.state.validation.relation}>
                    {" "}
                    {this.state.validation.relation.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Website *</label>
                  <FormInput
                    name="website"
                    type="text"
                    value={this.state.organization.website}
                    onChange={this.on_change}
                    {...this.state.validation.website}
                  />{" "}
                  <FormFeedback {...this.state.validation.website}>
                    {" "}
                    {this.state.validation.website.message}{" "}
                  </FormFeedback>
                </div>

                {/* <div className="col-lg-6 mt-3">
                  <label>Site Incharge *</label>
                  <FormInput
                    name="contactPerson"
                    type="text"
                    value={this.state.organization.contactPerson}
                    onChange={this.on_change}
                    {...this.state.validation.contactPerson}
                  />{" "}
                  <FormFeedback {...this.state.validation.contactPerson}>
                    {" "}
                    {this.state.validation.contactPerson.message}{" "}
                  </FormFeedback>
                </div> */}

                {/* <div className="col-lg-6 mt-3">
                  <label>Contact Person Designation *</label>
                  <FormInput
                    name="contactPersonDesignation"
                    type="text"
                    value={this.state.organization.contactPersonDesignation}
                    onChange={this.on_change}
                    {...this.state.validation.contactPersonDesignation}
                  />{" "}
                  <FormFeedback
                    {...this.state.validation.contactPersonDesignation}
                  >
                    {" "}
                    {
                      this.state.validation.contactPersonDesignation.message
                    }{" "}
                  </FormFeedback>
                </div> */}

                <div className="col-lg-6 mt-3">
                  <label>Address</label>
                  <FormInput
                    name="address"
                    type="text"
                    value={this.state.organization.address}
                    onChange={this.on_change}
                    {...this.state.validation.address}
                  />{" "}
                  <FormFeedback {...this.state.validation.address}>
                    {" "}
                    {this.state.validation.address.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>City</label>
                  <FormInput
                    name="city"
                    type="text"
                    value={this.state.organization.city}
                    onChange={this.on_change}
                    {...this.state.validation.city}
                  />{" "}
                  <FormFeedback {...this.state.validation.city}>
                    {" "}
                    {this.state.validation.city.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>State</label>
                  <FormInput
                    name="state"
                    type="text"
                    value={this.state.organization.state}
                    onChange={this.on_change}
                    {...this.state.validation.state}
                  />{" "}
                  <FormFeedback {...this.state.validation.state}>
                    {" "}
                    {this.state.validation.state.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Country</label>
                  <FormInput
                    name="country"
                    type="text"
                    value={this.state.organization.country}
                    onChange={this.on_change}
                    {...this.state.validation.country}
                  />{" "}
                  <FormFeedback {...this.state.validation.country}>
                    {" "}
                    {this.state.validation.country.message}{" "}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Pin Code *</label>
                  <FormInput
                    name="zip"
                    type="text"
                    maxLength={6}
                    value={this.state.organization.zip}
                    onChange={this.on_change}
                    {...this.state.validation.zip}
                  />{" "}
                  <FormFeedback {...this.state.validation.zip}>
                    {" "}
                    {this.state.validation.zip.message}{" "}
                  </FormFeedback>
                </div>

                <div className="my-4 col-12">
                  <Button
                    theme="danger"
                    type="button"
                    onClick={() => this.props.history.push("organization-list")}
                    className="w-25 mr-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={this.toggleModal}
                    className="w-25"
                  >
                    {" "}
                    {this.props.button_title}{" "}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edit_session: state.AppState.edit_session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { saveEditSession, displayResponseMessage },
      dispatch
    )
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationFormTemplate)
);
