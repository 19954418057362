import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  navbarActions,
  saveEditSession,
  displayResponseMessage,
  deleteEditSession,
  deleteEditSessionInHouse
} from "../../../../actions/AppStateActions";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Modal,
  Breadcrumb,
  BreadcrumbItem
} from "shards-react";

import PageTitle from "../../../common/PageTitle";
import ReactTooltip from "react-tooltip";
import MaterialIcon from "material-icons-react";
// import FontAwesomeIcon from "react-fontawesome";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { string, object, func } from "prop-types";
import ResponseMessage from "../../../common/ResponseMessage";
import * as inHouseLabAnalysisService from "../../../../services/InHouseLabAnalysisService";
import * as ExternalLabAnalysisService from "../../../../services/ExternalLabAnalysisService";
import logo from '../../../assets/Spinner-1s-200px.gif';




class ListLabAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entryList: [],
      open: false,
      openForInHouse: false,
      entry: null,
      tooltipForInHouse: false,
      tooltipForExternal: false,
      ExternalLabAnalysisData : [],
      isDataLoaded:false,
      errorMessage: ""
      
    };
    // this.setup_search = this.setup_search.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalForInhouse = this.toggleModalForInhouse.bind(this);
    this.delete_entry = this.delete_entry.bind(this);
    this.delete_entry_InHouse = this.delete_entry_InHouse.bind(this);
    this.toggleTooltipForInHouse = this.toggleTooltipForInHouse.bind(this);
    this.toggleTooltipForExternal = this.toggleTooltipForExternal.bind(this);
    this.toggleModalCancel = this.toggleModalCancel.bind(this)
  }

  toggleTooltipForInHouse(){
    this.setState({
        tooltipForInHouse: !this.state.tooltipForInHouse
      });
  }

  toggleTooltipForExternal(){
    this.setState({
        tooltipForExternal: !this.state.tooltipForExternal
      });
  }



  componentDidMount() {
    this.setState({
      isDataLoaded: !this.state.isDataLoaded
    })
    let dailyProcess = JSON.parse(localStorage.getItem("currentDailyProcess"));
    let plant_id = localStorage.getItem("plant_id");
    let process_detail_id = dailyProcess._id.$oid;
    const listFunc = inHouseLabAnalysisService.getInHouseLabAnalysisByProcessIdPlantId;
    listFunc(plant_id,process_detail_id).then(entryList => {
      entryList = entryList ? entryList : [];
      if(entryList.length == 0){
        this.setState({
          errorMessage: 'Not Added Any Lab Analysis Data'
        })
      }else{
        this.setState({
          errorMessage: ''
        })
      }
      // entryList.sort(function(a,b){
      //   var c = new Date(a.date);
      //   var d = new Date(b.date);        
      //   return c-d;
      //   });
      // let lastdate= entryList[entryList.length-1].date;
      // entryList.reverse();
        
      // localStorage.setItem("current_inhouse_date",lastdate );
      this.setState({ entryList });
      this.setState({
        isDataLoaded: false
      })

    });    
  }


  edit_entry(entry) {    
    this.props.actions.saveEditSession(entry);
    if(entry.analysis_type == 'Inhouse'){
      this.props.history.push( "/admin/plants/"+ this.props.match.params.plant_name + "/edit-lab-analysis-process-data");      
    }else {
      this.props.history.push("edit-external-lab-analysis-process-data");
    }
  }
  edit_entry_external(entry){    
    this.props.actions.saveEditSession(entry);
    this.props.history.push("edit-external-lab-analysis-process-data");
  }

  toggleModal(entry) {
    this.setState({ entry }, () => {
      this.setState({ open: !this.state.open });
    });    
  }
  toggleModalCancel(entry) {
    this.setState({ entry }, () => {
      this.setState({ openForInHouse: !this.state.openForInHouse });
    });    
  }

  toggleModalForInhouse(entry) {
    if(entry != null){
      if(entry.analysis_type == 'Inhouse'){
        this.setState({ entry }, () => {
          this.setState({ openForInHouse: !this.state.openForInHouse });
        });
      }else {
        this.setState({ entry }, () => {
          this.setState({ open: !this.state.open });
        });
      }
    }
  }

  delete_entry_InHouse(id) {
    const deleteFunc = inHouseLabAnalysisService.deleteInHouseLabAnalysis;
    deleteFunc(id).then(
      () => {
        this.setState(
          { entryList: this.state.entryList.filter(e => e._id.$oid != id) },
          () => {
            this.toggleModalCancel()
          }
        );
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "Entry deleted successfully"
        );
       // this.toggleModal(null);
      },
      () => {
        this.toggleModalCancel();
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong"
        );
      }
    );
      this.props.history.push("list-lab-analysis");

  }


  delete_entry(id) {
    const deleteFunc = ExternalLabAnalysisService.deleteExternalLabAnalysis;
    deleteFunc(id).then(
      () => {
        this.setState(
          { entryList: this.state.entryList.filter(e => e._id.$oid != id) },
          () => {
            this.toggleModal(null)
          }
        );
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "Entry deleted successfully"
        );
      },
      () => {
        this.toggleModal();
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong"
        );
      }
    );
    this.props.history.push("list-lab-analysis");
  }

  render() {
    const isDataLoaded = this.state.isDataLoaded 
    ? <div className="text-center" >
      <img style={{width:"90px"}} className="img-thumbnail rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const listItemsAll = this.setup_searchOne();
    const modalWindow = (
      <Modal
        size="lg"
        open={this.state.open}
        style={{ marginTop: "55%" }}
        toggle={() => this.toggleModal(null)}
        className="h-75 d-flex align-items-center justify-content-center"
      >
        <h4 className="text-center p-5 w-100">
          Are you sure you want to delete External
        </h4>

        <div className="d-flex w-75 mx-auto justify-content-center">
          <Button
            theme="danger"
            onClick={() => this.toggleModal(null)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.delete_entry(this.state.entry._id.$oid)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Yes
          </Button>
        </div>
      </Modal>
    );

    const modalWindowForInHouse = (
      <Modal
        size="lg"
        open={this.state.openForInHouse}
        style={{ marginTop: "55%" }}
        toggle={() => this.toggleModalCancel(null)}
        className="h-75 d-flex align-items-center justify-content-center"
      >
        <h4 className="text-center p-5 w-100">
          Are you sure you want to delete InHouse Data
        </h4>

        <div className="d-flex w-75 mx-auto justify-content-center">
          <Button
            theme="danger"
            onClick={() => this.toggleModalCancel(null)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.delete_entry_InHouse(this.state.entry._id.$oid)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Yes
          </Button>
        </div>
      </Modal>
    );

    return (
      <div className=" mh-85" onClick={this.props.navToggle}>
        <ResponseMessage />
        <Container fluid className="main-content-container px-4">
          <Breadcrumb className="mt-3">
            <BreadcrumbItem> 
            <a onClick={() => this.props.history.push("/admin/plants/"+ this.props.match.params.plant_name+ "/overview/"+ localStorage.getItem('plant_id'))} style={{color:"#007bff", cursor:"pointer"}}> {new String(this.props.match.params.plant_name)}  Overview</a>
            </BreadcrumbItem>
            <BreadcrumbItem> 
              <a onClick={() => this.props.history.push("list-daily-process")} style={{color:"#007bff", cursor:"pointer"}}>List Daily Process</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              List Lab Analysis
            </BreadcrumbItem>
          </Breadcrumb>
          <Row
            noGutters
            className="page-header py-4 align-items-center justify-content-between"
          >
            <PageTitle
              sm="4"
              title= {new String(this.props.match.params.plant_name)}
              subtitle={this.props.page_sub_title}
              className="text-sm-left"
            />
          </Row>
          {modalWindow}
          {modalWindowForInHouse}
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr className="text-center">
                        <th scope="col" className="border-0">
                        </th>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Analysis Type
                        </th>
                        <th scope="col" className="border-0">
                          No. Of Load
                        </th>
                        <th scope="col" className="border-0">
                          Date
                        </th>
                       
                        <th scope="col" className="border-0" style={{width: "20%"}}>
                            Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listItemsAll}
                    </tbody>
                  </table>
                  {isDataLoaded}
                  <div style={{color:"Red"}} className="text-center" >
                    {this.state.errorMessage}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  // This method is used to show load status in list
  showLoadStatus(item){
    if(item.analysis_type=='Inhouse'){    
      return item.srs_labs.length;
    }else if(item.analysis_type=="External"){
      return 'NA'
    }
  } 

  // this method is used to exclamation icon for each list
  showExclamation(item){
    if(item.status){
      return;
    }else if (!item.status){
      return (
        <FontAwesomeIcon 
          icon={faExclamationTriangle}
          size="1x"      
          />
        )
      }
    }

    //This method is used to display list in table view
    setup_searchOne(){
      let rearrangedEntryList = [] ;
      rearrangedEntryList.push(...this.state.entryList);
      return rearrangedEntryList.map((item, i) => {
        return(
          <tr className="text-center" key={i} id={item.id}>
            <td style={{verticalAlign: "middle"}} >
              <div style={{color: "red"}} data-tip="Please fill all the field">
                {this.showExclamation(item)}
              </div>
              <ReactTooltip/>
            </td>
            <td style={{verticalAlign: "middle"}}>{i+1}</td>
            <td style={{verticalAlign: "middle"}}>{item.analysis_type}</td>
            {/* <td>{item.process_detail_id.$oid}</td> */}
            <td style={{verticalAlign: "middle"}}>{this.showLoadStatus(item)}</td>
            <td style={{verticalAlign: "middle"}}>{
                item.date
                }
              </td>
            <td>
            {this.props.permission.edit == "YES" ? (
            <Button
              style={{
                borderRadius: "50px",
                backgroundColor: "white",
                border: "none"
              }}
              onClick={() => this.toggleModalForInhouse(item)}
              className="hover-blue">
              <MaterialIcon icon="delete_outline" />
            </Button>
             ): (
              ""
            )}
            {this.props.permission.edit == "YES" ? (
            <Button
              style={{
                borderRadius: "50px",
                backgroundColor: "white",
                border: "none"
              }}
              onClick={() => this.edit_entry(item)}
              className="hover-blue ml-2">
              <MaterialIcon  id={item.id} icon="edit" />
            </Button>
            ): (
              ""
            )}
            </td>
          </tr>
        )
      }); 
    }
}

function mapStateToProps(state) {
  return {
    search_key: state.AppState.search_key,
    all_data: state.AppState.labAnalysisInHouse,
    all_data_external: state.AppState.labAnalysisExternal,
    permission: state.AppState.permission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { navbarActions, saveEditSession, displayResponseMessage, 
        deleteEditSession, deleteEditSessionInHouse },
      dispatch
    )
  };
}

ListLabAnalysis.propTypes = {
  page_title: string.isRequired,
  page_sub_title: string.isRequired,
  edit_entry_link: string.isRequired,
  add_entry_link: string.isRequired,
  colDef: object.isRequired,
  entryList: object,
  collection_name: string.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
  delete_action_type: string.isRequired,
  navToggle: func.isRequired,
  search_key: string.isRequired,
  all_data: object.isRequired,
  all_data_external: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListLabAnalysis)
);
