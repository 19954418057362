import React from "react";
import { Nav } from "shards-react";

// import Notifications from "./Notifications";
import UserActions from "./UserActions";
import { func } from "prop-types";

export const NavbarNav = ({ toggleActions }) => (
  <Nav navbar className="border-left flex-row">
    {/* <Notifications toggleNotifications={toggleActions} /> */}
    <UserActions toggleUserActions={toggleActions} />
  </Nav>
);

NavbarNav.propTypes = {
  toggleActions: func.isRequired
};
