export const SeptageReceived = (
    septage_type = null,
    vehical_number = null,
    no_of_trips = null,
    quantity_of_septage = null,
    uploaded_to_tank_num = null,
  ) => {
    return {
      septage_type,
        vehical_number,
        no_of_trips,
        quantity_of_septage,
        uploaded_to_tank_num
    };
  };
  export const SeptageReceivedValidation = {
    septage_type: {},
    vehical_number: {},
    no_of_trips: {},
    quantity_of_septage: {},
    uploaded_to_tank_num: {}
  };
  
  
  
  export default SeptageReceived(
  );