

export const DrierInput = (
    ts = ""
  ) => {
    return {
      ts
    };
  };
  
  
  export default DrierInput(
    "10",
    "20",
    "30"
  );
  