import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Card, CardBody } from "shards-react";
import { withRouter } from "react-router";
import { object, func } from "prop-types";

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let dashboardTemplate = "";
    if (this.props.current_user) {
      dashboardTemplate = this.props.current_user.plants.map((p, i) => {
        //console.log(p.plant_id)
        return (
          <div key={i} className="col-lg-4 mb-5">
            <Card
              className="mt-5 h-100 py-5 cursor-pointer"
              onClick={() =>
                this.props.history.push("plants/" + p.plant_name + "/overview/" +p.plant_id)
              }
            >
              <CardBody className="bg-white border-0 py-3 d-flex align-items-center justify-content-center">
                <h1 className="text-dark py-5 text-center"> {p.plant_name} </h1>
              </CardBody>
            </Card>
          </div>
        );
      });
    }

    return (
      <div className="mh-85 w-100" onClick={this.props.navToggle}>
        <Row className="d-flex col-10 mx-auto flex-wrap align-content-stretch pb-5">
          {dashboardTemplate}
          <div className="col-lg-4 mb-5">
            <Card 
              className="mt-5 h-100 py-5 cursor-pointer"
              onClick={() =>
                this.props.history.push("/admin/add-plant")               
              }
            >
              <CardBody style={{fontSize: "156px", color:"#D3D3D3"}} className="bg-white border-0 py-3 d-flex align-items-center justify-content-center">
                <i className="material-icons">
                  add_circle_outline
                </i>  
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    );
  }
}

AdminDashboard.propTypes = {
  current_user: object,
  history: object.isRequired,
  navToggle: func.isRequired
};

function mapStateToProps(state) {
  return {
    current_user: state.AppState.current_user
  };
}

export default connect(mapStateToProps)(withRouter(AdminDashboard));
