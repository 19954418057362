import React from "react";
import WrangalDailyProcessFormTemplate from "../../../containers/WrangalPlant/WrangalDailyProcessFormTemplate";

import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const EditDailyProcessData = ({ navToggle }) => {
  return (
    <WrangalDailyProcessFormTemplate
      action={types.EDIT_DAILY_PROCESS}
      form_title={"Daily Process Data"}
      navToggle={navToggle}
      button_title={"Update Daily Process Data"}
    />
  );
};

EditDailyProcessData.propTypes = {
  navToggle: func.isRequired
};

export default EditDailyProcessData;
