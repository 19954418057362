import React, { Component } from "react";
import { number, string, oneOfType, object, func } from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  FormFeedback,
  Button
} from "shards-react";
import queryString from "query-string";
import * as authService from "../../../services/AuthService";
import { chain_validations } from "../../common/Validations";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordObj: {
        username: "",
        password: "",
        password_confirmation: ""
      },
      validations: {
        password: {},
        password_confirmation: {}
      },
      allowValidations: false,
      submitted: false
    };
    const values = queryString.parse(this.props.location.search);
    this.state.invitation_token = values.invitation_token;
    this.state.reset_password_token = values.reset_password_token;
    this.onChange = this.onChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  onChange(e) {
    const passwordObj = {
      ...this.state.passwordObj,
      [e.target.name]: e.target.value
    };
    this.setState({ passwordObj }, this.validateForm);
  }

  validateForm = () => {
    const tests = {
      password: chain_validations(["is_empty"], {
        input: this.state.passwordObj.password
      }),
      password_confirmation: chain_validations(["is_empty"], {
        input: this.state.passwordObj.password_confirmation
      })
    };
    const conformityTest =
      this.state.passwordObj.password ==
      this.state.passwordObj.password_confirmation;
    this.setState({
      validations: {
        password: {
          invalid:
            this.state.allowValidations &&
            (!conformityTest || tests.password.is_empty),
          message: !conformityTest
            ? "Entered password is not same as confirmation password"
            : "Please enter a password"
        },
        password_confirmation: {
          invalid:
            this.state.allowValidations &&
            (!conformityTest || tests.password_confirmation.is_empty),
          message: !conformityTest
            ? "Confirmed password is not same as entered password"
            : "Please confirm the new password"
        }
      }
    });
    let validations = [];
    if (tests.password.is_empty) {
      validations.push("password");
    }
    if (tests.password_confirmation.is_empty) {
      validations.push("password_confirmation");
    }
    return validations;
  };

  resetPassword(e) {
    e.preventDefault();
    this.setState({ submitted: true, allowValidations: true }, () => {
      if (this.validateForm().length == 0) {
        const passwordObj = this.state.passwordObj;
        const submit_func = this.state.invitation_token
          ? authService.acceptInvite
          : authService.resetPassword;
        submit_func(
          this.state.invitation_token
            ? this.state.invitation_token
            : this.state.reset_password_token,
          passwordObj
        ).then(res => {
          if (res.success) this.props.history.push("/");
        });
      }
    });
  }

  render() {
    const button = this.state.submitted ? (
      <img
        className="pull-right"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
    ) : (
      "Reset Password"
    );
    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <Card className="col-5 mb-5 p-0">
          <CardHeader className="bg-white border-bottom">
            <h4 className="text-dark">Reset Password</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.resetPassword}>
              <div>
                <label>New Password</label>
                <FormInput
                  name="password"
                  type="password"
                  value={this.state.passwordObj.password}
                  onChange={this.onChange}
                  {...this.state.validations.password}
                />
                <FormFeedback {...this.state.validations.password}>
                  {this.state.validations.password.message}
                </FormFeedback>
              </div>
              <div className="mt-4">
                <label>Confirm New Password</label>
                <FormInput
                  name="password_confirmation"
                  type="password"
                  value={this.state.passwordObj.password_confirmation}
                  onChange={this.onChange}
                  {...this.state.validations.password_confirmation}
                />
                <FormFeedback {...this.state.validations.password_confirmation}>
                  {this.state.validations.password_confirmation.message}
                </FormFeedback>
              </div>
              <div className="mt-4 d-flex align-items-center justify-content-center">
                <Button className=" px-5"> {button} </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  password: oneOfType([number, string]),
  password_confirmation: oneOfType([number, string]),
  history: object.isRequired,
  location: object.isRequired,
  resetPassword: func.isRequired
};

export default ResetPassword;
