import { validity_msg, chain_validations } from "../../common/Validations";

export const validate = (name, input) => {
  let tests = {};
  let validations = [];
  switch (name) {
    case "plant_name":
      validations = ["is_empty", "is_all_alphabet"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return validity_msg(!tests.is_empty, "Plant Name Required");
      }
      return validity_msg(
        tests.is_all_alphabet,
        tests.is_all_alphabet ? "Looks Good !" : "Only Alphabets allowed"
      );

    case "capacity":
      validations = ["is_empty"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return {
          valid: false,
          invalid: false,
          message: "Please enter the capacity of the plant"
        };
      }
      return { valid: true, invalid: false, message: "" };

    case "contactPerson":
      validations = ["is_empty", "is_all_alphabet"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return validity_msg(!tests.is_empty, "Contact Person Name Required");
      }
      return validity_msg(
        tests.is_all_alphabet,
        tests.is_all_alphabet ? "Looks Good !" : "Only Alphabets allowed"
      );

    case "email":
      validations = ["is_empty", "email_valid"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return validity_msg(!tests.is_empty, "Email Required");
      }
      return validity_msg(
        tests.email_valid,
        tests.email_valid ? "Looks Good !" : "Please enter a valid email"
      );

    case "countryCode":
      validations = ["is_empty", "country_code_valid"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return validity_msg(!tests.is_empty, "Country Code Required");
      }
      return validity_msg(
        tests.country_code_valid,
        tests.country_code_valid
          ? "Looks Good !"
          : "Please enter a valid country code"
      );

    case "phone":
      validations = ["is_empty", "phone_valid"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return validity_msg(!tests.is_empty, "Phone Number Required");
      }
      return validity_msg(
        tests.phone_valid,
        tests.phone_valid ? "Looks Good !" : "Please enter 10 digit number"
      );

    case "address":
      validations = ["is_empty"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return { valid: false, invalid: false, message: "" };
      }
      return { valid: true, invalid: false, message: "" };

    case "organization":
      validations = ["is_empty"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return { valid: false, invalid: false, message: "" };
      }
      return { valid: true, invalid: false, message: "" };

    case "city":
    case "state":
    case "country":
      validations = ["is_empty", "is_all_alphabet"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return { valid: false, invalid: false, message: "" };
      }
      return validity_msg(
        tests.is_all_alphabet,
        tests.is_all_alphabet ? "Looks Good !" : "Only Alphabets allowed"
      );

    case "zip":
      validations = ["is_empty", "is_all_numeric"];
      tests = chain_validations(validations, { input });
      if (tests.is_empty) {
        return validity_msg(!tests.is_empty, "Pin Code Required");
      }
      return validity_msg(
        tests.is_all_numeric,
        tests.is_all_numeric ? "Looks Good !" : "Please enter a valid pin code"
      );

    default:
      return false;
  }
};
