import React, { Component } from "react";
import { withRouter } from "react-router";
import {Line} from 'react-chartjs-2'
import * as reportService from "../../../../../services/ReportService";
import logo from '../../../../assets/Spinner-1s-200px.gif';
import {  object } from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv,faFileExcel,faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Card,
  FormInput,
  Button,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  Progress
} from "shards-react";

class PlantOperationGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData:{},
      chartData2:{},
      chartData3:{},
      chartData4:{},
      chartData5:{},
      open: false,
      modalDiv: null,
      labels: [],
      startDate:  Date(),
      endDate: Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      isGraphView: false,
      pyrolyzer: [],
      power_consumed: [],
      treated_septage: [],
      treated_water_taken: [],
      qty_of_solids_generated: [],
      entryListForTableRes:{},
      isDataLoaded: false,
      validationError: "",
      dataNotFoundMessaage: "",
      response: [],
      isGraphRender: '',
      isCSVData: false,



    };
    this.on_change = this.on_change.bind(this);

  
  }

  componentDidMount(){
    this.setState({
      startDate: null,
      endDate: null  
    })
  }
 

  on_change(e) {
    this.setState({
     [e.target.name]: [e.target.value],
     validationError:  e.target.value != "" 
     ? "" :  e.target.name === "startDate" ? " Please Select the start date" : e.target.name === "endDate" ?  "Please select the end date" : "",

    }); 
  }

  getChartData(){
    // Ajax calls here
    this.setState({
      chartData:{
        labels: this.state.labels,
        datasets:[
          {
            label:'Pyrolyzer Run Time',
            data:this.state.pyrolyzer,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }


  getChartData2(){
    // Ajax calls here
    this.setState({
      chartData2:{
        labels: this.state.labels,
        datasets:[
          {
            label:'Power Consumed',
            data:this.state.power_consumed,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  getChartData3(){
    // Ajax calls here
    this.setState({
      chartData3:{
        labels: this.state.labels,
        datasets:[
          {
            label:'Septage Treated',
            data:this.state.treated_septage,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  getChartData4(){
    // Ajax calls here
    this.setState({
      chartData4:{
        labels:this.state.labels,
        datasets:[
          {
            label:'Treated Waterr',
            data:this.state.treated_water_taken,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  getChartData5(){
    // Ajax calls here
    this.setState({
      chartData5:{
        labels: this.state.labels,
        datasets:[
          {
            label:'Solid Generated',
            data:this.state.qty_of_solids_generated,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  checkValidation(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
      //console.log('Validation Failed')
      if(startDate == null){
        this.setState ({
          validationError: "Please Select the start date first"
        }); 
      }else if(endDate == null){
        this.setState({
          validationError: "Please Select the end date"
        }); 
      }
      return false;
    }else{
      return true;
    }
  }




  setGraphViewVisible(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
        if(startDate == null){
          this.setState ({
            validationError: "Please Select the start date first"
          }); 
        }else if(endDate == null){
          this.setState({
            validationError: "Please Select the end date"
          }); 
        }
    }else{
      this.getChartDataAll();
      this.setState({
        dataNotFoundMessaage:"",
        isDataLoaded: true
      })
    }

    // if(this.state.isGraphView){
    //  this.getChartDataAll();
    // }
  }
  getChartDataAll(){

    if(this.checkValidation()){
      this.setState({
        dataNotFoundMessaage:"",
        isDataLoaded: true,
        isGraphRender: ''
      })


    


    
    let data;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    const plant_id = localStorage.getItem("plant_id")
    //let PlantIO=this.state.tabledata;
    const listTableData = reportService.getPlantOperationDataGraph;    
     listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
       data = entryListForTableRes;
       //console.log(data);
       this.setState({
        labels: [],
        SeptageReceivedHH: [],
        SeptageReceivedCTPT: [],
        TreatedWater: [],
        BioChar:[],
        dataNotFoundMessaage:"",
        isGraphView: true,
        isGraphRender: ''
  
      });
      data.sort(function(a,b){
        var c = new Date(a.date);
        var d = new Date(b.date);        
        return c-d;
        });
      data.map((item) => {
        let current_datetime = new Date(item.date)
        let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1)
        this.state.labels.push(formatted_date);
        let power_consumed = item.metering.energy_meter_final - item.metering.energy_meter_initial;
        let treated_septage = item.metering.treated_water_taken + item.dewaterted_solids_handel.qty_of_solids_generated;
        this.state.pyrolyzer.push(item.hours_hh_septage.pyrolyzer);
        this.state.power_consumed.push(power_consumed);
        this.state.treated_septage.push(treated_septage);
        this.state.treated_water_taken.push(item.metering.treated_water_taken);
        this.state.qty_of_solids_generated.push(item.dewaterted_solids_handel.qty_of_solids_generated);        
      });      
      this.getChartData();
      this.getChartData2();
      this.getChartData3();
      this.getChartData4();
      this.getChartData5();
      this.setState({
        isDataLoaded: false
      })


     },(error)=>{
      //console.log(JSON.stringify(error));
      this.setState({
       isDataLoaded:false,
       isGraphView:false,
      })
      let message  = error.response.data.message;
      this.setState({
        dataNotFoundMessaage: message
      })

    });
    
  }
  }

  //Download progress modal
  modal() {
    return(
      <Modal 
        style={{ marginTop: "150px"}}
        open={this.state.isCSVData}
        className="h-75 d-flex align-items-center justify-content-center modal-width">
        <ModalBody >
        <div>
          <div>
          <Progress animated theme="primary" value={100} />
          </div>
          <div className="modal-body-name" style= {{ marginTop: "15px", textAlign: "center"}}>
            Exporting {this.selectedReportType} Report...
          </div>
          <div className="modal-body-message"style= {{textAlign: "center"}}>
           Just a moment Please
          </div>
          {/* <div className="modal-body-discard"style= {{textAlign: "center"}}>
            Discard
          </div> */}
        </div>
        </ModalBody>
      </Modal>
    );
  }

  dateFormat(date){
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + (current_datetime.getFullYear());
    return formatted_date;
  }
  getFileName(fileType =  String){
    let startDate = this.state.startDate;
    let endDate= this.state.endDate;
    let fileName = "Plant_Operation_Report_" + new String(this.props.match.params.plant_name) +"_"+ startDate+"_" +endDate+"."+fileType;
    return fileName;

  }

    //Method is used to download report in the from as CSV
    downloadReportAsCSV(){
      this.selectedReportType = "CSV"
      if(this.checkValidation()){
      this.setState({
        isCSVData : true
      })
      //console.log('downloadReportAsCSV');
      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getPlantOperationDataGraph;
      
       listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
         data = entryListForTableRes;  
         data.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
  
          let csvData = [];
          for(let i=0;i<data.length;i++){
            let a  = {};
            a = {
              Date: this.dateFormat(data[i].date),
              pyrolyzer:   data[i].hours_hh_septage.pyrolyzer,
              treated_water_taken: data[i].metering.treated_water_taken,
              power_consumed: data[i].metering.energy_meter_final - data[i].metering.energy_meter_initial,
              treated_septage: data[i].metering.treated_water_taken + data[i].dewaterted_solids_handel.qty_of_solids_generated,
              qty_of_solids_generated: data[i].dewaterted_solids_handel.qty_of_solids_generated
              
            }
            csvData.push(a);
          }
          this.setState({
            response:csvData
          }, () => {
            this.csvLink.link.click();
            this.setState({
              isCSVData : false
            })
         })      
        this.setState({
          isDataLoaded: false
        })
       },
       (error) =>{
         this.setState({
          isDataLoaded:false,
          isGraphView:false,
          isCSVData : false
         })
         let message  = error.response.data.message;
         this.setState({
           dataNotFoundMessaage: message
         })
       }
      );
    }
  }

      //Method is used to download report in the from as CSV
      downloadReportAsExcel(){
        this.selectedReportType = "EXCEL"
        if(this.checkValidation()){
        this.setState({
          isCSVData : true
        })
        //console.log('downloadReportAsCSV');
        let data;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        const plant_id = localStorage.getItem("plant_id")
        const listTableData = reportService.getPlantOperationDataGraph;
        
         listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
           data = entryListForTableRes;  
           data.sort(function(a,b){
            var c = new Date(a.date);
            var d = new Date(b.date);        
            return c-d;
            });
    
            let csvData = [];
            for(let i=0;i<data.length;i++){
              let a  = {};
              a = {
                Date: this.dateFormat(data[i].date),
                pyrolyzer:   data[i].hours_hh_septage.pyrolyzer,
                treated_water_taken: data[i].metering.treated_water_taken,
                power_consumed: data[i].metering.energy_meter_final - data[i].metering.energy_meter_initial,
                treated_septage: data[i].metering.treated_water_taken + data[i].dewaterted_solids_handel.qty_of_solids_generated,
                qty_of_solids_generated: data[i].dewaterted_solids_handel.qty_of_solids_generated
                
              }
              csvData.push(a);
            }
            this.setState({
              response:csvData
            }, () => {
              this.csvLinkForExcel.link.click();
              this.setState({
                isCSVData : false
              })
           })      
          this.setState({
            isDataLoaded: false
          })
         },
         (error) =>{
           this.setState({
            isDataLoaded:false,
            isGraphView:false,
            isCSVData : false
           })
           let message  = error.response.data.message;
           this.setState({
             dataNotFoundMessaage: message
           })
         }
        );
      }
    }

    downloadReportAsPdf(){
      this.selectedReportType = "PDF"
      if(this.checkValidation()){
        if(this.state.isGraphView){
          this.setState({
            isCSVData : true
          })
  
    
      var pdf = new jsPDF("p", "mm", "a4");
      var totalPagesExp = "{total_pages_count_string}";
  
      var header = function(data) {
        pdf.setFontSize(10);
        pdf.setTextColor(40);
        pdf.setFontStyle('normal');
        //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
        //var pdfTitle = this.drPolicy.name + "Report" +" (Generated on "+ this.getDateinFormat(new Date())+")"
        pdf.text('Plant Operation Report', data.settings.margin.left, 20);
            // FOOTER
            var str = "Page " + data.pageCount;
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === 'function') {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);
            pdf.text(str, data.settings.margin.left, pdf.internal.pageSize.height - 10);
        };
  
        var options = {
          addPageContent: header,
          margin: {
            top: 30
          },
  
        };
        var canvas = document.getElementById('pyrolyzer');
        var pyrolyzer = canvas.toDataURL();
        var canvas2 = document.getElementById('power');
        var power = canvas2.toDataURL();
        var canvas3 = document.getElementById('treatedWater');
        var treatedWater = canvas3.toDataURL();
        var canvas4 = document.getElementById('solid');
        var solid= canvas4.toDataURL();
        var canvas5 = document.getElementById('septage');
        var septage= canvas5.toDataURL();
  
        let csvData = [];
        const head = ["Date", "Pyrolyzer Run Time(Hour)", "Power Consumed(KWatts)", "Treated Water(Ltrs)","Solid Generated(Kgs)","Septage Treated(Ltrs)"]
  
        let data;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        const plant_id = localStorage.getItem("plant_id")
        const listTableData = reportService.getPlantOperationDataGraph;
        
        listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
          data = entryListForTableRes;  
          data.sort(function(a,b){
            var c = new Date(a.date);
            var d = new Date(b.date);        
            return c-d;
            });
            for(let i=0;i<data.length;i++){
              let a  = [];
              a.push(this.dateFormat(data[i].date))
              a.push(data[i].hours_hh_septage.pyrolyzer)
              a.push(data[i].metering.energy_meter_final - data[i].metering.energy_meter_initial)
              a.push(data[i].metering.treated_water_taken)
              a.push(data[i].dewaterted_solids_handel.qty_of_solids_generated)
              a.push(data[i].metering.treated_water_taken + data[i].dewaterted_solids_handel.qty_of_solids_generated)
              csvData.push(a)         
            }
            this.setState({
              response:csvData
            }, () => {
              pdf.autoTable(head, this.state.response, options);
              pdf.addPage();
        
              //It is used to add image
              // let startImageSize = pdf.lastAutoTable.finalY;
            
              pdf.text('Pyrolyzer Run Time(Hour)', 15, 20);
              pdf.addImage(pyrolyzer, 'JPEG', 15,  30, 160, 80);
        
              pdf.addPage();
              pdf.text('Power Consumed(KWatts)', 15, 20);
              pdf.addImage(power, 'JPEG', 15, 30,160, 80);
              // pdf.addPage();
              pdf.addPage();
              pdf.text('Treated Water(Ltrs)', 15, 20);
              pdf.addImage(treatedWater, 'JPEG', 15, 30, 160, 80);
        
              pdf.addPage();
              pdf.text('Solid Generated(Kgs)', 15, 20);
              pdf.addImage(solid, 'JPEG', 15, 30, 160, 80);

              pdf.addPage();
              pdf.text('Septage Treated(Ltrs)', 15, 20);
              pdf.addImage(septage, 'JPEG', 15, 30, 160, 80);
            // let startImageSizee = pdf.lastAutoTable.finalY;    
        
        
              if (typeof pdf.putTotalPages === 'function') {
                pdf.putTotalPages(totalPagesExp);
              }
              pdf.save(this.getFileName('pdf'));
            
              this.setState({
                isCSVData : false
              })
          })      
          this.setState({
            isDataLoaded: false
          })
        },
        (error) =>{
          this.setState({
            isDataLoaded:false,
            isGraphView:false,
            isCSVData : false
          })
          let message  = error.response.data.message;
          this.setState({
            dataNotFoundMessaage: message
          })
          }     
        );
      }else{
        this.setState({
          isGraphRender: 'Please Show Graph First'
        })
       }
      }
    }






  


 
  render() {
    const generatingReport = this.modal();
    let headers = [
      { label: "Date", key: "Date" },
      { label: "Pyrolyzer", key: "pyrolyzer" },
      { label: "Treated Water Taken", key: "treated_water_taken" },
      { label: "Power Consumed", key: "power_consumed" },
      { label: "Septage Treated", key: "treated_septage" },
      { label: "Qty Of Solids Generated", key: "qty_of_solids_generated" }
    ];
    const isDataLoaded = this.state.isDataLoaded 
    ? <div className="text-center" >
      <img style={{width:"45px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const graphicalView = this.state.isGraphView;
    return (
        <div>
           <Row className="py-2">
            <Col className="d-flex mb-2">     
              <FormInput    
                name = "startDate"
                type="date"
                value = {
                  this.state.startDate
                }
                onChange={this.on_change}
              />
              <FormInput    
                name = "endDate"
                type="date"
                value = {
                  this.state.endDate
                }
                onChange={this.on_change}
              />
              <Button style={{marginRight:"29px"}}
              onClick={(e) => this.setGraphViewVisible(e)}>
                Show          
              </Button>
              {/* <Row className="py-2">
                <Col  className="">
                <div style={{color:"Red"}}>
                  {this.state.dataNotFoundMessaage}
                </div> 
                </Col>
              </Row>  */}
              {isDataLoaded}
            </Col>
            <Col >
            {this.props.permission.export == "YES" ? (
            <div className="float-right d-flex ">
              <h6 className="m-0" style={{padding : "10px"}}>Export As</h6>
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"#007bff"

                  }}
                 onClick={() => this.downloadReportAsCSV()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFileCsv} size="2x" />
                  
              </Button>
              <CSVLink 
                data={this.state.response}
                asyncOnClick={true}
                filename={this.getFileName('csv')}
                headers={headers}
                ref={(r) => this.csvLink = r}
                // onClick={() => this.downloadReportAsCSV()}
              
              >
                  </CSVLink>
                  {generatingReport}
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"green"

                  }}
                  onClick={() => this.downloadReportAsExcel()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFileExcel} size="2x" />
              </Button>
              <CSVLink 
                data={this.state.response}
                asyncOnClick={true}
                filename={this.getFileName('xlsx')}
                headers={headers}
                ref={(r) => this.csvLinkForExcel = r}
                // onClick={() => this.downloadReportAsCSV()}
              
              >
                  </CSVLink>
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"red"

                  }}
                  onClick={() => this.downloadReportAsPdf()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFilePdf} size="2x" />
              </Button>
            </div> 
            ): (
              ""
            )}
            </Col>
          </Row>
          <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
          </Row>
          <Row className="py-2">
      <Col  className="">
      <div style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
      </div> 
      </Col>
    </Row> 
    <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.isGraphRender}
            </div> 
            </Col>
          </Row>
          <div>
          {graphicalView ? (
          <Row>
            <div className="mx-auto mb-4 col-lg-6">
              <Card small className="h-100">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Pyrolyzer Run Time</h6>
                </CardHeader>
                <CardBody className="pt-0"  style={{marginTop: "20px"}}>
                  <Line
                  id="pyrolyzer"
                      data={this.state.chartData}
                      width={100}
                      height={50}
                      options={{
                        legend : {
                          display: false
                      }          ,
                      scales : {
                        yAxes : [{
                          scaleLabel: {
                            display : true,
                            labelString :  'Hours'
                          }
                        }],
                        xAxes : [{
                          scaleLabel :{
                            display: true,
                            labelString: 'Date'
                          }
                        }],
                      } 
                      }  
                      }
                    />
                </CardBody>
              </Card>
            </div>
            <div className="mx-auto mb-4 col-lg-6">
              <Card small className="h-100">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Power Consumed</h6>
                </CardHeader>
                <CardBody className="pt-0"  style={{marginTop: "20px"}}>

                  <Line
                  id="power"
                      data={this.state.chartData2}
                      width={100}
                      height={50}
                      options={{
                        legend : {
                          display: false
                      }          ,
                      scales : {
                        yAxes : [{
                          scaleLabel: {
                            display : true,
                            labelString :  'KWatts'
                          }
                        }],
                        xAxes : [{
                          scaleLabel :{
                            display: true,
                            labelString: 'Date'
                          }
                        }],
                      } 
                      }  
                      }
                    />
                </CardBody>
              </Card>
            </div>
            <div className="mx-auto mb-4 col-lg-6">
              <Card small className="h-100">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Septage Treated</h6>
                </CardHeader>
                <CardBody className="pt-0"  style={{marginTop: "20px"}}>
                  <Line
                  id="septage"
                      data={this.state.chartData3}
                      width={100}
                      height={50}
                      options={{
                        legend : {
                          display: false
                      }          ,
                      scales : {
                        yAxes : [{
                          scaleLabel: {
                            display : true,
                            labelString :  'Litres'
                          }
                        }],
                        xAxes : [{
                          scaleLabel :{
                            display: true,
                            labelString: 'Date'
                          }
                        }],
                      } 
                      }  
                      }
                    />
                </CardBody>
              </Card>
            </div>
            <div className="mx-auto mb-4 col-lg-6">
              <Card small className="h-100">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Treated Water</h6>
                </CardHeader>
                <CardBody className="pt-0"  style={{marginTop: "20px"}}>
                  <Line
                  id="treatedWater"
                      data={this.state.chartData4}
                      width={100}
                      height={50}
                      options={{
                        legend : {
                          display: false
                      }          ,
                      scales : {
                        yAxes : [{
                          scaleLabel: {
                            display : true,
                            labelString :  'Litres'
                          }
                        }],
                        xAxes : [{
                          scaleLabel :{
                            display: true,
                            labelString: 'Date'
                          }
                        }],
                      } 
                      }  
                      }
                    />
                </CardBody>
              </Card>
            </div>
            <div className="mx-auto mb-4 col-lg-6">
              <Card small className="h-100">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Solid Generated</h6>
                </CardHeader>
                <CardBody className="pt-0"  style={{marginTop: "20px"}}>
                  <Line
                  id="solid"
                      data={this.state.chartData5}
                      width={100}
                      height={50}
                      options={{
                        legend : {
                          display: false
                      }          ,
                      scales : {
                        yAxes : [{
                          scaleLabel: {
                            display : true,
                            labelString :  'Kgs'
                          }
                        }],
                        xAxes : [{
                          scaleLabel :{
                            display: true,
                            labelString: 'Date'
                          }
                        }],
                      } 
                      }  
                      }
                    />
                </CardBody>
              </Card>
            </div>
          </Row>
           ) : (
            <h1></h1>
          )}
          </div>
        </div>
    );
  }
}

PlantOperationGraph.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired,
};
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,

  };
}

// export default withRouter(PlantOperationGraph);
export default withRouter(
  connect(
    mapStateToProps
  )(PlantOperationGraph)
);