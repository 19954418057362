import { SRS } from './SRS';
import { HoldingTank } from './HoldingTank';
import {KKBRInlet} from './KKBRInlet';
import {KKBROutlet} from './KKBROutlet';
import { DewartedSolid} from './DewartedSolid';
import {PhytoridOutlet} from './PhytoridOutlet';
import {DrierInput} from './DrierInput';
import { DrierOutput} from './DrierOutput';
import { TreatedWater } from './TreatedWater';
import { Filtrate } from './Filtrate';

import uuid from 'uuid';

export const InHouse = (
    id = uuid(),
    // AnalysisType = "InHouse",
    plant_id = "",
    user_id = "",
    process_detail_id = "",
    date =  Date(),
    observations = "",
    srs_labs = [new SRS()],
    holding_tank = new HoldingTank(),
    kkbr1_outlet = new KKBRInlet(),
    kkbr2_outlet = new KKBROutlet(),
    dewaterted_solid = new DewartedSolid(),
    phytorid_outlet = new PhytoridOutlet(),
    drier_input = new DrierInput(),
    drier_output = new DrierOutput(),
    treated_water = new TreatedWater(),
    filtrate = new Filtrate()
) => {
    return {
        id,
        process_detail_id,
        // AnalysisType,
        plant_id,
        user_id,
        date,
        observations,
        srs_labs,
        holding_tank,
        kkbr1_outlet,
        kkbr2_outlet,
        dewaterted_solid,
        phytorid_outlet,
        drier_input,
        drier_output,
        treated_water,
        filtrate
    };
};


export default InHouse();