import React from "react";
import ListLabAnalysisDataTemplate from "../../../containers/WrangalPlant/ListLabAnalysisTemplate";

// import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const ListLabAnalysisData = ({ navToggle }) => {
  return (
    <ListLabAnalysisDataTemplate
      // collection_name={"plants"}
      // delete_action_type={types.DELETE_PLANT}
      navToggle={navToggle}
      page_sub_title={"List Of Lab Analysis data"}
      page_title={"Wrangal Plant"}
      colDef={{
        Analysis_Type: "",
        ProcessID: "",
        No_Of_Load: "",
        Date: ""
      }}
     
    />
  );
};

ListLabAnalysisData.propTypes = {
  navToggle: func.isRequired
};

export default ListLabAnalysisData;
