import React, { Component } from "react";
import { number, string, oneOfType, object } from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  FormFeedback,
  Button
} from "shards-react";
import { connect } from "react-redux";
import {
  saveAuthToken,
  saveCurrentUser,
  displayResponseMessage,
  saveRoleMapping
} from "../../../actions/AppStateActions";
import { bindActionCreators } from "redux";
import * as authService from "../../../services/AuthService";
import * as userService from "../../../services/UserService";
import * as plantService from "../../../services/PlantService";
import { getPlantByUserId } from "../../../services/UserPlantsService";
import ResponseMessage from "../../common/ResponseMessage";
import { chain_validations } from "../../common/Validations";

class Login extends Component {
  componentDidMount() {
    //After successfull login, if you press the back button
    //It will throw to the dashboard page
    // if (this.props.token) {
    //   this.props.history.push(
    //     (this.props.current_user.designation == "Admin"
    //       ? "/admin/"
    //       : "/") + "dashboard"
    //   )
    // }
  }
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: ""
      },
      validations: {
        email: {},
        password: {}
      },
      submitted: false,
      allowValidations: false,
      conversations: { test: "test" }
    };
  }

  validateForm = () => {
    const tests = {
      email: chain_validations(["is_empty", "email_valid"], {
        input: this.state.user.email
      }),
      password: chain_validations(["is_empty"], {
        input: this.state.user.password
      })
    };
    this.setState({
      validations: {
        email: {
          invalid:
            this.state.allowValidations &&
            (tests.email.is_empty || !tests.email.email_valid),
          message: "Please enter a valid email"
        },
        password: {
          invalid: this.state.allowValidations && tests.password.is_empty,
          message: "Password is Required"
        }
      }
    });
    let validations = [];
    if (tests.email.is_empty || !tests.email.email_valid) {
      validations.push("email");
    }
    if (tests.password.is_empty) {
      validations.push("password");
    }
    return validations;
  };

  onChange = e => {
    const user = { ...this.state.user, [e.target.name]: e.target.value };
    this.setState({ user }, this.validateForm);
  };

  login = e => {
    e.preventDefault();
    this.setState({ submitted: true, allowValidations: true }, () => {
      if (this.validateForm().length == 0) {
        authService.authenticate({ user: this.state.user }).then(
          response => {
            //console.log(response)
            this.props.actions.saveAuthToken(response.authToken);
            userService.getUserByUserId(response.id).then(res => {
              //console.log(res.role);
              this.props.actions.saveRoleMapping(res.role);
              getPlantByUserId(response.id).then(plantRes => {
                if (res.designation == "Admin") {
                  localStorage.setItem("currentUserdesignation", res.designation);
                  plantService.getPlants().then(plants => {
                    this.props.actions.saveCurrentUser({
                      ...res,
                      organization: "",
                      plants
                    });
                    this.props.history.push("/admin/dashboard");
                  });
                } else {
                  localStorage.setItem("currentUserdesignation", res.designation);
                  plantRes = plantRes ? plantRes : [];
                  const finalPlants = [];
                  plantRes.forEach((plant, i) => {

                    plantService
                      .getPlantByMasterId(plant.plant_id)

                      .then(plant_detail => {
                        //console.log(plant_detail);
                        
                        finalPlants.push(plant_detail);
                        if (i == plantRes.length - 1) {
                          this.props.actions.saveCurrentUser({
                            ...res,
                            plants: finalPlants
                          });
                          //console.log(finalPlants);
                          
                          this.props.history.push("/dashboard");
                        }
                      });
                  });
                }
              });
            });
          },
          () => {
            this.setState({ submitted: false });
            this.props.actions.displayResponseMessage(
              true,
              "fail",
              "Login unsuccessful. Please enter valid credentials."
            );
          }
        );
      } else {
        this.setState({ submitted: false });
      }
    });
  };

  render() {
    const loginState = this.state.submitted ? (
      <img
        className="pull-right"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
    ) : (
      "Login"
    );

    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <ResponseMessage />
        <Card className="col-5 p-0">
          <CardHeader className="bg-white border-bottom d-flex align-items-center w-100">
            <img
              id="main-logo"
              className="mr-3"
              style={{ maxWidth: "50px" }}
              src={require("../../../favicon.ico")}
              alt="TTPL Dashboard"
            />{" "}
            <h4 className="text-dark m-0">Plants Dashboard</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.login}>
              <div>
                <label>Email</label>
                <FormInput
                  name="email"
                  type="text"
                  value={this.state.user.email}
                  onChange={this.onChange}
                  {...this.state.validations.email}
                />
                <FormFeedback {...this.state.validations.email}>
                  {this.state.validations.email.message}
                </FormFeedback>{" "}
              </div>
              <div className="mt-4">
                <label>Password</label>
                <FormInput
                  name="password"
                  type="password"
                  value={this.state.user.password}
                  onChange={this.onChange}
                  {...this.state.validations.password}
                />
                <FormFeedback {...this.state.validations.password}>
                  {this.state.validations.password.message}
                </FormFeedback>{" "}
              </div>
              <div className="mt-5 d-flex align-items-center justify-content-center">
                <Button className=" px-5" disabled={this.state.submitted}>
                  {" "}
                  {loginState}{" "}
                </Button>
              </div>
              <div className="text-right mt-3">
                {/* <a
                  className="cursor-pointer text-blue"
                  onClick={() => {
                    this.props.history.push("reset-password?id=2");
                  }}
                >
                  Reset Password
                </a>
                <a className="px-2"> {"|"} </a> */}
                <a
                  className="cursor-pointer text-blue"
                  onClick={() => {
                    this.props.history.push("forgot-password");
                  }}
                >
                  Forgot Password?
                </a>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Login.propTypes = {
  email: string,
  password: oneOfType([number, string]),
  history: object.isRequired,
  actions: object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { saveAuthToken, saveCurrentUser, displayResponseMessage, saveRoleMapping },
      dispatch
    )
  };
}
function mapStateToProps(state) {
  return {
    token: state.AppState.token,
    current_user: state.AppState.current_user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
