import React from "react";
import { OrganizationFormTemplate } from "../../containers";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const EditOrganization = ({ navToggle }) => {
  return (
    <OrganizationFormTemplate
      action={types.EDIT_ORGANIZATION}
      navToggle={navToggle}
      form_head_title={"New Organization"}
      form_head_sub_title={""}
      form_title={"Edit Organization"}
      form_sub_title={"Organization Details"}
      button_title={"Save Changes"}
    />
  );
};

EditOrganization.propTypes ={
  navToggle : func.isRequired
}

export default EditOrganization;
