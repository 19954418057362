import React from "react";
import { OrganizationFormTemplate } from "../../containers";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const AddOrganization = ({ navToggle }) => {
  return (
    <OrganizationFormTemplate
      action={types.ADD_ORGANIZATION}
      navToggle={navToggle}
      form_head_title={"New Organization"}
      form_head_sub_title={""}
      form_title={"Add Organization"}
      form_sub_title={"Organization Details"}
      button_title={"Add Organization"}
    />
  );
};

AddOrganization.propTypes = {
  navToggle: func.isRequired
};

export default AddOrganization;
