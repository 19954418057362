export const Organization = (
  id = "",
  organization_name = "",
  email = "",
  countryCode = "",
  phone = "",
  website = "",
  address = "",
  city = "",
  state = "",
  country = "",
  zip = "",
  organization_type = "",
  domain = "",
  relation = "",
  // contactPerson = "",
  // contactPersonDesignation = ""
) => {
  return {
    id,
    organization_name,
    email,
    countryCode,
    phone,
    website,
    address,
    city,
    state,
    country,
    zip,
    organization_type,
    domain,
    relation,
    // contactPerson,
    // contactPersonDesignation
  };
};

export const OrganizationValidation = {
  organization_name: {},
  email: {},
  countryCode: {},
  phone: {},
  website: {},
  address: {},
  city: {},
  state: {},
  country: {},
  zip: {},
  organization_type: {},
  domain: {},
  relation: {},
  // contactPerson: {},
  // contactPersonDesignation: {}
};

let organizations = [
  "Organization one",
  "Organization two",
  "Organization three"
];

export default organizations.map(o => {
  return Organization(
    o.toLowerCase(),
    o,
    "admin@" + o.toLowerCase().replace(" ", "") + ".com",
    "+91",
    "9999999999",
    "www.google.com",
    "address",
    "city",
    "state",
    "India",
    "123456",
    "private",
    "sanitation",
    "self",
    o + " Employee",
    "Designation " + o
  );
});
