import {
    SeptageReceived
} from './SeptageReceived';
import {
    HHSeptage
} from './HHSeptage'

import {
    CTPTSeptage
} from './CTPTSeptage';

import {
    SRSValue
} from './SRSValue';
import {
    DewartedSolidHandle
} from './DewartedSolidHandle'
import {Consumable} from './Consumable';
import {Metering} from './Metering';

import uuid from 'uuid';

export const DailyProcess = (
    plant_id = "",
    user_id = "",
    id = uuid(),
    process_detail_id = uuid(),
    location = "",
    name = "",
    date =  Date(),
    observations = "",
    septages = [new SeptageReceived()],
    hours_hh_septage = new HHSeptage(),
    hours_ct_pt_septage = new CTPTSeptage(),
    srs_value = new SRSValue(),
    dewaterted_solids_handel = new DewartedSolidHandle(),
    consumable = new Consumable(),
    metering = new Metering()
) => {
    return {
        id,
        plant_id,
        user_id,
        location,
        process_detail_id,
        septages,
        date,
        name,
        observations,
        hours_hh_septage,
        hours_ct_pt_septage,
        srs_value,
        dewaterted_solids_handel,
        consumable,
        metering
    };
};


export default DailyProcess();