import axios from "axios";

export const getUserById = id => {
  return axios.get("/api/v1/user_details/" + id);
};

export const getUserByUserId = user_id => {
  return axios.get("/api/v1/user_details/user_id/" + user_id);
};

export const getUsers = () => {
  return axios.get("/api/v1/user_details/");
};

export const addUser = user => {
  return axios.post("/api/v1/invitation", user);
};

export const editUser = user => {
  return axios.put("/api/v1/user_details/" + user.user_detail.id, user);
};

export const editProfile = user => {
  const id = user.user_detail.id;
  delete user.user_detail["id"];
  return axios.put("/api/v1/user_details/profile/" + id, user);
};

export const deleteUser = id => {
  return axios.delete("/api/v1/user_details/" + id);
};
