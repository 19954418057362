import React, { Component } from "react";
// import ChartsTemplate from "./charts/ChartsTemplate";
// import PageTitle from "../../common/PageTitle";
import PageTitle from "../../../common/PageTitle";
import logo from '../../../assets/Spinner-1s-200px.gif';
import { connect } from "react-redux";


import { withRouter } from "react-router";
// import MainSidebar from "../../layout/MainSidebar/MainSidebar";

import * as reportService from "../../../../services/ReportService";

import MainSidebar from '../../../layout/MainSidebar/MainSidebar'
import PlantInputOutPutGraph from '../../../dashboard/plants-dashboard/Report/PlantInputOutputGraph'
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  FormInput,
  CardBody,
  Button
} from "shards-react";
import { object } from "prop-types";
// import { getMetrics } from "../../../services/DashboardService";

class PlantInputOutputReportTemplate extends Component {
  constructor(props) {
    super(props);
    this.showGraph = this.showGraph.bind(this);

    // this.toggleModal = this.toggleModal.bind(this);

    // this.formatDataset = this.formatDataset.bind(this);
    // this.getChartConf = this.getChartConf.bind(this);
    // this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.on_change = this.on_change.bind(this);

    // this.handleStartDateChange = this.handleStartDateChange.bind(this);

    this.state = {
      date: Date(),
      open: false,
      modalDiv: null,
      labels: [],
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30),
      endDate: new Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      entryListForTable: {},
      SeptageReceivedHH: [],
      SeptageReceivedCTPT: [],
      TreatedWater: [],
      Biochar: [],
      selectedTab: "plant_io",
      plant_id:"",
      isDataLoaded:false,
      isDataLoadedFully:false,
      validationError: "",
      dataNotFoundMessaage: "",

    };

   
  }
  // componentDidUpdate(){  
  //   this.setState({
  //     plant_id:localStorage.getItem('plant_id')
  //   })
     
  // }

  toggle() {
    this.setState(prevState => {
      return { dropdown1: !prevState.dropdown1 };
    });
  }

  on_change(e) {
   

      this.setState({
        date: e.target.value,
        validationError: e.target.value === "" ? "Please select the date" : ""
       }); 

    
    
       
  }
  componentDidMount(){
    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully
    })
    this.setState({
      plant_id:localStorage.getItem('plant_id')

    })
    let data;
    let date = localStorage.getItem('current_daily_process_date');
    this.setState({
      date:date
    })
    const plant_id = localStorage.getItem('plant_id');
    const listTableData = reportService.getPlantInputOutput;
    listTableData(plant_id, date).then(entryListForTableRes => {
      data = entryListForTableRes;       
     //entryListForTable = entryListForTable ? entryListForTable : [];
     if(data.message !=null){
       this.setState({
         isDataLoaded: true,
         entryListForTable: data,
         Biochar: "",
         SeptageReceivedHH: "",
         SeptageReceivedCTPT: "",
         TreatedWater: ""
       })
       this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully
      })       
     }else{
       this.setState({ 
         isDataLoaded: false,
         entryListForTable: data,
         Biochar: entryListForTableRes.biochar_generated_kgs,
         SeptageReceivedHH: entryListForTableRes.quantity_of_septage,
         SeptageReceivedCTPT: entryListForTableRes.srs,
         TreatedWater: entryListForTableRes.treated_water_taken
       });
       this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully
      })

     }

     
   }, (error) => {
   
    if(error.response.data.status == 500){
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
       })
      this.setState({
        dataNotFoundMessaage: "No Record Found"
      })

    }else{
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
       })
       let message  = error.response.data.message;
       this.setState({
         dataNotFoundMessaage: message
       })

    }


});
    

  }
  
  getTableData(){
    const date = this.state.date;
    //console.log(date)
    // if(!date){
    //   let errorMessage = "Please select the date";
    //   this.setState({
    //     validationError: errorMessage
    //   })
    // }
    if(date == ""){
      let errorMessage = "Please select the date";
      this.setState({
        validationError: errorMessage
      })
    }else{
    
    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully,
      entryListForTable: {},
      isDataLoaded: false
    }) 
    let data;
    // const date = this.state.date;
    const plant_id = this.state.plant_id;
    //let PlantIO=this.state.tabledata;
    const listTableData = reportService.getPlantInputOutput;
     listTableData(plant_id, date).then(entryListForTableRes => {
       data = entryListForTableRes; 
       //console.log(data);
             
      //entryListForTable = entryListForTable ? entryListForTable : [];
      if(data.message !=null){
        this.setState({
          isDataLoaded: true,
          entryListForTable: data,
          Biochar: "",
          SeptageReceivedHH: "",
          SeptageReceivedCTPT: "",
          TreatedWater: "",
          validationError:""
        }) 
        this.setState({
          isDataLoadedFully: !this.state.isDataLoadedFully
        })       
      }else{
        this.setState({ 
          isDataLoaded: false,
          entryListForTable: data,
          Biochar: entryListForTableRes.biochar_generated_kgs,
          SeptageReceivedHH: entryListForTableRes.quantity_of_septage,
          SeptageReceivedCTPT: entryListForTableRes.srs,
          TreatedWater: entryListForTableRes.treated_water_taken,
          validationError:""
        });
        this.setState({
          isDataLoadedFully: !this.state.isDataLoadedFully
        }) 

      }

      
    }, (error) => {
            //console.log(JSON.stringify(error));
            this.setState({
              isDataLoadedFully:false,
              isDataLoaded:true,
             })
             let message  = error.response.data.message;
             this.setState({
               dataNotFoundMessaage: message
             })

    });
   // console.log(this.state.entryListForTable);
  }
        
  }

  showGraph(){    
    this.setState({
      isMoreButtonClicked: !this.state.isMoreButtonClicked
    })    
  }

  render() {
    const isDataLoadedFully = this.state.isDataLoadedFully 
    ? <div className="text-center" >
      <img style={{width:"90px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const isDataLoaded = this.state.isDataLoaded 
    ?             <Row className="py-2">
    <Col  className="">
      <div className="text-center" style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
      </div> 
    </Col>
 </Row>
    : null;
    const listItems = this.setup_searchOne();
    const graphicalView = this.state.isMoreButtonClicked;
    let button;
    // if(!graphicalView){
    //   button=  <LogoutButton/>;
    // }else {
    //   button = <PlantInputOutPutGraph/>;
    // }

    if(graphicalView){
      button = <PlantInputOutPutGraph/>;

    }
    
    
    const table = (
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr className="text-center">
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">Septage Received HH (Litres)</th>
                  <th scope="col" className="border-0">Septage Received CTPT (Litres)</th>
                  <th scope="col" className="border-0">Treated Water Taken Out (Litres)</th>
                  <th scope="col" className="border-0">BioChar Generated (Kgs)</th>
                </tr>
              </thead>
             
              <tbody>
              {listItems}
                </tbody>
            </table>
            {isDataLoaded}
            {isDataLoadedFully}
 
          </CardBody>
        </Card>
      </Col>
    </Row>
    );
    return (
      <div className="mh-85">
        <MainSidebar 
          selectedtab={"plant_io"}/>

        <Breadcrumb className="col-10 float-right p-0">
        <BreadcrumbItem> 
          <a onClick={() => this.props.history.push("/admin/plants/"+ this.props.match.params.plant_name+ "/overview/"+ localStorage.getItem('plant_id'))} style={{color:"#007bff", cursor:"pointer"}}>
          {new String(this.props.match.params.plant_name)} Overview
          </a>
          </BreadcrumbItem>

          <BreadcrumbItem active>
          {new String(this.props.match.params.process_name)}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container
          fluid
          className="main-content-container px-4 col-10 float-right"
        >
          <Row noGutters className="page-header py-4">
         
           <PageTitle
              title={
               "Plant Input Output Report"
              }
              subtitle=""
              className="text-sm-left mb-3"
            />
           
           <Col>
           <div className="float-right">
           {this.props.permission.edit == "YES" ? (
            <Button style={{marginRight:"29px"}}
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/dailyprocess/")}
            >
              
              Add Daily Process Data
            </Button>): (
          ""
        )}
            
            <Button
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")}
            >
              List Daily Process Data
            </Button>
            </div>
           </Col>

            

            
       
            {/* <Button
              onClick={() => this.props.history.push("dailyprocess")}
            >
              Add Daily Process Data
            </Button>
            
            
            <Button
              onClick={() => this.props.history.push("list-daily-process")}
            >
              List Daily Process Data
            </Button> */}
            
           
          </Row>


          <div style={{display: "flex"}}>

          {/* <div className="text-sm-left">
            <label>Plant Input Output Overview</label>

          </div> */}
          <div style={{marginLeft:"700px"}}>

            {/* <Dropdown
          open={this.state.dropdown1}
          toggle={() => this.toggle(1)}
          
          className="offset-md-4 mb-2"
        >
          <DropdownToggle caret>Select Report Type</DropdownToggle>
          <DropdownMenu right>
          <DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/one")}>Plant Input/Output Overview</DropdownItem>
          <DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/two")}>Treated Water Data</DropdownItem>
          <DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/three")}>Plant Operation Data</DropdownItem>
          <DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/four")}> Comments</DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
        </div>
        </div>
        <Row className="py-2">
        <Col sm="2" className="d-flex mb-2 mb-sm-0"> 
            <FormInput
              required    
              name = "date"
              type="date"
              value = {
                this.state.date
              }
              onChange={this.on_change}
            />
            <Button
             onClick={(e) => this.getTableData(e)}>
               Show
          </Button>
          </Col>
          </Row>
          <Row className="py-2">
            <Col sm="2" className="d-flex mb-2 mb-sm-0">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
          </Row>
         
         

          {table}
          <Button style={{marginRight:"29px"}}
             onClick={(e) => this.showGraph(e)}>
                 {graphicalView ? "Less" : "More"}
          </Button>
          {/* <div className="row">
          <div  className="col-md-3" style={{marginLeft: "-15px"}}>
            <FormInput    
              name = "date"
              type="date"
              value = {
                this.state.date
              }
              onChange={this.on_change}
            />
            </div>
            <div  className="col-md-3" style={{marginLeft: "-15px"}}>
            <FormInput    
              name = "date"
              type="date"
              value = {
                this.state.date
              }
              onChange={this.on_change}
            />
            </div>
            </div> */}
         
          {button}
         
    

          {/* <Row>{charts}</Row> */}
        </Container>
      </div>
    );
  }

  setup_searchOne(){
   // this.getTableData();
    let rearrangedEntryList = {} ;

   // let qty = 0;
    rearrangedEntryList = this.state.entryListForTable;
    let keys = Object.keys(rearrangedEntryList);
    if(keys.length != 0){
    // for(let i=0;i< rearrangedEntryList.quantity_of_septage.length; i++){
    //   qty = qty + rearrangedEntryList.quantity_of_septage[i]

    // }
    
    //return rearrangedEntryList.map((item, i) => {
     return(
        <tr className="text-center">
          <td>1</td>
          <td> {rearrangedEntryList.septage_received_HH != null ? rearrangedEntryList.septage_received_HH: "NA"}</td>
          <td> {rearrangedEntryList.septage_received_CTPT != null  ? rearrangedEntryList.septage_received_CTPT: "NA"}</td>
          <td> {rearrangedEntryList.treated_water_taken[0] != null ? rearrangedEntryList.treated_water_taken[0] : "NA"}</td>
          <td> {rearrangedEntryList.biochar_generated_kgs[0] != null ? rearrangedEntryList.biochar_generated_kgs[0] : "NA"}</td>
        </tr>
         );
    //});
  }

  }
}
function mapStateToProps(state) {
  return {
    search_key: state.AppState.search_key,
    current_user: state.AppState.dailyProcessess,
    permission: state.AppState.permission,
  };
}

PlantInputOutputReportTemplate.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired
};

// export default withRouter(PlantInputOutputReportTemplate);
export default withRouter(
  connect(
    mapStateToProps
  )(PlantInputOutputReportTemplate)
);