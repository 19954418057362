export const Metering = (
    initial_flow_meter_value = "",
    final_flow_meter_value = "",
    treated_water_taken = "",
    energy_meter_initial = "",
    energy_meter_final = "",
    fresh_water_initial_lts = "",
    fresh_water_final_lts
  ) => {
    return {
        initial_flow_meter_value,
        final_flow_meter_value,
        treated_water_taken,
        energy_meter_initial,
        energy_meter_final,
        fresh_water_initial_lts,
        fresh_water_final_lts
    };
  };
  
  
  export default Metering(
  );