import React from "react";
import PlantFormTemplate from "../../containers/PlantFormTemplate";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const EditPlant = ({ navToggle }) => {
  return (
    <PlantFormTemplate
      action={types.EDIT_PLANT}
      form_title={"Edit Plant"}
      navToggle={navToggle}
      form_sub_title={"Plant Details"}
      button_title={"Save Changes"}
    />
  );
};

EditPlant.propTypes = {
  navToggle: func.isRequired
};

export default EditPlant;
