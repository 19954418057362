export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const INIT_CONFIG = "INIT_CONFIG";
export const AUTHENTICATE = "AUTHENTICATE";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const LOGOUT = "LOGOUT";

export const DELETE_USER = "DELETE_USER";
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const EDIT_PROFILE = "EDIT_PROFILE";

export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";

export const DELETE_PLANT = "DELETE_PLANT";
export const GET_PLANTS = "GET_PLANTS";
export const ADD_PLANT = "ADD_PLANT";
export const EDIT_PLANT = "EDIT_PLANT";

export const ADD_SEPTAGE_RECEIVED = "ADD_SEPTAGE_RECEIVED";
export const GET_DAILY_DATA = "GET_DAILY_DATA";

export const EDIT_DAILY_PROCESS = "EDIT_DAILY_PROCESS";
export const ADD_INHOUSE_LAB_ANALYSIS = "ADD_INHOUSE_LAB_ANALYSIS";
export const EDIT_LAB_ANALYSIS_INHOUSE = "EDIT_LAB_ANALYSIS_INHOUSE";


export const ADD_EXTERNAL_LAB_ANALYSIS = "ADD_EXTERNAL_LAB_ANALYSIS";
export const EDIT_LAB_ANALYSIS_EXTERNAL = "EDIT_LAB_ANALYSIS_EXTERNAL";