import React from "react";
import InHouseLabAnalysisFormTemplate from "../../../containers/WrangalPlant/InHouseLabAnalysisTemplate";

import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const LabAnalysisInHouse = ({ navToggle }) => {
  return (
    <InHouseLabAnalysisFormTemplate
      action={types.ADD_INHOUSE_LAB_ANALYSIS}
      form_title={"Lab Analysis-InHouse"}
      navToggle={navToggle}
      form_head_title={"New Lab Analysis"}
      form_head_sub_title={""}
      form_sub_title={"Lab Analysis Details"}
      button_title={"Add Lab Analysis-InHouse data"}
    />
  );
};

LabAnalysisInHouse.propTypes = {
  navToggle: func.isRequired
};

export default LabAnalysisInHouse;
