import React from "react";
import ExternalLabAnalysisFormTemplate from '../../../containers/WrangalPlant/ExternalLabAnalysisFormTemplate'


import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import {
    func
} from "prop-types";

const EditLabAnalysisExternal = ({navToggle }) => {
    return ( 
      <ExternalLabAnalysisFormTemplate 
        action = {types.EDIT_LAB_ANALYSIS_EXTERNAL}
        form_title = {"Lab Analysis External"}
        navToggle = {navToggle}
        button_title = {"Update Lab Analysis External"}
      />
    );
};

EditLabAnalysisExternal.propTypes = {
    navToggle: func.isRequired
};

export default EditLabAnalysisExternal;