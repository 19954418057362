import React, { Component } from "react";
import {
  saveCurrentDailyProcess, editCurrentDailyProcess, displayResponseMessage,
  saveEditSession
} from "../../../../actions/AppStateActions";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { DailyProcess } from '../../../../models/DailyProcess/DailyProcess';
import ResponseMessage from "../../../common/ResponseMessage";
import * as dailyProcessService from "../../../../services/DailyProcessService";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { object } from "prop-types";
import MaterialIcon from "material-icons-react";


import {
  Card,
  CardBody,
  Form,
  Button,
  FormInput,
  Collapse,
  FormSelect,
  FormTextarea,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Row,
} from "shards-react";


class WrangalDailyProcessFormTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      process_detail: new DailyProcess(),
      validation: [],
      collapseRecordedBy: true,
      collapseSeptageReceived: false,
      collapseSRS: false,
      collapseHH: false,
      collapseCCPT: false,
      collapseDSH: false,
      collapseConsumable: false,
      collapseMetering: false,
      collapseObservable: false,
      submitted: false,
      septageReceivedUi: [],
      open: false,
      septageLen: false,
      validationError: "",
      isValidationSucess: true,
      negativeMessage: [],
      finalErrorMessage: '',
      septageArrayError: []

    };
    this.dropdownSeptagereceived = this.dropdownSeptagereceived.bind(this);
    this.dropdownSRS = this.dropdownSRS.bind(this);
    this.dropdownHH = this.dropdownHH.bind(this);
    this.dropdownCCPT = this.dropdownCCPT.bind(this);
    this.dropdownDSH = this.dropdownDSH.bind(this);
    this.dropdownConsumable = this.dropdownConsumable.bind(this);
    this.dropdownMetering = this.dropdownMetering.bind(this);
    this.dropdownObservable = this.dropdownObservable.bind(this);
    this.submit_form = this.submit_form.bind(this);
    this.on_change = this.on_change.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.on_change_septage = this.on_change_septage.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.on_change_septage_arr = this.on_change_septage_arr.bind(this);

    this.on_change_observations = this.on_change_observations.bind(this);
    this.on_change_metering_attributes = this.on_change_metering_attributes.bind(this);
    this.on_change_consumable_attributes = this.on_change_consumable_attributes.bind(this);
    this.on_change_dewaterted_solids_handel_attributes = this.on_change_dewaterted_solids_handel_attributes.bind(this);
    this.on_change_hours_ct_pt_septage_attributes = this.on_change_hours_ct_pt_septage_attributes.bind(this);
    this.on_change_srs_value_attributes = this.on_change_srs_value_attributes.bind(this);
    this.on_change_hours_hh_septage_attributes = this.on_change_hours_hh_septage_attributes.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.saveEditSession(null);
  }

  componentDidMount() {

    if (this.props.action == "EDIT_DAILY_PROCESS") {
      // console.log(this.props.current_data)

      this.setState(prevState => ({
        ...prevState,
        process_detail: this.props.current_data
      }))



      this.setState({
        process_detail: this.props.current_data
      });
      const process_detail = this.props.current_data;
      if ("undefined" === typeof (process_detail["septages"])) {
        // console.log(this.state.process_detail)
        // // const pro = this.props.current_data;
        // process_detail['septages'] = [];
        // this.setState({
        //   process_detail: process_detail
        // });
      } else {
        let septageLen = this.props.current_data.septages.length;
        this.setState({
          septageLen
        })
      }

    } else {
      this.setState(prevState => ({
        ...prevState,
        process_detail: {
          ...prevState.process_detail,
          user_id: this.props.current_user.user_id,
          location: this.props.match.params.plant_name,
          plant_id: localStorage.getItem('plant_id'),
          name: this.props.current_user.first_name + " " + this.props.current_user.last_name,
          date: null
        }
      }))
    }
  }


  on_change(e) {
    const process_detail = {
      ...this.state.process_detail,
      [e.target.name]: e.target.value,
    };
    this.setState({
      process_detail,
      validationError: e.target.value === "" ? "Please select the date" : "",
    });
  }

  handleStartDateChange(date) {
    this.setState({
      process_detail: {
        ...this.state.process_detail,
        date: date
      }
    })
  }


  on_change_septage(event) {
    this.setState(prevState => ({
      ...prevState,
      dailyProcess: {
        ...prevState.dailyProcess,
        septageReceived: {
          ...prevState.dailyProcess.septageReceived,
          [event.target.name]: event.target.value
        }
      }
    }));
  }

  toggleModal() {
    const date = this.state.process_detail.date;
    // if (this.state.process_detail.septages.length == 0) {
    //   ToastsStore.error("At least one Septage Received must be entered");
    //   return;
    // }
    if (date == null || date == "") {
      let errorMessage = "Please select the date";
      // ToastsStore.error("* These fields are required");

      this.setState({
        validationError: errorMessage,
        finalErrorMessage: errorMessage
      })
    } else if (!this.form_validate()) {
      //ToastsStore.error("* These fields are required");
      let errorMessage = "Fields marked by (*) are required!";

      this.setState({
        finalErrorMessage: errorMessage
      })
    }
    else {
      this.setState({
        open: !this.state.open,
        finalErrorMessage: ''
      });
    }

  }
  form_validate() {
    this.setState({
      submitted: true
    })
    let septages = this.state.process_detail.septages;
    const septageArrayError = [];
    septages.forEach((septage, septageIndex) => {
      const septageError = { septage_type: null, vehical_number: null, no_of_trips: null, quantity_of_septage: null, uploaded_to_tank_num: null };
      if (septage.septage_type == null) {
        septageError.septage_type = 'Required';
        septageArrayError[septageIndex] = septageError;
      }
      if (septage.vehical_number == null) {
        septageError.vehical_number = 'Required';
        septageArrayError[septageIndex] = septageError;
      }
      if (septage.no_of_trips == null) {
        septageError.no_of_trips = 'Required';
        septageArrayError[septageIndex] = septageError;
      } else if (septage.no_of_trips < 0) {
        septageError.no_of_trips = 'Value Can Not Be Negative';
        septageArrayError[septageIndex] = septageError;
      }
      if (septage.quantity_of_septage == null) {
        septageError.quantity_of_septage = 'Required';
        septageArrayError[septageIndex] = septageError;
      } else if (septage.quantity_of_septage < 0) {
        septageError.quantity_of_septage = 'Value Can Not Be Negative';
        septageArrayError[septageIndex] = septageError;
      }
      if (septage.uploaded_to_tank_num == null) {
        septageError.uploaded_to_tank_num = 'Required';
        septageArrayError[septageIndex] = septageError;
      }

    })
    // console.log(septageArrayError)
    this.setState({
      septageArrayError: septageArrayError
    });
    if (septageArrayError.length == 0) {
      return true;
    }

  }


  field_validate(e) {
    if (this.state.submitted) {
      let septageArrayError = this.state.septageArrayError;
      const septa = [...this.state.process_detail.septages];
      if (e.target.value != '') {
        septageArrayError[e.target.dataset.id][e.target.name] = "";
      } else if (e.target.value == '') {
        septageArrayError[e.target.dataset.id][e.target.name] = "Required";
        septa[e.target.dataset.id][e.target.name] = null;
      }
      this.setState({ septageArrayError });
    }
  }

  submit_form(e) {
    e.preventDefault();
    this.setState({
      submitted: true
    });
    // actual service call-------
    // if (this.state.process_detail.septages.length == 0) {
    //   this.toggleModal();
    //   this.props.actions.displayResponseMessage(
    //     true,
    //     "fail",
    //     "UnProcessable Entity"
    //   );
    //   return;
    // }

    const submit_func =
      this.props.action == "ADD_DAILY_PROCESS"
        ? dailyProcessService.addDailyProcess
        : dailyProcessService.editDailyProcess;

    let process_detail = this.state.process_detail;
    if (!this.props.edit_session) {
      delete process_detail["id"];
    }
    this.setState({
      submitted: true

    });
    submit_func({ process_detail }).then(
      () => {
        localStorage.setItem("current_daily_process_date", process_detail.date)
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "Daily Process " +
          (this.props.edit_session ? "edited" : "added") +
          " successful."
        );
        this.setState({
          submitted: false
        });

        this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process");
        // this.setState({
        //   open: !this.state.open
        // });
      },
      () => {
        this.toggleModal();
        this.setState({
          submitted: false
        });
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong."
        );
      }
    );
    //actual service call ends---------------
    this.setState({
      open: !this.state.open
    });
    //this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process");



  }

  dropdownRecordedBy() {
    this.setState({
      collapseRecordedBy: true
    })
  }
  dropdownSeptagereceived() {
    this.setState({ collapseSeptageReceived: !this.state.collapseSeptageReceived });
  }
  dropdownSRS() {
    this.setState({ collapseSRS: !this.state.collapseSRS });
  }

  dropdownHH() {
    this.setState({ collapseHH: !this.state.collapseHH })
  }
  dropdownCCPT() {
    this.setState({ collapseCCPT: !this.state.collapseCCPT })
  }

  dropdownDSH() {
    this.setState({ collapseDSH: !this.state.collapseDSH })
  }
  dropdownConsumable() {
    this.setState({ collapseConsumable: !this.state.collapseConsumable })
  }
  dropdownMetering() {
    this.setState({ collapseMetering: !this.state.collapseMetering })
  }
  dropdownObservable() {
    this.setState({ collapseObservable: !this.state.collapseObservable })
  }

  addSeptageUI() {
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        septages: [
          ...prevState.process_detail.septages,
          {
            septage_type: null,
            vehical_number: null,
            quantity_of_septage: null,
            no_of_trips: null,
            uploaded_to_tank_num: null
          }
        ]
      }
    }))
    this.setState(prevState => ({
      ...prevState,
      validation: [
        ...prevState.validation,
        {
          septage_type: null,
          vehical_number: null,
          quantity_of_septage: null,
          no_of_trips: null,
          uploaded_to_tank_num: null
        }
      ]

    }))
    // console.log(this.state.dailyProcess);

  }

  removeSeptageUI(event, index) {
    this.state.process_detail.septages.splice(index, 1);
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail
      }
    }))
    //delete this.state.process_detail.septages[2];
    // let l = this.state.process_detail.septages.length;
    // l=l-1;
    // this.state.process_detail.septages.splice(l,1);
  }

  ////////////////////////

  on_change_septage_arr(e) {
    const septa = [...this.state.process_detail.septages];
    septa[e.target.dataset.id][e.target.name] = e.target.value
    this.setState({ septa }
    )
    this.field_validate(e);
  }


  on_change_srs_value_attributes(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        srs_value: {
          ...prevState.process_detail.srs_value,
          [event.target.name]: event.target.value
        }
      }
    }))

  }
  on_change_hours_hh_septage_attributes(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        hours_hh_septage: {
          ...prevState.process_detail.hours_hh_septage,
          [event.target.name]: event.target.value
        }
      }
    }))

  }
  on_change_hours_ct_pt_septage_attributes(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        hours_ct_pt_septage: {
          ...prevState.process_detail.hours_ct_pt_septage,
          [event.target.name]: event.target.value
        }
      }
    }))

  }

  on_change_dewaterted_solids_handel_attributes(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        dewaterted_solids_handel: {
          ...prevState.process_detail.dewaterted_solids_handel,
          [event.target.name]: event.target.value
        }
      }
    }))

  }

  on_change_consumable_attributes(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        consumable: {
          ...prevState.process_detail.consumable,
          [event.target.name]: event.target.value
        }
      }
    }))

  }
  on_change_metering_attributes(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        metering: {
          ...prevState.process_detail.metering,
          [event.target.name]: event.target.value
        }
      }
    }))

  }
  on_change_observations(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      process_detail: {
        ...prevState.process_detail,
        [event.target.name]: event.target.value
      }
    }))
  }

  /////////////////////////

  render() {
    let totalCTPTQty = 0;
    let totalHHQty = 0;
    let energyMeterReading;
    let freshWaterConsumed;
    let treatedWaterTaken;
    //let totalSeptageTreated;
    let septage = this.state.process_detail.septages;
    for (let i = 0; i < septage.length; i++) {
      // if(septage[i].septage_type.length >1){
      if (septage[i].septage_type == "CTPT") {
        totalCTPTQty = totalCTPTQty + Number(septage[i].quantity_of_septage)
      } else if (septage[i].septage_type == "HH") {
        totalHHQty = totalHHQty + Number(septage[i].quantity_of_septage)
      }
      //   }

      //console.log(septage[i])
      // totalCTPTQty = totalCTPTQty + Number(septage[i].quantity_of_septage)
    }
    energyMeterReading = Number(this.state.process_detail.metering.energy_meter_final) - Number(this.state.process_detail.metering.energy_meter_initial);
    // totalSeptageTreated = Number(this.state.process_detail.metering.treated_water_taken) + Number(this.state.process_detail.dewaterted_solids_handel.qty_of_solids_generated);

    freshWaterConsumed = Number(this.state.process_detail.metering.fresh_water_final_lts) - Number(this.state.process_detail.metering.fresh_water_initial_lts);
    treatedWaterTaken = Number(this.state.process_detail.metering.final_flow_meter_value) - Number(this.state.process_detail.metering.initial_flow_meter_value)


    // const loginState = this.state.submitted ? (
    //   <img
    //     className="pull-right"
    //     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
    //   />
    // ) : (
    //   "Add"
    // );
    return (
      // eslint-disable-next-line react/prop-types
      <div className="mh-85" onClick={this.props.navToggle}>
        <ResponseMessage />
        {/* <ToastsContainer store={ToastsStore}/> */}

        {/* <ToastsContainer store={ToastsStore}/> */}

        <div className="text-dark my-3 px-5">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/overview/" + localStorage.getItem('plant_id'))} style={{ color: "#007bff", cursor: "pointer" }}> {new String(this.props.match.params.plant_name)} Overview </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")} style={{ color: "#007bff", cursor: "pointer" }}> List Daily Process</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              Daily Process Data
          </BreadcrumbItem>
          </Breadcrumb>
          <small> {new String(this.props.match.params.plant_name)} </small>
          <h4>Daily Process Data</h4>
        </div>

        <Modal
          size="lg"
          open={this.state.open}
          style={{ marginTop: "55%" }}
          toggle={this.toggleModal}
          className="h-75 d-flex align-items-center justify-content-center"
        >
          <h4 className="text-center p-5 w-100">
            {"Are You sure you want to " + this.props.button_title + "?"}{" "}
          </h4>

          <div className="d-flex w-75 mx-auto justify-content-center">
            <Button
              theme="danger"
              onClick={this.toggleModal}
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Cancel
            </Button>
            <Button
              form="dailyprocessdata_form"
              className="col-5 mx-1 mt-2 mb-5 mx-auto"

            >
              Add
            </Button>
          </div>
        </Modal>

        <div className="d-flex flex-wrap h-75 mt-2 px-5 col-12 align-items-center justify-content-center">
          <Card style={{ marginBottom: "30px", }} className="col-12 p-0">

            <CardBody className="p-0">
              {/* <ToastsContainer store={ToastsStore}/> */}

              <Form
                id="dailyprocessdata_form"
                className="d-flex flex-wrap justify-content-between"
                onSubmit={this.submit_form}
              >
                <div className="mt-2 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Recorded By</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>
                    <ToastsContainer store={ToastsStore} />


                    <Collapse open={this.state.collapseRecordedBy} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-4 mt-3">
                          <label>Date *</label>
                          <div>
                            <FormInput
                              name="date"
                              type="date"
                              value={
                                this.state.process_detail.date
                              }
                              onChange={this.on_change}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                          <label>Location *</label>
                          <FormInput
                            disabled
                            name="location"
                            type="text"
                            value={
                              this.state.process_detail.location
                            }
                            onChange={this.on_change}
                          />
                        </div>
                        <div className="col-lg-4 mt-3">
                          <label>Name *</label>
                          <FormInput
                            disabled
                            name="name"
                            type="text"
                            value={this.state.process_detail.name}
                            onChange={this.on_change
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3  mt-3">
                        <div style={{ color: "Red" }}>
                          {this.state.validationError}
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>
                {/* For steptage received  */}
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Septage Received</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownSeptagereceived}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>
                    </div>
                    <Collapse open={this.state.collapseSeptageReceived} style={{ margin: "10px" }}>
                      {this.state.process_detail.septages.map((septage, index) => {
                        return (
                          <div key={index}>
                            <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                              <label>Septage Received -- {index + 1}</label>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="col-lg-2 mt-3">
                                <label>Type Of Septage *</label>
                                <Row style={{ marginLeft: "0px" }}>
                                  <FormSelect
                                    disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                                    name="septage_type"
                                    id={index + 1}
                                    data-id={index}
                                    value={this.state.process_detail.septages[index].septage_type}
                                    onChange={this.on_change_septage_arr}
                                  >
                                    <option value="">
                                      Select Septage Type
                                    </option>
                                    <option value="CTPT">CTPT</option>
                                    <option value="HH">HH</option>
                                  </FormSelect>
                                </Row >
                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.septageArrayError[index] ? this.state.septageArrayError[index].septage_type : ''}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                              {/* {this.state.validation[index].septage_type} */}

                              <div className="col-lg-2 mt-3">
                                <Row style={{ marginLeft: "0px" }}>
                                  <label>Vehicle Number *</label>
                                  <FormInput
                                    disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                                    name="vehical_number"
                                    type="text"
                                    list="data"
                                    data-id={index}
                                    id={index + 1}
                                    value={this.state.process_detail.septages[index].vehical_number}
                                    onChange={this.on_change_septage_arr}
                                  />
                                  <datalist id="data">
                                    <option value="MH-40BN-0559"></option>
                                    <option value="MH-40BN-0560"></option>
                                    <option value="MH-40BN-0561"></option>
                                    <option value="MH-40BN-0562"></option>
                                    <option value="MH-40BN-0563"></option>
                                  </datalist>
                                </Row>
                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.septageArrayError[index] ? this.state.septageArrayError[index].vehical_number : ''}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                              <div className="col-lg-2 mt-3">
                                <label>Unloaded to the tank *</label>
                                <Row style={{ marginLeft: "0px" }}>
                                  <FormSelect
                                    disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                                    name="uploaded_to_tank_num"
                                    id={index + 1}
                                    data-id={index}
                                    value={this.state.process_detail.septages[index].uploaded_to_tank_num}
                                    onChange={this.on_change_septage_arr}
                                  >
                                    <option value="">
                                      Select Tank
                                    </option>
                                    <option value="T1">T1</option>
                                    <option value="T2">T2</option>
                                    <option value="T3">T3</option>
                                    <option value="T4">T4</option>
                                  </FormSelect>
                                </Row>
                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.septageArrayError[index] ? this.state.septageArrayError[index].uploaded_to_tank_num : ''}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                              <div className="col-lg-2 mt-3">
                                <label>Number Of Trip*</label>
                                <Row style={{ marginLeft: "0px" }}>
                                  <FormInput
                                    disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                                    name="no_of_trips"
                                    type="number"
                                    min={0}
                                    data-id={index}
                                    value={this.state.process_detail.septages[index].no_of_trips}
                                    onChange={this.on_change_septage_arr}
                                  />
                                </Row>
                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.septageArrayError[index] ? this.state.septageArrayError[index].no_of_trips : ''}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                              <div className="col-lg-3 mt-3">
                                <label>Quantity Of Septage Received(Litres)*</label>
                                <Row style={{ marginLeft: "0px" }}>
                                  <FormInput
                                    disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                                    name="quantity_of_septage"
                                    type="number"
                                    data-id={index}
                                    min={0}
                                    value={this.state.process_detail.septages[index].quantity_of_septage}
                                    onChange={this.on_change_septage_arr}
                                  />
                                </Row>
                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.septageArrayError[index] ? this.state.septageArrayError[index].quantity_of_septage : ''}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                              <Button
                                style={{
                                  borderRadius: "50px",
                                  backgroundColor: "white",
                                  border: "none",
                                  fontSize: "50px",
                                  color: "#D3D3D3"
                                }}
                                id={index + 1}
                                data-id={index}
                                onClick={(event) => this.removeSeptageUI(event, index)}
                                disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                              >
                                <MaterialIcon icon="remove_circle_outline" />
                              </Button>
                            </div>
                          </div>
                        )
                      })
                      }
                      <div className="col-lg-6  mt-3">
                        <div style={{ color: "blue" }}>
                          Total CTPT Septage Received: {totalCTPTQty}{"(Litres)"}
                        </div>
                      </div>
                      <div className="col-lg-6  mt-3">
                        <div style={{ color: "blue" }}>
                          Total HH Septage Received: {totalHHQty}{"(Litres)"}
                        </div>
                      </div>
                      <div className="add-septage mt-3" style={{ marginRight: "15px", float: "right", cursor: "pointer" }} onClick={this.handleAddSeptageReceived}>
                        <i className="material-icons md-36" onClick={(e) => this.addSeptageUI(e)}>
                          add_circle_outline
                    </i>
                      </div>
                    </Collapse>
                  </Card>
                </div>
                {/* For SRS  */}
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>SRS</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownSRS}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>
                    </div>
                    <Collapse open={this.state.collapseSRS} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>

                        <div className="col-lg-3 mt-3">
                          <label>SRS Waste(Kgs)</label>
                          <FormInput
                            name="srs_waste"
                            type="number"
                            value={this.state.process_detail.srs_value.srs_waste}
                            onChange={this.on_change_srs_value_attributes}
                          />

                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>GRIT Collected(Kgs)</label>
                          <FormInput
                            name="grit_created"
                            type="text"
                            value={this.state.process_detail.srs_value.grit_created}
                            onChange={this.on_change_srs_value_attributes}
                          >
                          </FormInput>
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>HH Septage</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownHH}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseHH} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>SRS(Hours)</label>
                          <FormInput
                            name="srs"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.srs}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Pasteurization(Hours)</label>
                          <FormInput
                            name="pasteurization"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.pasteurization}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Volute Press(Hours)</label>
                          <FormInput
                            name="volute_press"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.volute_press}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Drier(Hours)</label>
                          <FormInput
                            name="drier"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.drier}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                      </div>

                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>Pyrolyzer(Hours)</label>
                          <FormInput
                            name="pyrolyzer"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.pyrolyzer}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Phytorid(Hours)</label>
                          <FormInput
                            name="phytorid"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.phytorid}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>PSTP(Hours)</label>
                          <FormInput
                            name="pstp"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.pstp}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        {/* <div className="col-lg-3 mt-3">
                          <label>KKBR(Hours)</label>
                          <FormInput
                            name="kkbr"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.kkbr}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div> */}
                      </div>

                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>PSF-ACF(Hours)</label>
                          <FormInput
                            name="psf_acf"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.psf_acf}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Chlorine Dosing(Hours)</label>
                          <FormInput
                            name="chlorine_dosing"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.chlorine_dosing}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>UV Filter(Hours)</label>
                          <FormInput
                            name="uv_filter"
                            type="text"
                            value={this.state.process_detail.hours_hh_septage.uv_filter}
                            onChange={this.on_change_hours_hh_septage_attributes}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>

                {/* ccpt-sepatge */}

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>CT-PT Septage</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownCCPT}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseCCPT} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>SRS(Hours)</label>
                          <FormInput
                            name="srs"
                            type="text"
                            value={this.state.process_detail.hours_ct_pt_septage.srs}
                            onChange={this.on_change_hours_ct_pt_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>KKBR1(Hours)</label>
                          <FormInput
                            name="kkbr1"
                            type="text"
                            value={this.state.process_detail.hours_ct_pt_septage.kkbr1}
                            onChange={this.on_change_hours_ct_pt_septage_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>KKBR2(Hours)</label>
                          <FormInput
                            name="kkbr2"
                            type="text"
                            value={this.state.process_detail.hours_ct_pt_septage.kkbr2}
                            onChange={this.on_change_hours_ct_pt_septage_attributes}
                          />
                        </div>
                      </div>

                    </Collapse>
                  </Card>
                </div>

                {/* DEWARTED SOLIDS HANDELED */}

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Dewaterd Solid Handled</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownDSH}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseDSH} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>Solids Generated(Kg)</label>
                          <FormInput
                            name="qty_of_solids_generated"
                            type="text"
                            value={this.state.process_detail.dewaterted_solids_handel.qty_of_solids_generated}
                            onChange={this.on_change_dewaterted_solids_handel_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Solids Pyrolyzed(Kg)</label>
                          <FormInput
                            name="qty_of_solids_pyrolyzed"
                            type="text"
                            value={this.state.process_detail.dewaterted_solids_handel.qty_of_solids_pyrolyzed}
                            onChange={this.on_change_dewaterted_solids_handel_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Solids Disposed(Kg)</label>
                          <FormInput
                            name="qty_of_solids_disposed"
                            type="text"
                            value={this.state.process_detail.dewaterted_solids_handel.qty_of_solids_disposed}
                            onChange={this.on_change_dewaterted_solids_handel_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Biochar Generated(Kg)</label>
                          <FormInput
                            name="biochar_generated_kgs"
                            type="text"
                            value={this.state.process_detail.dewaterted_solids_handel.biochar_generated_kgs}
                            onChange={this.on_change_dewaterted_solids_handel_attributes}
                          />
                        </div>

                      </div>
                      <div className="col-lg-3 mt-3">
                        <label>Usage Of Solid Elsewhere</label>
                        <FormInput
                          name="usage_of_solids_elsewhere"
                          type="text"
                          value={this.state.process_detail.dewaterted_solids_handel.usage_of_solids_elsewhere}
                          onChange={this.on_change_dewaterted_solids_handel_attributes}
                        />
                      </div>

                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Consumables</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownConsumable}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseConsumable} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>Pellets(Kg)</label>
                          <FormInput
                            name="pellets"
                            type="text"
                            value={this.state.process_detail.consumable.pellets}
                            onChange={this.on_change_consumable_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Polymer(Gm)</label>
                          <FormInput
                            name="polymer"
                            type="text"
                            value={this.state.process_detail.consumable.polymer}
                            onChange={this.on_change_consumable_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Chlorine(Litre)</label>
                          <FormInput
                            name="chlorine"
                            type="text"
                            value={this.state.process_detail.consumable.chlorine}
                            onChange={this.on_change_consumable_attributes}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Metering</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownMetering}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>
                    </div>

                    <Collapse open={this.state.collapseMetering} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>Initial flow meter value(Liters)</label>
                          <FormInput
                            name="initial_flow_meter_value"
                            type="text"
                            value={this.state.process_detail.metering.initial_flow_meter_value}
                            onChange={this.on_change_metering_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Final flow meter value(Liters)</label>
                          <FormInput
                            name="final_flow_meter_value"
                            type="text"
                            value={this.state.process_detail.metering.final_flow_meter_value}
                            onChange={this.on_change_metering_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Initial Energy Meter Reading(Unit)</label>
                          <FormInput
                            name="energy_meter_initial"
                            type="text"
                            value={this.state.process_detail.metering.energy_meter_initial}
                            onChange={this.on_change_metering_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Final Energy Meter Reading(Unit)</label>
                          <FormInput
                            name="energy_meter_final"
                            type="text"
                            value={this.state.process_detail.metering.energy_meter_final}
                            onChange={this.on_change_metering_attributes}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>

                        <div className="col-lg-3 mt-3">
                          <label>Initial Fresh Water Reading(Litres)</label>
                          <FormInput
                            name="fresh_water_initial_lts"
                            type="text"
                            value={this.state.process_detail.metering.fresh_water_initial_lts}
                            onChange={this.on_change_metering_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Final Fresh Water Reading(Litres)</label>
                          <FormInput
                            name="fresh_water_final_lts"
                            type="text"
                            value={this.state.process_detail.metering.fresh_water_final_lts}
                            onChange={this.on_change_metering_attributes}
                          />
                        </div>

                      </div>
                      <div className="col-lg-3 mt-3">
                        <div style={{ color: "blue" }}>
                          Total Energy Meter Reading: {energyMeterReading}{" (Unit)"}
                        </div>
                      </div>
                      <div className="col-lg-3 mt-1">
                        <div style={{ color: "blue" }}>
                          Treated Water taken out: {treatedWaterTaken}{" (Unit)"}
                        </div>
                      </div>
                      {/* <div className="col-lg-3 mt-3">
                          <div  style={{color:"blue"}}>
                          Total Septage Treated: {totalSeptageTreated}{" (Unit)"}
                         </div> 
                        </div> */}
                      <div className="col-lg-3 mt-1">
                        <div style={{ color: "blue" }}>
                          Fresh water consumed: {freshWaterConsumed}{" (Unit)"}
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Observation</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownObservable}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseObservable} style={{ marginLeft: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-12">
                          <label></label>
                          <FormTextarea
                            name="observations"
                            type="text"
                            value={this.state.process_detail.observations}
                            onChange={this.on_change_observations}
                          />
                        </div>
                      </div>

                    </Collapse>
                  </Card>
                </div>


                <div className="my-4 col-12">
                  <Button
                    theme="danger"
                    type="button"
                    className="w-25 mr-2"
                    onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")}
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled={this.state.submitted == true}
                    type="button"
                    onClick={
                      this.toggleModal
                    }
                    className="w-25"
                  >
                    {" "}
                    {this.props.button_title}{" "}
                  </Button>
                  <div>
                    <div style={{ color: "Red" }}>
                      {this.state.finalErrorMessage}
                    </div>
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

WrangalDailyProcessFormTemplate.propTypes = {
  history: object.isRequired,
  current_user: object.isRequired,
  current_data: object.isRequired,
  actions: object.isRequired,
  edit_session: object.isRequired,
  button_title: String.isRequired,
  match: object.isRequired,
  action: String.isRequired,
};

function mapStateToProps(state) {
  return {
    current_user: state.AppState.current_user,
    current_data: state.AppState.edit_session

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        saveCurrentDailyProcess, displayResponseMessage, editCurrentDailyProcess, saveEditSession
      },
      dispatch
    )

  };
}





export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrangalDailyProcessFormTemplate)
);
