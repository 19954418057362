import React from "react";
import ManagerTemplate from "../../containers/ManagerTemplate";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const OrganizationManager = ({ navToggle }) => {
  return (
    <ManagerTemplate
      collection_name={"organizations"}
      delete_action_type={types.DELETE_ORGANIZATION}
      navToggle={navToggle}
      page_sub_title={"List Of Added Organizations"}
      page_title={"Organization Details"}
      colDef={{
        Name: "organization_name",
        Email: "email",
        Phone: "phone",
        // Incharge: "contactPersonDesignation"
      }}
      add_entry_link={"add-organization"}
      edit_entry_link={"edit-organization"}
      button_title={"Add Organization"}
    />
  );
};

OrganizationManager.propTypes = {
  navToggle: func.isRequired
};

export default OrganizationManager;
