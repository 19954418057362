import React from "react";
import ManagerTemplate from "../../containers/ManagerTemplate";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const UserManager = ({ navToggle }) => {
  return (
    <ManagerTemplate
      collection_name={"users"}
      navToggle={navToggle}
      delete_action_type={types.DELETE_USER}
      page_sub_title={"List Of Added Users"}
      page_title={"User Details"}
      colDef={{
        "First Name": "first_name",
        "Last Name": "last_name",
        Designation: "designation",
        Plants: "plants",
        Role: "role"
      }}
      add_entry_link={"add-user"}
      edit_entry_link={"edit-user"}
      button_title={"Add User"}
    />
  );
};

UserManager.propTypes = {
  navToggle: func.isRequired
};

export default UserManager;
