
export const SRS = (
  vehical_number = null,
  ph = null,
  orp = null,
  ts = null
) => {
  return {
    vehical_number,
    ph,
    orp,
    ts
  };
};


export default SRS(
  "10",
  "20",
  "30"
);
