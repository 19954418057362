/* eslint-disable import/default */

import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import store, { history } from "./store/configureStore";
import Root from "./components/Root";
require("./favicon.ico"); // Tell webpack to load favicon.ico
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.scss";
import "./styles/shards.scss";
import "react-picky/dist/picky.css"; // Include CSS
// require("babel-core/register");
// require("babel-polyfill");
import AxiosInterceptor from "./utils/axios-interceptor";

AxiosInterceptor.setupInterceptors(store);

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}
