import {
    KKBROutlet
} from './KKBROutlet';
import {
    TreatedWater
} from './TreatedWater'

import {
    PhytoroidOutlet
} from './PhytoroidOutlet';

import {
    AdditionalAnalysis
} from './AdditionalAnalysis';


export const External = (
    plant_id = "",
    user_id = "",
    AnalysisType = "External",
    process_detail_id = "",
    date =  Date(),
    observations = "",
    treated_water_external = new TreatedWater(),
    kkbr_outlet_external = new KKBROutlet(),
    phytorid_outlet_external = new PhytoroidOutlet(),
    additional_analysis =[new AdditionalAnalysis()]
) => {
    return {
        
        plant_id,
        user_id,
        process_detail_id,
        AnalysisType,
        date,
        observations,
        kkbr_outlet_external,
        treated_water_external,
        phytorid_outlet_external,
        additional_analysis
    };
};


export default External();