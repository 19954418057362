import React, { Component } from "react";
import { number, string, oneOfType, object, func } from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  Button
} from "shards-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { changePasssword } from "../../../services/AuthService";
import ResponseMessage from "../../common/ResponseMessage";
import { displayResponseMessage } from "../../../actions/AppStateActions";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },
      validations: {
        currentPassword: {},
        newPassword: {},
        confirmNewPassword: {}
      }
    };
  }

  validateFields = () => {
    if (this.state.user.newPassword != this.state.user.confirmNewPassword) {
      this.setState({
        validations: {
          ...this.state.validations,
          confirmNewPassword: { invalid: true },
          newPassword: { invalid: true }
        }
      });
      this.props.actions.displayResponseMessage(
        true,
        "fail",
        "New password and Confirm new password not same"
      );
      return false;
    }
    this.setState({
      validations: {
        ...this.state.validations,
        confirmNewPassword: { invalid: false, valid: true },
        newPassword: { invalid: false, valid: true }
      }
    });
    return true;
  };

  onChange = e => {
    const user = { ...this.state.user, [e.target.name]: e.target.value };
    this.setState({ user });
  };

  changePassword = e => {
    e.preventDefault();
    if (!this.validateFields()) {
      return;
    }
    const user = {
      email: this.props.current_user.email,
      current_password: this.state.user.currentPassword,
      password: this.state.user.newPassword,
      password_confirmation: this.state.user.confirmNewPassword
    };
    changePasssword({ user }).then(
      res => {
        if (res.success) {
          this.props.history.push("dashboard");
          this.props.actions.displayResponseMessage(
            true,
            "success",
            "Password Changed successfully"
          );
        }
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong"
        );
      },
      () => {
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong"
        );
      }
    );
  };

  render() {
    return (
      <div
        className="mh-85 d-flex align-items-center justify-content-center"
        onClick={this.props.navToggle}
      >
        <ResponseMessage />
        <Card className="col-5 p-0 align-self-center">
          <CardHeader className="bg-white border-bottom">
            <h4 className="text-dark">Change Password</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.changePassword}>
              <div>
                <label>Current Password</label>
                <FormInput
                  name="currentPassword"
                  type="password"
                  value={this.state.user.currentPassword}
                  onChange={this.onChange}
                  required
                  {...this.state.validations.currentPassword}
                />
              </div>
              <div className="mt-4">
                <label>New Password</label>
                <FormInput
                  name="newPassword"
                  type="password"
                  value={this.state.user.newPassword}
                  onChange={this.onChange}
                  required
                  {...this.state.validations.newPassword}
                />
              </div>
              <div className="mt-4">
                <label>Confirm New Password</label>
                <FormInput
                  type="password"
                  name="confirmNewPassword"
                  value={this.state.user.confirmNewPassword}
                  onChange={this.onChange}
                  required
                  {...this.state.validations.confirmNewPassword}
                />
              </div>
              <div className="mt-4 d-flex align-items-center justify-content-center">
                <Button className=" px-5"> Change Password </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  currentPassword: oneOfType([number, string]),
  newPassword: oneOfType([number, string]),
  confirmNewPassword: oneOfType([number, string]),
  actions: object.isRequired,
  history: object.isRequired,
  navToggle: func.isRequired,
  current_user: object
};

function mapStateToProps(state) {
  return {
    current_user: state.AppState.current_user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ displayResponseMessage }, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePassword)
);
