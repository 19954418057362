import React, { Component } from "react";
import PageTitle from "../../common/PageTitle";
import { withRouter } from "react-router";
// import MainSidebar from "../../layout/MainSidebar/MainSidebar";
// import SmallStats from "../../common/SmallStats";
import { Container, Row,Card, CardBody, Modal, Progress, ModalBody } from "shards-react";
import { object, func } from "prop-types";
import * as dailyProcessService from "../../../services/DailyProcessService";
import * as inHouseLabAnalysisService from "../../../services/InHouseLabAnalysisService"
import * as ExternalLabAnalysisService from "../../../services/ExternalLabAnalysisService"

class ProcessesDashboard extends Component {
  constructor(props) {
    super(props);
    this.getTempDataset = this.getTempDataset.bind(this);
    this.getTempScatterDataset = this.getTempScatterDataset.bind(this);
    this.processes = [
      "Daily Process"
    ];
    this.metrics = {};
    this.labels = [];
    const today = new Date().getTime();
    for (let i = 0; i < 30; i++) {
      this.labels.push(today - i * 24 * 60 * 60 * 1000);
    }
    this.state = {
      isLoadedProcess: false
    }
  }
  loadinModal() {
    // const animate = 
    //   return (<Progress theme="primary" value={50} />);
    return(
      <Modal 
      style={{ marginTop: "150px"}}
      open={this.state.isLoadedProcess}
      className="h-75 d-flex align-items-center justify-content-center modal-width">
      <ModalBody >
      <div>
        <div>
        <Progress animated theme="primary" value={100} />
        </div>
        <div className="modal-body-name" style= {{ marginTop: "15px", textAlign: "center"}}>
          Loading Processes...
        </div>
        <div className="modal-body-message"style= {{textAlign: "center"}}>
         Just a moment Please
        </div>
      </div>
      </ModalBody>
    </Modal>
    );
   }

  getTempDataset() {
    let temp = [];
    for (let i = 0; i < 30; i++) {
      temp.push(Math.floor(Math.random() * Math.floor(1000)));
    }
    return temp;
  }

  getTempScatterDataset() {
    let temp = [];
    for (let i = 0; i < 30; i++) {
      temp.push({
        x: Math.floor(Math.random() * Math.floor(100)),
        y: Math.floor(Math.random() * Math.floor(100))
      });
    }
    return temp;
  }
  componentDidMount(){
    this.setState({
      isLoadedProcess: true
    })
    localStorage.setItem('plant_id', this.props.match.params.plant_id);
    const plant_id = localStorage.getItem("plant_id");
    const listFunc = dailyProcessService.getDailyProcess;
    const listFuncExternal = ExternalLabAnalysisService.getExternalLabAnalysis;
    const listFuncInhouse = inHouseLabAnalysisService.getInHouseLabAnalysis;

    listFunc(plant_id).then(dailyProcessData => {
      dailyProcessData = dailyProcessData ? dailyProcessData : [];
      if(dailyProcessData.length > 0){
        dailyProcessData.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
          let lastdate= dailyProcessData[dailyProcessData.length-1].date;
          localStorage.setItem("current_daily_process_date",lastdate )
      }   else {
        localStorage.setItem("current_daily_process_date",null);
      }    
    })
    .then(() => {
      listFuncInhouse(plant_id).then(inHouseData => {
        inHouseData = inHouseData ? inHouseData : [];
        if(inHouseData.length >0){
          inHouseData.sort(function(a,b){
            var c = new Date(a.date);
            var d = new Date(b.date);        
            return c-d;
            });
          let lastdate= inHouseData[inHouseData.length-1].date;
          localStorage.setItem("current_inhouse_date",lastdate );
        }else{
          localStorage.setItem("current_inhouse_date", null);
        }
      });
    })
    .then(() => {
      listFuncExternal(plant_id).then(ExternalLabAnalysisData => {
        ExternalLabAnalysisData = ExternalLabAnalysisData ? ExternalLabAnalysisData: [];
        this.setState({
          isLoadedProcess: false
        })
        if(ExternalLabAnalysisData.length >0){
          ExternalLabAnalysisData.sort(function(a,b){
            var c = new Date(a.date);
            var d = new Date(b.date);        
            return c-d;
            });
          let lastdate= ExternalLabAnalysisData[ExternalLabAnalysisData.length-1].date;  
          localStorage.setItem("current_external_date",lastdate );
          
        }else{
          localStorage.setItem("current_external_date", null)
        }
      })
    })

    // listFuncInhouse(plant_id).then(inHouseData => {
    //   inHouseData = inHouseData ? inHouseData : [];
    //   if(inHouseData.length >0){
    //     inHouseData.sort(function(a,b){
    //       var c = new Date(a.date);
    //       var d = new Date(b.date);        
    //       return c-d;
    //       });
    //     let lastdate= inHouseData[inHouseData.length-1].date;
    //     localStorage.setItem("current_inhouse_date",lastdate );
    //   }else{
    //     localStorage.setItem("current_inhouse_date", null);
    //   }
    // });

    // listFuncExternal(plant_id).then(ExternalLabAnalysisData => {
    //   ExternalLabAnalysisData = ExternalLabAnalysisData ? ExternalLabAnalysisData: [];
    //   this.setState({
    //     isLoadedProcess: false
    //   })
    //   if(ExternalLabAnalysisData.length >0){
    //     ExternalLabAnalysisData.sort(function(a,b){
    //       var c = new Date(a.date);
    //       var d = new Date(b.date);        
    //       return c-d;
    //       });
    //     let lastdate= ExternalLabAnalysisData[ExternalLabAnalysisData.length-1].date;  
    //     localStorage.setItem("current_external_date",lastdate );
        
    //   }else{
    //     localStorage.setItem("current_external_date", null)
    //   }
    // })
  }

  render() {
    //let dashboardTemplate = "";
    const loadinModal = this.loadinModal();

    const charts = this.processes.map((p, i) => {
        return (
          <div key={i} className="mb-4 col-md-6 col-lg-3">
            <Card
              small
              className = "stats-small cursor-pointer"
              onClick={() => this.props.history.push("processes/" + p)}
            >
              <CardBody>
              <h4 className=" px-0 pb-0 card-body my-3 text-center"> {p} </h4>
              </CardBody>
            </Card>
            {/* <Card small 
              className="mt-5 h-100 py-5 cursor-pointer stats-small h-100"
              // onClick={() =>
              //   this.props.history.push("plants/" + p.plant_name + "/overview/" +p.plant_id)
              //   //this.props.history.push("wrangal/inhouse")
              //   //this.props.history.push("iextrenalLabAnalysis")
               
              // }
              onClick={() => this.props.history.push("processes/" + p)}
            >
              <CardBody className="p-0 d-flex bg-white border-0 py-3 d-flex align-items-center justify-content-center">
                <h1 className="text-dark py-5 text-center"> {p} </h1>
              </CardBody>
            </Card> */}
          </div>
        );
      });
    
    // const colors = [
    //   {
    //     borderColor: "rgba(0,123,255)",
    //     backgroundColor: "rgba(0,123,255,0.8)"
    //   },
    //   {
    //     borderColor: "rgba(255,65,105)",
    //     backgroundColor: "rgba(255,65,105,0.8)"
    //   },
    //   {
    //     borderColor: "rgb(23,198,113)",
    //     backgroundColor: "rgb(23,198,113,0.8)"
    //   }
    // ];
    // const charts = this.processes.map((p, i) => {
    //   return (
    //     <div
    //       key={i}
    //       className="col-lg-4 mt-4 h-50 cursor-pointer"
    //       onClick={() => this.props.history.push("processes/" + p)}
    //     >
    //       <SmallStats
    //         variation="0"
    //         chartData={[
    //           {
    //             label: "Today",
    //             fill: "start",
    //             borderWidth: 1.5,
    //             backgroundColor: "transaparent",
    //             borderColor: colors[0].borderColor,
    //             data: this.getTempDataset()
    //           },
    //           {
    //             label: "Today",
    //             fill: "start",
    //             borderWidth: 1.5,
    //             backgroundColor: "transparent",
    //             borderDash: [3, 3],
    //             borderColor: colors[1].borderColor,
    //             data: this.getTempDataset()
    //           },
    //           {
    //             label: "Today",
    //             fill: "start",
    //             borderWidth: 1.5,
    //             backgroundColor: "transparent",
    //             borderDash: [3, 3],
    //             borderColor: colors[2].borderColor,
    //             data: this.getTempDataset()
    //           }
    //         ]}
    //         chartLabels={this.labels}
    //         label={"Metrics"}
    //         value={p.toUpperCase()}
    //       />
    //     </div>
    //   );
    // });

    return (
      <div className="mh-85 h-85" onClick={this.props.navToggle}>
        {/* <MainSidebar /> */}
        <Container
          fluid
          className="main-content-container h-100 px-4"
        >
          {/* Page Header */}
          {loadinModal}
          <Row noGutters className="page-header col-12 py-4">
            <PageTitle
              title={
                new String(this.props.match.params.plant_name) +
                " Overview"
              }
              subtitle="Metrics"
              className="text-sm-left mb-3"
            />
            {/* <div style={{marginLeft:"350px"}}> */}
            {/* <Button style={{marginRight:"29px"}}
              onClick={() => this.props.history.push("dailyprocess")}
            >
              Add Daily Process Data
            </Button>
            
            <Button
              onClick={() => this.props.history.push("list-daily-process")}
            >
              List Daily Process Data
            </Button>
            </div> */}
            
            
          </Row>
          <Row style={{marginLeft: "10px"}} noGutters className="d-flex  py-4 flex-wrap align-content-stretch pb-5">
          {charts}
        </Row>
        </Container>
      </div>
    );
  }
}

ProcessesDashboard.propTypes = {
  history: object.isRequired,
  navToggle: func.isRequired,
  match: object.isRequired
};

export default withRouter(ProcessesDashboard);
