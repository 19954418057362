/* eslint-disable import/no-named-as-default */
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";

import AdminApp from "./admin/AdminApp";
// import AuthApp from "./auth/AuthApp";

import { Switch, Route } from "react-router-dom";
import PublicApp from "./public/PublicApp";
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
import ProtectedRoute from "../utils/ProtectedRoute";

class App extends React.Component {
  render() {
    return (
      <Switch>
        <ProtectedRoute path="/admin" component={AdminApp} />
        <Route path="/" component={PublicApp} />
      </Switch>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
