import React, {
  Component
} from "react";
import {
  Switch,
  Route
} from "react-router-dom";
// import ProcessMetricsDashboard from "./ProcessMetricsDashboard";
import ProcessesDashboard from "./ProcessesDashboard";
import DailyProcessData from "../../admin/views/plant/WrangalPlant/DailyProcessData";
import WrangalPlantManager from "../../admin/views/plant/WrangalPlant/ListDailyProcessData"
import LabAnalysisInHouseData from "../../admin/views/plant/WrangalPlant/LabAnalysisInHouseData"
import LabAnalysisExternaleData from "../../admin/views/plant/WrangalPlant/LabAnalysisExternalData"
import ListLabAnalysisData from "../../admin/views/plant/WrangalPlant/ListLabAnalysisData"
import EditDailyProcessData from "../../admin/views/plant/WrangalPlant/EditDailyProcessData";
import EditLabAnalysisInHouseData from "../../admin/views/plant/WrangalPlant/EditLabAnalysisInHouseData"
import ExternalLabAnalysisFormTemplate from '../../admin/views/plant/WrangalPlant/EditLabAnalysisExternal';

import PlantInputOutputReport from "../../admin/containers/Report/PlantInputOutputReportTemplate";
import PlantOperationReport from "../../admin/containers/Report/PlantOperationReportTemplate"
import TreatedWaterReportTemplate from "../../admin/containers/Report/TreatedWaterReportTemplate"
import CommentReportTemplate from '../../admin/containers/Report/CommentReportTemplate'
import FreeFormReportTemplate from '../../admin/containers/Report/FreeFormReportTemplate'
import {
  func
} from "prop-types";

class PlantsOverviewApp extends Component {
  render() {
      return ( 
        <Switch >
          <Route exact path = "/admin/plants/:plant_name/overview/:plant_id"
          render = {
            () => < ProcessesDashboard navToggle = {
              this.props.navToggle
            }
            />} 
            />
          {/* <Route exact path = "/admin/plants/:plant_name/overview/:plant_id"
          render = {
            () => < ProcessesDashboard navToggle = {
              this.props.navToggle
            }
            />} 
            /> */}
            <Route
            exact
            path = "/admin/plants/:plant_name/:plant_id/processes/:process_name"
            render = {
              () => ( <PlantInputOutputReport navToggle = {
                  this.props.navToggle
                }
                />
              )
            }
            
            /> 

                        {/* <Route
            exact
            path = "/plants/:plant_name/processes/:process_name"
            render = {
              () => ( <PlantInputOutputReport navToggle = {
                  this.props.navToggle
                }
                />
              )
            }
            
            />  */}
            {/* <Route
            exact
            path = "/admin/plants/:plant_name/processes/list-daily-process"
            render = {
              () => ( <DailyProcessData 
                navToggle = {
                  this.props.navToggle
                }
                />
              )
            }
            />  */}
            <Route
            exact
            path = "/admin/plants/:plant_name/dailyprocess"
            render = {
              () => < DailyProcessData navToggle = {
                this.props.navToggle
              }
              />} 
              />
              <Route
              exact
              path = "/admin/plants/:plant_name/list-daily-process"
              render = {
                () => < WrangalPlantManager navToggle = {
                  this.props.navToggle
                }
                />} />
                <Route
                exact
                path = "/admin/plants/:plant_name/In-house"
                render = {
                  () => < LabAnalysisInHouseData navToggle = {
                    this.props.navToggle
                  }
                  />} />
                  <Route
                  exact
                  path = "/admin/plants/:plant_name/external"
                  render = {
                    () => < LabAnalysisExternaleData navToggle = {
                      this.props.navToggle
                    }
                    />} />
                    <Route
                    exact
                    path = "/admin/plants/:plant_name/list-lab-analysis"
                    render = {
                      () => < ListLabAnalysisData navToggle = {
                        this.props.navToggle
                      }
                      />} />
                      <Route
                      exact
                      path = "/admin/plants/:plant_name/edit-daily-process-data"
                      render = {
                        () => < EditDailyProcessData navToggle = {
                          this.props.navToggle
                        }
                        />} 
                        />
                        <Route
                        exact
                        path = "/admin/plants/:plant_name/edit-lab-analysis-process-data"
                        render = {
                          () => <EditLabAnalysisInHouseData navToggle = {
                            this.props.navToggle
                          }
                          />} 
                          />
                          
                          <Route
                          exact
                          path="/admin/plants/:plant_name/edit-external-lab-analysis-process-data"
                          render={() => <ExternalLabAnalysisFormTemplate navToggle={this.toggleActions} />}
                        />

                                                 
                        <Route
                          exact
                          path="/admin/plants/:plant_name/processes/:process_name/one"
                          render={() => <PlantInputOutputReport navToggle={this.toggleActions} />}
                        />
                         <Route
                          exact
                          path="/admin/plants/:plant_name/processes/:process_name/two"
                          render={() => <TreatedWaterReportTemplate navToggle={this.toggleActions} />}
                        />

                        <Route
                          exact
                          path="/admin/plants/:plant_name/processes/:process_name/three"
                          render={() => <PlantOperationReport navToggle={this.toggleActions} />}
                        />
                        <Route
                          exact
                          path="/admin/plants/:plant_name/processes/:process_name/four"
                          render={() => <CommentReportTemplate navToggle={this.toggleActions} />}
                        />
                        <Route
                          exact
                          path="/admin/plants/:plant_name/processes/:process_name/five"
                          render={() => <FreeFormReportTemplate navToggle={this.toggleActions} />}
                        />
                          </Switch>
                        );
                      }
                    }

              PlantsOverviewApp.propTypes = {
                navToggle: func.isRequired
              };

            export default PlantsOverviewApp;