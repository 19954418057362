export const SIDEBAR_TAB_SWITCH = "SIDEBAR_TAB_SWITCH";
export const SAVE_EDIT_SESSION = "SAVE_EDIT_SESSION";
export const CLEAR_EDIT_STATE = "CLEAR_EDIT_STATE";
export const NAVBAR_ACTIONS = "NAVBAR_ACTIONS";
export const SEARCH_LIST = "SEARCH_LIST";

// new
export const UNAUTH_USER = "UNAUTH_USER";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_CURRENT_USER = "SAVE_CURRENT_USER";
export const LOGOUT = "LOGOUT";
export const RESPONSE_DIALOG = "RESPONSE_DIALOG";
export const SAVE_NEW_PLANT = "SAVE_NEW_PLANT";
export const EDIT_PLANT = "EDIT_PLANT";
export const DELETE_PLANT = "DELETE_PLANT"




export const ADD_DAILY_PROCESS = "ADD_DAILY_PROCESS";
export const DELETE_DAILY_PROCESS = "DELETE_DAILY_PROCESS";
export const EDIT_DAILY_PROCESS = "EDIT_DAILY_PROCESS";



export const ADD_LAB_ANALYSIS_IN_HOUSE = "ADD_LAB_ANALYSIS_IN_HOUSE";
export const EDIT_LAB_ANALYSIS_IN_HOUSE = "EDIT_LAB_ANALYSIS_IN_HOUSE";
export const DELETE_LAB_ANALYSIS_INHOUSE = "DELETE_LAB_ANALYSIS_INHOUSE";

export const ADD_LAB_ANALYSIS_EXTERNAL = "ADD_LAB_ANALYSIS_EXTERNAL";
export const EDIT_LAB_ANALYSIS_EXTERNAL = "EDIT_LAB_ANALYSIS_EXTERNAL";
export const DELETE_LAB_ANALYSIS_EXTERNAL = "DELETE_LAB_ANALYSIS_EXTERNAL";

export const SAVE_PERMISSION = "SAVE_PERMISSION";

export const ITEM_PER_PAGE = "10";