import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";

export default ({ searchBarVisible, search_key, on_search_function }) => {
  if (!searchBarVisible) {
    return (
      <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
        &nbsp;
      </div>
    );
  } else {
    return (
      <Form
        className="main-navbar__search w-100 d-none d-md-flex d-lg-flex"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <InputGroup seamless className="ml-3">
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <i className="material-icons">search</i>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            value={search_key}
            onChange={on_search_function}
            className="navbar-search"
            placeholder="Search for something..."
          />
        </InputGroup>
      </Form>
    );
  }
};
