
export const CTPTSeptage = (
    srs = "",
    kkbr1 = "",
    kkbr2 = "",
  ) => {
    return {
        srs,
        kkbr1,
        kkbr2
    };
  };
  
  
  export default CTPTSeptage(
  );