import axios from "axios";
import {
  UNAUTH_USER,
  SAVE_TOKEN
} from "../constants/actionTypes/AppStateActionTypes";
import * as authService from "../services/AuthService";

export default {
  setupInterceptors: store => {
    axios.interceptors.request.use(
      function(config) {
        const state = store.getState();
        if (state.AppState.token) {
          // console.log('state.AppState.token');
          const time_diff =
            state.AppState.tokenReceivedTime +
            24 * 55 * 60 * 1000 -
            new Date().getTime();
            // console.log(time_diff);
            
          if (time_diff < 0) {
            store.dispatch({
              type: UNAUTH_USER
            });
            return authService.refreshToken().then(res => {
              // config.onUploadProgress = progressEvent => {
              //   let percentCompleted = Math.floor(
              //     (progressEvent.loaded * 100) / progressEvent.total
              //   );
              // };

              config.headers.authorization = res.authToken;
              store.dispatch({
                type: SAVE_TOKEN,
                token: res.authToken
              });
              return Promise.resolve(config);
            });
          } else {
            // console.log('!!!state.AppState.token');
            // config.onUploadProgress = progressEvent => {
            //   let percentCompleted = Math.floor(
            //     (progressEvent.loaded * 100) / progressEvent.total
            //   );
            // };
            config.headers.authorization = state.AppState.token;
            store.dispatch({
              type: SAVE_TOKEN,
              token: state.AppState.token
            });
            return config;
          }
        }
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function(response) {
        if (response.headers.authorization) {
          return {
            ...response.data,
            authToken: response.headers.authorization
          };
        }
        return response.data;
      },
      function(error) {
        const resStatus = error.response.status;
        if (resStatus == 401) {
          store.dispatch({ type: UNAUTH_USER });
        }
        // let test = "";
        // switch (error.response.status) {
        //   case 401:
        //     store.dispatch({ type: UNAUTH_USER });
        //     break;
        //   default:
        //     test = "out of options";
        // console.log("working");
        // store.dispatch({ type: UNAUTH_USER });
        // }
        return Promise.reject(error);
      }
    );
  }
};
