import React, { Component } from "react";
// import ChartsTemplate from "./charts/ChartsTemplate";
// import PageTitle from "../../common/PageTitle";
import PageTitle from "../../../common/PageTitle";
import * as reportService from "../../../../services/ReportService";
import logo from '../../../assets/Spinner-1s-200px.gif';
import { withRouter } from "react-router";
// import MainSidebar from "../../layout/MainSidebar/MainSidebar";
import { connect } from "react-redux";

import MainSidebar from '../../../layout/MainSidebar/MainSidebar'
import PlantOperationGraph from './Graph/PlantOperationGraph';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  FormInput,
  Button
} from "shards-react";
import { object } from "prop-types";
// import { getMetrics } from "../../../services/DashboardService";

class PlantOperationReportTemplate extends Component {
  constructor(props) {
    super(props);

    // this.toggleModal = this.toggleModal.bind(this);

    // this.formatDataset = this.formatDataset.bind(this);
    // this.getChartConf = this.getChartConf.bind(this);
    // this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.on_change = this.on_change.bind(this);
    // this.handleStartDateChange = this.handleStartDateChange.bind(this);

    this.state = {
      date: Date(),
      open: false,
      modalDiv: null,
      labels: [],
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30),
      endDate: new Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      entryListForTable: {},
      plant_id:"",
      isDataLoadedFully:false,
      isDataLoaded:false,
      validationError: "",  
      dataNotFoundMessaage: "",
    };

  }
  componentDidMount(){
    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully
    })
    this.setState({
      plant_id:localStorage.getItem('plant_id')
    })
    let data;
    let date = localStorage.getItem('current_daily_process_date');
    this.setState({
      date:date
    })
    const plant_id = localStorage.getItem('plant_id');
    const listTableData = reportService.getPlantOperationData;
     listTableData(plant_id, date).then(entryListForTableRes => {
       data = entryListForTableRes;              
      this.setState({ 
        entryListForTable: data,
        // PH: entryListForTableRes.PH,
        // BOD: entryListForTableRes.BOD,
        // COD: entryListForTableRes.COD,
        // TSS: entryListForTableRes.TSS,
        // F_coil: entryListForTableRes.F_coil
      });
      this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully
      })  
    }, () => {
      //console.log(JSON.stringify(error));
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
       })
       //let message  = error.response.data.message;
       //console.log(message)
       this.setState({
        dataNotFoundMessaage: "No Record Found"
      })

});  
  }

  
  on_change(e) {
    this.setState({
     date: e.target.value,
     validationError: e.target.value === "" ? "Please select the date" : ""
    });        
  }


  toggle() {
    this.setState(prevState => {
      return { dropdown1: !prevState.dropdown1 };
    });
  }
  showGraph(){
    //this.getTableData();
    this.setState({
      isMoreButtonClicked: !this.state.isMoreButtonClicked
    })    
  }

  getTableData(){
    const date = this.state.date;
  
    if(date == ""){
      let errorMessage = "Please select the date";
      this.setState({
        validationError: errorMessage
      })
    }else{
    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully,
      isDataLoaded: false,
      entryListForTable: {}           
    })
    let data;
    const date = this.state.date;
    const plant_id = this.state.plant_id;
    const listTableData = reportService.getPlantOperationData;
     listTableData(plant_id, date).then(entryListForTableRes => {
       data = entryListForTableRes;

        
       if(data.message != null){
        this.setState({
          isDataLoaded: true
          })
          this.setState({ 
            isDataLoadedFully: !this.state.isDataLoadedFully,
            entryListForTable: data,
            PH: "",
            BOD: "",
            COD: "",
            TSS: "",
            F_coil: ""
          });
          
       }else{
        this.setState({ 
          entryListForTable: data,
          isDataLoaded: false
          // PH: entryListForTableRes.PH,
          // BOD: entryListForTableRes.BOD,
          // COD: entryListForTableRes.COD,
          // TSS: entryListForTableRes.TSS,
          // F_coil: entryListForTableRes.F_coil
        });
        this.setState({
          isDataLoadedFully: !this.state.isDataLoadedFully
        })
       

       }             

    }, (error) => {
      //console.log(JSON.stringify(error));
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
       })
       let message  = error.response.data.message;
       //console.log(message)
       this.setState({
         dataNotFoundMessaage: message
       })

});  
  }
  }
  


  render() {
    const isDataLoadedFully = this.state.isDataLoadedFully 
    ? <div className="text-center" >
      <img style={{width:"90px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const isDataLoaded = this.state.isDataLoaded 
    ? <Row className="py-2">
    <Col  className="">
      <div className="text-center" style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
      </div> 
    </Col>
 </Row>
    : null;
    

    const graphicalView = this.state.isMoreButtonClicked;
    const listItems = this.setup_searchOne();

    let button;

    if(graphicalView){
      button = <PlantOperationGraph/>;

    }

    const table = (
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr className="text-center">
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">Pyrolyzer Run Time(Hour)</th>
                  <th scope="col" className="border-0">Power Consumed(KWatts)</th>
                  
                  <th scope="col" className="border-0">Treated Water(Ltrs)</th>
                  <th scope="col" className="border-0">Solid Generated(Kgs)</th>
                  <th scope="col" className="border-0">Septage Treated(Ltrs)</th>
                </tr>
              </thead>
             
              <tbody>
              {listItems}
      
                </tbody>
            </table>
            {isDataLoadedFully}
            {isDataLoaded}
          </CardBody>
        </Card>
      </Col>
    </Row>
    );

    

    return (
      <div className="mh-85">
        <MainSidebar />

        <Breadcrumb className="col-10 float-right p-0">
        <BreadcrumbItem> 
          <a onClick={() => this.props.history.push("/admin/plants/"+ this.props.match.params.plant_name+ "/overview/"+ localStorage.getItem('plant_id'))} style={{color:"#007bff", cursor:"pointer"}}>
            {new String(this.props.match.params.plant_name)} Overview
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
          {new String(this.props.match.params.process_name)}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container
          fluid
          className="main-content-container px-4 col-10 float-right"
        >
          {/* <Modal
            modalClassName="offset-2 col-10 d-flex align-items-center"
            className="w-95"
            size="lg"
            open={this.state.open}
            toggle={this.toggleModal}
          >
            {/* <ModalHeader>Header</ModalHeader> */}
            {/* <ModalBody>{this.state.modalDiv}</ModalBody> */}
            {/* {this.state.modalDiv}
          </Modal> */} 


          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={
               "Plant Operation Report"
              }
              subtitle=""
              className="text-sm-left mb-3"
            />
                       <Col>
                       <div className="float-right">
                       {this.props.permission.edit == "YES" ? (
            <Button style={{marginRight:"29px"}}
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/dailyprocess/")}
            >
              
              Add Daily Process Data
            </Button>): (
          ""
        )}
            
            <Button
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")}
            >
              List Daily Process Data
            </Button>
            </div>
           </Col>
                

          </Row>
          {/* <div className="d-flex w-75 mx-auto justify-content-center">
            <Button
              theme="danger"
              onClick={() => this.props.history.push("report")}
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Cancel
            </Button>
            </div> */}

        

{/*                   
            <div>
              <FormSelect className="col-md-4 offset-md-8 mb-2">
                <option value="Select Report Type">Select Report Type</option>
                <option value="first">Plant Input/Output Overview</option>
                <option value="first">Plant Operation Data</option>
                <option value="first">Comments</option>
                <option value="first">Free Form</option>

              </FormSelect>
            </div> */}
                      <div style={{display: "flex"}}>

{/* <div className="text-sm-left">
  <label>Plant Operation Report</label>

</div> */}
<div style={{marginLeft:"773px"}}>

  {/* <Dropdown
open={this.state.dropdown1}
toggle={() => this.toggle(1)}

className="offset-md-4 mb-2"
>
<DropdownToggle caret>Select Report Type</DropdownToggle>
<DropdownMenu right>
<DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/one")}>Plant Input/Output Overview</DropdownItem>
<DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/two")}>Treated Water Data</DropdownItem>
<DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/three")}>Plant Operation Data</DropdownItem>
<DropdownItem onClick={() => this.props.history.push(new String(this.props.match.params.process_name) + "/report/four")}> Comments</DropdownItem>
</DropdownMenu>
</Dropdown> */}
</div>
</div>


        <Row className="py-2">
          <Col sm="2" className="d-flex mb-2 mb-sm-0"> 
            <FormInput    
              name = "date"
              type="date"
              value = {
                this.state.date
              }
              onChange={this.on_change}
            />            
            <Button
             onClick={(e) => this.getTableData(e)}>
               Show
            </Button>
          </Col>
        </Row> 
        <Row className="py-2">
            <Col sm="2" className="d-flex mb-2 mb-sm-0">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
        </Row>
         
         

          {table}
          <Button style={{marginRight:"29px"}}
             onClick={(e) => this.showGraph(e)}
          >
              {graphicalView ? "Less" : "More"}
            </Button>
         
          {button}

    

          {/* <Row>{charts}</Row> */}
        </Container>
      </div>
    );
  }

  setup_searchOne(){
    let rearrangedEntryList = {} ;
    rearrangedEntryList = this.state.entryListForTable;   
    if(rearrangedEntryList.pyrolyzer != null){
     return(
        <tr className="text-center">
          <td>1</td>
          <td> {rearrangedEntryList.pyrolyzer[0] != null? rearrangedEntryList.pyrolyzer[0] : 'NA'}</td>
          <td> {rearrangedEntryList.power_consumed != null ? rearrangedEntryList.power_consumed : 'NA'}</td>
          <td> {rearrangedEntryList.treated_water_taken[0] != null ? rearrangedEntryList.treated_water_taken[0]: 'NA'} </td>
          <td>{rearrangedEntryList.qty_of_solids_generated[0] != null? rearrangedEntryList.qty_of_solids_generated[0] : 'NA'}</td>
          <td>{rearrangedEntryList.septage_treated != null ? rearrangedEntryList.septage_treated : 'NA'}</td>
        </tr>
          );
   }else{
    return(
      <tr className="text-center">
        <td></td>
        <td> </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
        );
   }

  }
}

function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,
  };
}

PlantOperationReportTemplate.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired

};

// export default withRouter(PlantOperationReportTemplate);
export default withRouter(
  connect(
    mapStateToProps
  )(PlantOperationReportTemplate)
);