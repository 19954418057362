import axios from "axios";

// export const getExternalLabAnalysisById = id => {
//   return axios.get("/api/v1/lab_analysis_externals/" + id);
// };

export const getExternalLabAnalysis = (plant_id) => {
  return axios.get("/api/v1/lab_analysis/external/" +  plant_id);
};

export const getExternalLabAnalysisByProcessIdPlantId = (plant_id, process_id ) => {
  return axios.get("/api/v1/lab_analysis/external/" + plant_id + "/" +process_id );

};

export const addExternalLabAnalysis = ExternalLabAnalysis => {
  return axios.post("/api/v1/lab_analysis_externals", ExternalLabAnalysis);
};

export const editExternalLabAnalysis = lab_analysis_external => {
  return axios.put(
    "/api/v1/lab_analysis_externals/" + lab_analysis_external.lab_analysis_external._id.$oid,
    lab_analysis_external
  );
};

export const deleteExternalLabAnalysis = id => {
  return axios.delete("/api/v1/lab_analysis_externals/" + id);
};
