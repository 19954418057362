import React from "react";
import ExternalabAnalysisFormTemplate from "../../../containers/WrangalPlant/ExternalLabAnalysisFormTemplate";

import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const LabAnalysisExternal = ({ navToggle }) => {
  return (
    <ExternalabAnalysisFormTemplate
      action = {types.ADD_EXTERNAL_LAB_ANALYSIS}
      form_title = {
        "External Lab Analysis"
      }
      navToggle={navToggle}
      button_title = {
        "Add External Lab Analysis data"
      }
    />
  );
};

LabAnalysisExternal.propTypes = {
  navToggle: func.isRequired
};

export default LabAnalysisExternal;
