import React, { Component } from "react";
import {
  saveExternalLabAnalysisData,
  saveEditSession,
  displayResponseMessage,
  editExternalLabAnalysisData
} from "../../../../actions/AppStateActions";
import {External} from '../../../../models/LabAnalysis/External/External';

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {object} from 'prop-types';
//import Picky from "react-picky";

import {
  Card,
  CardBody,
  Form,
  Button,
  FormInput,
  Collapse,
  FormTextarea,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  FormSelect
} from "shards-react";

// import Select from 'react-select';


import ResponseMessage from "../../../common/ResponseMessage";
import * as ExternalLabAnalysisService from "../../../../services/ExternalLabAnalysisService";


class ExternalLabAnalysisFormTemplate extends Component {
  //addAnalysisArr = [];
  constructor(props) {
    super(props);
    this.addAnalysis = {}
    //this.addAnalysisArr = [];
    this.state = {
      lab_analysis_external: new External(),
      collapseSeptageReceived: false,
      collapseSRS: false,
      collapseHH:false,
      collapseCCPT:false,
      collapseDSH:false,
      collapseConsumable: false,
      collapseMetering: false,
      collapseObservable: false,
      submitted:false,
      open: false,
      selectedOption: {value: 'ph', label: 'ph'},
      additional_analysis: [
      {value: 'ph', label: 'PH'},
      {value: 'orp', label: 'ORP'},
      {value: 'ts', label: 'TS'},
      {value: 'bod', label: 'BOD'},
      {value: 'cod', label: 'COD'},
      {value: 'f_coil', label: 'F Coli'},
      {value:'nitrates', label: 'Nitrates'},
      {value:'phosphates', label: 'Phosphates'},
      {value:'potassium', label: 'Potassium'},
      {value:'solid_carbon', label: 'Solid carbon'},
      {value:'solid_hydrogen', label: 'Solid hydrogen'},
      {value:'solid_nitrogen', label: 'Solid Nitrogen'},
      {value:'solid_oxygen', label: 'Solid oxygen'},
      {value:'solid_sulphur', label: 'Solid sulphur'},
      {value:'sludge_moisture', label: 'Sludge moisture'},
      {value:'sludge_volatiles', label: 'Sludge volatiles'},
      {value:'sludge_fixed_carbon', label: 'Sludge fixed carbon'},
      {value:'sludge_ash', label: 'Sludge ash'},
      {value:'biochar_ash_moisture', label: 'Biochar ash moisture'},
      {value:'biochar_fixed_carbon', label: 'Biochar  fixed carbon'},
      ],
      selectedValue: [{value: 'ph', label: 'ph'}],
      addAnalysisArr: [],
      count: 1,
      selectedAddItem: '',
      isSubmitted: false,
    };
    this.dropdownSeptagereceived = this.dropdownSeptagereceived.bind(this);
    this.dropdownSRS = this.dropdownSRS.bind(this);
    this.dropdownHH = this.dropdownHH.bind(this);
    this.dropdownCCPT=this.dropdownCCPT.bind(this);
    this.dropdownDSH=this.dropdownDSH.bind(this);
    this.dropdownConsumable = this.dropdownConsumable.bind(this);
    this.dropdownMetering = this.dropdownMetering.bind(this);
    this.dropdownObservable = this.dropdownObservable.bind(this);

    this.on_change = this.on_change.bind(this);
    this.on_change_treated_water =  this.on_change_treated_water.bind(this);
    this.on_change_kkbr_outlet =  this.on_change_kkbr_outlet.bind(this);
    this.on_change_phytorid = this.on_change_phytorid.bind(this);
    this.on_change_additional_analysis = this.on_change_additional_analysis.bind(this);
    this.on_multidrop_change = this.on_multidrop_change.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
    this.submit_form = this.submit_form.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addAdd = this.addAdd.bind(this);
  }

  componentDidMount() {
    // // console.log(this.state.lab_analysis_external);
    if(this.props.action == "EDIT_LAB_ANALYSIS_EXTERNAL"){
      // // console.log("EDIT_LAB_ANALYSIS_EXTERNAL");
      //console.log(this.props.edit_session);
      this.setState({
        lab_analysis_external: this.props.edit_session
      });
      // console.log(this.state.lab_analysis_external);
      // console.log(this.props.edit_session);
      // for(let i=0; i<this.props.edit_session.)
      let add = this.props.edit_session.additional_analysis;
      //let a = [];
      for(let i=0;i<add.length;i++){
        //let b = {};
        this.state.addAnalysisArr.push(add[i].key);
      }
      // for(let key in add){
      //   //console.log(add[key])
      //   let b = {};
      //   //selectedValue: [{value: 'ph', label: 'ph'}],
      //   if(key != '_id'){
      //     if(add[key]){
      //       this.state.addAnalysisArr.push(key);
      //       b= {
      //         value: [key],
      //         label: [key]
      //       }
      //       a.push(b);
      //       //console.log('has value')
      //     }
      //   }

      //   //add[key]`

      // }
      // this.setState({
      //   selectedValue: a
      // })


            
    }

    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external,
        user_id: this.props.current_user.user_id,
        plant_id:localStorage.getItem('plant_id'),
        process_detail_id:localStorage.getItem('currentProcessId'),
        date: localStorage.getItem("currentProcesssDate")
      }
    }))
  }

  addAdd(event){
    //console.log(event.target.dataset.id)
    this.setState({selectedAddItem: event.target.value})
    // console.log(this.state.selectedAddItem);
    // console.log(event.target.value)
    // console.log(event.target.dataset.id)
    const addAnalysisArr = [...this.state.addAnalysisArr];
    addAnalysisArr[event.target.dataset.id] = event.target.value
    this.setState({addAnalysisArr});

    const septa = [...this.state.lab_analysis_external.additional_analysis];
    septa[event.target.dataset.id]['key'] = this.state.addAnalysisArr[event.target.dataset.id];
    this.setState({septa}
    )


    // this.setState({
    //   [addAnalysisArr][event.target.dataset.id]: event.target.value
    // })
    // this.state.addAnalysisArr[event.target.dataset.id] = event.target.value;
    // let l =this.state.addAnalysisArr.length;
    // if(l >= event.target.dataset.id){
    //   this.state.addAnalysisArr.pop();
    // }else{
    //   this.state.addAnalysisArr.push(event.target.value);
    // }
    //this.state.addAnalysisArr.splice(event.target.dataset.id, 0, event.target.value);

    // var a = this.state.additional_analysis.filter(function(item){
    //   return item.value != event.target.value
    // })
    // this.setState({
    //   additional_analysis: a
    // })
  }
  handleSelect(selectedOption) {
    // console.log(selectedOption);
    // console.log(this.state.selectedOption);
    this.addAnalysisArr.push(selectedOption.value);
  //   this.setState({
  //     selectedOption:selectedOption
  //   })

  //   var a = this.state.additional_analysis.filter(function(item){
  //     return item.value != selectedOption.value
  //   })
  //   this.setState({
  //     additional_analysis: a
  //   })
  //  // console.log(selectedOption)
  // var a = this.state.additional_analysis.filter(function(item){
  //   return item.value != selectedOption.value
  // })
 


   

   // this.state.additional_analysis
  }



  componentWillUnmount() {
    this.props.actions.saveEditSession(null);
  }


  dropdownSeptagereceived(){
    this.setState({ collapseSeptageReceived: !this.state.collapseSeptageReceived });
  }
  dropdownSRS(){
    this.setState({ collapseSRS: !this.state.collapseSRS });
  }

  dropdownHH(){
    this.setState({collapseHH: !this.state.collapseHH})
  }
  dropdownCCPT(){
    this.setState({collapseCCPT: !this.state.collapseCCPT})
  }

  dropdownDSH(){
    this.setState({collapseDSH: !this.state.collapseDSH})
  }
  dropdownConsumable(){
    this.setState({collapseConsumable: !this.state.collapseConsumable})
  }
  dropdownMetering(){
    this.setState({collapseMetering: !this.state.collapseMetering})
  }
  dropdownObservable(){
    this.setState({collapseObservable: !this.state.collapseObservable})
  }
  on_multidrop_change(value){
    //console.log("Val", value);
    this.setState({ arrayValue: value });
    //console.log(plants);
    


  }
  removeLoadUI(event){
    // console.log(event.target.dataset.id);
    this.state.lab_analysis_external.additional_analysis.splice(event.target.dataset.id, 1);
    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external
      }
    }))


    // let length = this.state.selectedValue.length;
    // this.state.selectedValue.splice(length -1, 1);
    // this.setState({
    //   selectedValue: this.state.selectedValue

    //})

  }
  on_change_treated_water(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external,
        treated_water_external: {
          ...prevState.lab_analysis_external.treated_water_external,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_kkbr_outlet(event){
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external,
        kkbr_outlet_external: {
          ...prevState.lab_analysis_external.kkbr_outlet_external,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_phytorid(event){
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external,
        phytorid_outlet_external: {
          ...prevState.lab_analysis_external.phytorid_outlet_external,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_additional_analysis(e){
    // console.log(event.target.value);
    // console.log(event.target.name)
    
    const septa = [...this.state.lab_analysis_external.additional_analysis];
    septa[e.target.dataset.id][e.target.name] = e.target.value
    this.setState({septa}
    )
    // this.setState(prevState => ({
    //   ...prevState,
    //   lab_analysis_external: {
    //     ...prevState.lab_analysis_external,
    //     additional_analysis: {
    //       ...prevState.lab_analysis_external.additional_analysis,
    //       [event.target.name]: event.target.value
    //     }
    //   }
    // }))
  }

  on_change(event){
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external,
        [event.target.name]: event.target.value
      }
    }))
  }

  toggleModal() {
    for(let i=0;i<this.state.addAnalysisArr.length; i++){
      const septa = [...this.state.lab_analysis_external.additional_analysis];
      septa[i]['key'] = this.state.addAnalysisArr[i];
      this.setState({septa}
      )
      // this.setState(prevState => ({
      //   ...prevState,
      //   lab_analysis_external: {
      //     ...prevState.lab_analysis_external,
      //     [event.target.name]: event.target.value
      //   }
      // }))
      // this.state.lab_analysis_external.additional_analysis[i].key = this.state.addAnalysisArr[i];
    }
    this.setState({
      submitted: true,
      open: !this.state.open
    });
  }

  addAdditionalAnalysis(){
    this.setState(prevState => ({
      ...prevState,
      lab_analysis_external: {
        ...prevState.lab_analysis_external,
        additional_analysis: [
          ...prevState.lab_analysis_external.additional_analysis,
          { 
            key: "",
            value: "",
            comment: "",
          }
        ]             
      }
    }))
    //console.log(this.state.addAnalysisArr)

    // let nextCount = this.state.count;
    // nextCount = nextCount+1;
    // this.setState({
    //   count:nextCount
    // })

   // console.log(type);
    // this.state.selectedValue.push(type);
    // this.setState({
    //   selectedValue: this.state.selectedValue

    // })

    // var 
    // const 
    // let q= 3.14;
    // const a = 2;
    // a = 3;
    // console.log(a);
    // let q = [];
    // q = []
    // let a = [
    //   let q = 25;
    // ]
      
  
    //console.log(this.state.selectedValue);
    // this.setState({
    //   selectedOption: ""
    // })
    // let addA = [...this.state.additional_analysis]
    // let addAnalysisArr = [...this.state.addAnalysisArr];
    
    // console.log(addAnalysisArr);
    // console.log(this.state.additional_analysis);
    //   var a = this.state.additional_analysis.filter(function(item){
    //     //console.log(item, index)
    //   return addAnalysisArr.indexOf(item.value) == -1
    // })
    // this.setState({
    //   additional_analysis: a
    // })
    // const f =[];
    // addA.forEach((e1) => addAnalysisArr.forEach((e2) =>
    // {
    //   if(e1.value != e2){
    //     f.push(e1)
    //   }
    // }
    // ));
    // console.log(f);
    //    this.setState({
    //   additional_analysis: f
    // })
    // for(){

    // }
    
    // for(let i=0;i<addA.length;i++){
    //   for(let j = 0;j<addAnalysisArr.length;j++){
    //     if(addAnalysisArr[j] == addA[i].value){
    //       console.log(addAnalysisArr[j])
    //       console.log(addA[i].value)
    //       console.log(j)
    //       console.log(i)
    //       addA.splice(j,1);
    //       break;
    //     }
    //   }

    
    // }
    // s
    
  }

  submit_form(e) {
    e.preventDefault();        
    //let externalLabAnalysisData = this.state.lab_analysis_external;
    this.setState({
      isSubmitted: true,
    })


    const submit_func =
    this.props.action == "ADD_EXTERNAL_LAB_ANALYSIS"
      ? ExternalLabAnalysisService.addExternalLabAnalysis
      : ExternalLabAnalysisService.editExternalLabAnalysis;
    
    let lab_analysis_external = this.state.lab_analysis_external;
    if (!this.props.edit_session) {
      delete lab_analysis_external["_id"];
    }
    
      submit_func({ lab_analysis_external }).then(
        () => {
          // eslint-disable-next-line react/prop-types
          this.props.actions.displayResponseMessage(
            true,
            "success",
            "External  " +
              (this.props.edit_session ? "edited" : "added") +
              " successful."
          );
          this.props.history.push("list-lab-analysis");
          this.setState({
            open: !this.state.open,
            isSubmitted: false
          });
        },
        () => {
          this.toggleModal();
          this.setState({
            submitted: false,
            isSubmitted: false
          });
          this.props.actions.displayResponseMessage(
            true,
            "fail",
            "Sorry, something went wrong."
          );
        }
      );


    // // // console.log(externalLabAnalysisData);
    // if(this.props.action == "ADD_EXTERNAL_LAB_ANALYSIS"){
    //   // // console.log('ADD_EXTERNAL_LAB_ANALYSIS');
    //   // eslint-disable-next-line react/prop-types
    //   this.props.actions.saveExternalLabAnalysisData(externalLabAnalysisData);
    // }else{
    //   // console.log('EDIT_EXTERNAL_LAB_ANALYSIS');
    //   // eslint-disable-next-line react/prop-types
    //   this.props.actions.editExternalLabAnalysisData(externalLabAnalysisData);
    // }
    

    // this.props.history.push("list-lab-analysis");
  }

  render() {
    // const options = [
    //   {value: 'ph', label: 'ph'},
    //   {value: 'orp', label: 'orp'},
    //   {value: 'ts', label: 'ts'},
    //   {value: 'bod', label: 'bod'},
    //   {value: 'cod', label: 'cod'},
    //   {value: 'f_coil', label: 'f_coil'},
    // ];
    // const options = this.state.additional_analysis;
    // const { selectedOption } = this.state.selectedOption;
    // const plantList = this.state.arrayValue.map((p, i) => {
    //   return (
    //     <div key = {i}className="col-lg-3 mt-3">
    //     <label>{p}</label>
    //     <FormInput
    //       name={p}
    //       type="text"
    //       value = {this.state.lab_analysis_external.additional_analysis.ph}
    //       onChange = {this.on_change_additional_analysis}
    //     />
    //   </div>
    //   );
    // });
    return (
      <div className="mh-85">
        <ResponseMessage />
        <div className="text-dark my-3 px-5">
        <Breadcrumb className="mt-3">
          <BreadcrumbItem> 
          <a onClick={() => this.props.history.push("/admin/plants/"+ this.props.match.params.plant_name+ "/overview/"+ localStorage.getItem('plant_id'))} style={{color:"#007bff", cursor:"pointer"}}> {new String(this.props.match.params.plant_name)}  Overview</a>
          </BreadcrumbItem>
          <BreadcrumbItem active> 
            <a onClick={() => this.props.history.push("list-daily-process")} style={{color:"#007bff", cursor:"pointer"}}>List Daily Process</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
           External Lab Analysis
          </BreadcrumbItem>
        </Breadcrumb>
          <small> {new String(this.props.match.params.plant_name)}  Plant</small>
          <h4>Lab Analysis-External</h4>
        </div>

        {/* modal open for confirmation */}
        <Modal
          size="lg"
          open={this.state.open}
          style={{ marginTop: "55%" }}
          toggle={this.toggleModal}
          className="h-75 d-flex align-items-center justify-content-center"
          >
            <h4 className="text-center p-5 w-100">
            {"Are You sure you want to " + this.props.button_title + "?"}{" "}
            </h4>
            <div className="d-flex w-75 mx-auto justify-content-center">
              <Button
                theme="danger"
                onClick={this.toggleModal}
                className="col-5 mx-1 mt-2 mb-5 mx-auto"
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.isSubmitted == true}
                form = "external_lab_analysis_form"
                className="col-5 mx-1 mt-2 mb-5 mx-auto"
              >
                Yes
              </Button>
            </div>
        </Modal>



        <div className="d-flex flex-wrap h-75 mt-2 px-5 col-12 align-items-center justify-content-center">
          <Card style={{marginBottom: "30px"}} className="col-12 p-0">

            <CardBody className="p-0">
              <Form
                id="external_lab_analysis_form"
                className="d-flex flex-wrap justify-content-between"
                onSubmit={this.submit_form}
              >

              {/* For TREATED WATER  */}
              <div className="mt-1 p-0 col-12">
                <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop:"20px", paddingBottom:"15px"}}>
                  <div className="flex-wrap">
                    <label style={{ marginLeft: "15px", marginTop: "10px" }}>Treated Water</label>
                    <span className="pull-right" style={{float: "right", cursor: "pointer"}} onClick={this.dropdownSeptagereceived}>
                      <i className="material-icons md-36">arrow_drop_down</i>
                    </span>

                  </div>

                   <Collapse open={this.state.collapseSeptageReceived} style={{margin: "10px"}}>
                      <div style={{display: "flex"}}>
                        <div className="col-lg-3 mt-3">        
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value = {this.state.lab_analysis_external.treated_water_external.ph}
                            onChange = {this.on_change_treated_water}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="tss"
                            type="text"
                            value = {this.state.lab_analysis_external.treated_water_external.tss}
                            onChange = {this.on_change_treated_water}
                            />                    
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>COD(mg/L)</label>
                          <FormInput
                            name="cod"
                            type="text"
                            value = {this.state.lab_analysis_external.treated_water_external.cod}
                            onChange = {this.on_change_treated_water}
                            />                    
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>F-COLI(MPN/100ml)</label>
                          <FormInput
                            name="f_coil"
                            type="text"
                            value = {this.state.lab_analysis_external.treated_water_external.f_coil}
                            onChange = {this.on_change_treated_water}
                            />                    
                        </div>
                      </div>
                      <div className="col-lg-3 mt-3">
                          <label>BOD(mg/L)</label>
                          <FormInput
                            name="bod"
                            type="text"
                            value = {this.state.lab_analysis_external.treated_water_external.bod}
                            onChange = {this.on_change_treated_water}
                            />                    
                      </div>                   
                  </Collapse>
                 </Card>
              </div>

               {/* For KKBR Outlet  */}
               <div className="mt-1 p-0 col-12">
                <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop:"20px", paddingBottom:"15px"}}>
                  <div className="flex-wrap">
                    <label style={{ marginLeft: "15px", marginTop: "10px" }}>KKBR Outlet</label>
                    <span className="pull-right" style={{float: "right", cursor: "pointer"}} onClick={this.dropdownSRS}>
                      <i className="material-icons md-36">arrow_drop_down</i>
                    </span>

                  </div>

                   <Collapse open={this.state.collapseSRS} style={{margin: "10px"}}>
                     <div style={{display: "flex"}}>
                     <div className="col-lg-3 mt-3">        
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value = {this.state.lab_analysis_external.kkbr_outlet_external.ph}
                            onChange = {this.on_change_kkbr_outlet}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="tss"
                            type="text"
                            value = {this.state.lab_analysis_external.kkbr_outlet_external.tss}
                            onChange = {this.on_change_kkbr_outlet}
                            />                    
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>COD(mg/L)</label>
                          <FormInput
                            name="cod"
                            type="text"
                            value = {this.state.lab_analysis_external.kkbr_outlet_external.cod}
                            onChange = {this.on_change_kkbr_outlet}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>F-Coli(MPN/100ml)</label>
                          <FormInput
                            name="f_coil"
                            type="text"
                            value = {this.state.lab_analysis_external.kkbr_outlet_external.f_coil}
                            onChange = {this.on_change_kkbr_outlet}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mt-3">
                          <label>BOD(mg/L)</label>
                          <FormInput
                            name="bod"
                            type="text"
                            value = {this.state.lab_analysis_external.kkbr_outlet_external.bod}
                            onChange = {this.on_change_kkbr_outlet}
                          />
                        </div>                      
                  </Collapse>
                 </Card>
              </div>

              {/* PHYTORID OUTLET */}

              <div className="mt-1 p-0 col-12">
                <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop:"20px", paddingBottom:"15px"}}>
                  <div className="flex-wrap">
                    <label style={{ marginLeft: "15px", marginTop: "10px" }}>Phytorid Outlet</label>
                    <span className="pull-right" style={{float: "right", cursor: "pointer"}} onClick={this.dropdownHH}>
                      <i className="material-icons md-36">arrow_drop_down</i>
                    </span>

                  </div>

                   <Collapse open={this.state.collapseHH} style={{margin: "10px"}}>
                        <div style={{display: "flex"}}>
                       
                        <div className="col-lg-3 mt-3">        
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value = {this.state.lab_analysis_external.phytorid_outlet_external.ph}
                            onChange = {this.on_change_phytorid}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="tss"
                            type="text"
                            value = {this.state.lab_analysis_external.phytorid_outlet_external.tss}
                            onChange = {this.on_change_phytorid}
                            />                    
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>COD(mg/L)</label>
                          <FormInput
                            name="cod"
                            type="text"
                            value = {this.state.lab_analysis_external.phytorid_outlet_external.cod}
                            onChange = {this.on_change_phytorid}
                            />                    
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>F-COLI(MPN/100ml)</label>
                          <FormInput
                            name="f_coil"
                            type="text"
                            value = {this.state.lab_analysis_external.phytorid_outlet_external.f_coil}
                            onChange = {this.on_change_phytorid}
                            />                    
                        </div>
                      </div>
                      <div className="col-lg-3 mt-3">
                          <label>BOD(mg/L)</label>
                          <FormInput
                            name="bod"
                            type="text"
                            value = {this.state.lab_analysis_external.phytorid_outlet_external.bod}
                            onChange = {this.on_change_phytorid}
                            />                    
                      </div>
                  </Collapse>
                 </Card>
              </div>

              {/* ADDITIONAL ANALYSSIS */}

              <div className="mt-1 p-0 col-12">
                <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop:"20px", paddingBottom:"15px"}}>
                  <div className="flex-wrap">
                    <label style={{ marginLeft: "15px", marginTop: "10px" }}>Additional Analysis</label>
                    <span className="pull-right" style={{float: "right", cursor: "pointer"}} onClick={this.dropdownDSH}>
                      <i className="material-icons md-36">arrow_drop_down</i>
                    </span>

                  </div>

                   <Collapse open={this.state.collapseDSH} style={{margin: "10px"}}>
                   {/* <div className="col-lg-4 mt-3 plants-valid floar-right">
                    <label>Select Input Field from Dropdown</label>
                    <Picky
                      valueKey="id"
                      labelKey="additional_analysis"
                      options={this.state.additional_analysis}
                      value={this.state.arrayValue}
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      onChange={this.on_multidrop_change}
                      dropdownHeight={600}
                    />

                  </div> */}
{/* 
{                  this.state.arrayValue.length > 0 ? (
                  <div>
                    {plantList}
                  </div>
                ) : (
                  ""
                )} */}
                      {/* <div className="col-md-10" style={{display: "flex"}}>
                      <label>Attribute:</label>
                      <Select className="col-md-4" value={selectedOption}
                       onChange={this.handleSelect}
                        options={options} />
                      <label>Value:</label>
                      <FormInput
                      className="col-md-4"
                        name={this.state.selectedOption.value}
                        type="text"
                        value = {this.state.lab_analysis_external.additional_analysis.ph}
                        onChange = {this.on_change_additional_analysis}
                        /> 

                    </div> */}

                {/* {
                  this.state.selectedValue.map((septage,index) => {
                    return(
                      <div key = {index} className="col-md-10" style={{display: "flex"}}>
                      <label>Attribute:</label>
                      <FormSelect
                          style={{marginLeft:"15px"}}
                          className="col-md-2"
                          name="Select" 
                          value={selectedOption}
                          onChange={this.handleSelect}
                      >
                      { this.state.full_array_list.map((option, id) => <option value={option}  key={id}>{option}</option>)}
                      </FormSelect>
                        <label style={{marginLeft:"15px"}}>Value:</label>
                      <FormInput
                      className="col-md-2"
                      style={{marginLeft:"15px"}}
                        name={this.state.selectedOption.value}
                        type="text"
                        value = {this.state.lab_analysis_external.additional_analysis[this.state.selectedOption.value]}
                        onChange = {this.on_change_additional_analysis}
                        /> 
  
                      </div>

                    )

                  
                })
              } */}
 
 {/* this.setState({selectedAddItem: e.target.value}) */}

                {
                  this.state.lab_analysis_external.additional_analysis.map((attr,index) => {
                    return(
                      <div  key={index}>
                        <div className="col-md-10" style={{display: "flex"}}>
                        <label>Attribute:</label>
                        <FormSelect className="col-md-3" style={{marginLeft:"15px"}} value={this.state.addAnalysisArr[index]} 
                                id={index + 1}
                                data-id={index}
                                onChange={(e) => this.addAdd(e)}>
                                  <option>Select Option</option>
                          {this.state.additional_analysis.map((team) => <option key={team.value} value={team.value}>{team.label}</option>)}
                        </FormSelect>
                        <label style={{marginLeft:"15px"}}>Value:</label>

                        <FormInput
                      className="col-md-3"
                      style={{marginLeft:"25px"}}
                        name="value"
                        id={index + 1}
                        data-id={index}
                        type="text"
                        value = {this.state.lab_analysis_external.additional_analysis[index].value}
                        onChange = {this.on_change_additional_analysis}
                        /> 

                        <label style={{marginLeft:"15px"}}>comment:</label>

                        <FormInput
                        className="col-md-3"
                        style={{marginLeft:"25px"}}
                        name="comment"
                        type="text"
                        id={index + 1}
                        data-id={index}
                        value = {this.state.lab_analysis_external.additional_analysis[index].comment}
                        onChange = {this.on_change_additional_analysis}
                        /> 

                        <div className="col-lg-3"style={{float: "right",cursor: "pointer"}}  >
                          <i className="material-icons md-36" 
                          id={index + 1}
                          data-id={index} 
                          onClick={(e) => this.removeLoadUI(e)}>
                          remove_circle_outline
                          </i>  
                      </div>
                        </div>
                      </div>
                    )
                  })}






                {/* {
                  this.state.selectedValue.map((attr,index) => {
                    // console.log(this.state.lab_analysis_external.additional_analysis[attr.value])
                    return(
                      <div key={index}>
                      <div className="col-md-10" style={{display: "flex"}}>
                      <label>Attribute:</label>
                      <Select className="col-md-4" value={selectedOption}
                       onChange={this.handleSelect}
                        options={options} />
                      <label>Value:</label>
                      <FormInput
                      className="col-md-4"
                      style={{marginLeft:"25px"}}
                        name={this.state.selectedOption.value}
                        type="text"
                        value = {this.state.lab_analysis_external[this.state.selectedOption.value]}
                        onChange = {this.on_change_additional_analysis}
                        /> 
                      <div className="col-lg-3"style={{float: "right",cursor: "pointer"}} onClick={this.handleAddSeptageReceived} >
                          <i className="material-icons md-36" onClick={(e) => this.removeLoadUI(e)}>
                          remove_circle_outline
                          </i>  
                      </div>

                    </div>

                    </div>
                    )
                  })


                }  */}
                <div className="add-septage" style={{marginRight:"15px", float: "right",cursor: "pointer"}} onClick={this.handleAddSeptageReceived}>
                  <i className="material-icons md-36" onClick={(e) => this.addAdditionalAnalysis(e, this.state.selectedOption)}>
                     add_circle_outline
                  </i>
                </div> 








{/* 
                        <div style={{display: "flex"}}>
                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.ph}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.orp}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="ts"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.ts}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>BOD(mg/L)</label>
                          <FormInput
                            name="bod"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.bod}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                      </div>
                      <div style={{display: "flex"}}>
                        <div className="col-lg-3 mt-3">
                          <label>COD(mg/L)</label>
                          <FormInput
                            name="cod"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.cod}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>F-COLI(MPN/100ml)</label>
                          <FormInput
                            name="f_coil"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.f_coil}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>E-COLI(MPN/100ml)</label>
                          <FormInput
                            name="e_coil"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.e_coil}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Nitrates(mg/L)</label>
                          <FormInput
                            name="nitrates"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.nitrates}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                      </div>

                      <div style={{display: "flex"}}>
                        <div className="col-lg-3 mt-3">
                          <label>POTASSIUM(mg/L)</label>
                          <FormInput
                            name="potassium"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.potassium}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Solid Carbon(%)</label>
                          <FormInput
                            name="solid_carbon"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.solid_carbon}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Solid Nitrogen(%)</label>
                          <FormInput
                            name="solid_nitrogen"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.solid_nitrogen}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Solid Sulphur(%)</label>
                          <FormInput
                            name="solid_sulphur"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.solid_sulphur}
                            onChange = {this.on_change_additional_analysis}
                            />
                        </div>
                      </div>
                      <div style={{display: "flex"}}>
                        <div className="col-lg-3 mt-3">
                          <label>Sludge Moisture(%)</label>
                          <FormInput
                            name="sludge_moisture"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.sludge_moisture}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Sludge Volatiles(%)</label>
                          <FormInput
                            name="sludge_volatiles"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.sludge_volatiles}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Sludge Fixed Carbon(%)</label>
                          <FormInput
                            name="sludge_fixed_carbon"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.sludge_fixed_carbon}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Phosphates(%)</label>
                          <FormInput
                            name="phosphates"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.phosphates}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                      </div>

                      <div style={{display: "flex"}}>
                        <div className="col-lg-3 mt-3">
                          <label>Sludge Ash(%)</label>
                          <FormInput
                            name="sludge_ash"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.sludge_ash}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Bio Char Ash Moisture(%)</label>
                          <FormInput
                            name="biochar_ash_moisture"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.biochar_ash_moisture}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Biochar Fixed Carbon(%)</label>
                          <FormInput
                            name="biochar_fixed_carbon"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.biochar_fixed_carbon}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>Solid Hydrogen(%)</label>
                          <FormInput
                            name="solid_hydrogen"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.solid_hydrogen}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mt-3">
                          <label>Solid Oxygen(%)</label>
                          <FormInput
                            name="solid_oxygen"
                            type="text"
                            value = {this.state.lab_analysis_external.additional_analysis.solid_oxygen}
                            onChange = {this.on_change_additional_analysis}
                          />
                        </div> */}
                  </Collapse>
                 </Card>
              </div>
              <div className="mt-1 p-0 col-12">
                <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop:"20px", paddingBottom:"15px"}}>
                  <div className="flex-wrap">
                    <label style={{ marginLeft: "15px", marginTop: "10px" }}>Observation</label>
                    <span className="pull-right" style={{float: "right", cursor: "pointer"}} onClick={this.dropdownObservable}>
                      <i className="material-icons md-36">arrow_drop_down</i>
                    </span>
                  </div>

                   <Collapse open={this.state.collapseObservable} style={{marginleft: "10px"}}>
                        <div style={{display: "flex"}}>
                        <div className="col-lg-12 mt-3">
                          <label></label>
                          <FormTextarea
                            name="observations"
                            type="text"
                            value = {this.state.lab_analysis_external.observations}
                            onChange = {this.on_change}
                          />
                        </div>
                      </div>
                  </Collapse>
                 </Card>
              </div>
                 
              <div className="my-4 col-12">
                <Button
                  theme="danger"
                  type="button"
                  className="w-25 mr-2"
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={this.toggleModal}
                  className="w-25"
                >
                  {" "}
                    {this.props.button_title}{" "}
                </Button>
              </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edit_session: state.AppState.edit_session,
    current_user: state.AppState.current_user,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { saveEditSession, displayResponseMessage,
        saveExternalLabAnalysisData, editExternalLabAnalysisData },
      dispatch
    )
  };
}
ExternalLabAnalysisFormTemplate.propTypes = {
  history: object.isRequired,
  actions: object.isRequired,
  edit_session: object.isRequired,
  action: String.isRequired,
  current_user: object.isRequired,
  match: object.isRequired,
  button_title: String.isRequired,

};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExternalLabAnalysisFormTemplate)
);
