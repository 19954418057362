import React from "react";
import UserFormTemplate from "../admin/containers/UserFormTemplate";
import * as types from "../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const EditUserProfile = ({ navToggle }) => {
  return (
    <UserFormTemplate
      action={types.EDIT_PROFILE}
      navToggle={navToggle}
      form_head_sub_title={""}
      form_title={"Edit Profile"}
      form_sub_title={"Profile Details"}
      button_title={"Save Changes"}
      visibilityFilter={{
        email: true,
        phone_number: true,
        gender: true,
        address: true
      }}
      readOnlyFilter={{
        email: true
      }}
    />
  );
};

EditUserProfile.propTypes = {
  navToggle: func.isRequired
};

export default EditUserProfile;
