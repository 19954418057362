export const PhytoroidOutlet = (
    ph = "",
    tss = "",
    bod = "",
    cod = "",
    f_coil = ""
) => {
    return {
        ph,
        tss,
        bod,
        cod,
        f_coil
    };
};


export default PhytoroidOutlet(
    "10",
    "20",
    "30"
);