import React, { Component } from "react";
import {
  saveLabAnalysisInHouse, updateLabAnalysisInHouse, displayResponseMessage, saveEditSession

} from "../../../../actions/AppStateActions";
import { InHouse } from '../../../../models/LabAnalysis/InHouse/InHouse';
import ResponseMessage from "../../../common/ResponseMessage";
import MaterialIcon from "material-icons-react";


import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { object } from "prop-types";
import {
  Card,
  CardBody,
  Form,
  Button,
  FormInput,
  Collapse,
  FormTextarea,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Row

} from "shards-react";

import * as inHouseLabAnalysisService from "../../../../services/InHouseLabAnalysisService";

class InHouseLabAnalysisFormTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inHouse: InHouse(),
      validation: [],
      collapseLoad: true,
      collapseSRS: false,
      collapseHH: false,
      collapseCCPT: false,
      collapseDSH: false,
      collapseConsumable: false,
      collapseMetering: false,
      collapseDrierOutput: false,
      collapseTeadted: false,
      collapseObservable: false,
      collapseFiltrate: false,
      septageReceivedUi: [],
      dropdown: [false, false],
      submitted: false,
      open: false,
      vechicleNumber: [],
      isValidationSucess: true,
      isSubmitted: false,
      srsLabsArrayError: [],
      finalErrorMessage: null,
      isSeptage: ''
    };

    this.submit_form = this.submit_form.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.dropdownSeptagereceived = this.dropdownSeptagereceived.bind(this);
    this.dropdownSRS = this.dropdownSRS.bind(this);
    this.dropdownHH = this.dropdownHH.bind(this);
    this.dropdownCCPT = this.dropdownCCPT.bind(this);
    this.dropdownDSH = this.dropdownDSH.bind(this);
    this.dropdownConsumable = this.dropdownConsumable.bind(this);
    this.dropdownMetering = this.dropdownMetering.bind(this);
    this.drier_output = this.drier_output.bind(this);
    this.treated = this.treated.bind(this);
    this.on_change_filtrate = this.on_change_filtrate.bind(this);
    this.filtrate = this.filtrate.bind(this)

    this.dropdownObservable = this.dropdownObservable.bind(this);
    // this.on_change = this.on_change.bind(this);

    this.on_change_load = this.on_change_load.bind(this);
    this.on_change_holding_tank_attributes = this.on_change_holding_tank_attributes.bind(this);
    this.on_change_kkbr_inlet = this.on_change_kkbr_inlet.bind(this);
    this.on_change_kkbr_outlet = this.on_change_kkbr_outlet.bind(this);
    this.on_change_phytorid = this.on_change_phytorid.bind(this);
    this.on_change_dewarted = this.on_change_dewarted.bind(this);
    this.on_change_drier_input = this.on_change_drier_input.bind(this);
    this.on_change_drier_output = this.on_change_drier_output.bind(this);
    this.on_change_treated_water = this.on_change_treated_water.bind(this);
    this.on_change_observations = this.on_change_observations.bind(this);
  }

  componentDidMount() {



    // const septa = [...this.state.inHouse.srs_labs];
    // septa[0][vehicle_number] = "ss";
    // this.setState({
    //   septa
    // // eslint-disable-next-line no-console
    // }, () => console.log(this.state.inHouse.srs_labs))





    // // console.log(this.state.inHouse);
    if (this.props.action == "EDIT_LAB_ANALYSIS_INHOUSE") {
      // // console.log('edit mode');
      // console.log(this.props.edit_session);
      this.setState({
        inHouse: this.props.edit_session
        // eslint-disable-next-line no-console
      }, () => console.log(this.props.edit_session));

      let daily = JSON.parse(localStorage.getItem("currentDailyProcess"));
      if ("undefined" === typeof (daily["septages"])) {
        process_detail['septages'] = [];
        // console.log(process_detail)

        // this.state.process_detail['septages'] = [];
        // console.log('Septage Not Found')
      } else {
        let septage = daily.septages;
        for (let k = 0; k < septage.length; k++) {
          this.state.vechicleNumber.push(septage[k].vehical_number);
        }
      }
    } else {
      //console.log(JSON.parse(localStorage.getItem("currentDailyProcess")))
      let daily = JSON.parse(localStorage.getItem("currentDailyProcess"));
      if ("undefined" === typeof (daily["septages"])) {
        // console.log('Septage Not Found');
        let currentInhouse = this.state.inHouse;
        currentInhouse.srs_labs = [];
        this.setState({
          srs_labs: currentInhouse,
          isSeptage: 'false'
        });
        console.log(this.state.inHouse)

      } else {
        let septage = daily.septages;
        //console.log(septage)
        for (let i = 0; i < septage.length; i++) {
          //let newState1 = [];
          this.state.vechicleNumber.push(septage[i].vehical_number);
          //newState1[type] = !this.state[type];
          // newState1.push

          //this.setState({ ...this.state.inHouse.srs_labs, ...newState1 });
          // this.setState(prevState => ({
          //   ...prevState,
          //   inHouse: {
          //     ...prevState.inHouse,
          //     srs_labs: [
          //         ...prevState.inHouse.srs_labs,
          //         {
          //           vehical_number: septage[i].vehical_number,
          //           ph: "",
          //           ts:"",
          //           orp: ""
          //         }
          //     ]
          //   }
          // }))
        }
      }
    }

    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        user_id: this.props.current_user.user_id,
        plant_id: localStorage.getItem('plant_id'),
        process_detail_id: localStorage.getItem('currentProcessId'),
        date: localStorage.getItem("currentProcesssDate")
      }
    }))
  }
  componentWillUnmount() {
    this.props.actions.saveEditSession(null);
  }

  // collase handle function
  dropdownSeptagereceived() {
    this.setState({ collapseLoad: !this.state.collapseLoad });
  }

  dropdownSRS() {
    this.setState({ collapseSRS: !this.state.collapseSRS });
  }

  dropdownHH() {
    this.setState({ collapseHH: !this.state.collapseHH })
  }
  dropdownCCPT() {
    this.setState({ collapseCCPT: !this.state.collapseCCPT })
  }

  dropdownDSH() {
    this.setState({ collapseDSH: !this.state.collapseDSH })
  }

  dropdownConsumable() {
    this.setState({ collapseConsumable: !this.state.collapseConsumable })
  }

  dropdownMetering() {
    this.setState({ collapseMetering: !this.state.collapseMetering })
  }
  drier_output() {
    this.setState({ collapseDrierOutput: !this.state.collapseDrierOutput })
  }
  treated() {
    this.setState({ collapseTeadted: !this.state.collapseTeadted })
  }

  filtrate() {
    this.setState({
      collapseFiltrate: !this.state.collapseFiltrate
    })
  }

  dropdownObservable() {
    this.setState({ collapseObservable: !this.state.collapseObservable })
  }
  // collase handle function ends



  toggleModal() {
    // console.log(this.state.inHouse);
    if (!this.form_validate_inHouse()) {
      this.setState({
        finalErrorMessage: '* These fields are required'
      })
      return;

    }
    this.setState({
      open: !this.state.open,
      finalErrorMessage: ''
    });
  }


  //////////////////////////////////////////////////////

  //this is for adding input field on click
  addLoadUI() {
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        srs_labs: [
          ...prevState.inHouse.srs_labs,
          {
            vehical_number: null,
            ph: null,
            orp: null,
            ts: null
          }
        ]
      }
    }));

    this.setState(prevState => ({
      ...prevState,
      validation: [
        ...prevState.validation,
        {
          ph: "",
          orp: "",
          ts: ""
        }
      ]

    }));
  }
  removeLoadUI(event, index) {
    // console.log(index)
    //delete this.state.process_detail.septages[2];
    //console.log(index)
    // let l = this.state.inHouse.srs_labs.length;
    // l=l-1;
    // this.state.inHouse.srs_labs.splice(l,1);
    // this.setState(prevState => ({
    //   ...prevState,
    //   inHouse: {
    //     ...prevState.inHouse
    //   }
    // }))

    this.state.inHouse.srs_labs.splice(index, 1);
    // console.log(this.state.inHouse.srs_labs)
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse
      }
    }))

    // this.setState({ collapseLoad: !this.state.collapseLoad });


    // this.setState({ collapseLoad: !this.state.collapseLoad });

  }

  form_validate() {
    let isValidationSucess = true;
    let failed = [];
    let srs_labs = this.state.inHouse.srs_labs;
    // console.log(srs_labs);
    for (let i = 0; i < srs_labs.length; i++) {
      let temp = {};
      Object.keys(srs_labs[i]).forEach(v => {
        // console.log(v)
        if (v != 'vehical_number' && v != 'load' && v != '_id') {
          if (srs_labs[i][v] == null) {
            temp[v] = "This field is required";
            isValidationSucess = false;
          } else {
            temp[v] = "";
          }
        }

      })
      failed.push(temp)
    }
    this.setState({
      validation: failed
    })
    //console.log(failed);
    //console.log(this.state.validation);
    return isValidationSucess;
  }


  form_validate_inHouse() {
    this.setState({
      submitted: true
    })
    let loads = this.state.inHouse.srs_labs;
    // console.log(loads)
    const srsLabsArrayError = [];
    loads.forEach((load, loadIndex) => {
      const loadError = { ph: null, orp: null, ts: null };
      if (load.ph == null) {
        loadError.ph = 'Required';
        srsLabsArrayError[loadIndex] = loadError;
      }
      if (load.orp == null) {
        loadError.orp = 'Required';
        srsLabsArrayError[loadIndex] = loadError;
      }
      if (load.ts == null) {
        loadError.ts = 'Required';
        srsLabsArrayError[loadIndex] = loadError;
      }

    })
    // console.log(srsLabsArrayError)
    this.setState({
      srsLabsArrayError: srsLabsArrayError
    });
    if (srsLabsArrayError.length == 0) {
      return true;
    }

  }




  field_validate(e) {


    if (this.state.submitted) {
      let loadsArrayError = this.state.srsLabsArrayError;
      const loads = [...this.state.inHouse.srs_labs];
      if (e.target.value != '') {
        loadsArrayError[e.target.dataset.id][e.target.name] = "";
      } else if (e.target.value == '') {
        loadsArrayError[e.target.dataset.id][e.target.name] = "Required";
        loads[e.target.dataset.id][e.target.name] = null;
      }
      this.setState({
        srsLabsArrayError: loadsArrayError
      });
    }




    // if (this.state.submitted) {
    //   // const validation = {
    //   //   ...this.state.validation,
    //   //   [e.target.name]: {
    //   //     ...this.state.validation[e.target.name],
    //   //     ...validate(e.target.name, e.target.value)
    //   //   }
    //   // };
    //   const validation = this.state.validation;
    //   validation[e.target.dataset.id][e.target.name] = "";
    //   //let index = 
    //   this.setState({ validation });
    // }
  }

  //state for load
  on_change_load(e) {
    const septa = [...this.state.inHouse.srs_labs];
    septa[e.target.dataset.id][e.target.name] = e.target.value
    this.setState({
      septa
      // eslint-disable-next-line no-console
    }, () => console.log(this.state.inHouse.srs_labs))
    this.field_validate(e)
  }

  on_change_holding_tank_attributes(event) {
    // console.log(this.state.inHouse.holding_tank_attributes);

    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        holding_tank: {
          ...prevState.inHouse.holding_tank,
          [event.target.name]: event.target.value
        }
      }
    }))

  }

  on_change_kkbr_inlet(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        kkbr1_outlet: {
          ...prevState.inHouse.kkbr1_outlet,
          [event.target.name]: event.target.value
        }
      }
    }))

  }

  on_change_filtrate(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        filtrate: {
          ...prevState.inHouse.filtrate,
          [event.target.name]: event.target.value
        }
      }
    }))

  }

  on_change_kkbr_outlet(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        kkbr2_outlet: {
          ...prevState.inHouse.kkbr2_outlet,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_phytorid(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        phytorid_outlet: {
          ...prevState.inHouse.phytorid_outlet,
          [event.target.name]: event.target.value
        }
      }
    }))

  }

  on_change_dewarted(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        dewaterted_solid: {
          ...prevState.inHouse.dewaterted_solid,
          [event.target.name]: event.target.value
        }
      }
    }))

  }
  on_change_drier_input(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        drier_input: {
          ...prevState.inHouse.drier_input,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_drier_output(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        drier_output: {
          ...prevState.inHouse.drier_output,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_treated_water(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        treated_water: {
          ...prevState.inHouse.treated_water,
          [event.target.name]: event.target.value
        }
      }
    }))
  }

  on_change_observations(event) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      inHouse: {
        ...prevState.inHouse,
        [event.target.name]: event.target.value
      }
    }))
  }




  submit_form(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      isSubmitted: true
    })
    if (!this.form_validate()) {
      this.setState({
        isSubmitted: false
      })
      return;
    }
    const septa = [...this.state.inHouse.srs_labs];
    for (let i = 0; i < this.state.inHouse.srs_labs.length; i++) {
      //console.log(this.state.vechicleNumber[i])
      septa[i].vehical_number = this.state.vechicleNumber[i]
      this.setState({
        septa
        // eslint-disable-next-line no-console
      }, () => console.log(this.state.inHouse.srs_labs))

    }





    const submit_func =
      this.props.action == "ADD_INHOUSE_LAB_ANALYSIS"
        ? inHouseLabAnalysisService.addInHouseLabData
        : inHouseLabAnalysisService.editInHouseLabData;

    let lab_analysis_inhouse = this.state.inHouse;
    if (!this.props.edit_session) {
      delete lab_analysis_inhouse["id"];
    }

    submit_func({ lab_analysis_inhouse }).then(
      () => {
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "InHouse " +
          (this.props.edit_session ? "edited" : "added") +
          " successful."
        );
        this.props.history.push("list-lab-analysis");
        this.setState({
          open: !this.state.open,
          isSubmitted: false
        });
      },
      () => {
        this.toggleModal();
        this.setState({
          submitted: false,
          isSubmitted: false
        });
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong."
        );
      }
    );


    // if (this.props.action == "ADD_INHOUSE_LAB_ANALYSIS") {
    //   this.props.actions.saveLabAnalysisInHouse(
    //     this.state.inHouse
    //   );
    // }else{
    //   this.props.actions.updateLabAnalysisInHouse(
    //     this.state.inHouse);
    // }

    // this.props.history.push("list-lab-analysis");
  }

  render() {
    // let totalQty = 0;
    // let energyMeterReading;
    // let dailyProcess = JSON.parse(localStorage.getItem("currentDailyProcess"));
    // console.log(dailyProcess)
    // let septage = dailyProcess.septages;
    // for(let i=0;i<septage.length;i++){
    //   totalQty = totalQty + Number(septage[i].quantity_of_septage)

    // let
    // }
    let isSeptage = null;

    if(this.state.inHouse.srs_labs == []){
      isSeptage = 'false'
    }else if(this.state.inHouse.srs_labs != []){
      isSeptage = 'true'
    }
    let maxLoad = 7;
    let currentLoadLength = this.state.inHouse.srs_labs.length;
    console.log('currentLoadLenght', currentLoadLength, 'maxLoad', maxLoad);
    return (
      <div className="mh-85">
        <ResponseMessage />
        <div className="text-dark my-3 px-5">
          <Breadcrumb className="mt-3">
            <BreadcrumbItem>
              <a onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/overview/" + localStorage.getItem('plant_id'))} style={{ color: "#007bff", cursor: "pointer" }}> {new String(this.props.match.params.plant_name)}  Overview</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <a onClick={() => this.props.history.push("list-daily-process")} style={{ color: "#007bff", cursor: "pointer" }}>List Daily Process</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              InHouse Lab Analysis
          </BreadcrumbItem>
          </Breadcrumb>
          <small> {new String(this.props.match.params.plant_name)} </small>
          <h4>Lab Analysis-InHouse</h4>
        </div>

        <Modal
          size="lg"
          open={this.state.open}
          style={{ marginTop: "55%" }}
          toggle={this.toggleModal}
          className="h-75 d-flex align-items-center justify-content-center"
        >
          <h4 className="text-center p-5 w-100">
            {"Are You sure you want to " + this.props.button_title + "?"}{" "}
          </h4>

          <div className="d-flex w-75 mx-auto justify-content-center">
            <Button
              theme="danger"
              onClick={this.toggleModal}
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.isSubmitted == true}
              form="inHouseLabAnalysis_form"
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Yes
            </Button>
          </div>
        </Modal>

        <div className="d-flex flex-wrap h-75 mt-2 px-5 col-12 align-items-center justify-content-center">
          <Card style={{ marginBottom: "30px" }} className="col-12 p-0">

            <CardBody className="p-0">
              <Form
                id="inHouseLabAnalysis_form"
                className="d-flex flex-wrap justify-content-between"
                onSubmit={this.submit_form}
              >

                {/* SRS  */}
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>SRS</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownSeptagereceived}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseLoad} style={{ margin: "10px" }}>

                      {this.state.inHouse.srs_labs.map((septage, index) => {
                        return (
                          <div key={index}>
                            <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                              <label>Load-{index + 1}{" (Vechicle Number: "} {this.state.vechicleNumber[index]}{")"}</label>
                            </div>

                            <div style={{ display: "flex" }}>
                              {/* <div className="col-lg-2 mt-3">        
                          <label>Vehicle Number</label>
                          <FormInput
                         // disabled={this.props.action == types.EDIT_PLANT}
                            disabled={this.props.action == "ADD_INHOUSE_LAB_ANALYSIS" || this.props.action == "EDIT_LAB_ANALYSIS_INHOUSE"}
                            name="vehicle_number"
                            type="text"  EDIT_LAB_ANALYSIS_INHOUSE
                            data-id={index}
                            id={index+1}
                            value = {this.state.inHouse.srs_labs[index].vehical_number}
                            onChange={this.on_change_load}
                          />
                        </div> */}
                              <div className="col-lg-3 mt-3">
                                <Row style={{ marginLeft: "0px" }}>
                                  <label>PH(Number) *</label>

                                  <FormInput
                                    name="ph"
                                    type="number"
                                    step="any"
                                    data-id={index}
                                    id={index + 1}
                                    value={this.state.inHouse.srs_labs[index].ph}
                                    onChange={this.on_change_load}
                                  />

                                </Row>

                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red", marginLeft: "0px" }}>
                                      {this.state.srsLabsArrayError[index] ? this.state.srsLabsArrayError[index].ph : ''}
                                    </div>
                                  </div>

                                </Row>
                              </div>
                              <div className="col-lg-3 mt-3">
                                <Row style={{ marginLeft: "0px" }}>
                                  <label>ORP(mV) *</label>

                                  <FormInput
                                    name="orp"
                                    type="number"
                                    step="any"
                                    data-id={index}
                                    id={index + 1}
                                    value={this.state.inHouse.srs_labs[index].orp}
                                    onChange={this.on_change_load}
                                  />
                                </Row>

                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.srsLabsArrayError[index] ? this.state.srsLabsArrayError[index].orp : ''}
                                    </div>
                                  </div>

                                </Row>
                              </div>
                              <div className="col-lg-3 mt-3">
                                <Row style={{ marginLeft: "0px" }}>

                                  <label>TS(%) *</label>
                                  <FormInput
                                    name="ts"
                                    type="number"
                                    step="any"
                                    data-id={index}
                                    id={index + 1}
                                    value={this.state.inHouse.srs_labs[index].ts}
                                    onChange={this.on_change_load}
                                  />
                                </Row>


                                <Row style={{ marginLeft: "0px" }}>
                                  <div>
                                    <div style={{ color: "Red" }}>
                                      {this.state.srsLabsArrayError[index] ? this.state.srsLabsArrayError[index].ts : ''}
                                    </div>
                                  </div>

                                </Row>

                              </div>
                              <Button
                                style={{
                                  borderRadius: "50px",
                                  backgroundColor: "white",
                                  border: "none",
                                  fontSize: "50px",
                                  color: "#D3D3D3",
                                  marginTop: '20px'
                                }}
                                id={index + 1}
                                data-id={index}
                                onClick={(event) => this.removeLoadUI(event, index)}
                              // disabled={this.props.action == "EDIT_DAILY_PROCESS" && this.state.septageLen > index}
                              >
                                <MaterialIcon icon="remove_circle_outline" />
                              </Button>

                              {/* <div className="col-lg-3 mt-3"style={{marginLeft:"30px", float: "right",cursor: "pointer"}} onClick={this.handleAddSeptageReceived} >
                          <i className="material-icons md-36" onClick={(e) => this.removeLoadUI(e, index)}>
                          remove_circle_outline
                          </i>  
                        </div> */}
                            </div>
                          </div>
                        )
                      })}
                      {/* <div className="col-lg-3  mt-3">
                       <div  style={{color:"blue"}}>
                         Total Septage Received: {totalQty}{"(Litres)"}
                        </div> 

                       </div> */}
{/* 
this.props.collection_name == "users"
        ? userService.getUsers
        : this.props.collection_name == "organizations"
        ? organizationService.getOrganizations
        : plantService.getPlants; */}

                      {this.state.isSeptage == 'false'  ? ( 
                      <div style={{ color: "Red" }} className="text-center" >
                        No Septage Received
                      </div>
                      ) : maxLoad != currentLoadLength ? (
                        <Button
                        style={{
                          borderRadius: "50px",
                          backgroundColor: "white",
                          border: "none",
                          marginRight: "15px", float: "right"
                        }}

                        className="hover-blue"
                        disabled={this.state.vechicleNumber.length <= this.state.inHouse.srs_labs.length}
                        onClick={(e) => this.addLoadUI(e)}
                      >
                        <MaterialIcon icon=" add_circle_outline" />
                      </Button>
                      ):
                      (""
                      )}
                         

                      {/* {this.state.isSeptage == 'false' ? (
                        // <label style={{marginLeft: '5px'}}> No Septage Found</label>
                        <div style={{ color: "Red" }} className="text-center" >
                          No Septage Received
                        </div>
                      ):       (
                        <Button
                        style={{
                          borderRadius: "50px",
                          backgroundColor: "white",
                          border: "none",
                          marginRight: "15px", float: "right"
                        }}

                        className="hover-blue"
                        disabled={this.state.vechicleNumber.length <= this.state.inHouse.srs_labs.length}
                        onClick={(e) => this.addLoadUI(e)}
                      >
                        <MaterialIcon icon=" add_circle_outline" />
                      </Button>

                        )} */}





                      {/* <div className="add-septage mt-3" 
                  disabled = {this.state.vechicleNumber.length == this.state.inHouse.srs_labs.length }
                  style={{marginRight:"15px", float: "right",cursor: "pointer"}} onClick={this.handleAddSeptageReceived}>
                    <i className="material-icons md-36" 

                    
                    onClick={(e) => this.addLoadUI(e)}>
                        add_circle_outline
                    </i>
                  </div>                      */}
                    </Collapse>
                  </Card>
                </div>

                {/* For Holding Tank  */}
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Holding Tank</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownSRS}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseSRS} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value={this.state.inHouse.holding_tank.ph}
                            onChange={this.on_change_holding_tank_attributes}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value={
                              this.state.inHouse.holding_tank.orp
                            }
                            onChange={
                              this.on_change_holding_tank_attributes
                            }
                          />

                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="ts"
                            type="text"
                            value={
                              this.state.inHouse.holding_tank.ts
                            }
                            onChange={this.on_change_holding_tank_attributes}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>KKBR1 outlet</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownHH}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseHH} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>

                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value={
                              this.state.inHouse.kkbr1_outlet.ph
                            }
                            onChange={this.on_change_kkbr_inlet}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value={
                              this.state.inHouse.kkbr1_outlet.orp
                            }
                            onChange={this.on_change_kkbr_inlet}
                          />
                        </div>

                      </div>
                    </Collapse>
                  </Card>
                </div>

                {/* ccpt-sepatge */}

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>KKBR2 outlet</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownCCPT}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseCCPT} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value={
                              this.state.inHouse.kkbr2_outlet.ph
                            }
                            onChange={this.on_change_kkbr_outlet}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value={
                              this.state.inHouse.kkbr2_outlet.orp
                            }
                            onChange={this.on_change_kkbr_outlet}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>E Coil(MPN/100ml)</label>
                          <FormInput
                            name="e_coil"
                            type="text"
                            value={
                              this.state.inHouse.kkbr2_outlet.e_coil
                            }
                            onChange={this.on_change_kkbr_outlet}
                          />
                        </div>
                      </div>

                    </Collapse>
                  </Card>
                </div>

                {/* DEWARTED SOLIDS HANDELED */}

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Phytorid</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownDSH}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseDSH} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value={
                              this.state.inHouse.phytorid_outlet.ph
                            }
                            onChange={this.on_change_phytorid}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value={
                              this.state.inHouse.phytorid_outlet.orp
                            }
                            onChange={this.on_change_phytorid}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>E. Coli(MPN/100ml)</label>
                          <FormInput
                            name="e_coil"
                            type="text"
                            value={
                              this.state.inHouse.phytorid_outlet.e_coil
                            }
                            onChange={this.on_change_phytorid}
                          />
                        </div>


                      </div>


                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Dewarted Solids</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownConsumable}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseConsumable} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="ts"
                            type="text"
                            value={
                              this.state.inHouse.dewaterted_solid.ts
                            }
                            onChange={this.on_change_dewarted}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Drier Input</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownMetering}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseMetering} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="ts"
                            type="text"
                            value={
                              this.state.inHouse.drier_input.ts
                            }
                            onChange={this.on_change_drier_input}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Drier Output</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.drier_output}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseDrierOutput} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>TS(%)</label>
                          <FormInput
                            name="ts"
                            type="text"
                            value={
                              this.state.inHouse.drier_output.ts
                            }
                            onChange={this.on_change_drier_output}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Treated Water</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.treated}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseTeadted} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value={
                              this.state.inHouse.treated_water.ph
                            }
                            onChange={this.on_change_treated_water}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value={
                              this.state.inHouse.treated_water.orp
                            }
                            onChange={this.on_change_treated_water}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>E. COLI(MPN/100ml)</label>
                          <FormInput
                            name="e_coil"
                            type="text"
                            value={
                              this.state.inHouse.treated_water.e_coil
                            }
                            onChange={this.on_change_treated_water}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>
                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Filtrate</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.filtrate}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseFiltrate} style={{ margin: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-3 mt-3">
                          <label>PH(Number)</label>
                          <FormInput
                            name="ph"
                            type="text"
                            value={
                              this.state.inHouse.filtrate.ph
                            }
                            onChange={this.on_change_filtrate}
                          />
                        </div>
                        <div className="col-lg-3 mt-3">
                          <label>ORP(mV)</label>
                          <FormInput
                            name="orp"
                            type="text"
                            value={
                              this.state.inHouse.filtrate.orp
                            }
                            onChange={this.on_change_filtrate}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </div>

                <div className="mt-1 p-0 col-12">
                  <Card style={{ marginLeft: "15px", marginRight: "15px", paddingTop: "20px", paddingBottom: "15px" }}>
                    <div className="flex-wrap">
                      <label style={{ marginLeft: "15px", marginTop: "10px" }}>Observation</label>
                      <span className="pull-right" style={{ float: "right", cursor: "pointer" }} onClick={this.dropdownObservable}>
                        <i className="material-icons md-36">arrow_drop_down</i>
                      </span>

                    </div>

                    <Collapse open={this.state.collapseObservable} style={{ marginLeft: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="col-lg-12 mt-3">
                          <label></label>
                          <FormTextarea
                            name="observations"
                            type="text"
                            value={
                              this.state.inHouse.observations
                            }
                            onChange={this.on_change_observations}
                          />
                        </div>

                      </div>

                    </Collapse>
                  </Card>
                </div>


                <div className="my-4 col-12">
                  <Button
                    theme="danger"
                    type="button"
                    onClick={() => this.props.history.goBack()}
                    className="w-25 mr-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={this.toggleModal}
                    className="w-25"
                  >
                    {" "}
                    {this.props.button_title}{" "}
                  </Button>
                  <div>
                    <div style={{ color: "Red" }}>
                      {this.state.finalErrorMessage}
                    </div>
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edit_session: state.AppState.edit_session,
    current_user: state.AppState.current_user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        saveLabAnalysisInHouse, updateLabAnalysisInHouse, displayResponseMessage, saveEditSession
      },
      dispatch
    )
  };
}
InHouseLabAnalysisFormTemplate.propTypes = {
  history: object.isRequired,
  actions: object.isRequired,
  edit_session: object.isRequired,
  action: String.isRequired,
  current_user: object.isRequired,
  match: object.isRequired,
  button_title: String.isRequired,


};



export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InHouseLabAnalysisFormTemplate)
);
