import { createStore, compose, applyMiddleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import createHistory from "history/createBrowserHistory";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { connectRouter, routerMiddleware } from "connected-react-router";
import rootReducer from "../reducers";

export const history = createHistory();
const connectRouterHistory = connectRouter(history);



function configureStoreProd() {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware
  ];

  return createStore(
    connectRouterHistory(rootReducer),
    persistedState,
    compose(applyMiddleware(...middlewares))
  );
}

function configureStoreDev() {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    connectRouterHistory(rootReducer),
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers").default; // eslint-disable-line global-require
      store.replaceReducer(connectRouterHistory(nextRootReducer));
    });
  }

  return store;
}
const persistedState = loadFromLocalStorage();
//console.log(persistedState);


const configureStore =
  process.env.NODE_ENV === "production"
    ? configureStoreProd
    : configureStoreDev;

const store = configureStore();
store.subscribe(() => {
  localStorage.setItem("app_state_root_store",store.getState().AppState);
  //localStorage.setItem("app_state_root_store",JSON.stringify(store.getState().AppState));
  const token = store.getState().AppState.token;
  saveToLocalStorage(store.getState());
  if (token) {
    localStorage.setItem("token", store.getState().AppState.token);
    localStorage.setItem(
      "tokenReceivedTime",
      store.getState().AppState.tokenReceivedTime
    );
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenReceivedTime");
  }
});

function saveToLocalStorage(state){
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState); 
  } catch (e) {
    //console.log(e);
  }
}

function loadFromLocalStorage(){
  try {
    const serializedState = localStorage.getItem('state');
    if(serializedState == null){
      return undefined;
    }
    return JSON.parse(serializedState)
  } catch (e) {
    //console.log(e);
    return undefined;
  }
}

export default store;
