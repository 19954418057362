import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { array } from "prop-types";
// import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: this.props.items
    };
    // this.state = {
    //   navItems: Store.getSidebarItems()
    // };

    this.onChange = this.onChange.bind(this);
  }

  // componentWillMount() {
  //   Store.addChangeListener(this.onChange);
  // }

  // componentWillUnmount() {
  //   Store.removeChangeListener(this.onChange);
  // }

  onChange() {
    this.setState({
      ...this.state
      // navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    // const items = ["Option 1"];
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    );
  }
}

SidebarNavItems.propTypes = {
  items: array.isRequired
};

export default SidebarNavItems;
