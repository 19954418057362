
export const DewartedSolidHandle = (
    qty_of_solids_generated = "",
    qty_of_solids_pyrolyzed = "",
    qty_of_solids_disposed = "",
    usage_of_solids_elsewhere = "",
    biochar_generated_kgs = ""
  ) => {
    return {
        qty_of_solids_generated,
        qty_of_solids_pyrolyzed,
        qty_of_solids_disposed,
        usage_of_solids_elsewhere,
        biochar_generated_kgs
    };
  };
  
  
  export default DewartedSolidHandle(
  );