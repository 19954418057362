import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";

class ProtectedRoute extends Component {
  componentDidMount() {
    if (!this.props.token) {
      this.props.history.push("/");
    }
  }

  render() {
    return <Route {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    token: state.AppState.token
  };
}

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
