import React, { Component } from "react";
import { withRouter } from "react-router";
import {Line} from 'react-chartjs-2'
import * as reportService from "../../../../../services/ReportService";
import logo from '../../../../assets/Spinner-1s-200px.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv,faFileExcel,faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { connect } from "react-redux";

import {
  Row,
  Col,
  Card,
  FormInput,
  Button,
  CardBody,
  CardHeader,
  FormCheckbox,
  Modal,
  ModalBody,
  Progress
} from "shards-react";
import {  object } from "prop-types";

class TreatedWaterGraph extends Component {
  selectedReportType="";
  constructor(props) {
    super(props);
    this.state = {
      chartData:{},
      chartData2:{},
      chartData3:{},
      chartData4:{},
      chartData5:{},
      open: false,
      modalDiv: null,
      labels: [],
      startDate: Date(),
      endDate: Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      isGraphView: false,
      PH: [],
      BOD: [],
      COD: [],
      TSS: [],
      F_coil: [],
      entryListForTableRes:{},
      isDataLoaded: false,
      selectedAttr: null,
      validationError: "",
      dataNotFoundMessaage: "",
      ph: false,
      cod: false,
      bod: false,
      tss: false,
      f_coil: false,
      response: [],
      isGraphRender:""
    };
    this.on_change = this.on_change.bind(this);
    this.changePh = this.changePh.bind(this);
    this.changeCod = this.changeCod.bind(this);
    this.changeBod = this.changeBod.bind(this);
    this.changeTss = this.changeTss.bind(this);
    this.changeFCoil = this.changeFCoil.bind(this);
  }

  componentDidMount(){
    this.setState({
      startDate: null,
      endDate: null  
    })
  }
  changePh() {
    this.setState({
      ph: !this.state.ph
    });
  }
  changeBod() {
    this.setState({
      bod: !this.state.bod
    });
  }

  changeCod() {
    this.setState({
      cod: !this.state.cod
    });
  }

  changeTss() {
    this.setState({
      tss: !this.state.tss
    });
  }

  changeFCoil() {
    this.setState({
      f_coil: !this.state.f_coil
    });
  }

  //this is used to set the value in input field
  on_change(e) {
    this.setState({
     [e.target.name]: [e.target.value],
     validationError:  e.target.value != "" 
     ? "" :  e.target.name === "startDate" ? " Please Select the start date" : e.target.name === "endDate" ?  "Please select the end date" : "",

    }); 
  }
  //For Chart One
  getChartData(){
    this.setState({
      chartData:{
        labels:this.state.labels,
        datasets:[
          {
            label:'pH',
            data:this.state.PH,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  //For Chart Two
  getChartData2(){
    this.setState({
      chartData2:{
        labels: this.state.labels,
        datasets:[
          {
            label:'BOD',
            data:this.state.BOD,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  //For Chart Tre
  getChartData3(){
    this.setState({
      chartData3:{
        labels: this.state.labels,
        datasets:[
          {
            label:'COD',
            data:this.state.COD,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  // For Chat 4
  getChartData4(){
    this.setState({
      chartData4:{
        labels: this.state.labels,
        datasets:[
          {
            label:'F COLI',
            data:this.state.TSS,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  //FOr chart 5
  getChartData5(){
    this.setState({
      chartData5:{
        labels: this.state.labels,
        datasets:[
          {
            label:'F COIL',
            data:this.state.TSS,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }
  
  // checking validation
  checkValidation(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
      //console.log('Validation Failed')
      if(startDate == null){
        this.setState ({
          validationError: "Please Select the start date first"
        }); 
      }else if(endDate == null){
        this.setState({
          validationError: "Please Select the end date"
        }); 
      }
      return false;
    }else{
      // console.log('Validation Suucess')
      return true;
    }
  }

  setGraphViewVisible(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
        if(startDate == null){
          this.setState ({
            validationError: "Please Select the start date first"
          }); 
        }else if(endDate == null){
          this.setState({
            validationError: "Please Select the end date"
          }); 
        }
    }else{
      
      this.setState({
        dataNotFoundMessaage:"",
        isDataLoaded: true
      })
      this.getChartDataAll();
    }

    // if(this.state.isGraphView){
    //  this.getChartDataAll();
    // }
  }

  //this method is used to render the chart
  getChartDataAll(){ 
    if(this.checkValidation()){
      this.setState({
        dataNotFoundMessaage:"",
        isDataLoaded: true,
        isGraphRender:""
      })   
        if(this.state.ph == false && this.state.tss==false && this.state.bod == false && this.state.cod == false && this.state.f_coil ==  false){
          this.setState({
            isDataLoaded:false,
          })
          let message="Select Attribute First";
          this.setState({
            dataNotFoundMessaage: message
          })
        }else{
          let data;
          let startDate = this.state.startDate;
          let endDate = this.state.endDate;
          const plant_id = localStorage.getItem("plant_id");
          const listTableData = reportService.getTreatedWaterGraph;    
          listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
            data = entryListForTableRes;
            //console.log(data);
            this.setState({
              
              labels: [],
              PH: [],
              BOD: [],
              TSS: [],
              COD:[],
              F_coil: [],
              dataNotFoundMessaage:"",
              isGraphView: true,        
            });
            data.sort(function(a,b){
              var c = new Date(a.date);
              var d = new Date(b.date);        
              return c-d;
              });
              //console.log(data);
      
            
            data.map((item) => {
              let current_datetime = new Date(item.date)
              let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1)
              this.state.labels.push(formatted_date);
              this.state.PH.push(item.attributes.ph);
              this.state.BOD.push(item.attributes.bod);
              this.state.COD.push(item.attributes.cod);
              this.state.TSS.push(item.attributes.tss);
              this.state.F_coil.push(item.attributes.f_coil);  
            });   
              this.getChartData();
              this.getChartData2();
              this.getChartData3();
              this.getChartData4();
              this.setState({
                isDataLoaded: false
              })
          },(error)=>{
            this.setState({
              isDataLoaded:false,
              isGraphView:false,
            })
            let message  = error.response.data.message;
            this.setState({
              dataNotFoundMessaage: message
            })
          }
        );
      }
    }
  }


 

  modal() {
    // const animate = 
    //   return (<Progress theme="primary" value={50} />);
    return(
      <Modal 
      style={{ marginTop: "150px"}}
      open={this.state.isCSVData}
      className="h-75 d-flex align-items-center justify-content-center modal-width">
      <ModalBody >
      <div>
        <div>
        <Progress animated theme="primary" value={100} />
        </div>
        <div className="modal-body-name" style= {{ marginTop: "15px", textAlign: "center"}}>
          Exporting {this.selectedReportType} Report...
        </div>
        <div className="modal-body-message"style= {{textAlign: "center"}}>
         Just a moment Please
        </div>
        {/* <div className="modal-body-discard"style= {{textAlign: "center"}}>
          Discard
        </div> */}
      </div>
      </ModalBody>
    </Modal>
    );
   }

   getFileName(fileType =  String){
    let startDate = this.state.startDate;
    let endDate= this.state.endDate;
    let fileName = "Treated_Water_Report_" + new String(this.props.match.params.plant_name) +"_"+ startDate+"_" +endDate+"."+fileType;
    return fileName;

  }

    //Method is used to download report in the from as CSV
    downloadReportAsCSV(){
      this.selectedReportType="CSV";
      if(this.checkValidation()){
      this.setState({
        isCSVData : true
      })
      //console.log('downloadReportAsCSV');
      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getTreatedWaterGraph;
      
       listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
         data = entryListForTableRes;  
         data.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
  
          let csvData = [];
          for(let i=0;i<data.length;i++){
            let a  = {};
            a = {
              Date: this.dateFormat(data[i].date),
              ph:   data[i].attributes.ph,
              tss: data[i].attributes.tss,
              bod: data[i].attributes.bod,
              cod: data[i].attributes.cod,
              f_coil: data[i].attributes.f_coil
            }
            csvData.push(a);
          }
          this.setState({
            response:csvData
          }, () => {
            this.csvLink.link.click();
            this.setState({
              isCSVData : false
            })
         })      
        this.setState({
          isDataLoaded: false
        })
       },
       (error) =>{
         this.setState({
          isDataLoaded:false,
          isGraphView:false,
          isCSVData : false
         })
         let message  = error.response.data.message;
         this.setState({
           dataNotFoundMessaage: message
         })
       }
  
       
       );
      }
    }

        //Method is used to download report in the from as CSV
        downloadReportAsExcel(){
          this.selectedReportType="EXCEL";

          if(this.checkValidation()){
          this.setState({
            isCSVData : true
          })
          //console.log('downloadReportAsCSV');
          let data;
          let startDate = this.state.startDate;
          let endDate = this.state.endDate;
          const plant_id = localStorage.getItem("plant_id")
          const listTableData = reportService.getTreatedWaterGraph;
          
           listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
             data = entryListForTableRes;  
             data.sort(function(a,b){
              var c = new Date(a.date);
              var d = new Date(b.date);        
              return c-d;
              });
      
              let csvData = [];
              for(let i=0;i<data.length;i++){
                let a  = {};
                a = {
                  Date: this.dateFormat(data[i].date),
                  ph:   data[i].attributes.ph,
                  tss: data[i].attributes.tss,
                  bod: data[i].attributes.bod,
                  cod: data[i].attributes.cod,
                  f_coil: data[i].attributes.f_coil
                }
                csvData.push(a);
              }
              this.setState({
                response:csvData
              }, () => {
                this.csvLinkForExcel.link.click();
                this.setState({
                  isCSVData : false
                })
             })      
            this.setState({
              isDataLoaded: false
            })
           },
           (error) =>{
             this.setState({
              isDataLoaded:false,
              isGraphView:false,
              isCSVData : false
             })
             let message  = error.response.data.message;
             this.setState({
               dataNotFoundMessaage: message
             })
            }
          );
        }
      }
    
    //for download PDF
    downloadReportAsPDF(){
      this.selectedReportType="PDF";

      if(this.checkValidation()){
        if(this.state.isGraphView){
      this.setState({
        isCSVData : true
      })
      var pdf = new jsPDF("p", "mm", "a4");
      var totalPagesExp = "{total_pages_count_string}";
      var header = function(data) {
        pdf.setFontSize(10);
        pdf.setTextColor(40);
        pdf.setFontStyle('normal');
        //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
        //var pdfTitle = this.drPolicy.name + "Report" +" (Generated on "+ this.getDateinFormat(new Date())+")"
        pdf.text('Treated Water Report', data.settings.margin.left, 20);
            // FOOTER
            var str = "Page " + data.pageCount;
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === 'function') {
              str = str + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);
            pdf.text(str, data.settings.margin.left, pdf.internal.pageSize.height - 10);
        };
        var options = {
          addPageContent: header,
          margin: {
            top: 30
          },
  
        };
        var canvas = document.getElementById('ph');
        var ph = canvas.toDataURL();
        var canvas2 = document.getElementById('tss');
        var tss = canvas2.toDataURL();
        var canvas3 = document.getElementById('bod');
        var bod = canvas3.toDataURL();
        var canvas4 = document.getElementById('cod');
        var cod= canvas4.toDataURL();
        var canvas5 = document.getElementById('fcoli');
        var fcoli= canvas5.toDataURL();
  
        let csvData = []
        const head = ["Date", "PH", "COD", "COD","TSS", "F COIL"]
  
      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getTreatedWaterGraph;
      
       listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
         data = entryListForTableRes;  
         data.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
  
          
          for(let i=0;i<data.length;i++){
            let a  = [];
              a.push(this.dateFormat(data[i].date)),
              a.push(data[i].attributes.ph),
              a.push(data[i].attributes.tss),
              a.push(data[i].attributes.bod),
              a.push(data[i].attributes.cod),
              a.push(data[i].attributes.f_coil)
            
            csvData.push(a);
          }
          this.setState({
            response:csvData
          }, () => {
            pdf.autoTable(head, this.state.response, options);
            pdf.addPage();

                      //It is used to add image
            // let startImageSize = pdf.lastAutoTable.finalY;
          
            pdf.text('PH', 15, 20);
            pdf.addImage(ph, 'JPEG', 15,  30, 160, 80);
      
            pdf.addPage();
            pdf.text('BOD', 15, 20);
            pdf.addImage(bod, 'JPEG', 15, 30,160, 80);
            // pdf.addPage();
            pdf.addPage();
            pdf.text('COD', 15, 20);
            pdf.addImage(cod, 'JPEG', 15, 30, 160, 80);
      
            pdf.addPage();
            pdf.text('TSS', 15, 20);
            pdf.addImage(tss, 'JPEG', 15, 30, 160, 80);

            pdf.addPage();
            pdf.text('F COIL', 15, 20);
            pdf.addImage(fcoli, 'JPEG', 15, 30, 160, 80);
          // let startImageSizee = pdf.lastAutoTable.finalY;    
      
      
            if (typeof pdf.putTotalPages === 'function') {
              pdf.putTotalPages(totalPagesExp);
            }
            pdf.save(this.getFileName('pdf'));
            this.setState({
              isCSVData : false
            })
         })      
        this.setState({
          isDataLoaded: false
        })
       },
       (error) =>{
         this.setState({
          isDataLoaded:false,
          isGraphView:false,
          isCSVData : false
         })
         let message  = error.response.data.message;
         this.setState({
           dataNotFoundMessaage: message
         })
       }
  
       
       );
      }else{
        this.setState({
          isGraphRender: 'Please Show Graph First'
        })
       }
    }
    }


    dateFormat(date){
      let current_datetime = new Date(date);
      let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + (current_datetime.getFullYear());
      return formatted_date;
    }


 
  render() {
    const generatingReport = this.modal();
    let headers = [
      { label: "Date", key: "Date" },
      { label: "PH", key: "ph" },
      { label: "TSS", key: "tss" },
      { label: "BOD", key: "bod" },
      { label: "COD", key: "cod" },
      { label: "F COIL", key: "f_coil" }
    ];
    const graphicalView = this.state.isGraphView;
    const isDataLoaded = this.state.isDataLoaded 
    ? <div className="text-center" >
      <img style={{width:"45px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
          
  





  

    return (
      <div>
        <p className="mb-2">Select Any One Attribute:</p>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.ph}
            onChange={() => {
              this.changePh();
            }}
          >
            PH
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.bod}
            onChange={() => {
              this.changeBod();
            }}
          >
            BOD
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.cod}
            onChange={() => {
              this.changeCod();
            }}
          >
            COD
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.tss}
            onChange={() => {
              this.changeTss();
            }}
          >
            TSS
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.f_coil}
            onChange={() => {
              this.changeFCoil();
            }}
          >
            F COLI
          </FormCheckbox>
        <Row className="py-2">
          <Col className="d-flex mb-2">       
            <FormInput    
              name = "startDate"
              type="date"
              value = {
                this.state.startDate
              }
              onChange={this.on_change}
            />
            <FormInput    
              name = "endDate"
              type="date"
              value = {
                this.state.endDate
              }
              onChange={this.on_change}
            />
            <Button style={{marginRight:"29px"}}
            onClick={(e) => this.getChartDataAll(e)}>
              Show
            </Button>
            {isDataLoaded}
          </Col>
          <Col >
          {this.props.permission.export == "YES" ? (

            <div className="float-right d-flex ">
              <h6 className="m-0" style={{padding : "10px"}}>Export As</h6>

              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"#007bff"

                  }}
                 onClick={() => this.downloadReportAsCSV()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFileCsv} size="2x" />
                  
              </Button>
              <CSVLink 
                data={this.state.response}
                asyncOnClick={true}
                filename={this.getFileName('csv')}
                headers={headers}
                ref={(r) => this.csvLink = r}
                // onClick={() => this.downloadReportAsCSV()}
              
              >
                  </CSVLink>
                  {generatingReport}

              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"green"

                  }}
                 onClick={() => this.downloadReportAsExcel()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFileExcel} size="2x" />
              </Button>
              <CSVLink 
                data={this.state.response}
                asyncOnClick={true}
                filename={this.getFileName('xlsx')}
                headers={headers}
                ref={(r2) => this.csvLinkForExcel = r2}
                // onClick={() => this.downloadReportAsCSV()}
              
              >
                </CSVLink>
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"red"

                  }}
                  onClick={() => this.downloadReportAsPDF()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFilePdf} size="2x" />
              </Button>
            </div> 
            ): (
              ""
            )}
            </Col>
        </Row>
        <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
          </Row>
          <Row className="py-2">
      <Col  className="">
      <div style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
      </div> 
      </Col>
    </Row> 
    <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.isGraphRender}
            </div> 
            </Col>
          </Row>
        <div>
          {graphicalView ? (
        <Row>
          {this.state.ph ? (
            <div className="mx-auto mb-4 col-lg-6">
            <Card  className="h-80">
              <CardHeader className="border-bottom">
                <h6 className="m-0">PH</h6>
              </CardHeader>
              <CardBody className="pt-0"  style={{marginTop: "20px"}}>
                <Line
                id="ph"
                data={this.state.chartData}
                width={100}
                height={50}
                options={{
                  legend : {
                    display: false
                }
                ,
                scales : {
                  yAxes : [{
                    scaleLabel: {
                      display : true,
                      labelString :  'Number'
                    }
                  }],
                  xAxes : [{
                    scaleLabel :{
                      display: true,
                      labelString: 'Date'
                    }
                  }],
                } 
                }  
                }
                />
              </CardBody>
          </Card>
        </div>

          ): (
          <h1></h1>
          )}

{this.state.bod ? (
  <div className="mx-auto mb-4 col-lg-6">
  <Card small className="h-100">
    <CardHeader className="border-bottom">
      <h6 className="m-0">BOD</h6>
    </CardHeader>
    <CardBody className="pt-0"  style={{marginTop: "20px"}}>
      <Line 
          id="bod"
          data={this.state.chartData2}
          width={100}
          height={50}
          options={{
            legend : {
              display: false
          }          ,
          scales : {
            yAxes : [{
              scaleLabel: {
                display : true,
                labelString :  'mg/L'
              }
            }],
            xAxes : [{
              scaleLabel :{
                display: true,
                labelString: 'Date'
              }
            }],
          } 
          }  
          }
        />
    </CardBody>
  </Card>
</div>

  ): (
    <h1></h1>
    )}

{this.state.cod ? (

  <div className="mx-auto mb-4 col-lg-6">
  <Card small className="h-100">
    <CardHeader className="border-bottom">
      <h6 className="m-0">COD</h6>
    </CardHeader>
    <CardBody className="pt-0"  style={{marginTop: "20px"}}>
      <Line
        id="cod"
        data={this.state.chartData3}
        width={100}
        height={50}
        options={{
          legend : {
            display: false
        }          ,
        scales : {
          yAxes : [{
            scaleLabel: {
              display : true,
              labelString :  'mg/L'
            }
          }],
          xAxes : [{
            scaleLabel :{
              display: true,
              labelString: 'Date'
            }
          }],
        } 
        }  
        }
      />
    </CardBody>
  </Card>
</div>
  ): (
    <h1></h1>
    )}
    {this.state.tss ? (
            <div className="mx-auto mb-4 col-lg-6">
            <Card small className="h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">TSS</h6>
              </CardHeader>
              <CardBody className="pt-0"  style={{marginTop: "20px"}}>
                <Line
                    id="tss"
                    data={this.state.chartData4}
                    width={100}
                    height={50}
                    options={{
                      legend : {
                        display: false
                    }          ,
                    scales : {
                      yAxes : [{
                        scaleLabel: {
                          display : true,
                          labelString :  '%'
                        }
                      }],
                      xAxes : [{
                        scaleLabel :{
                          display: true,
                          labelString: 'Date'
                        }
                      }],
                    } 
                    }  
                    }
                  />
              </CardBody>
            </Card>
          </div>
  ): (
    <h1></h1>
    )}
    {this.state.f_coil ? (

<div className="mx-auto mb-4 col-lg-6">
<Card small className="h-100">
  <CardHeader className="border-bottom">
    <h6 className="m-0">F COLI</h6>
  </CardHeader>
  <CardBody className="pt-0"  style={{marginTop: "20px"}}>
    <Line
        id="fcoli"
        data={this.state.chartData4}
        width={100}
        height={50}
        options={{
          legend : {
            display: false
        },
        scales : {
          yAxes : [{
            scaleLabel: {
              display : true,
              labelString :  'MPN/100ml'
            }
          }],
          xAxes : [{
            scaleLabel :{
              display: true,
              labelString: 'Date'
            }
          }],
        } 
        } }
      />
  </CardBody>
</Card>
</div>
  ): (
    <h1></h1>
    )}
    </Row>
      ) : (
      <h1></h1>
    )}
    </div>
  </div> 
       
    );

  }
}

TreatedWaterGraph.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired
};
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,

  };
}

// export default withRouter(TreatedWaterGraph);
export default withRouter(
  connect(
    mapStateToProps
  )(TreatedWaterGraph)
);