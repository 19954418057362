import React, { Component } from "react";
import { withRouter } from "react-router";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import {Line} from 'react-chartjs-2'
import * as reportService from "../../../../services/ReportService";
import logo from '../../../assets/Spinner-1s-200px.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv,faFileExcel,faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";

import {
  Row,
  Col,
  Card,
  FormInput,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  Progress
} from "shards-react";
import {  object } from "prop-types";

class PlantInputOutPutGraph extends Component {
  selectedReportType="";
  constructor(props) {
   
    super(props);
    this.state = {
      date: null,
      chartData:{},
      chartData2:{},
      chartData3:{},
      chartData4:{},
      open: false,
      modalDiv: null,
      labels: [],
      startDate: Date(),
      endDate: Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      SeptageReceivedHH: [],
      SeptageReceivedCTPT: [],
      TreatedWater: [],
      BioChar: [],
      isGraphView: false,
      isDataLoaded: false,
      validationError: "",
      dataNotFoundMessaage: "",
      response: [],
      isCSVData: false,
      isGraphRender: ''
    };
    this.on_change = this.on_change.bind(this);
  }

  componentDidMount(){
    //console.log(this.props.permission)
    this.setState({
      startDate: null,
      endDate: null  
    })
  }
  //Seting value to input field
  on_change(e) {
    this.setState({
     [e.target.name]: [e.target.value],
          validationError:  e.target.value != "" 
          ? "" :  e.target.name === "startDate" ? " Please Select the start date" : e.target.name === "endDate" ?  "Please select the end date" : "",
    }); 
  }

  getChartData(){    
    // Ajax calls here
    this.setState({
      chartData:{
        labels: this.state.labels,
        datasets:[
          {
            
            data:this.state.SeptageReceivedHH,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }


  getChartData2(){
    // Ajax calls here
    this.setState({
      chartData2:{
        labels: this.state.labels,
        datasets:[
          {
            
            data:this.state.SeptageReceivedCTPT,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  getChartData3(){
    // Ajax calls here
    this.setState({
      chartData3:{
        labels: this.state.labels,
        datasets:[
          {
            
            data:this.state.TreatedWater,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  getChartData4(){
    // Ajax calls here
    this.setState({
      chartData4:{
        labels:this.state.labels,
        datasets:[
          {
            label:'',
            data:this.state.BioChar,
            backgroundColor:[
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            lineTension: 0
          }
        ]
      }
    });
  }

  checkValidation(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
      //console.log('Validation Failed')
      if(startDate == null){
        this.setState ({
          validationError: "Please Select the start date first"
        }); 
      }else if(endDate == null){
        this.setState({
          validationError: "Please Select the end date"
        }); 
      }
      return false;
    }else{
      return true;
    }

    // if(this.state.isGraphView){
    //   this.getChartDataAll();
    // }

  }

  // setGraphViewVisible(){
  //   let startDate = this.state.startDate;
  //   let endDate = this.state.endDate;
  //   if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
  //     if(startDate == null){
  //       this.setState ({
  //         validationError: "Please Select the start date first"
  //       }); 
  //     }else if(endDate == null){
  //       this.setState({
  //         validationError: "Please Select the end date"
  //       }); 
  //     }
  //   }else{
  //     this.getChartDataAll();
  //     this.setState({
  //       dataNotFoundMessaage:"",
  //       isDataLoaded: true
  //     })

  //   }

  //   // if(this.state.isGraphView){
  //   //   this.getChartDataAll();
  //   // }
  // }



  getChartDataAll(){
    if(this.checkValidation()){
      this.setState({
        dataNotFoundMessaage:"",
        isDataLoaded: true
      })

      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      //let PlantIO=this.state.tabledata;
      const listTableData = reportService.getPlantInputOutputGraph;
      
       listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
         data = entryListForTableRes; 
         data.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
          // console.log(data);
          this.setState({
            response:entryListForTableRes
          })
       
        this.setState({ 
          isGraphView: true,
          dataNotFoundMessaage:"",
          labels: [],
          BioChar: [],
          SeptageReceivedHH:[],
          SeptageReceivedCTPT: [],
          TreatedWater: []
  
        });
        data.map((item) => {         
          let current_datetime = new Date(item.date)
          let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1)
          this.state.labels.push(formatted_date);
          // let totalSeptage = 0;
          // for(let i=0;i<item.septage.length;i++){
          //   totalSeptage = totalSeptage + item.septage[i].quantity_of_septage
          // }        
          this.state.SeptageReceivedHH.push(item.septage_received_HH);
          this.state.SeptageReceivedCTPT.push(item.septage_received_CTPT);
          this.state.TreatedWater.push(item.treated_water_taken);
          this.state.BioChar.push(item.biochar_generated_kgs);      
    
        });
  
        this.getChartData();
        this.getChartData2();
        this.getChartData3();
        this.getChartData4();
        this.setState({
          isDataLoaded: false,
          isGraphRender: ''
        })
       },
       (error) =>{
         //console.log(JSON.stringify(error));
         this.setState({
          isDataLoaded:false,
          isGraphView:false,
         })
         let message  = error.response.data.message;
         this.setState({
           dataNotFoundMessaage: message
         })
        }
      );
    }
  }


 


  //Download progress modal
  modal() {
    return(
      <Modal 
        style={{ marginTop: "150px"}}
        open={this.state.isCSVData}
        className="h-75 d-flex align-items-center justify-content-center modal-width">
        <ModalBody >
        <div>
          <div>
          <Progress animated theme="primary" value={100} />
          </div>
          <div className="modal-body-name" style= {{ marginTop: "15px", textAlign: "center"}}>
            Exporting {this.selectedReportType} Report...
          </div>
          <div className="modal-body-message"style= {{textAlign: "center"}}>
           Just a moment Please
          </div>
          {/* <div className="modal-body-discard"style= {{textAlign: "center"}}>
            Discard
          </div> */}
        </div>
        </ModalBody>
      </Modal>
    );
  }


  //This method is used to calcuate total septage
  calculateTotalSeptageValue(septage){
    let totalSeptage = 0;
      for(let i=0;i<septage.length;i++){
        totalSeptage = totalSeptage + septage[i].quantity_of_septage
      }   
      return totalSeptage;
  }


  //this method is used to format the date
  dateFormat(date){
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + (current_datetime.getFullYear());
    return formatted_date;
  }

  getFileName(fileType =  String){
    let startDate = this.state.startDate;
    let endDate= this.state.endDate;
    let fileName = "Plant_Input_Output_Report_" + new String(this.props.match.params.plant_name) +"_"+ startDate+"_" +endDate+"."+fileType;
    return fileName;

  }

  //Method is used to download report in the from of CSV
  downloadReportAsCSV(){
    this.selectedReportType = "CSV"

    if(this.checkValidation()){
      this.setState({
        isCSVData : true
      })
  
      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getPlantInputOutputGraph;
      
       listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
         data = entryListForTableRes;  
         //console.log(data)
         data.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
  
          let csvData = [];
          for(let i=0;i<data.length;i++){
            let a  = {};
            a = {
              Date: this.dateFormat(data[i].date),
              septageReceivedHH: data[i].septage_received_HH == null ? 'NA':  data[i].septage_received_HH,
              septageReceivedCTPT: data[i].septage_received_CTPT == null ? 'NA': data[i].septage_received_CTPT ,
              treatedWaterTaken: data[i].treated_water_taken == null ? 'NA': data[i].treated_water_taken,
              biocharGeneratedKgs: data[i].biochar_generated_kgs == null ? 'NA': data[i].biochar_generated_kgs
  
            }
            csvData.push(a);
          }
          this.setState({
            response:csvData
          }, () => {
            this.csvLink.link.click();
            this.setState({
            isCSVData : false
            })
         })      
        this.setState({
          isDataLoaded: false
        })
       },
       (error) =>{
         this.setState({
          isDataLoaded:false,
          isGraphView:false,
          isCSVData : false
         })
         let message  = error.response.data.message;
         this.setState({
           dataNotFoundMessaage: message
         })
       }
      );
    }

  }

  //This method is used to download Report in Excel format
  downloadReportAsExcel(){
    this.selectedReportType = "Excel";
    if(this.checkValidation()){
    this.setState({
      isCSVData : true
    })

    let data;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    const plant_id = localStorage.getItem("plant_id")
    const listTableData = reportService.getPlantInputOutputGraph;
    
     listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
       data = entryListForTableRes;  
       data.sort(function(a,b){
        var c = new Date(a.date);
        var d = new Date(b.date);        
        return c-d;
        });

        let csvData = [];
        for(let i=0;i<data.length;i++){
          let a  = {};
          a = {
            Date: this.dateFormat(data[i].date),
            septageReceivedHH: data[i].septage_received_HH == null ? 'NA':  data[i].septage_received_HH,
            septageReceivedCTPT: data[i].septage_received_CTPT == null ? 'NA': data[i].septage_received_CTPT ,
            treatedWaterTaken: data[i].treated_water_taken == null ? 'NA': data[i].treated_water_taken,
            biocharGeneratedKgs: data[i].biochar_generated_kgs == null ? 'NA': data[i].biochar_generated_kgs
          }
          csvData.push(a);
        }
        this.setState({
          response:csvData
        }, () => {
          this.csvLinkPdf.link.click();
          this.setState({
            isCSVData : false
          })
       })      
      this.setState({
        isDataLoaded: false
      })
     },
     (error) =>{
       this.setState({
        isDataLoaded:false,
        isGraphView:false,
        isCSVData : false
       })
       let message  = error.response.data.message;
       this.setState({
         dataNotFoundMessaage: message
       })
      }     
     );
    }
  }


  downloadReportAsPdf(){
    this.selectedReportType = "PDF"
    if(this.checkValidation()){
      if(this.state.isGraphView){
        this.setState({
          isCSVData : true
        })

  
    var pdf = new jsPDF("p", "mm", "a4");
    var totalPagesExp = "{total_pages_count_string}";

    var header = function(data) {
      pdf.setFontSize(10);
      pdf.setTextColor(40);
      pdf.setFontStyle('normal');
      //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
      //var pdfTitle = this.drPolicy.name + "Report" +" (Generated on "+ this.getDateinFormat(new Date())+")"
      pdf.text('Plant Input Output Report', data.settings.margin.left, 20);
          // FOOTER
          var str = "Page " + data.pageCount;
          // Total page number plugin only available in jspdf v1.0+
          if (typeof pdf.putTotalPages === 'function') {
            str = str + " of " + totalPagesExp;
          }
          pdf.setFontSize(10);
          pdf.text(str, data.settings.margin.left, pdf.internal.pageSize.height - 10);
      };

      var options = {
        addPageContent: header,
        margin: {
          top: 30
        },

      };
      var canvas = document.getElementById('SeptageReceivedHH');
      var dataURLForSeptageReceivedHH = canvas.toDataURL();
      var canvas2 = document.getElementById('septageReceivedCTPT');
      var dataURLForseptageReceivedCTPT = canvas2.toDataURL();
      var canvas3 = document.getElementById('treatedWaterTakenOut');
      var dataURLFortreatedWaterTakenOut = canvas3.toDataURL();
      var canvas4 = document.getElementById('biocharGenerated');
      var dataURLForbiocharGenerated= canvas4.toDataURL();

      let csvData = [];
      const head = ["Date", "Septage Received HH", "Septage Received CTPT", "Treated Water Taken","Biochar Generated Kgs"]

      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getPlantInputOutputGraph;
      
      listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
        data = entryListForTableRes;  
        data.sort(function(a,b){
          var c = new Date(a.date);
          var d = new Date(b.date);        
          return c-d;
          });
          for(let i=0;i<data.length;i++){
            let a  = [];
            a.push(this.dateFormat(data[i].date))
            a.push(data[i].septage_received_HH == null? 'NA': data[i].septage_received_HH)
            a.push(data[i].septage_received_CTPT == null ? 'NA': data[i].septage_received_CTPT)
            a.push(data[i].treated_water_taken == null ? 'NA': data[i].treated_water_taken)
            a.push(data[i].biochar_generated_kgs == null ? 'NA': data[i].biochar_generated_kgs)
            csvData.push(a)    
          }
          this.setState({
            response:csvData
          }, () => {
            pdf.autoTable(head, this.state.response, options);
            pdf.addPage();
      
            //It is used to add image
            // let startImageSize = pdf.lastAutoTable.finalY;
          
            pdf.text('Septage Received HH', 15, 20);
            pdf.addImage(dataURLForSeptageReceivedHH, 'JPEG', 15,  30, 160, 80);
      
            pdf.addPage();
            pdf.text('Septage Received CTPT', 15, 20);
            pdf.addImage(dataURLForseptageReceivedCTPT, 'JPEG', 15, 30,160, 80);
            // pdf.addPage();
            pdf.addPage();
            pdf.text('Treated Water Taken Out', 15, 20);
            pdf.addImage(dataURLFortreatedWaterTakenOut, 'JPEG', 15, 30, 160, 80);
      
            pdf.addPage();
            pdf.text('Bio Char Generated', 15, 20);
            pdf.addImage(dataURLForbiocharGenerated, 'JPEG', 15, 30, 160, 80);
          // let startImageSizee = pdf.lastAutoTable.finalY;    
      
      
            if (typeof pdf.putTotalPages === 'function') {
              pdf.putTotalPages(totalPagesExp);
            }
            pdf.save(this.getFileName('pdf'));
          
            this.setState({
              isCSVData : false
            })
        })      
        this.setState({
          isDataLoaded: false
        })
      },
      (error) =>{
        this.setState({
          isDataLoaded:false,
          isGraphView:false,
          isCSVData : false
        })
        let message  = error.response.data.message;
        this.setState({
          dataNotFoundMessaage: message
        })
        }     
      );
    }else{
      this.setState({
        isGraphRender: 'Please Show Graph First'
      })
     }
    }
  }

  


 
  render() {

    const generatingReport = this.modal();
    let headers = [
      { label: "Date", key: "Date" },
      { label: "Septage Received HH", key: "septageReceivedHH" },
      { label: "Septage Received CTPT", key: "septageReceivedCTPT" },
      { label: "Treated Water Taken", key: "treatedWaterTaken" },
      { label: "Biochar Generated Kgs", key: "biocharGeneratedKgs" }
    ];
    const isDataLoaded = this.state.isDataLoaded 
    ? <div className="text-center" >
      <img style={{width:"45px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const graphicalView = this.state.isGraphView;

    return (
        <div>
          <Row className="py-2">
            <Col className="d-flex mb-2">       
              <FormInput    
                name = "startDate"
                type="date"
                value = {
                  this.state.startDate
                }
                onChange={this.on_change}
              />
              <FormInput
                  
                name = "endDate"
                type="date"
                value = {
                  this.state.endDate
                }
                onChange={this.on_change}
              />
              <Button style={{marginRight:"29px"}}
                onClick={(e) => this.getChartDataAll(e)}>
                  Show
              </Button> 
              {isDataLoaded}
            </Col>
            <Col >
            {this.props.permission.export == "YES" ? (
              <div className="float-right d-flex ">
                <h6 className="m-0" style={{padding : "10px"}}>Export As</h6>
                <Button
                  style={{
                      borderRadius: "50px",
                      backgroundColor: "white",
                      border: "none",
                      color:"#007bff"
                    }}
                  onClick={() => this.downloadReportAsCSV()}
                    className="hover-blue ml-2"
                    id="TooltipExample"
                  >
                  <FontAwesomeIcon  icon={faFileCsv} size="2x" />
                </Button>
                <CSVLink 
                  data={this.state.response}
                  asyncOnClick={true}
                  filename={this.getFileName('csv')}
                  headers={headers}
                  ref={(r) => this.csvLink = r}
                  // onClick={() => this.downloadReportAsCSV()}
                  >
                </CSVLink>
                <Button
                    style={{
                      borderRadius: "50px",
                      backgroundColor: "white",
                      border: "none",
                      color:"green"

                    }}
                    onClick={() => this.downloadReportAsExcel()}
                    className="hover-blue ml-2"
                    id="TooltipExample"
                  >
                  <FontAwesomeIcon  icon={faFileExcel} size="2x" />
                </Button>
                <CSVLink 
                  data={this.state.response}
                  asyncOnClick={true}
                  filename={this.getFileName('xlsx')}
                  headers={headers}
                  ref={(r2) => this.csvLinkPdf = r2}                
                >
                </CSVLink>
                <Button
                    style={{
                      borderRadius: "50px",
                      backgroundColor: "white",
                      border: "none",
                      color:"red"

                    }}
                    onClick={() => this.downloadReportAsPdf()}
                    className="hover-blue ml-2"
                    id="TooltipExample"
                  >
                    {/* <i className="fas fa-file-csv"></i> */}
                    <FontAwesomeIcon  icon={faFilePdf} size="2x" />
                </Button>
              </div> 
            ): (
              ""
            )}
            </Col>
          </Row>
          {generatingReport}
          <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
          </Row>
          <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.dataNotFoundMessaage}
            </div> 
            </Col>
          </Row>
          <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.isGraphRender}
            </div> 
            </Col>
          </Row>
        <div>
      {graphicalView ? (
        <Row>
          <div className="mx-auto mb-4 col-lg-6">
            <Card small className="h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Septage Received HH</h6>
              </CardHeader>
              <CardBody className="pt-0" style={{marginTop: "20px"}}>
                <Line
                    id="SeptageReceivedHH"
                    data={this.state.chartData}
                    width={100}
                    height={50}
                    options={{
                      legend : {
                        display: false
                    },
                              
                    scales : {
                      yAxes : [{
                        scaleLabel: {
                          display : true,
                          labelString :  'Litres'
                        }
                      }],
                      xAxes : [{
                        scaleLabel :{
                          display: true,
                          labelString: 'Date'
                        }
                      }],
                    } 
                    }  
                    }

                  />
              </CardBody>
            </Card>
          </div>
          <div className="mx-auto mb-4 col-lg-6">
            <Card small className="h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Septage Received CTPT</h6>
              </CardHeader>
              <CardBody className="pt-0" style={{marginTop: "20px"}}>

                <Line
                    id="septageReceivedCTPT"
                    data={this.state.chartData2}
                    width={100}
                    height={50}
                    options={{
                      legend : {
                        display: false
                    }          ,
                    scales : {
                      yAxes : [{
                        scaleLabel: {
                          display : true,
                          labelString :  'Hours'
                        }
                      }],
                      xAxes : [{
                        scaleLabel :{
                          display: true,
                          labelString: 'Date'
                        }
                      }],
                    } 
                    }  
                    }
                  />
              </CardBody>
            </Card>
          </div>
          <div className="mx-auto mb-4 col-lg-6">
            <Card small className="h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Treated Water Taken Out</h6>
              </CardHeader>
              <CardBody className="pt-0" style={{marginTop: "20px"}}>

                <Line 
                    id="treatedWaterTakenOut"
                    data={this.state.chartData3}
                    width={100}
                    height={50}
                    options={{
                      legend : {
                        display: false
                    }
                    ,
                    scales : {
                      yAxes : [{
                        scaleLabel: {
                          display : true,
                          labelString :  'Litres'
                        }
                      }],
                      xAxes : [{
                        scaleLabel :{
                          display: true,
                          labelString: 'Date'
                        }
                      }],
                    } 
                    }  
                    }
                  />
              </CardBody>
            </Card>
          </div>
          <div className="mx-auto mb-4 col-lg-6">
            <Card small className="h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Biochar Generated</h6>
              </CardHeader>
              <CardBody className="pt-0" style={{marginTop: "20px"}}>
                <Line
                    id="biocharGenerated"
                    data={this.state.chartData4}
                    width={100}
                    height={50}
                    options={{
                      legend : {
                        display: false
                    }
                    ,

                    scales : {
                      yAxes : [{
                        scaleLabel: {
                          display : true,
                          labelString :  'Kgs'
                        }
                      }],
                      xAxes : [{
                        scaleLabel :{
                          display: true,
                          labelString: 'Date'
                        }
                      }],
                    } 
                  }
                }
                  />
              </CardBody>
            </Card> 
          </div>
        </Row>
          ) : (
            <h1></h1>
          )}
      </div>
    </div>     
    );
  }
}

PlantInputOutPutGraph.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired,
};
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,

  };
}

export default withRouter(
  connect(
    mapStateToProps
  )(PlantInputOutPutGraph)
);
