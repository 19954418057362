import axios from "axios";

export const getPlantById = id => {
  return axios.get("/api/v1/plant_details/" + id);
};

export const getPlantByMasterId = id => {
  return axios.get("/api/v1/plant_masters/" + id);
};

export const getPlants = () => {
  return axios.get("/api/v1/plant_details/");
};

export const addPlant = plant => {
  return axios.post("/api/v1/plant_details", plant);
};

export const editPlant = plant => {
  return axios.put("/api/v1/plant_details/" + plant.plant_details.id, plant);
};

export const deletePlant = id => {
  return axios.delete("/api/v1/plant_details/" + id);
};
