import React from "react";
import UserFormTemplate from "../../containers/UserFormTemplate";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const AddUser = ({ navToggle }) => {
  return (
    <UserFormTemplate
      action={types.ADD_USER}
      navToggle={navToggle}
      form_head_title={"New User"}
      form_head_sub_title={""}
      form_title={"Add User"}
      form_sub_title={"User Details"}
      button_title={"Add User"}
      visibilityFilter={{
        first_name: true,
        last_name: true,
        email: true,
        phone_number: true,
        organization: true,
        designation: true,
        role: true,
        gender: true,
        address: true,
        plants: true
      }}
    />
  );
};

AddUser.propTypes = {
  navToggle: func.isRequired
};

export default AddUser;
