import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import MainNavbar from "../layout/MainNavbar/MainNavbar";

import { connect } from "react-redux";
import { navbarActions } from "../../actions/AppStateActions";

import ChangePassword from "../auth/containers/ChangePassword";
// import PlantsDashboard from "../dashboard/plants-dashboard/PlantsDashboard";
import ProcessesDashboard from "../dashboard/plants-dashboard/ProcessesDashboard";
import PublicDashboard from "./containers/PublicDashboard";
import Login from "../auth/containers/Login";
import ForgotPassword from "../auth/containers/ForgotPassword";
import ResetPassword from "../auth/containers/ResetPassword";
import { object, func } from "prop-types";
// import ProcessMetricsDashboard from "../dashboard/plants-dashboard/ProcessMetricsDashboard";
import EditUserProfile from "../common/EditUserProfile";
import PlantInputOutputReport from "../admin/containers/Report/PlantInputOutputReportTemplate";

// import PlantInputOutputReport from "./containers/Report/PlantInputOutputReportTemplate";
// import PlantOperationReport from "../admin/containers/Report/PlantOperationReportTemplate"
// import TreatedWaterReportTemplate from "../admin/containers/Report/TreatedWaterReportTemplate"
// import CommentReportTemplate from '../admin/containers/Report/CommentReportTemplate'
// import FreeFormReportTemplate from '../admin/containers/Report/FreeFormReportTemplate'
// import ConversationsList from "../auth/containers/ConversationList";

class PublicApp extends Component {
  constructor(props) {
    super(props);
    this.toggleActions = this.toggleActions.bind(this);
  }

  toggleActions(dropdown_name) {
    this.props.navbarActions(
      dropdown_name,
      !this.props.nav_state[dropdown_name]
    );
  }

  bindNavbar(Component) {
    return (
      <div className="h-100">
        <MainNavbar navToggle={this.toggleActions} />
        <Component navToggle={this.toggleActions} />
      </div>
    );
  }

  render() {
    return (
      <Switch>
        {/* <Route exact path="/" component={ConversationsList} /> */}
        <Route exact path="/" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />

        <Route exact path="/invitation/accept" component={ResetPassword} />

        <Route exact path="/password/edit" component={ResetPassword} />

        <Route
          exact
          path="/dashboard"
          render={() => this.bindNavbar(PublicDashboard)}
        />

        <Route
          exact
          path="/change-password"
          render={() => this.bindNavbar(ChangePassword)}
        />

        <Route
          exact
          path="/plants/:plant_name/overview/:plant_id"
          render={() => this.bindNavbar(ProcessesDashboard)}
        />
        {/* <Route
          path="/:plant_name/processes/:process_name"
          render={() => this.bindNavbar(ChangePassword)}
        /> */}
        <Route
          exact
          path="/plants/:plant_name/overview/processes/:process_name"
          render={() => this.bindNavbar(PlantInputOutputReport)}
        />

          {/* <Route
            exact
            path = "/admin/plants/:plant_name/:plant_id/processes/:process_name"
            render = {
              () => ( <PlantInputOutputReport navToggle = {
                  this.props.navToggle
                }
                />
              )
            }
            
            /> */}



        {/* Editors */}
        <Route
          exact
          path="/edit-profile"
          render={() => this.bindNavbar(EditUserProfile)}
        />

        {/* <Route
            exact
            path = "/plants/:plant_name/overview/:plant_id/processes/:process_name"
            render={() => this.bindNavbar(EditUserProfile)}
        /> */}
      </Switch>
    );
  }
}

function mapStatetoProps(state) {
  return {
    current_user: state.AppState.current_user,
    nav_state: state.AppState.nav_state
  };
}

PublicApp.propTypes = {
  navbarActions: func.isRequired,
  nav_state: object.isRequired
};

export default connect(
  mapStatetoProps,
  { navbarActions }
)(withRouter(PublicApp));
