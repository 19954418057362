

export const DrierOutput = (
    ts = ""
  ) => {
    return {
      ts
    };
  };
  
  
  export default DrierOutput(
    "10",
    "20",
    "30"
  );
  