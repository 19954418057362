export const TreatedWater = (
    ph = "",
    orp = "",
    e_coil = ""
  ) => {
    return {
      ph,
      orp,
      e_coil
    };
  };
  
  
  export default TreatedWater(
    "10",
    "20",
    "30"
  );
  