import React, { Component } from "react";
// import ChartsTemplate from "./charts/ChartsTemplate";
// import PageTitle from "../../common/PageTitle";
import PageTitle from "../../../common/PageTitle";
import * as reportService from "../../../../services/ReportService";
import logo from '../../../assets/Spinner-1s-200px.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { withRouter } from "react-router";
import { connect } from "react-redux";


import MainSidebar from '../../../layout/MainSidebar/MainSidebar'

import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Button,
  CardBody,
  FormInput,
  Modal,
  ModalBody,
  Progress
} from "shards-react";
import { object } from "prop-types";
// import { getMetrics } from "../../../services/DashboardService";

class CommentReportTemplate extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.on_change = this.on_change.bind(this);
    this.on_change_single_date = this.on_change_single_date.bind(this);
    this.toogleComment = this.toogleComment.bind(this);

    this.state = {
      s: "",
      opencomment: false,
      date: Date(),
      selectedReport: "hi",
      open: false,
      modalDiv: null,
      labels: [],
      startDate: Date(),
      endDate: Date(),
      dataset: '',
      dropdown1: false,
      entryListForTable: [],
      isMoreButtonClicked: false,
      plant_id: "",
      isDataLoadedFully: false,
      isDataLoaded: false,
      validationError: "",
      validationErrorForDateRange: "",
      dataNotFoundMessaage: "",
      response: [],
      isCSVData: false,
      isGraphRender: ''
    };
  }

  componentDidMount() {
    this.setState({
      startDate: null,
      endDate: null
    })
    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully
    })
    this.setState({
      plant_id: localStorage.getItem('plant_id')
    })
    let dailyProcessDate = localStorage.getItem('current_daily_process_date');
    // let inHouseDate = localStorage.getItem('current_inhouse_date');
    // let externalDate = localStorage.getItem('current_external_date');
    //let a = [];
    // a.push(dailyProcessDate);
    // a.push(inHouseDate);
    // a.push(externalDate)

    // a.sort(function(a,b){
    //   var c = new Date(a);
    //   var d = new Date(b);        
    //   return c-d;
    //   });
    //   let lastdate= a[a.length-1];
    this.setState({
      date: dailyProcessDate
    })
    let data;

    const plant_id = localStorage.getItem('plant_id');
    const listTableData = reportService.getCommments;
    listTableData(plant_id, dailyProcessDate).then(entryListForTableRes => {
      data = entryListForTableRes;
      this.setState({
        entryListForTable: data,
      });
      this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully
      })
    }, () => {
      //console.log(JSON.stringify(error));
      this.setState({
        isDataLoadedFully: false,
        isDataLoaded: true,
      })
      //let message  = error.response.data.message;
      this.setState({
        dataNotFoundMessaage: "No Record Found"
      })
    });

  }
  toggle() {
    this.setState(prevState => {
      return { dropdown1: !prevState.dropdown1 };
    });
  }

  modal() {
    return (
      <Modal open={this.state.opencomment} toggle={this.toogleComment}>
        <div>
          <span className="float-right close" id={"toggler"} style={{ float: "right", cursor: "pointer", margin: "10px 10px -5px 10px" }} onClick={this.toogleComment}>
            <i className="material-icons">close</i>
          </span>
        </div>
        <ModalBody style={{ paddingTop: "0px" }}>{this.state.s}</ModalBody>
      </Modal>
    );

  }
  toogleComment(entry) {
    if (entry != undefined) {
      this.setState({
        s: entry.observations == undefined ? "" : entry.observations
      })
    }
    this.setState({
      opencomment: !this.state.opencomment
    })
  }

  on_change(e) {
    this.setState({
      [e.target.name]: [e.target.value],
      validationErrorForDateRange: e.target.value != ""
        ? "" : e.target.name === "startDate" ? " Please Select the start date" : e.target.name === "endDate" ? "Please select the end date" : "",

    });
  }
  on_change_single_date(e) {
    this.setState({
      date: e.target.value,
      validationError: e.target.value === "" ? "Please select the date" : "",

    });
  }
  setGraphViewVisible() {
    this.setState({
      isMoreButtonClicked: !this.state.isMoreButtonClicked,
      isDataLoaded: false,

    })

  }
  //for single date
  getTableData() {
    const startDate = this.state.date;
    if (startDate == "") {
      if (startDate == null) {
        this.setState({
          validationErrorForDateRange: "Please Select the  date first"
        });
      }
    } else {

      this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully,
        entryListForTable: {},
        isDataLoaded: false
      })
      let data;
      const singleDate = this.state.date;
      const plant_id = this.state.plant_id;
      const listTableData = reportService.getCommments;
      listTableData(plant_id, singleDate).then(entryListForTableRes => {
        data = entryListForTableRes;
        if (data.message != null) {
          this.setState({
            isDataLoaded: true
          })
          this.setState({
            isDataLoadedFully: !this.state.isDataLoadedFully,
            entryListForTable: {},
            validationErrorForDateRange: "",
            validationError: ""
          });

        } else {
          this.setState({
            entryListForTable: data,
            validationErrorForDateRange: "",
            validationError: ""
          });
          this.setState({
            isDataLoadedFully: !this.state.isDataLoadedFully
          })

        }


      }, (error) => {
        if (error.response.status == 404) {
          let message = error.response.data.message;
          this.setState({
            isDataLoadedFully: false,
            isDataLoaded: true,
            dataNotFoundMessaage: message
          })
        }

      }
      );
    }
  }

  checkValidation() {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if ((startDate == null || startDate[0] == "") || (endDate == null || endDate[0] == "")) {
      //console.log('Validation Failed')
      if (startDate == null) {
        this.setState({
          validationErrorForDateRange: "Please Select the start date first"
        });
      } else if (endDate == null) {
        this.setState({
          validationErrorForDateRange: "Please Select the end date"
        });
      }
      return false;
    } else {
      return true;
    }
  }

  //for date range
  getTableDataForDateRange() {
    this.setState({
      date: [""]
    })
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if ((startDate == null || startDate[0] == "") || (endDate == null || endDate[0] == "")) {
      if (startDate == null) {
        this.setState({
          validationErrorForDateRange: "Please Select the start date first"
        });
      } else if (endDate == null) {
        this.setState({
          validationErrorForDateRange: "Please Select the end date"
        });
      }
    } else {
      this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully,
        dataNotFoundMessaage: "",
        isDataLoaded: true,
        entryListForTable: {}
      })
      let data;
      const startDate = this.state.startDate;
      const endDate = this.state.endDate;
      const plant_id = this.state.plant_id;
      const listTableData = reportService.getCommmentsGraph;
      listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
        data = entryListForTableRes;
        if (data.message != null) {
          this.setState({
            isDataLoaded: true,
            validationErrorForDateRange: "",
            validationError: ""
          })
          this.setState({
            isDataLoadedFully: !this.state.isDataLoadedFully,
            entryListForTable: {},
          });

        } else {
          this.setState({
            validationErrorForDateRange: "",
            validationError: "",
            entryListForTable: data,
          });
          this.setState({
            isDataLoadedFully: !this.state.isDataLoadedFully
          })

        }

      }, (error) => {
        this.setState({
          isDataLoaded: false,
          entryListForTable: {}
        })
        let message = error.response.data.message;
        this.setState({
          isDataLoaded: true,
          isDataLoadedFully: false,
          dataNotFoundMessaage: message
        })

      });
    }
  }
  modalForAnimation() {
    return (
      <Modal
        style={{ marginTop: "150px" }}
        open={this.state.isCSVData}
        className="h-75 d-flex align-items-center justify-content-center modal-width">
        <ModalBody >
          <div>
            <div>
              <Progress animated theme="primary" value={100} />
            </div>
            <div className="modal-body-name" style={{ marginTop: "15px", textAlign: "center" }}>
              Exporting {this.selectedReportType} Report...
          </div>
            <div className="modal-body-message" style={{ textAlign: "center" }}>
              Just a moment Please
          </div>
            <div className="modal-body-discard" style={{ textAlign: "center" }}>
              Discard
          </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
  getFileName(fileType = String) {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let fileName = "Comment_Report_" + new String(this.props.match.params.plant_name) + "_" + startDate + "_" + endDate + "." + fileType;
    return fileName;

  }

  //Method is used to download report in the from as CSV
  downloadReportAsCSV() {
    this.selectedReportType = "CSV"
    if (this.checkValidation()) {
      this.setState({
        isCSVData: true
      })
      //console.log('downloadReportAsCSV');
      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getCommmentsGraph;
      let fileName = "Comment-Report-" + new String(this.props.match.params.plant_name) + "-" + startDate + "-" + endDate + ".csv"
      listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
        data = entryListForTableRes;
        data.sort(function (a, b) {
          var c = new Date(a.date);
          var d = new Date(b.date);
          return c - d;
        });

        let csvData = [];
        for (let i = 0; i < data.length; i++) {
          let a = {};
          a = {
            Date: this.dateFormat(data[i].date),
            analysis_type: data[i].analysis_type,
            observation: data[i].observations
          }
          csvData.push(a);
        }
        this.setState({
          response: csvData,
          fileName: fileName
        }, () => {
          this.csvLink.link.click();
          this.setState({
            isCSVData: false,

          })
        })
        this.setState({
          isDataLoaded: false
        })
      },
        (error) => {
          this.setState({
            isDataLoaded: false,
            isGraphView: false,
            isCSVData: false
          })
          let message = error.response.data.message;
          this.setState({
            dataNotFoundMessaage: message
          })
        }
      );
    }
  }

  downloadReportAsExcel() {
    this.selectedReportType = "Excel"
    if (this.checkValidation()) {
      this.setState({
        isCSVData: true
      })
      //console.log('downloadReportAsCSV');
      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getCommmentsGraph;
      let fileName = "Comment-Report-" + new String(this.props.match.params.plant_name) + "-" + startDate + "-" + endDate + ".csv"
      listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
        data = entryListForTableRes;
        data.sort(function (a, b) {
          var c = new Date(a.date);
          var d = new Date(b.date);
          return c - d;
        });

        let csvData = [];
        for (let i = 0; i < data.length; i++) {
          let a = {};
          a = {
            Date: this.dateFormat(data[i].date),
            analysis_type: data[i].analysis_type,
            observation: data[i].observations
          }
          csvData.push(a);
        }
        this.setState({
          response: csvData,
          fileName: fileName
        }, () => {
          this.csvLinkForExcel.link.click();
          this.setState({
            isCSVData: false,

          })
        })
        this.setState({
          isDataLoaded: false
        })
      },
        (error) => {
          this.setState({
            isDataLoaded: false,
            isGraphView: false,
            isCSVData: false
          })
          let message = error.response.data.message;
          this.setState({
            dataNotFoundMessaage: message
          })
        }
      );
    }
  }

  downloadReportAsPdf() {
    this.selectedReportType = "PDF"
    if (this.checkValidation()) {
      this.setState({
        isCSVData: true
      })


      var pdf = new jsPDF("p", "mm", "a4");
      var totalPagesExp = "{total_pages_count_string}";

      var header = function (data) {
        pdf.setFontSize(10);
        pdf.setTextColor(40);
        pdf.setFontStyle('normal');
        //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
        //var pdfTitle = this.drPolicy.name + "Report" +" (Generated on "+ this.getDateinFormat(new Date())+")"
        pdf.text('Comment Report', data.settings.margin.left, 20);
        // FOOTER
        var str = "Page " + data.pageCount;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        pdf.text(str, data.settings.margin.left, pdf.internal.pageSize.height - 10);
      };

      var options = {
        addPageContent: header,
        margin: {
          top: 30
        },
        columnStyles: {
          0: { columnWidth: 'wrap' },
          1: { columnWidth: 'wrap' },
          2: { columnWidth: 'auto' },
        }

      };


      let csvData = [];
      const head = ["Date", "Analysis Type", "Observations"]

      let data;
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      const plant_id = localStorage.getItem("plant_id")
      const listTableData = reportService.getCommmentsGraph;

      listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
        data = entryListForTableRes;
        data.sort(function (a, b) {
          var c = new Date(a.date);
          var d = new Date(b.date);
          return c - d;
        });
        for (let i = 0; i < data.length; i++) {
          let a = [];
          a.push(this.dateFormat(data[i].date))
          a.push(data[i].analysis_type)
          a.push(data[i].observations)
          csvData.push(a)
        }
        this.setState({
          response: csvData
        }, () => {
          pdf.autoTable(head, this.state.response, options);
          if (typeof pdf.putTotalPages === 'function') {
            pdf.putTotalPages(totalPagesExp);
          }
          pdf.save(this.getFileName('pdf'));
          this.setState({
            isCSVData: false
          })
        })
        this.setState({
          isDataLoaded: false
        })

      },
        (error) => {
          this.setState({
            isDataLoaded: false,
            isGraphView: false,
            isCSVData: false
          })
          let message = error.response.data.message;
          this.setState({
            dataNotFoundMessaage: message
          })
        }
      );

    }
  }
  dateFormat(date) {
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + (current_datetime.getFullYear());
    return formatted_date;
  }

  render() {
    const generatingReport = this.modalForAnimation();
    let headers = [
      { label: "Date", key: "Date" },
      { label: "Analysis Type", key: "analysis_type" },
      { label: "Observation", key: "observation" },

    ];
    const m = this.modal();
    const isDataLoadedFully = this.state.isDataLoadedFully
      ? <div className="text-center" >
        <img style={{ width: "90px" }} className="rounded mx-auto d-block" src={logo}></img>
      </div>
      : null;
    const isDataLoaded = this.state.isDataLoaded
      ? <Row className="py-2">
        <Col className="">
          <div className="text-center" style={{ color: "Red" }}>
            {this.state.dataNotFoundMessaage}
          </div>
        </Col>
      </Row>
      // <div className="text-center" style={{fontSize:"1.00rem"}} >
      //   <h4>{this.state.dataNotFoundMessaage}</h4>
      // </div>
      : null;
    const graphicalView = this.state.isMoreButtonClicked;
    const listItems = this.setup_searchOne();
    const table = (
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr className="text-center">
                    <th scope="col" className="border-0">
                      #
                  </th>
                    <th scope="col" className="border-0">Type</th>
                    <th scope="col" className="border-0">Date</th>
                    <th scope="col" width="450px" className="border-0">Observation</th>
                  </tr>
                </thead>

                <tbody>
                  {listItems}
                </tbody>
              </table>
              {isDataLoadedFully}
              {isDataLoaded}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );


    // const charts = this.charts_config.map((conf, i) => {
    //   return (
    //     <Col
    //       id={conf.chartTitle + i}
    //      // onClick={() => this.download_report(conf.chartTitle + i, conf)}
    //       lg={conf.chartType == "scatter" ? 12 : 6}
    //       className={
    //         conf.chartType == "scatter" ? "mx-auto mb-4 vh-30" : "mx-auto mb-4"
    //       }
    //       key={i}
    //     >
    //       <ChartsTemplate
    //         magnify={() => this.toggleModal(conf)}
    //         chartTitle={conf.chartTitle}
    //         height={conf.chartType == "scatter" ? "80" : "120"}
    //         chartType={conf.chartType}
    //         chartData={conf.chartData}
    //         rangeDatePicker={conf.datePickerTools}
    //         options={conf.options}
    //       />
    //     </Col>
    //   );
    // });
    return (
      <div className="mh-85">
        <MainSidebar />
        {/* <modal></modal> */}
        {m}

        <Breadcrumb className="col-10 float-right p-0">
          <BreadcrumbItem>
            <a onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/overview/" + localStorage.getItem('plant_id'))} style={{ color: "#007bff", cursor: "pointer" }}>
              {new String(this.props.match.params.plant_name)} Overview</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {new String(this.props.match.params.process_name)}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container
          fluid
          className="main-content-container px-4 col-10 float-right"
        >
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={
                "Comment Report"
              }
              subtitle=""
              className="text-sm-left mb-3"
            />
            <Col>
              <div className="float-right">
              {this.props.permission.export == "YES" ? (
                <Button style={{ marginRight: "29px" }}
                  onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/dailyprocess/")}
                >
                  Add Daily Process Data
            </Button>): (
          ""
        )}
            
                <Button
                  onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")}
                >
                  List Daily Process Data
            </Button>
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "768px" }}>
            </div>
          </div>
          <Row className="py-2">
            <Col sm="3" className="d-flex mb-2 mb-sm-0">
              <FormInput
                name="date"
                type="date"
                value={
                  this.state.date
                }
                onChange={this.on_change_single_date}
              />
              <Button style={{ marginRight: "29px" }}
                onClick={(e) => this.getTableData(e)}>
                Show
            </Button>
            </Col>
          </Row>
          <Row className="py-2">
            <Col sm="2" className="d-flex mb-2 mb-sm-0">
              <div style={{ color: "Red" }}>
                {this.state.validationError}
              </div>
            </Col>
          </Row>
          {/* <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.isGraphRender}
            </div> 
            </Col>
          </Row> */}
          {table}
          <Row>
            <Button style={{ marginLeft: "15px" }}
              onClick={(e) => this.setGraphViewVisible(e)}>
              {graphicalView ? "Less" : "More"}
            </Button>
          </Row>
          <div>
            {graphicalView ? (
              <Row className="py-2">
                <Col className="d-flex mb-2">
                  <FormInput
                    name="startDate"
                    type="date"
                    value={
                      this.state.startDate
                    }
                    onChange={this.on_change}
                  />
                  <FormInput
                    name="endDate"
                    type="date"
                    value={
                      this.state.endDate
                    }
                    onChange={this.on_change}
                  />
                  <Button style={{ marginRight: "29px" }}
                    onClick={(e) => this.getTableDataForDateRange(e)}>
                    Show
                </Button>
                </Col>
                <Col >
                {this.props.permission.export == "YES" ? (
                  <div className="float-right d-flex ">
                    <h6 className="m-0" style={{ padding: "10px" }}>Export As</h6>
                    <Button
                      style={{
                        borderRadius: "50px",
                        backgroundColor: "white",
                        border: "none",
                        color: "#007bff"

                      }}
                      onClick={() => this.downloadReportAsCSV()}
                      className="hover-blue ml-2"
                      id="TooltipExample"
                    >
                      {/* <i className="fas fa-file-csv"></i> */}
                      <FontAwesomeIcon icon={faFileCsv} size="2x" />

                    </Button>
                    <CSVLink
                      data={this.state.response}
                      asyncOnClick={true}
                      filename={this.getFileName('csv')}
                      //filename={".csv"}
                      headers={headers}
                      ref={(r) => this.csvLink = r}
                    // onClick={() => this.downloadReportAsCSV()}

                    >
                    </CSVLink>
                    {generatingReport}

                    <Button
                      style={{
                        borderRadius: "50px",
                        backgroundColor: "white",
                        border: "none",
                        color: "green"

                      }}
                      onClick={() => this.downloadReportAsExcel()}
                      className="hover-blue ml-2"
                      id="TooltipExample"
                    >
                      {/* <i className="fas fa-file-csv"></i> */}
                      <FontAwesomeIcon icon={faFileExcel} size="2x" />
                    </Button>
                    <CSVLink
                      data={this.state.response}
                      asyncOnClick={true}
                      filename={this.getFileName('xlsx')}
                      //filename={".csv"}
                      headers={headers}
                      ref={(r) => this.csvLinkForExcel = r}
                    // onClick={() => this.downloadReportAsCSV()}

                    >
                    </CSVLink>
                    <Button
                      style={{
                        borderRadius: "50px",
                        backgroundColor: "white",
                        border: "none",
                        color: "red"

                      }}
                      onClick={() => this.downloadReportAsPdf()}
                      className="hover-blue ml-2"
                      id="TooltipExample"
                    >
                      {/* <i className="fas fa-file-csv"></i> */}
                      <FontAwesomeIcon icon={faFilePdf} size="2x" />
                    </Button>
                  </div>
                  ): (
                    ""
                  )}
                </Col>
              </Row>
            ) : (
                <h1></h1>
              )}
            <Row className="py-2">
              <Col sm="2" className="d-flex mb-2 mb-sm-0">
                <div style={{ color: "Red" }}>
                  {this.state.validationErrorForDateRange}
                </div>
              </Col>
            </Row>
            <Row className="py-2">
              <Col className="">
                <div style={{ color: "Red" }}>
                  {this.state.dataNotFoundMessaage}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  setup_searchOne() {
    let rearrangedEntryList = {};
    rearrangedEntryList = this.state.entryListForTable;
    if (rearrangedEntryList.message) {
      rearrangedEntryList = {}
    }
    if (this.isEmpty(rearrangedEntryList)) {
      //console.log('object is empty');
    } else if (!this.isEmpty(rearrangedEntryList)) {
      return rearrangedEntryList.map((item, i) => {
        let analysis_type = ""
        if (item.analysis_type == "-------") {
          analysis_type = "Daily Process";
        } else {
          analysis_type = item.analysis_type + " Analysis"
        }

        let current_datetime = new Date(item.date)
        let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getFullYear()
        this.state.labels.push(formatted_date);
        return (
          <tr className="text-center" key={i} id={item.id}>
            <td>{i + 1}</td>
            <td> {analysis_type}</td>
            <td> {formatted_date}</td>
            <td  >
              {/* <p>
                {this.truncate(item.observations)}{"..."}
                    <UncontrolledCollapse toggler={"toggler" + i}>
                      {this.fullValue(item.observations)}
                    </UncontrolledCollapse>

                </p> */}
              <div style={{ cursor: "pointer" }} onClick={() => this.toogleComment(item)}>
                {this.truncate(item.observations)}
              </div>
            </td>

          </tr>
        );
      });

    }
  }
  truncate(s) {
    let l = s.length;
    if (l == 0) {
      return 'NA'
    }
    if (l < 50) {
      return s;
    } else {
      const l =
        <div className="d-flex" style={{}}>

          <div>
            <label>
              {s.slice(0, 50)}
            </label>

          </div>
          <div style={{ textDecoration: "underline", color: "#007bff", marginLeft: "5px" }}>
            ...more
      </div>
        </div>
      return l;
    }
  }
  fullValue(s) {
    //return s.substring(15, s.length);
    return s
  }
}

CommentReportTemplate.propTypes = {
  //navToggle: func.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired,
};
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,

  };
}

// export default withRouter(CommentReportTemplate);
export default withRouter(
  connect(
    mapStateToProps
  )(CommentReportTemplate)
);