import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import * as sidebar from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// import { Store } from "../../../flux";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false
      // sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    // eslint-disable-next-line no-console
    // console.log(this.props.current_user.designation);
    // eslint-disable-next-line no-console
    console.log(localStorage.getItem("currentUserdesignation"));
    
    
  }

  // componentWillUnmount() {
  //   Store.removeChangeListener(this.onChange);
  // }

  onChange() {
    this.setState({
      ...this.state
      // menuVisible: Store.getMenuState(),
      // sidebarNavItems: Store.getSidebarItems()
    });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
        <SidebarMainNavbar
          hideLogoText={this.props.hideLogoText}
          home_route={() =>
            this.props.history.push(

              (localStorage.getItem("currentUserdesignation") == "Admin"
                ? "/admin/"
                : "/") + "dashboard"
            )
          }
        />
        <sidebar.SidebarSearch />
        <SidebarNavItems
          items={[

            {
              title: "Plant Input Output Report",
              to:"/admin/plants/" + this.props.match.params.plant_name + "/processes/" + this.props.match.params.process_name +"/one"
            },
            {
              title: "Treated Water Report",
              to:"/admin/plants/" + this.props.match.params.plant_name + "/processes/" + this.props.match.params.process_name +"/two"            },
            {
              title: "Plant Operation Report",
              to:"/admin/plants/" + this.props.match.params.plant_name + "/processes/" + this.props.match.params.process_name +"/three"            },
            {
              title: "Comments Report",
              to:"/admin/plants/" + this.props.match.params.plant_name + "/processes/" + this.props.match.params.process_name +"/four"            },
            {
              title: "Free Form Report",
              to:"/admin/plants/" + this.props.match.params.plant_name + "/processes/" + this.props.match.params.process_name +"/five"             }
          ]}
        />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  history: PropTypes.object,
  current_user: PropTypes.object,
  match: PropTypes.object,
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

function mapStateToProps(state) {
  return {
    current_user: state.AppState.current_user
  };
}

export default withRouter(connect(mapStateToProps)(MainSidebar));
