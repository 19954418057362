import * as types from "../constants/actionTypes/AppStateActionTypes";

// new
export const saveAuthToken = token => dispatch => {
  dispatch({
    type: types.SAVE_TOKEN,
    token
  });
};

export const saveEditSession = state => dispatch => {
  dispatch({
    type: types.SAVE_EDIT_SESSION,
    state
  });
};

export const saveCurrentUser = current_user => dispatch => {

  dispatch({
    type: types.SAVE_CURRENT_USER,
    current_user
  });
};

export const saveRoleMapping = role => dispatch => {
  dispatch({
    type: types.SAVE_PERMISSION,
    role
  });

}

export const saveNewPlant = new_plant => dispatch => {

  dispatch({
    type: types.SAVE_NEW_PLANT,
    new_plant
  });
};
export const editPlant = edit_plant => dispatch => {

  dispatch({
    type: types.EDIT_PLANT,
    edit_plant
  });
};
export const deletePlant = delete_plant_id => dispatch => {

  dispatch({
    type: types.DELETE_PLANT,
    delete_plant_id
  });
};

export const saveCurrentDailyProcess = current_data => dispatch => {
  // console.log("current_data");

  dispatch({
    type: types.ADD_DAILY_PROCESS,
    current_data
  });
};


export const editCurrentDailyProcess = current_data => dispatch => {
  // console.log("current_data");

  dispatch({
    type: types.EDIT_DAILY_PROCESS,
    current_data
  });
};



export const deleteEditSessionDailyProcess = current_data => dispatch => {
  // console.log("current_data");

  dispatch({
    type: types.DELETE_DAILY_PROCESS,
    current_data
  });
};

export const saveLabAnalysisInHouse = current_data => dispatch => {
  // console.log("current_data");

  dispatch({
    type: types.ADD_LAB_ANALYSIS_IN_HOUSE,
    current_data
  });
};

export const updateLabAnalysisInHouse = current_data => dispatch => {
  // console.log("Edit current_data");

  dispatch({
    type: types.EDIT_LAB_ANALYSIS_IN_HOUSE,
    current_data
  });
};

export const deleteEditSessionInHouse = current_data => dispatch => {
  // console.log("delete current_data");

  dispatch({
    type: types.DELETE_LAB_ANALYSIS_INHOUSE,
    current_data
  });
};

export const saveExternalLabAnalysisData = current_data => dispatch => {
  // console.log("saveExternalLabAnalysisData");

  dispatch({
    type: types.ADD_LAB_ANALYSIS_EXTERNAL,
    current_data
  });
};

export const editExternalLabAnalysisData = current_data => dispatch => {
  // console.log(current_data.id);
  // console.log("editExternalLabAnalysisData");
  dispatch({
    type: types.EDIT_LAB_ANALYSIS_EXTERNAL,
    current_data
  });
};

export const deleteEditSession =current_data => dispatch => {
  // console.log(current_data.id);
  // console.log("deleteEditSession");
  dispatch({
    type: types.DELETE_LAB_ANALYSIS_EXTERNAL,
    current_data
  });

};





export const logout = () => dispatch => {
  dispatch({
    type: types.UNAUTH_USER
  });
};

export const displayResponseMessage = (show, status, message) => dispatch => {
  dispatch({
    type: types.RESPONSE_DIALOG,
    show,
    status,
    message
  });
  setTimeout(() => {
    dispatch({
      type: types.RESPONSE_DIALOG,
      show: false,
      status: "success",
      message: ""
    });
  }, 5000);
};

//

export const navbarActions = (component, status) => dispatch => {
  dispatch({
    type: types.NAVBAR_ACTIONS,
    component,
    status
  });
};

export const searchList = search_key => dispatch => {
  dispatch({
    type: types.SEARCH_LIST,
    search_key
  });
  return search_key;
};