import React, { Component } from "react";
import { withRouter } from "react-router";
import {Line} from 'react-chartjs-2'
import * as reportService from "../../../../../services/ReportService";
import logo from '../../../../assets/Spinner-1s-200px.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv,faFileExcel,faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import {  object } from "prop-types";
import { connect } from "react-redux";


import {
  Row,
  Col,
  Card,
  FormInput,
  Button,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  Progress
} from "shards-react";

class FreeFormGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xAxis:[],
      chartData:[],
      chartData2:{},
      chartData3:{},
      chartData4:{},
      chartData5:{},
      selectedReport: "hi",
      open: false,
      modalDiv: null,
      labels: [],
      startDate: Date(),
      endDate: Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      isGraphView: false,
      entryListForTableRes:{},
      validationError: "",
      dataNotFoundMessaage: "",
      checkBoxSelectedMessage : "",
      isDataLoaded: false,
      response: [],
      isCSVData: false,
      isGraphRender: '',
      selections:{
        report1:[],
        report2:[],
        report3:[],
        report4:[]
      },
      resultSet:{
        report1:{
          biochar_generated_kgs:[],
          srs:[],
          treated_water_taken:[],
          quantity_of_septage:[]
        },
        report2:{
          ph:[],
          tss:[],
          bod:[],
          cod:[],
          f_coil:[]
        },
        report3:{
          qty_of_solids_generated:[],
          pyrolyzer:[],
          energy_meter_final:[],
          energy_meter_initial:[],
          treated_water_taken:[],
          power_consumed:[]
        }

      },
      finalResultSet:{}
    };
    this.on_change = this.on_change.bind(this);
  }

  componentDidMount(){
    let selections  = this.props.selections;
    // console.log(selections);
    //delete selections.report4;
    // for(let key in selections){
    //   if(key != "report4"){
    //     console.log(key);
    //     this.setState({
    //       ...selections[key] , ...this.props.selections[key]
    //     })
    //     //this.state.selections[key] = 
    //   }
      
    // }
    this.setState({
      startDate: null,
      endDate: null,
      selections: selections
    })
  }

  //This method is used to render chart in UI
  renderChart(){
    for(let k=0;k< this.state.finalResultSet.length;k++){
        let chartData = {
          labels:this.state.labels,
          datasets:[
            {
              label:this.state.finalResultSet[k].key.replace(/_/g, " "),
              data:this.state.finalResultSet[k].value,
              backgroundColor:[
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)',
                'rgba(255, 206, 86, 0.6)',
                'rgba(75, 192, 192, 0.6)',
                'rgba(153, 102, 255, 0.6)',
                'rgba(255, 159, 64, 0.6)',
                'rgba(255, 99, 132, 0.6)'
              ],
              lineTension: 0
            }
          ]
        }
        this.state.chartData.push(chartData);
     }
   }


  //This method is used to make result set
  setResultSet(){
    let resultSet = this.state.resultSet;
    let selections = this.state.selections;
    delete selections.report4
    let finalResultSet = {};
    let result = [];
    for(let key in selections){
      if(selections.hasOwnProperty(key)){
        var property = selections[key];
        for(let k=0;k<property.length;k++){
          let item = property[k];
          result.push({
            key:item,
            value: resultSet[key][item]
          })
          finalResultSet[item] = resultSet[key][item]
        }
      }
    }
    this.setState({
      finalResultSet: result
    })
  }

  //This method is used to set value in the input field
  on_change(e) {
    this.setState({
     [e.target.name]: [e.target.value],
     validationError:  e.target.value != "" 
          ? "" :  e.target.name === "startDate" ? " Please Select the start date" : e.target.name === "endDate" ?  "Please select the end date" : ""
    }); 
  }


  setGraphViewVisible(){
    this.setState({
      isGraphView : false
    })
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
        if(startDate == null){
          this.setState ({
            validationError: "Please Select the start date first"
          }); 
        }else if(endDate == null){
          this.setState({
            validationError: "Please Select the end date"
          }); 
        }
    }else{
      this.setState({
        dataNotFoundMessaage:"",
        isDataLoaded: true
      })
      this.getTableData();
    }
  }



  getTableData(){
    this.setState({
      finalResultSet:{}
    })
    if(this.props.selections.report1.length == 0 && this.props.selections.report2.length == 0 && this.props.selections.report3.length == 0){
      this.setState({
        checkBoxSelectedMessage: "Please select the attribute",
        isDataLoaded: false
      })      
    }else{
    this.setState({
      finalResultSet:{},
      checkBoxSelectedMessage: "",
      labels: [],
      chartData:[],
      isGraphRender:""
    })
    //console.log(this.state);
    let data;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    const plant_id = localStorage.getItem("plant_id")
    const listTableData = reportService.getFreeFormDateRange;
     listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
       data = entryListForTableRes;       
       let a = {
          report1:{
          biochar_generated_kgs:[],
          srs:[],
          treated_water_taken:[],
          quantity_of_septage:[]
        },
        report2:{
          ph:[],
          tss:[],
          bod:[],
          cod:[],
          f_coil:[]
        },
        report3:{
          qty_of_solids_generated:[],
          pyrolyzer:[],
          septage_treated:[],
          energy_meter_final:[],
          energy_meter_initial:[],
          treated_water_taken_out:[],
          treated_water_taken:[],
          power_consumed:[]
        }
      }        
       this.setState({
        isDataLoaded:true,
       });
      this.setState({
        resultSet:{
          report1:{
            biochar_generated_kgs:[],
            srs:[],
            treated_water_taken:[],
            quantity_of_septage:[]
          },
          report2:{
            ph:[],
            tss:[],
            bod:[],
            cod:[],
            f_coil:[]
          },
          report3:{
            power_consumed:[],
            pyrolyzer:[],
            septage_treated:[],
            treated_water_taken_out:[],
            qty_of_solids_generated:[],
            
          },
          report4:{
            analysis_type:[],
            observations:[],
          }
        }
      })

      this.setState(prevState => ({
        ...prevState,
        resultSet:{
          ...prevState.report1,
          report1:{
            biochar_generated_kgs:[],
            srs:[],
            treated_water_taken:[],
            quantity_of_septage:[]
          },
          report2:{
            ph:[],
            tss:[],
            bod:[],
            cod:[],
            f_coil:[]

          },
          report3:{
            power_consumed:[],
            pyrolyzer:[],
            septage_treated:[],
            treated_water_taken_out:[],
            qty_of_solids_generated:[]
          }
        }
      }), () => {
        // console.log(this.state.resultSet)
      })

       this.setState(prevState => {
        return {
          resultSet : a
        }
      }, () =>{  
       for(let a = 0; a<data.length;a++){
        let current_datetime = new Date(data[a].date)
        let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1)
        this.state.labels.push(formatted_date);
          for(let i=0;i<data[a].report.length;i++){
            if(data[a].report[i].report_num == "report1"){
              for(let k = 0; k < data[a].report[i].septages.length;k++){
                this.state.resultSet.report1.quantity_of_septage.push(data[a].report[i].septages[k].quantity_of_septage);
              }
              this.state.resultSet.report1.srs.push(data[a].report[i].hours_ct_pt_septage.srs);
              this.state.resultSet.report1.treated_water_taken.push(data[a].report[i].metering.treated_water_taken);
              this.state.resultSet.report1.biochar_generated_kgs.push(data[a].report[i].dewaterted_solids_handel.biochar_generated_kgs);
            }else if (data[a].report[i].report_num == "report2"){
              this.state.resultSet.report2.ph.push(data[a].report[i].treated_water_external.ph);
              this.state.resultSet.report2.tss.push(data[a].report[i].treated_water_external.tss);
              this.state.resultSet.report2.bod.push(data[a].report[i].treated_water_external.bod);
              this.state.resultSet.report2.cod.push(data[a].report[i].treated_water_external.cod);
              this.state.resultSet.report2.f_coil.push(data[a].report[i].treated_water_external.f_coil);
            }else if (data[a].report[i].report_num == "report3"){
              this.state.resultSet.report3.septage_treated.push(data[a].report[i].metering.treated_water_taken + data[a].report[i].dewaterted_solids_handel.qty_of_solids_generated);

              this.state.resultSet.report3.pyrolyzer.push(data[a].report[i].hours_hh_septage.pyrolyzer);
              this.state.resultSet.report3.qty_of_solids_generated.push(data[a].report[i].dewaterted_solids_handel.qty_of_solids_generated);
              this.state.resultSet.report3.energy_meter_final.push(data[a].report[i].metering.energy_meter_final);
              this.state.resultSet.report3.energy_meter_initial.push(data[a].report[i].metering.energy_meter_initial);
              this.state.resultSet.report3.treated_water_taken_out.push(data[a].report[i].metering.treated_water_taken);
              this.state.resultSet.report3.power_consumed.push(data[a].report[i].metering.energy_meter_final - data[a].report[i].metering.energy_meter_initial);
            }
          }
        }
      })

          this.setResultSet();
          this.renderChart(); 
       this.setState({
        entryListForTable: data,
        isDataLoaded:false,
        isGraphView:true
        
       
       })
    },(error)=>{
      this.setState({
       isDataLoaded:false,
       isGraphView:false,
      })
      let message  = error.response.data.message;
      this.setState({
        dataNotFoundMessaage: message
      })

    }
    );  
  }
  }



  setResultSetForReport(){
    let resultSet = this.state.resultSet;
    let selections = this.state.selections;
    delete selections.report4
    let finalResultSet = {};
    let result = [];
    let r = [];
    for(let key in selections){
      let t={};
      if(selections.hasOwnProperty(key)){
        var property = selections[key];
        for(let k=0;k<property.length;k++){
          let item = property[k];
          // t = {
          //   [item]: resultSet[key][item][0]
          // }          
          result.push({
            key:item,
            value: resultSet[key][item]
          })
          finalResultSet[item] = resultSet[key][item]
        }
      }
      r.push(t);
    }
    this.setState({
      finalResultSet: result
    })
    let arr = [];
    if(this.selectedReportType != "PDF"){
    for(let i=0;i<6;i++){
      let abc = {};
      for(let j=0;j<this.state.finalResultSet.length; j++){
        //let a = {};
        let t = this.state.finalResultSet[j]
        var q = t.key
        var q2 = t.value[i];

        //console.log(t)
        abc[q] = q2
      }
      arr.push(abc)
    }
  }else{
    for(let i=0;i<6;i++){
      let abc = [];
     
      for(let j=0;j<this.state.finalResultSet.length; j++){
        let t = this.state.finalResultSet[j]
        abc.push(t.value[i])
      }
      arr.push(abc);
    }
  }
    if(this.selectedReportType == "CSV"){
      this.setState({
        response:arr
      }, () => {
        this.csvLink.link.click();
        this.setState({
        isCSVData : false
        })
     }) 

    }else if(this.selectedReportType == "EXCEL"){
      this.setState({
        response:arr
      }, () => {
        this.csvLinkForExcel.link.click();
        this.setState({
        isCSVData : false
        })
     }) 
      

    } else if(this.selectedReportType == "PDF"){
      this.setState({
        response:arr
      }, () => {
        this.setState({
        isCSVData : false
        })
        var pdf = new jsPDF("p", "mm", "a3");
    
        var header = function(data) {
          pdf.setFontSize(10);
          pdf.setTextColor(40);
          pdf.setFontStyle('normal');
          pdf.text('Free Form Report', data.settings.margin.left, 20);

           
          };
          var options = {
            addPageContent: header,
            margin: {
              top: 30
            },
          };
          const head = []
          let selections = this.state.selections;
          for(let key in selections){
            if(selections.hasOwnProperty(key)){
              var property = selections[key];
              for(let k=0;k<property.length;k++){
                let item = property[k];
                head.push(this.getGraphName(item))          
              }
            }
          }
          pdf.autoTable(head,this.state.response, options);
          pdf.addPage();
          let asd = [];
          for(let j=0;j<head.length;j++){
            let temp={};
            var canvas = document.getElementById(j);
            var url = canvas.toDataURL();
            temp={
              name: head[j],
              url: url
            }
            asd.push(temp)
          }
          for(let j=0;j< asd.length;j++){
            pdf.addPage();
            var name = asd[j].name;
            var urla = asd[j].url
            pdf.text(this.getGraphName(name), 15, 20);
            pdf.addImage(urla, 'JPEG', 15, 30,160, 80);
          }
    
          pdf.save(this.getFileName('pdf'));
     }) 
    }

  }
  
  //For Download Report Part
  callingAPI(){
    let data;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    const plant_id = localStorage.getItem("plant_id")
    const listTableData = reportService.getFreeFormDateRange;
     listTableData(plant_id, startDate, endDate).then(entryListForTableRes => {
       data = entryListForTableRes;
       this.makeDocumentStructure(data);
     },(error)=>{
      let message  = error.response.data.message;
      this.setState({
        dataNotFoundMessaage: message
      })
    }
  );
  }

  setDocumentStructreToNull(){
    this.setState({
      resultSet:{
        report1:{
          biochar_generated_kgs:[],
          srs:[],
          treated_water_taken:[],
          quantity_of_septage:[]
        },
        report2:{
          ph:[],
          tss:[],
          bod:[],
          cod:[],
          f_coil:[]
        },
        report3:{
          pyrolyzer:[],
          power_consumed:[],
          septage_treated:[],
          treated_water_taken_out:[],
          qty_of_solids_generated:[],
          
        },
        report4:{
          analysis_type:[],
          observations:[],
        }
  
      }

    })

  }

  makeDocumentStructure(data){

    //This is used to make array list null before adding value in them
    this.setDocumentStructreToNull();

    //////////////////////
    for(let a = 0; a<data.length;a++){
      let current_datetime = new Date(data[a].date)
      let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1)
      this.state.labels.push(formatted_date);
       //this.state.labels.push(data[a].date);
        for(let i=0;i<data[a].report.length;i++){
          if(data[a].report[i].report_num == "report1"){
            for(let k = 0; k < data[a].report[i].septages.length;k++){
              this.state.resultSet.report1.quantity_of_septage.push(data[a].report[i].septages[k].quantity_of_septage);
            }
            this.state.resultSet.report1.srs.push(data[a].report[i].hours_ct_pt_septage.srs);
            this.state.resultSet.report1.treated_water_taken.push(data[a].report[i].metering.treated_water_taken);
            this.state.resultSet.report1.biochar_generated_kgs.push(data[a].report[i].dewaterted_solids_handel.biochar_generated_kgs);
          }else if (data[a].report[i].report_num == "report2"){
            this.state.resultSet.report2.ph.push(data[a].report[i].treated_water_external.ph);
            this.state.resultSet.report2.tss.push(data[a].report[i].treated_water_external.tss);
            this.state.resultSet.report2.bod.push(data[a].report[i].treated_water_external.bod);
            this.state.resultSet.report2.cod.push(data[a].report[i].treated_water_external.cod);
            this.state.resultSet.report2.f_coil.push(data[a].report[i].treated_water_external.f_coil);
          }else if (data[a].report[i].report_num == "report3"){

            this.state.resultSet.report3.pyrolyzer.push(data[a].report[i].hours_hh_septage.pyrolyzer);
            this.state.resultSet.report3.power_consumed.push(data[a].report[i].metering.energy_meter_final - data[a].report[i].metering.energy_meter_initial);
            this.state.resultSet.report3.septage_treated.push(data[a].report[i].metering.treated_water_taken + data[a].report[i].dewaterted_solids_handel.qty_of_solids_generated);
            this.state.resultSet.report3.treated_water_taken_out.push(data[a].report[i].metering.treated_water_taken);
            this.state.resultSet.report3.qty_of_solids_generated.push(data[a].report[i].dewaterted_solids_handel.qty_of_solids_generated);
          }
        }
      }
      this.setResultSetForReport();
      // console.log(this.state.resultSet)
      //console.log(selections)
    }
 


  //This 
  renderSwitch(param) {    
    switch(param) {
      case 'Quantity of septage':
        return 'Litres';
      case 'Srs':
          return 'Kgs';
      case 'Treated water taken':
        return 'Kgs';
      case 'Biochar generated kgs':
        return 'Kgs';
      case 'Ph':
        return 'Number';
      case 'Bod':
        return 'mg/L'
      case 'Cod':
        return 'mg/L'
      case 'Tss':
        return '%'
      case 'F coil':
        return 'MPN/100ml'
      case 'Power consumed':
        return 'KWatts'
      case 'Septage treated':
        return 'Ltrs'  
      case 'Treated water taken out':
        return 'Ltrs'
      case 'Qty of solids generated':
        return 'Kgs' 
      case 'Pyrolyzer':
        return 'Hour'
    }
  }
  checkValidation(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if((startDate == null || startDate[0] == "")  || (endDate == null || endDate[0] == "" )){
      //console.log('Validation Failed')
      if(startDate == null){
        this.setState ({
          validationError: "Please Select the start date first"
        }); 
      }else if(endDate == null){
        this.setState({
          validationError: "Please Select the end date"
        }); 
      }
      return false;
    }else{
      return true;
    }
  }

  getFileName(fileType =  String){
    let startDate = this.state.startDate;
    let endDate= this.state.endDate;
    let fileName = "Free_Form_Report_" + new String(this.props.match.params.plant_name) +"_"+ startDate+"_" +endDate+"."+fileType;
    return fileName;

  }

  getGraphName(name = String){
    let correctName = name.replace(/_/g, " ");
    return correctName;
  }

  modal() {
    return(
      <Modal 
        style={{ marginTop: "150px"}}
        open={this.state.isCSVData}
        className="h-75 d-flex align-items-center justify-content-center modal-width">
        <ModalBody >
        <div>
          <div>
          <Progress animated theme="primary" value={100} />
          </div>
          <div className="modal-body-name" style= {{ marginTop: "15px", textAlign: "center"}}>
            Exporting {this.selectedReportType} Report...
          </div>
          <div className="modal-body-message"style= {{textAlign: "center"}}>
           Just a moment Please
          </div>
          {/* <div className="modal-body-discard"style= {{textAlign: "center"}}>
            Discard
          </div> */}
        </div>
        </ModalBody>
      </Modal>
    );
  }
    //this method is used to format the date
  dateFormat(date){
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + (current_datetime.getFullYear());
    return formatted_date;
  }

  downloadReportInCSV(){
    this.selectedReportType="CSV";
    if(this.checkValidation()){
      this.setState({
        isCSVData : true
      })
      this.callingAPI();
    }
  }
  downloadReportInExcel(){
    this.selectedReportType="EXCEL";
    if(this.checkValidation()){
      this.setState({
        isCSVData : true
      })
      this.callingAPI();
    }
  }
  downloadReportInPDF(){
    this.selectedReportType = "PDF"
    if(this.checkValidation()){
      if(this.state.isGraphView){
        this.setState({
          isCSVData : true
        })

        this.callingAPI();
    }else{
      this.setState({
        isGraphRender: 'Please Show Graph First'
      })
     }
    }
  }

  render() {
    const generatingReport = this.modal();
    const isDataLoaded = this.state.isDataLoaded 
    ? <div className="text-center" >
      <img style={{width:"45px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const graphicalView = this.state.isGraphView;    
    const g = this.getChart();

    return (
      <div>
        <Row className="py-2">
          <Col className="d-flex mb-2 mb-sm-0">       
            <FormInput    
              name = "startDate"
              type="date"
              value = {
                this.state.startDate
              }
              onChange={this.on_change}
            />
            <FormInput    
              name = "endDate"
              type="date"
              value = {
                this.state.endDate
              }
              onChange={this.on_change}
            />
            <Button style={{marginRight:"29px"}}
            onClick={(e) => this.setGraphViewVisible(e)}>
              Show
            </Button>
            {isDataLoaded}
          </Col>
          <Col >
          {this.props.permission.export == "YES" ? (
            <div className="float-right d-flex ">
              <h6 className="m-0" style={{padding : "10px"}}>Export As</h6>
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"#bcc5d3"

                  }}
                  onClick={() => this.downloadReportInCSV()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFileCsv} size="2x" />
              </Button>
              <CSVLink 
                  data={this.state.response}
                  asyncOnClick={true}
                  filename={this.getFileName('csv')}
                  // headers={headers}
                  ref={(r) => this.csvLink = r}
                  // onClick={() => this.downloadReportAsCSV()}
                  >
                </CSVLink>
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"green"

                  }}
                  onClick={() => this.downloadReportInExcel()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFileExcel} size="2x" />
              </Button>
              <CSVLink 
                  data={this.state.response}
                  asyncOnClick={true}
                  filename={this.getFileName('xlsx')}
                  // headers={headers}
                  ref={(r) => this.csvLinkForExcel = r}
                  // onClick={() => this.downloadReportAsCSV()}
                  >
                </CSVLink>
              <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"red"

                  }}
                  onClick={() => this.downloadReportInPDF()}
                  className="hover-blue ml-2"
                  id="TooltipExample"
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faFilePdf} size="2x" />
              </Button>
              {generatingReport}
            </div> 
            ): (
              ""
            )}
            </Col>
        </Row>
        <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
          </Row>
          <Row className="py-2">
      <Col  className="">
      <div style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
         {this.state.checkBoxSelectedMessage}
      </div> 
      </Col>
    </Row> 
    <Row className="py-2">
            <Col  className="">
            <div style={{color:"Red"}}>
              {this.state.isGraphRender}
            </div> 
            </Col>
          </Row>
        <div>
          {graphicalView ? (
        <Row>
          {g}


 

    </Row>
      ) : (
      <h1></h1>
    )}
    
    </div>
    {/* <Row> {g}</Row> */}
   
  </div>     
    );
  }
 

  getChart(){
    const resultSet = this.state.chartData;

    
    
    
    return resultSet.map((item, i) => {
      this.renderSwitch(item.datasets[0].label.replace(/_/g, " ").charAt(0).toUpperCase() + item.datasets[0].label.slice(1));
      // let xaxis = this.state.xAxis;           
      return(
        <div  key={i} className="mx-auto mb-4 col-lg-6">
         
        <Card small className="h-100">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{
              item.datasets[0].label.replace(/_/g, " ").charAt(0).toUpperCase() + item.datasets[0].label.slice(1)}</h6>
          </CardHeader>
          <CardBody className="pt-0" style={{marginTop: "15px"}}>
            <Line
                id={i}
                data={item}
                width={100}
                height={50}
                options={{
                  legend : {
                    display: false
                },
                scales : {
                  yAxes : [{
                    scaleLabel: {
                      display : true,
                      labelString : this.renderSwitch(item.datasets[0].label.replace(/_/g, " ").charAt(0).toUpperCase() + item.datasets[0].label.slice(1))
                      // xaxis[i]
                      //{ 
                      //   callback: function renderSwitch() {
                      //     console.log(object)
                      // }
                    //}                
                   }
                  }],
                  xAxes : [{
                    scaleLabel :{
                      display: true,
                      labelString: 'Date'
                    }
                  }],
                } 
                }  
                }
              />
          </CardBody>
        </Card>
      
      </div>


      );

          

     });
  }
}

FreeFormGraph.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  selections: object.isRequired
};
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,

  };
}

// export default withRouter(FreeFormGraph);
export default withRouter(
  connect(
    mapStateToProps
  )(FreeFormGraph)
);


