import axios from "axios";

export const getInHouseLabAnalysisById = id => {
  return axios.get("/api/v1/organization_details/" + id);
};



//get all lab analysis data
export const getInHouseLabAnalysis = (plant_id) => {
  return axios.get("/api/v1/lab_analysis/inhouse/" +  plant_id);
};

export const getInHouseLabAnalysisByProcessIdPlantId = (plant_id, process_id) => {
  return axios.get("/api/v1/lab_analysis/inhouse+external/" + plant_id + "/" +process_id );

};


//Add data

export const addInHouseLabData = InHouseLabAnalysis => {
  return axios.post("/api/v1/lab_analysis_inhouses", InHouseLabAnalysis);
};

//Edit lab analysis data

export const editInHouseLabData = lab_analysis_inhouse => {
  return axios.put(
    "/api/v1/lab_analysis_inhouses/" + lab_analysis_inhouse.lab_analysis_inhouse._id.$oid,
    lab_analysis_inhouse
  );
};

//Delete Lab analysis Inhouse  data

export const deleteInHouseLabAnalysis = id => {
  return axios.delete("/api/v1/lab_analysis_inhouses/" + id);
};
