import React from "react";
import InHouseLabAnalysisFormTemplate from '../../../containers/WrangalPlant/InHouseLabAnalysisTemplate'

import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import {
    func
} from "prop-types";

const EditLabAnalysisInHouseData = ({
    navToggle
}) => {
    return ( <
        InHouseLabAnalysisFormTemplate action = {
            types.EDIT_LAB_ANALYSIS_INHOUSE
        }
        form_title = {
            "Lab Analysis In House"
        }
        navToggle = {
            navToggle
        }
        button_title = {
            "Update Lab Analysis In House"
        }
        />
    );
};

EditLabAnalysisInHouseData.propTypes = {
    navToggle: func.isRequired
};

export default EditLabAnalysisInHouseData;