import React from "react";
import * as shards from "shards-react";
import { func } from "prop-types";


export const NavbarBrand = ({ on_click_function }) => (
  <shards.NavbarBrand
    className="w-25 mr-0 cursor-pointer"
    style={{ lineHeight: "25px" }}
  >
    <div className="d-table m-auto" onClick={on_click_function}>
      <span className="d-none d-md-flex align-items-center ml-1">
        <img
          id="main-logo"
          className="d-inline-block align-top mr-1"
          style={{ maxWidth: "50px" }}
          src={require("../../../favicon.ico")}
          alt="TTPL Dashboard"
        />
        <span className="mt-1 font-weight-bold">Plants Dashboard</span>
      </span>
    </div>
  </shards.NavbarBrand>
);

NavbarBrand.propTypes = {
  on_click_function: func.isRequired
};