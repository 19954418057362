import React, { Component } from "react";
import { User, UserValidation } from "../../../models/User";
import {
  saveEditSession,
  displayResponseMessage,
  saveCurrentUser
} from "../../../actions/AppStateActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip'

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  FormInput,
  FormSelect,
  Button,
  FormFeedback,
  Modal
} from "shards-react";
import Picky from "react-picky";
import * as types from "../../../constants/actionTypes/PlatformActionTypes";
import { validate } from "../validations/UserFormValidation";
import { addUser, editUser, editProfile } from "../../../services/UserService";
import { getOrganizations } from "../../../services/OrganizationService";
import { getPlantByMasterId } from "../../../services/PlantService";
import { getPlantIdsByOrganizationId } from "../../../services/OrganizationPlantsService";
import ResponseMessage from "../../common/ResponseMessage";

class UserFormTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.edit_session ? { ...this.props.edit_session } : User(),
      availableOrganizations: [],
      availablePlants: [],
      validation: UserValidation,
      submitted: false,
      open: false,
      isSubmitted: false,
    };
    this.messages = {
      ADD_USER: {
        modal:
          "The User will be sent an invitation e-mail. Are you sure you want to continue?",
        success: "An invitation has been sent to the user",
        fail: "Sorry, something went wrong"
      },
      EDIT_USER: {
        modal: "Are you sure you want to save changes?",
        success: "The user details have been updated",
        fail: "Sorry, something went wrong"
      },
      EDIT_PROFILE: {
        modal: "Are you sure you want to save changes?",
        success: "Profile updated successfully",
        fail: "Sorry, something went wrong"
      }
    };
  }

  componentDidMount() {
    getOrganizations().then(availableOrganizations => {
      this.setState({ availableOrganizations });
      if (this.props.edit_session && this.state.user.designation != "Admin") {
        getPlantIdsByOrganizationId(this.state.user.organization).then(
          plantIds => {
            let availablePlants = [];
            plantIds.forEach(plant => {
              getPlantByMasterId(plant.plant_id).then(res => {
                availablePlants.push(res);
                this.setState({
                  availablePlants
                });
              });
            });
          }
        );
      }
    });
  }

  componentWillUnmount() {
    this.props.actions.saveEditSession(null);
  }

  removePlant = id => {
    const user = {
      ...this.state.user,
      plants: this.state.user.plants.filter(p => id != p.id)
    };
    this.setState({ user });
  };

  form_validate = () => {
    let validation = { ...this.state.validation };
    let failed = [];
    Object.keys(this.state.validation).forEach(v => {
      let validation_results = {};
      if (this.state.user.designation == "Admin" && v == "organization") {
        validation_results = { invalid: false, valid: true, message: "" };
      } else {
        validation_results = { ...validate(v, this.state.user[v]) };
      }
      validation_results["valid"] =
        this.state.submitted && validation_results.valid;
      validation_results["invalid"] =
        this.state.submitted && validation_results.invalid;
      validation[v] = { ...validation[v], ...validation_results };
      // const adminBypass =
      //   v == "organization" && this.state.user.designation == "Admin";
      if (validation[v].invalid) {
        failed.push(v);
      }
    });
    this.setState({ validation });
    // if (this.state.user.designation == "Admin")
    //   failed.filter(f => {
    //     f != "organization";
    //   });
    return failed;
  };

  field_validate = e => {
    if (this.state.submitted) {
      const validation = {
        ...this.state.validation,
        [e.target.name]: {
          ...this.state.validation[e.target.name],
          ...validate(e.target.name, e.target.value)
        }
      };
      if (this.state.user.designation == "Admin") {
        validation.organization = { invalid: false, valid: true, message: "" };
      }
      this.setState({ validation });
    }
  };

  getSubmitFunc = () => {
    switch (this.props.action) {
      case types.ADD_USER:
        return addUser;
      case types.EDIT_USER:
        return editUser;
      case types.EDIT_PROFILE:
        return editProfile;
      default:
        return null;
    }
  };

  getParams = () => {
    let user_detail = {};
    switch (this.props.action) {
      case types.ADD_USER:
        user_detail = {
          ...this.state.user,
          plants: this.state.user.plants.map(p => {
            return p.id;
          })
        };
        delete user_detail["id"];
        delete user_detail["creation_date"];
        return user_detail;

      case types.EDIT_USER:
        user_detail = {
          ...this.state.user,
          plants: this.state.user.plants.map(p => {
            return p.id;
          })
        };
        return user_detail;

      case types.EDIT_PROFILE:
        return {
          id: this.state.user.id,
          countryCode: this.state.user.countryCode,
          phone_number: this.state.user.phone_number,
          gender: this.state.user.gender,
          address: this.state.user.address,
          city: this.state.user.city,
          state: this.state.user.state,
          country: this.state.user.country,
          zip: this.state.user.zip
        };

      default:
        return user_detail;
    }
  };

  submit_form = e => {
    e.preventDefault();
    this.setState({
      isSubmitted: true
    })
    this.setState({ submitted: true }, () => {
      if (this.form_validate().length > 0) {
        this.setState({
          isSubmitted: false
        })
        return;
      }

      const submit_func = this.getSubmitFunc();
      let user_detail = this.getParams();

      submit_func({ user_detail }).then(
        () => {
          
          this.props.actions.displayResponseMessage(
            true,
            "success",
            this.messages[this.props.action].success
          );
          if (this.props.action == types.EDIT_PROFILE) {
            this.props.history.push("dashboard");
          } else {
            this.props.history.push("user-list");
          }
          if (this.props.current_user.id == this.state.user.id) {
            this.props.actions.saveCurrentUser(this.state.user);
          }
          this.setState({
            isSubmitted: false
          })
        },
        err => {
          this.setState({
            isSubmitted: false
          })
          this.toggleModal();
          this.props.actions.displayResponseMessage(
            true,
            "fail",
            err.response.status == 422
              ? "This email is already registered. Please use another email."
              : this.messages[this.props.action].fail
          );
        }
      );
    });
  };

  on_change = e => {
    const user = { ...this.state.user, [e.target.name]: e.target.value };
    if (e.target.name == "organization") {
      user.plants = [];
      getPlantIdsByOrganizationId(e.target.value).then(plantIds => {
        let availablePlants = [];
        plantIds.forEach(plant => {
          getPlantByMasterId(plant.plant_id).then(res => {
            availablePlants.push(res);
            this.setState({ availablePlants });
          });
        });
      });
    }
    this.setState({ user });
    this.field_validate(e);
  };

  on_multidrop_change = plants => {
    const user = { ...this.state.user, plants };
    this.setState({ user });
    this.field_validate({ target: { name: "plants", value: plants } });
  };

  toggleModal = () => {
    this.setState({ submitted: true }, () => {
      const validate = this.form_validate();
      if (validate.length > 0) {
        return;
      }
      this.setState({ open: !this.state.open });
    });
  };
  // render_tooltip() {
  //   let toolTips = {};
  //   toolTips = {
      

  //   }
  //   this.state.entryList.forEach(u => {
  //     toolTips[u.id] = u.plants.map((p, i) => (
  //       <p key={i} className="mb-0">
  //         {i + 1 + ". " + p.plant_name}
  //       </p>
  //     ));
  //   });

  //   return toolTips;
  // }

  render() {

    const helpbox =
    <div>
      <table>
        <thead>
          <tr>
            <th>
            User
            </th>
            <th>
            View
            </th>
            <th>
            Edit
            </th>
            <th>
            Export
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Plant Staff</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Silver</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Gold</td>
            <td>Yes</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
        </tbody>
      </table>

    </div> 
    const plantList = this.state.user.plants.map((p, i) => {
      return (
        <div
          key={i}
          className="form-control d-flex justify-content-between col-lg-4"
        >
          {p.plant_name}{" "}
          <span
            onClick={() => this.removePlant(p.id)}
            className="text-danger cursor-pointer align-items-center px-2"
          >
            X
          </span>
        </div>
      );
    });

    const organizationList = this.state.availableOrganizations.map((o, i) => {
      return (
        <option key={i} value={o.organization_id}>
          {o.organization_name}
        </option>
      );
    });

    const roleList = [
      { id: "plantStaff", role_name: "Plant-Staff" },
      { id: "silver", role_name: "Silver" },
      { id: "gold", role_name: "Gold" }
    ].map((o, i) => {
      return (
        <option key={i} value={o.id}>
          {o.role_name}
        </option>
      );
    });


    
    
      
   

    const plantsClass = this.state.validation.plants.valid
      ? "col-lg-4 mt-3 plants-valid"
      : this.state.validation.plants.invalid
      ? "col-lg-4 mt-3 plants-invalid"
      : "col-lg-4 mt-3";

    const modalMessage = this.messages[this.props.action].modal;

    return (
      <div className="mh-85" onClick={this.props.navToggle}>
        <ResponseMessage />
        <div className="text-dark my-3 px-5">
          <small>User Profile</small>
          <div className="d-flex align-items-center">
            <h4>
              {this.props.form_head_title
                ? this.props.form_head_title
                : this.state.user.first_name + " " + this.state.user.last_name
              // +
              // ", " +
              // this.props.organizations.filter(
              //   o => o.id == this.state.user.organization
              // )[0].organization_name
              }
            </h4>
            <span className="text-muted d-block ml-3 mb-2 text-uppercase ">
              {this.props.form_head_sub_title}
            </span>
          </div>
        </div>

        <Modal
          size="lg"
          open={this.state.open}
          style={{ marginTop: "55%" }}
          toggle={this.toggleModal}
          className="h-75 d-flex align-items-center justify-content-center"
        >
          <h4 className="text-center p-5 w-100">{modalMessage}</h4>

          <div className="d-flex w-75 mx-auto justify-content-center">
            <Button
              theme="danger"
              onClick={this.toggleModal}
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Cancel
            </Button>
            <Button disabled={this.state.isSubmitted == true} form="user_form" className="col-5 mx-1 mt-2 mb-5 mx-auto">
              Yes
            </Button>
          </div>
        </Modal>
        <div className="d-flex flex-wrap h-75 mt-2 px-5 col-12 align-items-center justify-content-center">
          <Card className="mb-5 p-0">
            <CardHeader className="bg-white border-bottom  p-3">
              <h5 className="text-dark">{this.props.form_sub_title}</h5>
              <div className="mt-2 p-0 col-12">
                <small> Fields marked * are mandatory </small>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <Form
                id="user_form"
                className="d-flex flex-wrap"
                onSubmit={this.submit_form}
              >
                {this.props.visibilityFilter.first_name ? (
                  <div className="col-lg-6 mt-3">
                    <label>First Name *</label>
                    <FormInput
                      name="first_name"
                      type="text"
                      value={this.state.user.first_name}
                      onChange={this.on_change}
                      {...this.state.validation.first_name}
                    />
                    <FormFeedback {...this.state.validation.first_name}>
                      {this.state.validation.first_name.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.last_name ? (
                  <div className="col-lg-6 mt-3">
                    <label>Last Name *</label>
                    <FormInput
                      name="last_name"
                      type="text"
                      value={this.state.user.last_name}
                      onChange={this.on_change}
                      {...this.state.validation.last_name}
                    />
                    <FormFeedback {...this.state.validation.last_name}>
                      {this.state.validation.last_name.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.email ? (
                  <div className="col-lg-6 mt-3">
                    <label>E-mail *</label>
                    <FormInput
                      name="email"
                      type="email"
                      readOnly={
                        this.props.readOnlyFilter &&
                        this.props.readOnlyFilter.email
                      }
                      value={this.state.user.email}
                      onChange={this.on_change}
                      {...this.state.validation.email}
                    />
                    <FormFeedback {...this.state.validation.email}>
                      {this.state.validation.email.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.phone_number ? (
                  <div className="col-lg-6 mt-3">
                    <label>Phone Number *</label>
                    <InputGroup className="d-flex w-100 m-0">
                      <div className="col-3 p-0">
                        <FormInput
                          maxLength={4}
                          type="text"
                          name="countryCode"
                          placeholder="ex : +91"
                          value={this.state.user.countryCode}
                          onChange={this.on_change}
                          {...this.state.validation.countryCode}
                        />
                        <FormFeedback {...this.state.validation.countryCode}>
                          {this.state.validation.countryCode.message}
                        </FormFeedback>{" "}
                      </div>
                      <div className="col-9 p-0">
                        <FormInput
                          maxLength={10}
                          name="phone_number"
                          type="tel"
                          value={this.state.user.phone_number}
                          onChange={this.on_change}
                          {...this.state.validation.phone_number}
                        />
                        <FormFeedback {...this.state.validation.phone_number}>
                          {this.state.validation.phone_number.message}
                        </FormFeedback>{" "}
                      </div>
                    </InputGroup>
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.organization ? (
                  <div className="col-lg-6 mt-3">
                    <label>Organization *</label>
                    <FormSelect
                      name="organization"
                      value={this.state.user.organization}
                      onChange={this.on_change}
                      {...this.state.validation.organization}
                    >
                      <option value="" selected disabled>
                        Select Organization
                      </option>
                      {organizationList}
                    </FormSelect>
                    <FormFeedback {...this.state.validation.organization}>
                      {this.state.validation.organization.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.role ? (
                  <div className="col-lg-6 mt-3">
                    <label>Role *</label>
                    <Button
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "white",
                    border: "none",
                    color:"red",
                    float: "right"

                  }}
                  
                  className="hover-blue ml-2"
                  id="TooltipExample"
                  data-tip
                >
                  {/* <i className="fas fa-file-csv"></i> */}
                  <FontAwesomeIcon  icon={faQuestion} size="1x" />
              </Button>
              <ReactTooltip>{helpbox}</ReactTooltip>


                    <FormSelect
                      name="role"
                      value={this.state.user.role}
                      onChange={this.on_change}
                      {...this.state.validation.role}
                    >
                      <option value="" selected disabled>
                        Select role
                      </option>
                      {roleList}
                    </FormSelect>
                    <FormFeedback {...this.state.validation.role}>
                      {this.state.validation.role.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.designation ? (
                  <div className="col-lg-6 mt-3">
                    <label>Designation *</label>
                    <FormInput
                      name="designation"
                      type="text"
                      value={this.state.user.designation}
                      onChange={this.on_change}
                      {...this.state.validation.designation}
                    />
                    <FormFeedback {...this.state.validation.designation}>
                      {this.state.validation.designation.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.gender ? (
                  <div className="col-lg-4 mt-3">
                    <label>Gender</label>
                    <FormSelect
                      name="gender"
                      value={this.state.user.gender}
                      onChange={this.on_change}
                      {...this.state.validation.gender}
                    >
                      <option value="" selected disabled>
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Others</option>
                    </FormSelect>
                    <FormFeedback {...this.state.validation.gender}>
                      {this.state.validation.gender.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.plants ? (
                  <div className={plantsClass}>
                    <label>Plants *</label>
                    <Picky
                      valueKey="id"
                      labelKey="plant_name"
                      options={this.state.availablePlants}
                      value={this.state.user.plants}
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={true}
                      onChange={this.on_multidrop_change}
                      dropdownHeight={200}
                    />
                    <FormFeedback {...this.state.validation.plants}>
                      {this.state.validation.plants.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.plants &&
                this.state.user.plants.length > 0 ? (
                  <div className="col-lg-3 mx-4 mt-4 py-2  custom-1 d-flex  align-items-center flex-wrap">
                    {plantList}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.address ? (
                  <div className="col-lg-8 mt-3">
                    <label>Address</label>
                    <FormInput
                      name="address"
                      type="text"
                      value={this.state.user.address}
                      onChange={this.on_change}
                      {...this.state.validation.address}
                    />
                    <FormFeedback {...this.state.validation.address}>
                      {this.state.validation.address.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.address ? (
                  <div className="col-lg-4 mt-3">
                    <label>City</label>
                    <FormInput
                      name="city"
                      type="text"
                      value={this.state.user.city}
                      onChange={this.on_change}
                      {...this.state.validation.city}
                    />
                    <FormFeedback {...this.state.validation.city}>
                      {this.state.validation.city.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.address ? (
                  <div className="col-lg-3 mt-3">
                    <label>State</label>
                    <FormInput
                      name="state"
                      type="text"
                      value={this.state.user.state}
                      onChange={this.on_change}
                      {...this.state.validation.state}
                    />
                    <FormFeedback {...this.state.validation.state}>
                      {this.state.validation.state.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.address ? (
                  <div className="col-lg-3 mt-3">
                    <label>Country</label>
                    <FormInput
                      name="country"
                      type="text"
                      value={this.state.user.country}
                      onChange={this.on_change}
                      {...this.state.validation.country}
                    />
                    <FormFeedback {...this.state.validation.country}>
                      {this.state.validation.country.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {this.props.visibilityFilter.address ? (
                  <div className="col-lg-2 mt-3">
                    <label>Pin Code *</label>
                    <FormInput
                      name="zip"
                      type="text"
                      maxLength={6}
                      value={this.state.user.zip}
                      onChange={this.on_change}
                      {...this.state.validation.zip}
                    />
                    <FormFeedback {...this.state.validation.zip}>
                      {this.state.validation.zip.message}
                    </FormFeedback>{" "}
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="mt-4 col-12">
                  <small> Fields marked * are mandatory </small>
                </div> */}

                <div className="my-4 col-12">
                  <Button
                    theme="danger"
                    type="button"
                    onClick={() => this.props.history.push("user-list")}
                    className="w-25 mr-2"
                  >
                    Cancel
                  </Button>

                  <Button
                    type="button"
                    onClick={this.toggleModal}
                    className="w-25"
                  >
                    {" "}
                    {this.props.button_title}{" "}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edit_session: state.AppState.edit_session,
    current_user: state.AppState.current_user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { saveEditSession, displayResponseMessage, saveCurrentUser },
      dispatch
    )
  };
}

UserFormTemplate.defaultProps = {
  organization: "",
  role: ""
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserFormTemplate)
);
