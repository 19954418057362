export const KKBROutlet = (
  ph = "",
  tss = "",
  bod = "",
  cod = "",
  f_coil = ""
) => {
  return {
    ph,
    tss,
    bod,
    cod,
    f_coil
  };
};


export default KKBROutlet(
  "10",
  "20",
  "30"
);