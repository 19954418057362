import React from "react";
import ManagerTemplate from "../../containers/ManagerTemplate";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const PlantManager = ({ navToggle }) => {
  return (
    <ManagerTemplate
      collection_name={"plants"}
      delete_action_type={types.DELETE_PLANT}
      navToggle={navToggle}
      page_sub_title={"List Of Added Plants"}
      page_title={"Plant Details"}
      colDef={{
        Name: "plant_name",
        Email: "email",
        Phone: "phone",
        Incharge: "contactPerson"
      }}
      add_entry_link={"add-plant"}
      edit_entry_link={"edit-plant"}
      button_title={"Add Plant"}
    />
  );
};

PlantManager.propTypes = {
  navToggle: func.isRequired
};

export default PlantManager;
