import axios from "axios";

export const authenticate = user => {
  return axios.post("/api/v1/users/login", user);
};

export const logout = () => {
  return axios.delete("/api/v1/users/logout");
};

export const acceptInvite = (invitation_token, passwordObj) => {
  return axios.put("/api/v1/invitation/", { ...passwordObj, invitation_token });
};

export const resetPassword = (reset_password_token, passwordObj) => {
  return axios.put("/api/v1/password", {
    ...passwordObj,
    reset_password_token
  });
};

export const refreshToken = () => {
  const response = axios.post("/api/v1/users/token").then(res => {
    return res;
  });
  return response;
};

export const changePasssword = password_obj => {
  return axios.put("/api/v1/password", password_obj);
};

export const sendResetPasswordLink = email => {
  return axios.post("/api/v1/password", email);
};
