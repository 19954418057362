import axios from "axios";

export const getOrganizationById = id => {
  return axios.get("/api/v1/organization_details/" + id);
};

export const getOrganizations = () => {
  return axios.get("/api/v1/organization_details");
};

export const addOrganization = organization => {
  return axios.post("/api/v1/organization_details", organization);
};

export const editOrganization = organization => {
  return axios.put(
    "/api/v1/organization_details/" + organization.organization_detail.id,
    organization
  );
};

export const deleteOrganization = id => {
  return axios.delete("/api/v1/organization_details/" + id);
};
