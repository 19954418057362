// Set up your root reducer here...import { combineReducers } from 'redux';
import AppStateReducer from "./AppStateReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  AppState: AppStateReducer
});

export default rootReducer;
