import React from "react";
import ListDailyProcessDataTemplate from "../../../containers/WrangalPlant/ListDailyProcessDataTemplate";
// import * as types from "../../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const ListDailyProcessData = ({ navToggle }) => {
  return (
    <ListDailyProcessDataTemplate
      // collection_name={"plants"}
      // delete_action_type={types.DELETE_PLANT}
      navToggle={navToggle}
      page_sub_title={"List Of Added Daily Process data"}
      page_title={"Wrangal Plant"}
      colDef={{
        Name: "",
        Location: "",
        Date: "",
        Observation: ""
      }}
     
    />
  );
};

ListDailyProcessData.propTypes = {
  navToggle: func.isRequired
};

export default ListDailyProcessData;
