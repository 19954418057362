import React from "react";
import WrangalDailyProcessFormTemplate from "../../../containers/WrangalPlant/WrangalDailyProcessFormTemplate";

import * as types from "../../../../../constants/actionTypes/AppStateActionTypes";
import { func } from "prop-types";

const DailyProcessData = ({ navToggle }) => {
  return (
    <WrangalDailyProcessFormTemplate
      action={types.ADD_DAILY_PROCESS}
      form_title={"Daily Process Data"}
      navToggle={navToggle}
      button_title={"Add Daily Process data"}
    />
  );
};

DailyProcessData.propTypes = {
  navToggle: func.isRequired
};

export default DailyProcessData;
