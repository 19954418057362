export const HHSeptage = (
    srs = "",
    pasteurization = "",
    volute_press = "",
    drier = "",
    pyrolyzer = "",
    phytorid = "",
    pstp = "",
    kkbr = "",
    psf_acf = "",
    chlorine_dosing = "",
    uv_filter = ""
  ) => {
    return {
        srs,
        pasteurization,
        volute_press,
        drier,
        pyrolyzer,
        phytorid,
        pstp,
        kkbr,
        psf_acf,
        chlorine_dosing,
        uv_filter
    };
  };
  
  
  export default HHSeptage(
  );