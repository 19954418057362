import axios from "axios";

//for Plant InputOutput Graph

export const getPlantInputOutput = (plant_id, date) => {



  return axios.get("/api/v1/report1/" + plant_id + "/plant_io_overview/" + date);
};

export const getPlantInputOutputGraph = (plant_id, startDate, endDate) => {
  return axios.get("/api/v1/report1/"+ plant_id + "/plant_io_overview/" +startDate+"/"+endDate);
};


//For Report-2 Treated Water

export const getTreatedWater = (plant_id, date) => {
  return axios.get("/api/v1/report2/"+ plant_id + "/treated_water_data/" +date);
};
export const getTreatedWaterGraph = (plant_id,  startDate, endDate) => {
  return axios.get("/api/v1/report2/"+ plant_id + "/treated_water_data/" +startDate+"/"+endDate);
};

//For Report-3  Plant Operation Data

export const getPlantOperationData = (plant_id, date) => {
  return axios.get("/api/v1/report3/"+ plant_id + "/plant_operation_data/" +date);
};

export const getPlantOperationDataGraph = (plant_id, startDate, endDate) => {
  return axios.get("/api/v1/report3/"+ plant_id + "/plant_operation_data/" +startDate+"/"+endDate);
};

//For Report-4 Treated Water

export const getCommments = (plant_id, date) => {
  return axios.get("/api/v1/report4/"+ plant_id + "/comments/" +date);
};

export const getCommmentsGraph = (plant_id,  startDate, endDate) => {
  return axios.get("/api/v1/report4/"+ plant_id + "/comments/" +startDate+"/"+endDate);
};

//For Report-5 free form


export const getFreeForm = (plant_id, date) => {
  return axios.get("/api/v1/report5/"+ plant_id + "/free_form/" +date);
};

export const getFreeFormDateRange = (plant_id,  startDate, endDate) => {
  return axios.get("/api/v1/report5/"+ plant_id + "/free_form/" +startDate+"/"+endDate);
};
