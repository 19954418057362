export default {
  edit_session: null,
  nav_state: {
    notification_state_visible: false,
    user_dropdown_state_visible: false,
    search_bar_state_visible: false
  },
  search_key: "",

  // new
  token: null,
  dailyProcess: null,
  dailyProcessess: [],
  labAnalysisInHouse: [],
  labAnalysisExternal: [],
  token_received_time: null,
  response_message: {
    show: false,
    status: "success",
    message: ""
  },
  permission: {
    edit: "",
    export: ""
  }
};