import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import MainNavbar from "../layout/MainNavbar/MainNavbar";
import ChangePassword from "../auth/containers/ChangePassword";

import { connect } from "react-redux";
import { navbarActions } from "../../actions/AppStateActions";

import { func, object } from "prop-types";
import PlantsOverviewApp from "../dashboard/plants-dashboard/PlantsOverviewApp";
import EditUserProfile from "../common/EditUserProfile";

import { AdminDashboard } from "./containers";
import {
  UserManager,
  OrganizationManager,
  PlantManager,
  AddUser,
  EditUser,
  AddOrganization,
  EditOrganization,
  AddPlant,
  EditPlant,
  WrangalPlantManager
} from "./views";


class AdminApp extends Component {
  constructor(props) {
    super(props);
    this.toggleActions = this.toggleActions.bind(this);
  }

  toggleActions(dropdown_name) {
    let state;
    if (typeof dropdown_name == "object") {
      // dropdown_name.preventDefault();
      dropdown_name = "user_dropdown_state_visible";
      state = false;
    } else {
      state = !this.props.nav_state[dropdown_name];
    }
    this.props.navbarActions(dropdown_name, state);
  }

  render() {
    return (
      <div className="h-100">
        <MainNavbar navToggle={this.toggleActions} />
        <Switch>
          <Route
            exact
            path="/admin/dashboard"
            render={() => <AdminDashboard navToggle={this.toggleActions} />}
          />

          <Route
            exact
            path="/admin/change-password"
            render={() => <ChangePassword navToggle={this.toggleActions} />}
          />

          <Route
            path="/admin/plants/:plant_name"
            render={() => <PlantsOverviewApp navToggle={this.toggleActions} />}
          />

          {/* Managers */}
          <Route
            exact
            path="/admin/user-list"
            render={() => <UserManager navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/organization-list"
            render={() => (
              <OrganizationManager navToggle={this.toggleActions} />
            )}
          />
          <Route
            exact
            path="/admin/plant-list"
            render={() => <PlantManager navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/wrangal-plant-list"
            render={() => <WrangalPlantManager navToggle={this.toggleActions} />}
          />


          {/* Adders */}
          <Route
            exact
            path="/admin/add-user"
            render={() => <AddUser navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/add-organization"
            render={() => <AddOrganization navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/add-plant"
            render={() => <AddPlant navToggle={this.toggleActions} />}
          /> 
                   

          {/* Editors */}
          <Route
            exact
            path="/admin/edit-profile"
            render={() => <EditUserProfile navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/edit-user"
            render={() => <EditUser navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/edit-organization"
            render={() => <EditOrganization navToggle={this.toggleActions} />}
          />
          <Route
            exact
            path="/admin/edit-plant"
            render={() => <EditPlant navToggle={this.toggleActions} />}
          />
        </Switch>
      </div>
    );
  }
}

AdminApp.propTypes = {
  navbarActions: func.isRequired,
  nav_state: object.isRequired
};

function mapStatetoProps(state) {
  return {
    current_user: state.AppState.current_user,
    nav_state: state.AppState.nav_state
  };
}

export default connect(
  mapStatetoProps,
  { navbarActions }
)(withRouter(AdminApp));
