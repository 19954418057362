
export const SRSValue = (
    srs_waste = "",
    grit_created = ""
  ) => {
    return {
        srs_waste,
        grit_created
    };
  };
  
  
  export default SRSValue(
  );