import React, { Component } from "react";
import { Plant, PlantValidation } from "../../../models/Plant";
import {
  saveEditSession,
  displayResponseMessage,
  saveCurrentUser,
  saveNewPlant,
  editPlant
} from "../../../actions/AppStateActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  Button,
  FormFeedback,
  FormSelect,
  Modal,
  InputGroup
} from "shards-react";
import * as types from "../../../constants/actionTypes/PlatformActionTypes";
import { Marker, GoogleApiWrapper, Map } from "google-maps-react";
import { validate } from "../validations/PlantFormValidation";
import * as plantService from "../../../services/PlantService";
import { getOrganizations } from "../../../services/OrganizationService";
import { getOrganizationByPlantId } from "../../../services/OrganizationPlantsService";
import ResponseMessage from "../../common/ResponseMessage";

class PlantFormTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plant: this.props.edit_session ? { ...this.props.edit_session } : Plant(),
      validation: PlantValidation,
      availableOrganizations: [],
      submitted: false,
      open: false,
      formData: null,
      isSubmitted: false
        };
    this.submit_form = this.submit_form.bind(this);
    this.on_change = this.on_change.bind(this);
    this.set_location = this.set_location.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.field_validate = this.field_validate.bind(this);
    this.form_validate = this.form_validate.bind(this);
  }

  componentDidMount() {    
    getOrganizations().then(availableOrganizations => {
      this.setState({ availableOrganizations });
    });

    if (this.props.edit_session) {
      getOrganizationByPlantId(this.props.edit_session.plant_id).then(
        organization => {
          this.setState({
            plant: {
              ...this.state.plant,
              organization: organization.organization_id
            }
          });
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.actions.saveEditSession(null);
  }

  form_validate() {
    let validation = { ...this.state.validation };
    let failed = [];
    Object.keys(this.state.validation).forEach(v => {
      let validation_results = { ...validate(v, this.state.plant[v]) };
      validation_results["valid"] =
        this.state.submitted && validation_results.valid;
      validation_results["invalid"] =
        this.state.submitted && validation_results.invalid;
      validation[v] = { ...validation[v], ...validation_results };
      if (validation[v].invalid) {
        failed.push(v);
      }
    });
    this.setState({ validation });
    return failed;
  }

  field_validate(e) {
    if (this.state.submitted) {
      const validation = {
        ...this.state.validation,
        [e.target.name]: {
          ...this.state.validation[e.target.name],
          ...validate(e.target.name, e.target.value)
        }
      };
      this.setState({ validation });
    }
  }

  submit_form(e) {
    e.preventDefault();
    this.setState({
      isSubmitted: true
    })
    this.setState({ submitted: true }, () => {
      if (this.form_validate().length > 0) {
        this.setState({
          isSubmitted: false
        })
        return;
      }
      const submit_func =
        this.props.action == types.ADD_PLANT
          ? plantService.addPlant
          : plantService.editPlant;
      const plant_details = this.state.plant;
      if (!this.props.edit_session) {
        delete plant_details["id"];
      }
      submit_func({ plant_details }).then(
        (test) => {          
          this.props.actions.displayResponseMessage(
            true,
            "success",
            "Plant " +
              (this.props.edit_session ? "edited" : "added") +
              " successful."
          );
          if( this.props.action == types.ADD_PLANT){
            this.props.actions.saveNewPlant({
              test
            });
          }else{
            this.props.actions.editPlant({
              test
            });

          }
          this.setState({
            isSubmitted: false
          })
          this.props.history.push("dashboard");
        },
        () => {
          this.setState({
            isSubmitted: false
          })
          this.toggleModal();
          this.props.actions.displayResponseMessage(
            true,
            "fail",
            "Sorry , something went wrong"
          );
        }
      );
    });
  }

  set_location(_, $, c) {
    const plant = {
      ...this.state.plant,
      lat: c.latLng.lat(),
      lng: c.latLng.lng()
    };
    this.setState({
      plant
    });
  }

  on_change(e) {
    // let value = e.target.value;
    // if (e.target.name == "plantPhotos") {
    //   value = [];
    //   let fd = new FormData();
    //   e.target.files.forEach((file, i) => {
    //     value.push(file);
    //     fd.append(`plant_photos[${i}]`, file, file.name);
    //   });
    //   this.setState({ plant_photos: fd });

    const plant = { ...this.state.plant, [e.target.name]: e.target.value };
    this.setState({ plant });
    let formData = new FormData();
    Object.keys(this.state.plant).forEach(p => {
      formData.append(p, this.state.plant[p]);
    });
    this.setState({ formData });
    this.field_validate(e);
  }

  toggleModal() {
    this.setState({ submitted: true }, () => {
      if (this.form_validate().length > 0) {
        return;
      }
      this.setState({ open: !this.state.open });
    });
  }

  render() {
    const mapStyles = {
      width: "100%",
      height: "100%"
    };

    const organizationList = this.state.availableOrganizations.map((o, i) => {
      return (
        <option key={i} value={o.organization_id}>
          {o.organization_name}
        </option>
      );
    });

    return (
      <div className="mh-85" onClick={this.props.navToggle}>
        <ResponseMessage />
        <div className="text-dark my-3 px-5">
          <small>User Operations</small>
          <h4>{this.props.form_title} </h4>
        </div>

        <Modal
          size="lg"
          open={this.state.open}
          style={{ marginTop: "55%" }}
          toggle={this.toggleModal}
          className="h-75 d-flex align-items-center justify-content-center"
        >
          <h4 className="text-center p-5 w-100">
            {"Are You sure you want to " + this.props.button_title + "?"}{" "}
          </h4>

          <div className="d-flex w-75 mx-auto justify-content-center">
            <Button
              theme="danger"
              onClick={this.toggleModal}
              className="col-5 mx-1 mt-2 mb-5 mx-auto"
            >
              Cancel
            </Button>
            <Button 
              disabled={this.state.isSubmitted == true}
              form="plant_form" 
              className="col-5 mx-1 mt-2 mb-5 mx-auto">

              Yes
            </Button>
          </div>
        </Modal>
        <div className="d-flex flex-wrap h-75 mt-2 px-5 col-12 align-items-center justify-content-center">
          <Card className="mb-5 p-0">
            <CardHeader className="bg-white border-bottom  p-3">
              <h5 className="text-dark">{this.props.form_sub_title}</h5>

              <div className="mt-2 p-0 col-12">
                <small> Fields marked * are mandatory </small>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <Form
                id="plant_form"
                className="d-flex flex-wrap justify-content-between"
                onSubmit={this.submit_form}
              >
                <div className="col-lg-6 mt-3">
                  <label>Name *</label>
                  <FormInput
                    disabled={this.props.action == types.EDIT_PLANT}
                    name="plant_name"
                    type="text"
                    value={this.state.plant.plant_name}
                    onChange={this.on_change}
                    {...this.state.validation.plant_name}
                  />
                  <FormFeedback {...this.state.validation.plant_name}>
                    {this.state.validation.plant_name.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>E-mail *</label>
                  <FormInput
                    disabled={this.props.action == types.EDIT_PLANT}
                    name="email"
                    type="email"
                    value={this.state.plant.email}
                    onChange={this.on_change}
                    {...this.state.validation.email}
                  />
                  <FormFeedback {...this.state.validation.email}>
                    {this.state.validation.email.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Phone Number *</label>
                  <InputGroup className="d-flex w-100 m-0">
                    <div className="col-3 p-0">
                      <FormInput
                        maxLength={4}
                        type="text"
                        name="countryCode"
                        placeholder="ex : +91"
                        value={this.state.plant.countryCode}
                        onChange={this.on_change}
                        {...this.state.validation.countryCode}
                      />
                      <FormFeedback {...this.state.validation.countryCode}>
                        {this.state.validation.countryCode.message}
                      </FormFeedback>{" "}
                    </div>
                    <div className="col-9 p-0">
                      <FormInput
                        maxLength={10}
                        name="phone"
                        type="tel"
                        value={this.state.plant.phone}
                        onChange={this.on_change}
                        {...this.state.validation.phone}
                      />
                      <FormFeedback {...this.state.validation.phone}>
                        {" "}
                        {this.state.validation.phone.message}{" "}
                      </FormFeedback>
                    </div>
                  </InputGroup>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Organization *</label>
                  <FormSelect
                    name="organization"
                    value={this.state.plant.organization}
                    onChange={this.on_change}
                    {...this.state.validation.organization}
                  >
                    <option value="" selected disabled>
                      Select Organization
                    </option>
                    {organizationList}
                  </FormSelect>
                  <FormFeedback {...this.state.validation.organization}>
                    {this.state.validation.organization.message}
                  </FormFeedback>{" "}
                </div>

                {/* <div className="col-lg-6 mt-3">
                  <label>Phone Number *</label>
                  <FormInput
                    name="phone"
                    type="text"
                    value={this.state.plant.phone}
                    onChange={this.on_change}
                    {...this.state.validation.phone}
                  />
                  <FormFeedback {...this.state.validation.phone}>
                    {this.state.validation.phone.message}
                  </FormFeedback>
                </div> */}

                <div className="col-lg-6 mt-3">
                  <label>Capacity (KLD) *</label>
                  <FormInput
                    name="capacity"
                    type="number"
                    value={this.state.plant.capacity}
                    onChange={this.on_change}
                    {...this.state.validation.capacity}
                  />
                  <FormFeedback {...this.state.validation.capacity}>
                    {this.state.validation.capacity.message}
                  </FormFeedback>
                </div>

                {/* <div className="col-lg-6 mt-3">
                  <label>Photos </label>

                  <div className="custom-file mb-3">
                    <input
                      name="plantPhotos"
                      type="file"
                      className="custom-file-input"
                      id="customFile2"
                      onChange={this.on_change}
                      multiple
                    />
                  </div>
                </div> */}

                <div className="col-lg-6 mt-3">
                  <label>Site Incharge *</label>
                  <FormInput
                    name="contactPerson"
                    type="text"
                    value={this.state.plant.contactPerson}
                    onChange={this.on_change}
                    {...this.state.validation.contactPerson}
                  />
                  <FormFeedback {...this.state.validation.contactPerson}>
                    {this.state.validation.contactPerson.message}
                  </FormFeedback>
                </div>

                {/* <div className="col-lg-6 mt-3">
                  <label>Google Location *</label>
                  <FormInput
                    name="googleLocation"
                    type="text"
                    value={this.state.plant.googleLocation}
                    onChange={this.on_change}
                    {...this.state.validation}
                  />
                </div> */}

                <div className="col-lg-6 mt-3">
                  <label>Address</label>
                  <FormInput
                    name="address"
                    type="text"
                    value={this.state.plant.address}
                    onChange={this.on_change}
                    {...this.state.validation.address}
                  />
                  <FormFeedback {...this.state.validation.address}>
                    {this.state.validation.address.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>City</label>
                  <FormInput
                    name="city"
                    type="text"
                    value={this.state.plant.city}
                    onChange={this.on_change}
                    {...this.state.validation.city}
                  />
                  <FormFeedback {...this.state.validation.city}>
                    {this.state.validation.city.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>State</label>
                  <FormInput
                    name="state"
                    type="text"
                    value={this.state.plant.state}
                    onChange={this.on_change}
                    {...this.state.validation.state}
                  />
                  <FormFeedback {...this.state.validation.state}>
                    {this.state.validation.state.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Country</label>
                  <FormInput
                    name="country"
                    type="text"
                    value={this.state.plant.country}
                    onChange={this.on_change}
                    {...this.state.validation.country}
                  />
                  <FormFeedback {...this.state.validation.country}>
                    {this.state.validation.country.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-6 mt-3">
                  <label>Pin Code *</label>
                  <FormInput
                    name="zip"
                    type="text"
                    maxLength={6}
                    value={this.state.plant.zip}
                    onChange={this.on_change}
                    {...this.state.validation.zip}
                  />
                  <FormFeedback {...this.state.validation.zip}>
                    {this.state.validation.zip.message}
                  </FormFeedback>
                </div>

                <div className="col-lg-12 p-0 mt-3" style={{ height: "20vw" }}>
                  <Map
                    name="googleLocation"
                    google={this.props.google}
                    initialCenter={{
                      lat: this.state.plant.lat,
                      lng: this.state.plant.lng
                    }}
                    onClick={this.set_location}
                    style={mapStyles}
                  >
                    <Marker
                      title={this.state.plant.name}
                      position={{
                        lat: this.state.plant.lat,
                        lng: this.state.plant.lng
                      }}
                    />
                  </Map>
                </div>

                {/* <div className="mt-4 col-12">
                  <small> Fields marked * are mandatory </small>
                </div> */}
                <div className="my-4 col-12">
                  <Button
                    theme="danger"
                    type="button"
                    onClick={() => this.props.history.goBack()}
                    className="w-25 mr-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={this.toggleModal}
                    className="w-25"
                  >
                    {" "}
                    {this.props.button_title}{" "}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edit_session: state.AppState.edit_session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { saveEditSession, displayResponseMessage, saveCurrentUser,saveNewPlant, editPlant },
      dispatch
    )
  };
}

export default withRouter(
  GoogleApiWrapper({ apiKey: "AIzaSyChtldZ_APhspM115TXMJiJ_CN72DHv6zY" })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PlantFormTemplate)
  )
);
