export const Filtrate = (
    ph = "",
    orp = ""
  ) => {
    return {
      ph,
      orp
    };
  };
  
  
  export default Filtrate(
    "10",
    "20"
  );
  