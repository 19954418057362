import PropTypes, { func, object, bool } from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import * as NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import * as NavbarBrand from "./NavbarBrand";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { searchList } from "../../../actions/AppStateActions";

class MainNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_key: ""
    };
    this.on_search_function = this.on_search_function.bind(this);
  }

  on_search_function(e) {
    this.setState({
      search_key: this.props.searchList(e.target.value)
    });
  }

  render() {
    const classes = classNames(
      "main-navbar",
      "bg-white",
      this.props.stickyTop && "sticky-top"
    );
    if (this.props.current_user) {
      return (
        <div className={classes}>
          <Container fluid className="p-0">
            <Navbar
              type="light"
              className="align-items-stretch flex-md-nowrap p-0"
            >
              <NavbarBrand.NavbarBrand
                on_click_function={() =>
                  this.props.history.push(
                    (this.props.current_user.designation == "Admin"
                      ? "/admin/"
                      : "/") + "dashboard"
                  )
                }
              />
              <NavbarSearch
                searchBarVisible={this.props.search_bar_state_visible}
                search_key={this.state.search_key}
                on_search_function={this.on_search_function}
              />
              <NavbarNav.NavbarNav toggleActions={this.props.navToggle} />
              <NavbarToggle />
            </Navbar>
          </Container>
        </div>
      );
    } else {
      return "";
    }
  }
}

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

MainNavbar.propTypes = {
  searchList: func.isRequired,
  current_user: object,
  history: object.isRequired,
  search_bar_state_visible: bool.isRequired,
  navToggle: func.isRequired
};

function mapStateToProps(state) {
  return {
    current_user: state.AppState.current_user,
    search_bar_state_visible: state.AppState.nav_state.search_bar_state_visible
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { searchList }
  )(MainNavbar)
);
