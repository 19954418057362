import React, { Component } from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import MaterialIcon from "material-icons-react";

class ResponseMessage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const modalWindow = (
      <div
        className={
          "response-message d-flex align-items-center justify-content-center " +
          (this.props.responseMessage.show ? "show " : "hide ") +
          this.props.responseMessage.status
        }
      >
        {" "}
        {this.props.responseMessage.status == "success" ? (
          <MaterialIcon color="white" icon="done" />
        ) : (
          <MaterialIcon color="white" icon="error" />
        )}
        <span className="pl-2">{this.props.responseMessage.message}</span>
      </div>
    );
    return modalWindow;
  }
}

function mapStateToProps(state) {
  return {
    responseMessage: state.AppState.response_message
  };
}

ResponseMessage.propTypes = {
  responseMessage: object
};

export default connect(mapStateToProps)(ResponseMessage);
