import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import MaterialIcon from "material-icons-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { object, func, bool } from "prop-types";
import {
  saveEditSession,
  logout,
  displayResponseMessage
} from "../../../../actions/AppStateActions";
import * as authService from "../../../../services/AuthService";
import { bindActionCreators } from "redux";

class UserActions extends React.Component {
  constructor(props) {
    super(props);
  }

  logout = () => {
    localStorage.clear();
    authService.logout().then(
      res => {
        if (res.success) {
          this.props.actions.logout(null);
          this.props.history.push("/");
          displayResponseMessage(true, "success", "Logged out successfully");
        } else {
          displayResponseMessage(true, "fail", "Sorry, something went wrong");
        }
      },
      () => {
        displayResponseMessage(true, "fail", "Sorry, something went wrong");
      }
    );
  };

  edit_profile = () => {
    this.props.actions.saveEditSession(this.props.current_user);
    this.props.history.push("/edit-profile");
  };

  render() {
    const admin_class =
      this.props.current_user.designation == "Admin" ? "" : "d-none";
    return (
      <NavItem
        tag={Dropdown}
        caret
        toggle={() =>
          this.props.toggleUserActions("user_dropdown_state_visible")
        }
      >
        <DropdownToggle
          caret
          tag={NavLink}
          className="text-nowrap px-3 hover-blue cursor-pointer d-flex align-items-center justify-content-center text-center"
        >
          <span className="pt-1">
            <MaterialIcon icon="perm_identity" />
          </span>
          <span>
            {" "}
            {this.props.current_user.first_name +
              " " +
              this.props.current_user.last_name}{" "}
          </span>
        </DropdownToggle>
        <Collapse
          tag={DropdownMenu}
          style={{ minWidth: "12rem" }}
          right
          small
          open={this.props.user_dropdown_state_visible == true}
        >
          <DropdownItem
            className={admin_class}
            tag={Link}
            to="/admin/plant-list"
          >
            <i className="material-icons">&#xe7f1;</i> Manage Plants
          </DropdownItem>
          <DropdownItem
            className={admin_class}
            tag={Link}
            to="/admin/organization-list"
          >
            <i className="material-icons">&#xe8f8;</i> Manage Organizations
          </DropdownItem>

          <DropdownItem
            className={admin_class}
            tag={Link}
            to="/admin/user-list"
          >
            <i className="material-icons">&#xE7FD;</i> Manage Users
          </DropdownItem>

          <DropdownItem tag={Link} to="/change-password">
            <i className="material-icons">&#xe0da;</i> Change Password
          </DropdownItem>
          <DropdownItem onClick={this.edit_profile} to="edit-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.logout} className="text-danger">
            {/* <a href="/"> */}
            <i className="material-icons text-danger">&#xE879;</i> Logout
            {/* </a> */}
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

function mapStatetoProps(state) {
  return {
    current_user: state.AppState.current_user,
    user_dropdown_state_visible:
      state.AppState.nav_state.user_dropdown_state_visible
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ saveEditSession, logout }, dispatch)
  };
}

UserActions.propTypes = {
  current_user: object.isRequired,
  toggleUserActions: func.isRequired,
  user_dropdown_state_visible: bool.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
  logout: func
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(UserActions));
