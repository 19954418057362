/* eslint-disable no-console */
import * as types from "../constants/actionTypes/AppStateActionTypes";
import AppInitstate from "../constants/initStates/AppInitState";

export default function AppStateReducer(state = AppInitstate, action) {
  switch (action.type) {
    // new actions
    case types.UNAUTH_USER:
      return {
        ...state,
        current_user: null,
          token: null
      };

    case types.SAVE_TOKEN:
      return {
        ...state,
        token: action.token,
          tokenReceivedTime: new Date().getTime()
      };

    case types.SAVE_CURRENT_USER:
      return {
        ...state,
        current_user: action.current_user
      };
    
    case types.SAVE_PERMISSION:
      console.log('SAVE Per');
      console.log(action)
      if(action.role == "plantStaff"){
        return {
          ...state,
          permission: {
            view: "YES",
            edit: "YES",
            export: "NO"
          }
      };
    }else if(action.role == "silver"){
      return {
        ...state,
        permission: {
          view: "YES",
          edit: "NO",
          export: "NO"
        }
      };
    }else if (action.role == "gold"){
      console.log('gold')
      return {
        ...state,
        permission: {
          view: "YES",
          edit: "NO",
          export: "YES"
        }
      };
    }
    return {
      ...state,
      permission: {
        view: "YES",
        edit: 'YES',
        export: "YES"
      }
    }

    case types.SAVE_NEW_PLANT:
        console.log(action.new_plant.test);
        //this.state.AppState.current_user.plants.push(action.new_plant.plant_details)
        return {
          ...state,
          current_user: {
            ...state.current_user,
            plants: state.current_user.plants.concat(action.new_plant.test)
            // plants:{
            //   ...state.current_user.plants,
            //   plants: state.current_user.plants.concat(action.new_plant.plant_details)
             
            // }
          }
        };
      // return{
      //   ...state.AppState.current_user.plants,
      //   plants: state.current_user.plants.concat(action.new_plant.plant_details)

      // }
      // return Object.assign({}, state, {
      //   plants: state.current_user.plants.concat(action.new_plant)
      // });
    
      
    case types.EDIT_PLANT:
      {
        console.log('EDIT_DAILY_PROCESS');
        console.log(action.edit_plant.test);
        console.log(action.edit_plant.test.id);
        const dailyHouseFIleter = state.current_user.plants.filter(item =>
          item.id !== action.edit_plant.test.id
        );
        console.log('dailyHouseFIleter', dailyHouseFIleter);
        
        const UpdatedDailySingleItem = action.edit_plant.test;
        console.log('UpdatedDailySingleItem', UpdatedDailySingleItem);
        
        const UpdatedListDaily = dailyHouseFIleter.concat(UpdatedDailySingleItem);
        console.log('UpdatedListDaily ', UpdatedListDaily);

        // return {
        //   ...state,
        //   dailyProcessess: UpdatedListDaily 
        // };

        return {
          ...state,
          current_user: {
            ...state.current_user,
            plants: UpdatedListDaily
            // plants:{
            //   ...state.current_user.plants,
            //   plants: state.current_user.plants.concat(action.new_plant.plant_details)
             
            // }
          }
        };

      }
    case types.DELETE_PLANT:
        {
          console.log('DELETE_DAILY_PROCESS');
          console.log(action.delete_plant_id.id);
          //console.log(action.current_data.id);
          const filterListInternal = state.current_user.plants.filter(item =>
            item.id != action.delete_plant_id.id
          );
          // return {
          //   ...state,
          //   dailyProcessess: filterListInternal
          // };
          return {
            ...state,
            current_user: {
              ...state.current_user,
              plants: filterListInternal
              // plants:{
              //   ...state.current_user.plants,
              //   plants: state.current_user.plants.concat(action.new_plant.plant_details)
               
              // }
            }
          };
  
        }


    case types.ADD_DAILY_PROCESS:
      console.log(action.current_data);
      return Object.assign({}, state, {
        dailyProcessess: state.dailyProcessess.concat(action.current_data)
      });
      // state.dailyProcessess.push(action.current_data);
      // console.log(state.dailyProcessess.concat([action.current_data]))

      // return {
      //   ...state,
      //   dailyProcessess: {
      //     ...state:
      //     action.current_data
      //   }
      // };
      // eslint-disable-next-line no-case-declarations
      // let value = action.current_data;
      // return{state}

      // return {
      //   ...state,
      //   dailyProcessess: action.current_data
      // }

    case types.DELETE_DAILY_PROCESS:
        {
          console.log('DELETE_DAILY_PROCESS');
          console.log(action.current_data);
          console.log(action.current_data.id);
          const filterListInternal = state.dailyProcessess.filter(item =>
            item.id !== action.current_data.id
          );
          return {
            ...state,
            dailyProcessess: filterListInternal
          };
  
        }
    case types.EDIT_DAILY_PROCESS:
        {
          console.log('EDIT_DAILY_PROCESS');
          console.log(action.current_data);
          console.log(action.current_data.id);
          const dailyHouseFIleter = state.dailyProcessess.filter(item =>
            item.id !== action.current_data.id
          );
          const UpdatedDailySingleItem = action.current_data;
          const UpdatedListDaily = dailyHouseFIleter.concat(UpdatedDailySingleItem);
          return {
            ...state,
            dailyProcessess: UpdatedListDaily 
          };
  
        }



    case types.ADD_LAB_ANALYSIS_IN_HOUSE:
      console.log(action.current_data);
      return Object.assign({}, state, {
        labAnalysisInHouse: state.labAnalysisInHouse.concat(action.current_data)
      });

    case types.EDIT_LAB_ANALYSIS_IN_HOUSE:
      {
        console.log('EDIT_LAB_ANALYSIS_IN_HOUSE');
        console.log(action.current_data);
        console.log(action.current_data.id);
        const InHouseFilter = state.labAnalysisInHouse.filter(item =>
          item.id !== action.current_data.id
        );
        const UpdatedInHouseSingleItem = action.current_data;
        const UpdatedListInHouse = InHouseFilter.concat(UpdatedInHouseSingleItem);
  
        return {
          ...state,
          labAnalysisInHouse: UpdatedListInHouse
        };

      }
    
    case types.DELETE_LAB_ANALYSIS_INHOUSE:
      {
        console.log('DELETE_LAB_ANALYSIS_INHOUSE');
        console.log(action.current_data);
        console.log(action.current_data.id);
        const filterListInternal = state.labAnalysisInHouse.filter(item =>
          item.id !== action.current_data.id
        );
        return {
          ...state,
          labAnalysisInHouse: filterListInternal
        };

      }


    
    case types.ADD_LAB_ANALYSIS_EXTERNAL:
      console.log(action.current_data);
      return Object.assign({}, state, {
        labAnalysisExternal: state.labAnalysisExternal.concat(action.current_data)
      });
    
    case types.EDIT_LAB_ANALYSIS_EXTERNAL:
      {
        console.log('EDIT_LAB_ANALYSIS_EXTERNA');
        console.log(action.current_data);
        console.log(action.current_data.id);
        const filterExternal = state.labAnalysisExternal.filter(item =>
          item.id !== action.current_data.id
        );
        const UpdatedSingleItem = action.current_data;
        // const UpdatedList = filterExternal + UpdatedSingleItem;
        const UpdatedList = filterExternal.concat(UpdatedSingleItem);
        
        
        // const UpdatedList = state.labAnalysisExternal.find(item =>
        //   item.id !== action.current_data.id
        // );
        console.log(filterExternal);
        // console.log(UpdatedList);
        console.log(UpdatedList);

      return {
        ...state,
        labAnalysisExternal: UpdatedList
      };


      }
      
    case types.DELETE_LAB_ANALYSIS_EXTERNAL:
      {
        console.log('DELETE_LAB_ANALYSIS_EXTERNAL');
        console.log(action.current_data);
        console.log(action.current_data.id);
        const filterListExternal = state.labAnalysisExternal.filter(item =>
          item.id !== action.current_data.id
        );
        return {
          ...state,
          labAnalysisExternal: filterListExternal
        };

      }

       
    case types.RESPONSE_DIALOG:
      return {
        ...state,
        response_message: {
          show: action.show,
          status: action.status,
          message: action.message
        }
      };

      //////////////////////////////

    case types.SAVE_EDIT_SESSION:
      return {
        ...state,
        edit_session: action.state
      };

    case types.NAVBAR_ACTIONS: {
      let nav_state = {};
      Object.keys(state.nav_state).forEach(s => {
        if (s != "search_bar_state_visible") {
          nav_state[s] = false;
        }
      });
      if (action.component) {
        nav_state[action.component] = action.status;
      }

      return {
        ...state,
        nav_state: {
          ...state.nav_state,
          ...nav_state
        }
      };
    }

    case types.SEARCH_LIST:
      return {
        ...state,
        search_key: action.search_key
      };

    default:
      return state;
  }
}