import React from "react";
import PlantFormTemplate from "../../containers/PlantFormTemplate";
import * as types from "../../../../constants/actionTypes/PlatformActionTypes";
import { func } from "prop-types";

const AddPlant = ({ navToggle }) => {
  return (
    <PlantFormTemplate
      action={types.ADD_PLANT}
      form_title={"Add Plant"}
      navToggle={navToggle}
      form_sub_title={"Plant Details"}
      button_title={"Add Plant"}
    />
  );
};

AddPlant.propTypes = {
  navToggle: func.isRequired
};

export default AddPlant;
