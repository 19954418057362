export const Role = (id = "", role_name = "") => {
  return {
    id,
    role_name
  };
};

let roles = ["Silver", "Gold", "Platinum"];

export default roles.map(p => {
  return Role(p.toLowerCase(), p);
});
