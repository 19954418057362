
export const Consumable = (
    pellets = "",
    polymer = "",
    chlorine = ""
  ) => {
    return {
        pellets,
        polymer,
        chlorine
    };
  };
  
  
  export default Consumable(
  );