import axios from "axios";

// export const getDailyProcessById = id => {
//   return axios.get("/api/v1/organization_details/" + id);
// };

export const getDailyProcess = (plant_id) => {
  return axios.get("/api/v1/process_details/plant/"+plant_id);
};


export const addDailyProcess = dailyprocess => {
  return axios.post("/api/v1/process_details", dailyprocess);
};

export const editDailyProcess = process_detail => {  
  return axios.put(
    "/api/v1/process_details/" + process_detail.process_detail._id.$oid,
    process_detail
  );
};

export const deleteDailyProcess = id => {
  return axios.delete("/api/v1/process_details/" + id);
};
