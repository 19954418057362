import React, { Component } from "react";
import PageTitle from "../../../common/PageTitle";
import FreeFormGraph from './Graph/FreeFormGraph'
import * as reportService from "../../../../services/ReportService";
import logo from '../../../assets/Spinner-1s-200px.gif';

import { connect } from "react-redux";

import { withRouter } from "react-router";
import MainSidebar from '../../../layout/MainSidebar/MainSidebar'
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormInput,
  Button,
  FormCheckbox,
} from "shards-react";
import { func, object } from "prop-types";
class FreeFormReportTemplate extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.on_change = this.on_change.bind(this);
   // this.isSelected = this.isSelected.bind(this)

    this.state = {
      plant_id:"",

      srs: false,
      quantity_of_septage: false,
      treated_water_taken: false,
      biochar_generated_kgs: false,
      ph: false,
      bod: false,
      cod: false,
      tss: false,
      f_coil: false,
      pyrolyzer:false,
      power_consumed:false,
      septage_treated:false,
      treated_water_taken_out:false,
      qty_of_solids_generated:false,
      analysis_type:false,
      analysis_date:false,
      observations:false,
      date: Date(),
      open: false,
      modalDiv: null,
      labels: [],
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      entryListForTable: {},
      reportOne: false,
      reportTwo: false,
      reportThree: false,
      reportFour: false,
      tableHead:[],
      tableBodyData:[],
      isDataLoadedFully:false,
      isDataLoaded:false,
      validationError: "",  
      dataNotFoundMessaage: "",
      checkBoxNotSelectedMessage: "",
      selections:{
        report1:[],
        report2:[],
        report3:[],
        report4:[]
      },
      resultSet:{
        report1:{
          biochar_generated_kgs:[],
          srs:[],
          treated_water_taken:[],
          quantity_of_septage:[]
        },
        report2:{
          ph:[],
          tss:[],
          bod:[],
          cod:[],
          f_coil:[]
        },
        report3:{
          power_consumed:[],
          pyrolyzer:[],
          septage_treated:[],
          treated_water_taken_out:[],
          qty_of_solids_generated:[]
        },
        report4:{
          observations:[],
          date:[]
        }

      },
      finalResultSet:{
      
      }
    };


  }
  componentDidMount(){
    this.setState({
      plant_id:localStorage.getItem('plant_id'),
      date: null
    })
  //  console.log(this.state.resultSet)
  }

  handleChange(e, reportType) {
    const newState = {};
    newState[reportType] = !this.state[reportType];
    this.setState({ ...this.state, ...newState });
  }
  
  //handle plant attribute
  addTableHeading(e, type) {
    const newState = {};
    this.state.tableHead.push(type)

    newState[type] = !this.state[type];
    this.setState({ ...this.state, ...newState });  
    // this.state.tableBodyData.push(10);  
  }

  // addSeptageReceivedHH(event, type){
  //   this.state.tableHead.push(type)
  //   // console.log(event);
  //   // console.log(type);
  //   this.setState(prevState => ({
  //     ...prevState,
  //     selections: {
  //       ...prevState.SeptageReceivedHH,
  //       check: !this.state.SeptageReceivedHH.check,
  //       data: this.state.SeptageReceivedHHData
  //     }
      
  //   }))
  //   this.pushData(this.state.SeptageReceivedHHData);
 
  //  // console.log(this.state[reportType]);
    
  //   //this.state.SeptageReceivedHH.check = 
  // }


  addSelection = (event, type, reportNumber) => {
    // var a = type;
    this.state.tableHead.push(type)
    this.setState(prevState => {
      return {
        [type]: !prevState[type]
      }
    }, () =>{
      if(reportNumber == 'report1'){
        if(this.state[type]){
          this.state.selections.report1.push(type)
        }else{
          const index = this.state.selections.report1.indexOf(type);
          this.state.selections.report1.splice(index, 1);
        }
      }else if (reportNumber == 'report2'){
        if(this.state[type]){
          this.state.selections.report2.push(type)
        }else{
          const index = this.state.selections.report2.indexOf(type);
          this.state.selections.report2.splice(index, 1);
        }
        
      }else if(reportNumber == 'report3'){
        if(this.state[type]){
          this.state.selections.report3.push(type);
        }else{
          const index = this.state.selections.report3.indexOf(type);
          this.state.selections.report3.splice(index, 1);
        }
        
      }else if(reportNumber == 'report4'){
        if(this.state[type]){
          this.state.selections.report4.push(type)
        }else{
          const index = this.state.selections.report4.indexOf(type);
          this.state.selections.report4.splice(index, 1);
        }
      }  
    }
    )   
  }
  pushData(data){
    this.state.tableBodyData.push(data);
  }
  get(type){
    let newState1 = {};
    newState1[type] = !this.state[type];
    this.setState({ ...this.state, ...newState1 });
  }

    on_change(e) {
        this.setState({
        [e.target.name]: [e.target.value],
        validationError: e.target.value === "" ? "Please select the date" : ""
       }); 
     }

  getTableData(){
    let select = this.state.selections;
    this.setState({  
    resultSet:{
      report1:{
        biochar_generated_kgs:[],
        srs:[],
        treated_water_taken:[],
        quantity_of_septage:[]
      },
      report2:{
        ph:[],
        tss:[],
        bod:[],
        cod:[],
        f_coil:[]
      },
      report3:{
        power_consumed:[],
        pyrolyzer:[],
        septage_treated:[],
        treated_water_taken_out:[],
        qty_of_solids_generated:[]
      },
      report4:{
        analysis_type:[],
        observations:[],
      }

    }
  })
    this.setState({
      isDataLoadedFully: true,
      dataNotFoundMessaage: "",
      finalResultSet : {}
      
    })
    const date = this.state.date;
    //console.log(date)
    if(date == null || date[0] == ""){
      let errorMessage = "Please select the date";
      this.setState({
        validationError: errorMessage
      })
    }else if(select.report1.length == 0 && select.report2.length == 0 && select.report3.length == 0  && select.report4.length == 0){
      //console.log('check box check')
      this.setState({
        finalResultSet : {},
        isDataLoadedFully:false,
        isDataLoaded:true,
        dataNotFoundMessaage: "Please select the attribute"
      })      
    }else{
    let data;
    const plant_id = this.state.plant_id
    const listTableData = reportService.getFreeForm;
     listTableData(plant_id, date).then(entryListForTableRes => {
       data = entryListForTableRes;
      if(data.message !=null){
        this.setState({
          isDataLoaded: true
        }) 
        this.setState({
          isDataLoadedFully: !this.state.isDataLoadedFully
        }) 

      }else {
        this.setState({ 
          isDataLoaded: false,
        });
        this.setState({
          isDataLoadedFully: !this.state.isDataLoadedFully
        }) 

          for(let i=0;i<data.report.length;i++){
            if(data.report[i].report_num == "report1"){
              for(let k = 0; k < data.report[i].septages.length;k++){
                this.state.resultSet.report1.quantity_of_septage.push(data.report[i].septages[k].quantity_of_septage);
              }
              this.state.resultSet.report1.srs.push(data.report[i].hours_ct_pt_septage.srs);
              this.state.resultSet.report1.treated_water_taken.push(data.report[i].metering.treated_water_taken);
              this.state.resultSet.report1.biochar_generated_kgs.push(data.report[i].dewaterted_solids_handel.biochar_generated_kgs);
            }else if (data.report[i].report_num == "report2"){
              this.state.resultSet.report2.ph.push(data.report[i].treated_water_external.ph);
              this.state.resultSet.report2.tss.push(data.report[i].treated_water_external.tss);
              this.state.resultSet.report2.bod.push(data.report[i].treated_water_external.bod);
              this.state.resultSet.report2.cod.push(data.report[i].treated_water_external.cod);
              this.state.resultSet.report2.f_coil.push(data.report[i].treated_water_external.f_coil);
            }else if (data.report[i].report_num == "report3"){
              // let t=
              this.state.resultSet.report3.septage_treated.push(data.report[i].metering.treated_water_taken + data.report[i].dewaterted_solids_handel.qty_of_solids_generated);
              this.state.resultSet.report3.pyrolyzer.push(data.report[i].hours_hh_septage.pyrolyzer);
              this.state.resultSet.report3.qty_of_solids_generated.push(data.report[i].dewaterted_solids_handel.qty_of_solids_generated);
              this.state.resultSet.report3.power_consumed.push(data.report[i].metering.energy_meter_final - data.report[i].metering.energy_meter_initial);
              this.state.resultSet.report3.treated_water_taken_out.push(data.report[i].metering.treated_water_taken);
            }else if (data.report[i].report_num == "report4"){
              for(let j=0;j<data.report[i].data.length;j++){
                if(data.report[i].data[j] != null){
                  if(data.report[i].data[j].analysis_type != null){
                    this.state.resultSet.report4.analysis_type.push(data.report[i].data[j].analysis_type);
                  }else {
                    this.state.resultSet.report4.analysis_type.push("-------");
                  }
                  this.state.resultSet.report4.observations.push(data.report[i].data[j].observations);
                }
              }
            }
          }
        }
        this.setResultSet(); 
        this.setState({
        entryListForTable: data
       })
       this.setState({
        isDataLoadedFully: false
        })
    }, (error) => {
      //console.log(JSON.stringify(error));
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
        finalResultSet : {},
       })
       let message  = error.response.data.message;
       this.setState({
         dataNotFoundMessaage: message
       })
});  
}
  }

  setResultSet(){
    let resultSet = this.state.resultSet;
    let selections = this.state.selections;
    let finalResultSet = {};    
    let result = [];

    for(let key in selections){
      // console.log(key);
      if(selections.hasOwnProperty(key)){
        var property = selections[key];
        // console.log(property);
        // console.log(property);
        for(let k=0;k<property.length;k++){
          let item = property[k];
          // console.log(item);
          // console.log(resultSet[key][item]);
          result.push({
            key:item,
            value: resultSet[key][item]
          })
          finalResultSet[item] = resultSet[key][item]
          //console.log(resultSet.report2[item]);
        }
      }

      
    }
    this.setState({
      finalResultSet: result
    })
    // console.log(resultSet);
    // console.log(selections);
    // console.log(result);
    // console.log(this.state.finalResultSet);

  }

  toggle() {
    this.setState(prevState => {
      return { dropdown1: !prevState.dropdown1 };
    });
  }

  showGraph(){
   // this.getTableData();
  //  console.log(this.state);
   
    this.setState({
      isMoreButtonClicked: !this.state.isMoreButtonClicked
    })    
  }
  renderSwitch(param) {
    //console.log(param);
    switch(param) {
      case 'Quantity of septage':
        return 'Litres';
      case 'Srs':
          return 'Kgs';
      case 'Treated water taken':
        return 'Kgs';
      case 'Biochar generated kgs':
        return 'Kgs';
      case 'Ph':
        return 'Number';
      case 'Bod':
        return 'mg/L'
      case 'Cod':
        return 'mg/L'
      case 'Tss':
        return '%'
      case 'F coil':
        return 'MPN/100ml'
      case 'Power consumed':
        return 'KWatts'
      case 'Septage treated':
        return 'Ltrs'  
      case 'Treated water taken out':
        return 'Ltrs'
      case 'Qty of solids generated':
        return 'Kgs' 
      case 'Pyrolyzer':
        return 'Hour'
      // case default: 
      //   return ''
    }
  }

  render() {
    const isDataLoadedFully = this.state.isDataLoadedFully 
    ? <div className="text-center" >
      <img style={{width:"90px"}} className="rounded mx-auto d-block" src={logo}></img>
      </div>
    : null;
    const isDataLoaded = this.state.isDataLoaded 
    ? <Row className="py-2">
    <Col  className="">
      <div className="text-center" style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
      </div> 
    </Col>
 </Row>
    : null;
    //{console.log(this.state.isDataLoaded)}
    const graphicalView = this.state.isMoreButtonClicked;

    const head = this.setHeading();
    const listItems = this.setup_searchOne();
    // const headForComment = this.setHeadingForComment();
    // const listForComment = this.setup_searchOneForComment();
    const tableHeadLenght = this.state.tableHead.length;
    let button;
    if(graphicalView){
      button = <FreeFormGraph 
      selections={this.state.selections}
      />;
    }
    const reportOneContent = this.state.reportOne 
    ? <div>
          <FormCheckbox
          type="checkbox"
          inline
          checked={this.state.quantity_of_septage}
          onChange={e => this.addSelection(e, "quantity_of_septage", "report1")}
        >
          Septage Received HH
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.srs}
          onChange={e => this.addSelection(e, "srs", "report1")}
        >
          Septage Received CTPT
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.treated_water_taken}
          onChange={e => this.addSelection(e, "treated_water_taken", "report1")}
        >
           Treated Water Taken Out
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.biochar_generated_kgs}
          onChange={e => this.addSelection(e, "biochar_generated_kgs", "report1")}
        >
          BioChar Generated
        </FormCheckbox>
    </div>
    : null;

    const reportTwoContent = this.state.reportTwo 
    ? <div>
        <FormCheckbox
          inline
          checked={this.state.ph}
          onChange={e => this.addSelection(e, "ph", "report2")}
        >
          PH
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.bod}
          onChange={e => this.addSelection(e, "bod", "report2")}
        >
          BOD
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.cod}
          onChange={e => this.addSelection(e, "cod", "report2")}
        >
           COD
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.tss}
          onChange={e => this.addSelection(e, "tss", "report2")}
        >
          TSS
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.f_coil}
          onChange={e => this.addSelection(e, "f_coil", "report2")}
        >
          F COIL
        </FormCheckbox>

       
    </div>
    : null;
    const reportThreeContent = this.state.reportThree 
    ? <div>
              <FormCheckbox
          inline
          checked={this.state.pyrolyzer}
          onChange={e => this.addSelection(e, "pyrolyzer", "report3")}
        >
          Pyrolyzer
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.power_consumed}
          onChange={e => this.addSelection(e, "power_consumed", "report3")}
        >
          Power Consumed
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.septage_treated}
          onChange={e => this.addSelection(e, "septage_treated", "report3")}
        >
           Septage Treated
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.treated_water_taken_out}
          onChange={e => this.addSelection(e, "treated_water_taken_out", "report3")}
        >
          Treated Water
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.qty_of_solids_generated}
          onChange={e => this.addSelection(e, "qty_of_solids_generated", "report3")}
        >
        Solid Generated        
        </FormCheckbox>
      
      </div>
    : null;
    const reportFourContent = this.state.reportFour 
    ? <div>
        <FormCheckbox
          // disabled={graphicalView}
          inline
          checked={this.state.analysis_type}
          onChange={e => this.addSelection(e, "analysis_type", "report4")}
        >
          Analysis Type
        </FormCheckbox>
        {/* <FormCheckbox
          inline
          checked={this.state.analysis_date}
          onChange={e => this.addSelection(e, "analysis_date", "report4")}
        >
          Analysis Date
        </FormCheckbox> */}
        <FormCheckbox
          inline
          checked={this.state.observations}
          onChange={e => this.addSelection(e, "observations", "report4")}
        >
           Observations
        </FormCheckbox>
    </div>
    : null;
    // const headings = this.state.tableHead.map((e, i) => {
    //   return (
    //     <th key={i} scope="col" className="border-0">
    //       {e}
    //     </th>
    //   );
    // });    
    const table = tableHeadLenght > 0 ?
      <div style={{"overflowX":"auto"}}>
      <Row>
      <Col>

        {/* <Card small className="mb-4">
          <CardBody className="p-0 pb-3"> */}

            <table  id="dtDynamicVerticalScrollExample" className="table mb-0">
              <thead className="bg-light">
                <tr className="text-center">
                  {head}
                </tr>
              </thead>
              <tbody>
                {listItems}
              </tbody>
            </table>
           
            {/* <table  id="dtDynamicVerticalScrollExample" className="table mb-0">
              <thead className="bg-light">
                <tr className="text-center">
                  {/* {headForComment} */}
                {/* </tr>
              </thead>
              <tbody> */}
                {/* {listForComment} */}
              {/* </tbody>
            </table> */} 
            {isDataLoadedFully}
            {/* </CardBody>
            </Card> */}

      </Col>
    </Row>
    </div>
     : null;
    return (
      <div className="mh-85" onClick={this.props.navToggle}>
        <MainSidebar />

        <Breadcrumb className="col-10 float-right p-0">
          <BreadcrumbItem
            className="cursor-pointer text-blue"
            onClick={() => this.props.history.push("/admin/plants/"+ this.props.match.params.plant_name+ "/overview/"+ localStorage.getItem('plant_id'))} 
          >
             {new String(this.props.match.params.plant_name)} 
            {" "}Overview
          </BreadcrumbItem>
          <BreadcrumbItem active>
          {new String(this.props.match.params.process_name)}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container
          fluid
          className="main-content-container px-4 col-10 float-right"
        >
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={
                "Free Form Report"
              }
              subtitle=""
              className="text-sm-left mb-3"
            />
                       <Col>
                       <div className="float-right">
                       {this.props.permission.edit == "YES" ? (
            <Button style={{marginRight:"29px"}}
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/dailyprocess/")}
            >
              
              Add Daily Process Data
            </Button>): (
          ""
        )}
            
            <Button
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")}
            >
              List Daily Process Data
            </Button>
            </div>
           </Col>
          </Row>
          

        <div style={{display: "flex"}}>
          {/* <div className="text-sm-left">
            <label>Free Form Report</label>
          </div> */}
        </div>
        <Row className="py-2">
          <Col sm="2" className="d-flex mb-2 mb-sm-0"> 
            <FormInput    
              name = "date"
              type="date"
              value = {
                this.state.date
              }
              onChange={this.on_change}
            />            
            <Button
             onClick={(e) => this.getTableData(e)}>
               Show
            </Button>
          </Col>
        </Row>
        <Row className="py-2">
            <Col sm="2" className="d-flex mb-2 mb-sm-0">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
        </Row>
           

        <div>
        <p>Select the Report Type:</p>
        <Row>
          <div className="col-md-2">

        <FormCheckbox
          
          checked={this.state.reportOne}
          onChange={e => this.handleChange(e, "reportOne")}
        >
          Plant I/O 
        </FormCheckbox>
        </div>
        <div className="col-md-10">
        
        { reportOneContent }
        </div>
        </Row>
        <Row>
          <div className="col-md-2">


        <FormCheckbox
          
          checked={this.state.reportTwo}
          onChange={e => this.handleChange(e, "reportTwo")}
        >
          Treated Water
        </FormCheckbox>
        </div>
        <div className="col-md-10">
        { reportTwoContent }
        </div>
        </Row>
        <Row>
          <div className="col-md-2">


        <FormCheckbox
         
          checked={this.state.reportThree}
          onChange={e => this.handleChange(e, "reportThree")}
        >
           Plant Operation
        </FormCheckbox>
        </div>
        <div className="col-md-10">
        { reportThreeContent }
          </div>
          </Row>
          <Row>
            <div className="col-md-2">
              <FormCheckbox
                checked={this.state.reportFour}
                onChange={e => this.handleChange(e, "reportFour")}
              >
                Comments
              </FormCheckbox>
            </div>
            <div className="col-md-10">
              { reportFourContent }
            </div>
          </Row>
        </div>
        {table}
        {isDataLoaded}
        
        <Button style={{marginRight:"29px" , marginTop: "40px"}}
            onClick={(e) => this.showGraph(e)}>
              {graphicalView ? "Less" : "More"}
        </Button>
        {button}
        </Container>
      </div>
    );
  }

  isEmpty(obj){
    for(var key in obj){
      if(obj.hasOwnProperty(key)){
        return false;
      }
    }
    return true;
  }

  setHeading(){
    let data = this.state.finalResultSet;
    //console.log(data)
    if(this.isEmpty(data)){
      //console
    }else {
      const headings = this.state.finalResultSet.map((e, i) => {
        if(e.key != 'analysis_type' || e.key != 'observations'){
          //console.log(e.key);
          var a = e.key;
          var b = a.replace(/_/g, ' ');
          b= b.charAt(0).toUpperCase() + b.slice(1);
          //console.log(this.renderSwitch(b))
         // var c = b.toUpperCase()
          // console.log(b);
          // console.log(b.charAt(0).toUpperCase() + b.slice(1));
         //                                                                                      console.log(a.replace(/_/g, ' ').charAt(0).toUpperCase() + e.key.slice(1))
          return (
            <th key={i} scope="col" className="border-0">
              {/* {e.key} */}
              {b} {"("}{this.renderSwitch(b)}{")"}
            </th>
          );
        }
      });
      return headings;
    }
  }

  setup_searchOne(){
    let rearrangedEntryList = this.state.finalResultSet;
    if(this.isEmpty(rearrangedEntryList)){
    // console.log('object is empty');
    }else {
      return rearrangedEntryList.map((e, i) => {
        if(e.key != 'analysis_type' || e.key != 'observations'){
         // console.log(e.value[0]);
          return (
            <td className="text-center" key={i} scope="col">
              {e.value[0] != null ? e.value[0] : 'NA'}
            </td>
          );
        }

      });
    }   
  }

  // setHeadingForComment(){
  //   let data = this.state.finalResultSet;
  //   if(this.isEmpty(data)){
  //     //console
  //   }else {
  //     const headingsForComment = this.state.finalResultSet.map((e, i) => {
  //       if(e.key == 'analysis_type' || e.key == 'observations'){
  //         console.log(e.key);
          
  //         return (
  //           <th key={i} scope="col" className="border-0">
  //             {/* {e.key} */}
  //             {e.key.replace(/_/g, ' ')}
  //           </th>
  //         );
  //       }
  //     });
  //     return headingsForComment;
  //   }
  // }

  // setup_searchOneForComment(){
  //   let rearrangedEntryList = this.state.finalResultSet;
  //   if(this.isEmpty(rearrangedEntryList)){
  //   // console.log('object is empty');
  //   }else {
  //     return rearrangedEntryList.map((e, i) => {
  //       if(e.key == 'analysis_type' || e.key == 'observations'){
  //         return (
  //           <td className="text-center" key={i} scope="col">
  //             {e.value[0] ? e.value[0] : "-----"}
  //           </td>
  //         );
  //       }

  //     });
  //   }   
  // }
}
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,
  };
}
FreeFormReportTemplate.propTypes = {
  navToggle: func.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired

};

// export default withRouter(FreeFormReportTemplate);
export default withRouter(
  connect(
    mapStateToProps
  )(FreeFormReportTemplate)
);