import { DummyPlants } from "./index";

export const User = (
  id = "",
  first_name = "",
  last_name = "",
  email = "",
  countryCode = "",
  phone_number = "",
  creation_date = "",
  password = "",
  organization = "",
  designation = "",
  role = "",
  gender = "",
  address = "",
  city = "",
  state = "",
  country = "",
  zip = "",
  plants = []
) => {
  return {
    id,
    first_name,
    last_name,
    email,
    countryCode,
    phone_number,
    creation_date,
    password,
    organization,
    designation,
    role,
    gender,
    address,
    city,
    state,
    country,
    zip,
    plants
  };
};

export const UserValidation = {
  first_name: {},
  last_name: {},
  email: {},
  countryCode: {},
  phone_number: {},
  organization: {},
  designation: {},
  role: {},
  gender: {},
  address: {},
  city: {},
  state: {},
  country: {},
  zip: {},
  plants: {}
};

export default User(
  1,
  "Super",
  "Admin",
  "admin@tidetechnocrats.com",
  "+91",
  "9999999999",
  new Date(),
  "pro123",
  "organization one",
  "Admin",
  "Platinum",
  "male",
  "address",
  "city",
  "state",
  "country",
  "123456",
  DummyPlants
);
