import validator from "validator";

const email_valid = email => {
  return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email);
};

const is_all_alphabet = input => {
  return /^[a-zA-Z\s]+$/.test(input);
};

const website_valid = input => {
  // fetch(input).then(res => {
  //   return res.ok ? true : false;
  // });
  // return !!pattern.test(input);
  return validator.isURL(input) ? true : false;
};

const is_all_numeric = input => {
  return /[0-9]+/.test(input);
};

const zip_valid = input => {
  return /[0-9]{1,6}/.test(input);
};

const phone_valid = input => {
  return /[0-9]{10}/.test(input);
};

const is_empty = input => {
  if(input == undefined) {
    return false;
  }
  return input.length == 0;
};

const is_valid_length = (input, length, boundary) => {
  // if (typeof input != "string" || typeof input != "array") {
  //   input = input.toString();
  // }
  if (boundary == "gt") {
    return input.length > length;
  } else if (boundary == "lt") {
    return input.length < length;
  }
  return input.length == length;
};

const country_code_valid = input => {
  return /\++[0-9]{1,3}/.test(input);
};

export const validity_msg = (valid, message) => {
  return {
    valid,
    invalid: valid ? false : true,
    message
  };
};

export const chain_validations = (
  validations,
  data,
  validation_results = {}
) => {
  validations.forEach(v => {
    switch (v) {
      case "email_valid":
        validation_results[v] = email_valid(data.input);
        break;

      case "zip_valid":
        validation_results[v] = zip_valid(data.input);
        break;

      case "phone_valid":
        validation_results[v] = phone_valid(data.input);
        break;

      case "website_valid":
        validation_results[v] = website_valid(data.input);
        break;

      case "is_all_alphabet":
        validation_results[v] = is_all_alphabet(data.input);
        break;

      case "is_all_numeric":
        validation_results[v] = is_all_numeric(data.input);
        break;

      case "is_empty":
        validation_results[v] = is_empty(data.input);
        break;

      case "is_valid_length":
        validation_results[v] = is_valid_length(
          data.input,
          data.length,
          data.boundary
        );
        break;

      case "country_code_valid":
        validation_results[v] = country_code_valid(data.input);
        break;

      default:
        validation_results;
    }
  });
  return validation_results;
};
