import React, { Component } from "react";
// import ChartsTemplate from "./charts/ChartsTemplate";
// import PageTitle from "../../common/PageTitle";
import PageTitle from "../../../common/PageTitle";
import TreatedWaterGraph from './Graph/TreatedWaterGraph'
import * as reportService from "../../../../services/ReportService";
import logo from '../../../assets/Spinner-1s-200px.gif';
import { connect } from "react-redux";

import { withRouter } from "react-router";
// import MainSidebar from "../../layout/MainSidebar/MainSidebar";

import MainSidebar from '../../../layout/MainSidebar/MainSidebar'
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  FormInput,
  CardBody,
  Button,
} from "shards-react";
import { object } from "prop-types";
// import { getMetrics } from "../../../services/DashboardService";

class TreatedWaterReportTemplate extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.on_change = this.on_change.bind(this);
    this.changePh = this.changePh.bind(this);
    this.changeCod = this.changeCod.bind(this);
    this.changeBod = this.changeBod.bind(this);
    this.changeTss = this.changeTss.bind(this);
    this.changeFCoil = this.changeFCoil.bind(this);
    this.state = {
      date: Date(),
      open: false,
      modalDiv: null,
      labels: [],
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30),
      endDate: new Date(),
      dataset: '',
      dropdown1: false,
      isMoreButtonClicked: false,
      entryListForTable: {},
      ph: false,
      cod: false,
      bod: false,
      tss: false,
      f_coil: false,
      plant_id:"",
      isDataLoadedFully:false,
      isDataLoaded:false,
      validationError: "",
      dataNotFoundMessaage: "",
      selection:{
        report2:[]
      },
      resultSet:{
        report2:{
          PH:[],
          TSS:[],
          BOD:[],
          COD:[],
          F_coil:[]
        }
      },
      finalResultSet:{}
    };


  }
  on_change(e) {
    this.setState({
     date: e.target.value,
     validationError: e.target.value === "" ? "Please select the date" : ""
    });        
  }
  changePh() {
    this.setState({
      ph: !this.state.ph
    });
  }
  changeBod() {
    this.setState({
      bod: !this.state.bod
    });
  }

  changeCod() {
    this.setState({
      cod: !this.state.cod
    });
  }

  changeTss() {
    this.setState({
      tss: !this.state.tss
    });
  }

  changeFCoil() {
    this.setState({
      f_coil: !this.state.f_coil
    });
  }



  setResultSet(){
    let resultSet = this.state.resultSet;
    let selections = this.state.selection;
    // console.log(selections);
    // console.log(resultSet);
    
    

    let finalResultSet = {};

    
    let result = [];

    for(let key in selections){
      // console.log(key);
      if(selections.hasOwnProperty(key)){
        var property = selections[key];
        // console.log(property);
        // console.log(property);
        for(let k=0;k<property.length;k++){
          let item = property[k];
          // console.log(item);
          // console.log(resultSet[key][item]);
          result.push({
            key:item,
            value: resultSet[key][item]
          })
          finalResultSet[item] = resultSet[key][item]
          

          //console.log(resultSet.report2[item]);
        }
      }

      
    }
    this.setState({
      finalResultSet: result

    })
    // console.log(resultSet);
    // console.log(selections);
    // console.log(result);
    // console.log(this.state.finalResultSet);


  }
  addSelection(event, type){
    const newState = {};
    newState[type] = !this.state[type];
    this.setState({ ...this.state, ...newState });    
    this.state.selection.report2.push(type)    
  }

  componentDidMount(){
    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully
    })
    this.setState({
      plant_id:localStorage.getItem('plant_id')
    })
    let data;
    let date = localStorage.getItem('current_external_date');
    this.setState({
      date:date
    })
    const plant_id = localStorage.getItem('plant_id');
    const listTableData = reportService.getTreatedWater;
     listTableData(plant_id, date).then(entryListForTableRes => {
       data = entryListForTableRes;       
      this.setState({ 
        entryListForTable: data,
        PH: entryListForTableRes.PH,
        BOD: entryListForTableRes.BOD,
        COD: entryListForTableRes.COD,
        TSS: entryListForTableRes.TSS,
        F_coil: entryListForTableRes.F_coil
      });
      this.setState({
        isDataLoadedFully: !this.state.isDataLoadedFully
      })  
    }, () => {
      //console.log(JSON.stringify(error));
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
       })
      //  let message  = error.response.data.message;
       this.setState({
         dataNotFoundMessaage: "No Record Found"
       })

}); 
  }



  getTableData(){
    const date = this.state.date;
  
    if(date == ""){
      let errorMessage = "Please select the date";
      this.setState({
        validationError: errorMessage
      })
    }else{

    this.setState({
      isDataLoadedFully: !this.state.isDataLoadedFully,
      isDataLoaded: false,
      entryListForTable: {} 
    })  
    let data;
    const date = this.state.date;
    const plant_id = this.state.plant_id
    const listTableData = reportService.getTreatedWater;
     listTableData(plant_id, date).then(entryListForTableRes => {
       data = entryListForTableRes;  

      // console.log(data);
      //  console.log(data.BOD[0]);

      //  this.state.resultSet.report2.PH.push(data.PH[0]);
      //  this.state.resultSet.report2.BOD.push(data.BOD[0]);
      //  this.state.resultSet.report2.COD.push(data.COD[0]);
      //  this.state.resultSet.report2.TSS.push(data.TSS[0]);
      //  this.state.resultSet.report2.F_coil.push(data.F_coil[0]);
      //  console.log( this.state.resultSet);
       


       
       if(data.message != null){
        this.setState({
          isDataLoaded: true
          })
          this.setState({
            isDataLoadedFully: !this.state.isDataLoadedFully,
            entryListForTable: data,
            PH: "",
            BOD: "",
            COD: "",
            TSS: "",
            F_coil: ""
          }) 
       } else{
        this.setState({ 
          isDataLoaded: false,
          entryListForTable: data,
          PH: entryListForTableRes.PH,
          BOD: entryListForTableRes.BOD,
          COD: entryListForTableRes.COD,
          TSS: entryListForTableRes.TSS,
          F_coil: entryListForTableRes.F_coil
        });
        this.setState({
          isDataLoadedFully: !this.state.isDataLoadedFully
        }) 
       } 
       this.setResultSet();
    }, (error) => {
      //console.log(JSON.stringify(error));
      this.setState({
        isDataLoadedFully:false,
        isDataLoaded:true,
       })
       let message  = error.response.data.message;
       this.setState({
         dataNotFoundMessaage: message
       })

});  
  }
     
  }

 
  toggle() {
    this.setState(prevState => {
      return { dropdown1: !prevState.dropdown1 };
    });
  }
  showGraph(){
    //this.getTableData();
    this.setState({
      isMoreButtonClicked: !this.state.isMoreButtonClicked
    })    
  }



  render() {
    const isDataLoadedFully = this.state.isDataLoadedFully 
    ? <div className="text-center" >
      <img style={{width:"90px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    const isDataLoaded = this.state.isDataLoaded 
    ? <Row className="py-2">
    <Col  className="">
      <div className="text-center" style={{color:"Red"}}>
        {this.state.dataNotFoundMessaage}
      </div> 
    </Col>
 </Row>
    : null;
    const graphicalView = this.state.isMoreButtonClicked;
    const listItems = this.setup_searchOne();
    let button;
    if(graphicalView){
      button = <TreatedWaterGraph/>;
    }
    
    const table = (
      <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr className="text-center">
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">PH (Number)</th>
                  <th scope="col" className="border-0">BOD (mg/L)</th>
                  <th scope="col" className="border-0">COD (mg/L)</th>
                  <th scope="col" className="border-0">TSS (%)</th>
                  <th scope="col" className="border-0">F COLI (MPN/100ml)</th>
                </tr>
              </thead>
              <tbody>
                {listItems}
              </tbody>
            </table>
            {isDataLoadedFully}
            {isDataLoaded}
          </CardBody>
        </Card>
      </Col>
    </Row>
    );

    return (
      <div className="mh-85">
        <MainSidebar />

        <Breadcrumb className="col-10 float-right p-0">
        <BreadcrumbItem> 
          <a onClick={() => this.props.history.push("/admin/plants/"+ this.props.match.params.plant_name+ "/overview/"+ localStorage.getItem('plant_id'))} style={{color:"#007bff", cursor:"pointer"}}>
          {new String(this.props.match.params.plant_name)} Overview
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
          {new String(this.props.match.params.process_name)}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container
          fluid
          className="main-content-container px-4 col-10 float-right"
        >
         


          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={
               "Treated Water Report"
              }
              subtitle=""
              className="text-sm-left mb-3"
            />
                       <Col>
                       <div className="float-right">
                       {this.props.permission.edit == "YES" ? (
            <Button style={{marginRight:"29px"}}
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/dailyprocess/")}
            >
              
              Add Daily Process Data
            </Button>): (
          ""
        )}
            
            <Button
              onClick={() => this.props.history.push("/admin/plants/" + this.props.match.params.plant_name + "/list-daily-process")}
            >
              List Daily Process Data
            </Button>
            </div>
           </Col>
          </Row>
          

<div style={{display: "flex"}}>

{/* <div className="text-sm-left">
  <label>Treated Water Report</label>

</div> */}

</div>

        <Row className="py-2">
          <Col sm="2" className="d-flex mb-2 mb-sm-0"> 
            <FormInput    
              name = "date"
              type="date"
              value = {
                this.state.date
              }
              onChange={this.on_change}
            />            
            <Button
             onClick={(e) => this.getTableData(e)}>
               Show
            </Button>
          </Col>
        </Row>
        <Row className="py-2">
            <Col sm="2" className="d-flex mb-2 mb-sm-0">
            <div style={{color:"Red"}}>
              {this.state.validationError}
            </div> 
            </Col>
          </Row>
          {/* <FormCheckbox
            inline
            name="sport"
            checked={this.state.ph}
            onChange={() => {
              this.changePh();
            }}
          >
            PH
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.bod}
            onChange={() => {
              this.changeBod();
            }}
          >
            BOD
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.cod}
            onChange={() => {
              this.changeCod();
            }}
          >
            COD
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.tss}
            onChange={() => {
              this.changeTss();
            }}
          >
            TSS
          </FormCheckbox>
          <FormCheckbox
            inline
            name="sport"
            checked={this.state.f_coil}
            onChange={() => {
              this.changeFCoil();
            }}
          >
            F COLI
          </FormCheckbox> */}
        {/* <FormCheckbox
          inline
          checked={this.state.PH}
          onChange={e => this.addSelection(e, "PH")}
        >
          PH
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.BOD}
          onChange={e => this.addSelection(e, "BOD")}
        >
          BOD
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.COD}
          onChange={e => this.addSelection(e, "COD")}
        >
           COD
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.TSS}
          onChange={e => this.addSelection(e, "TSS")}
        >
          TSS
        </FormCheckbox>
        <FormCheckbox
          inline
          checked={this.state.F_coil}
          onChange={e => this.addSelection(e, "F_coil")}
        >
          F COIL
        </FormCheckbox> */}

           
        

          {table}
          <Button style={{marginRight:"29px"}}
             onClick={(e) => this.showGraph(e)}>
               {graphicalView ? "Less" : "More"}
          </Button>
         
          {button}
         
    

          {/* <Row>{charts}</Row> */}
        </Container>
      </div>
    );
  }
  setup_searchOne(){
     let rearrangedEntryList = {} ;
     rearrangedEntryList = this.state.entryListForTable;
     
     if(rearrangedEntryList.BOD != null){
      return(
         <tr className="text-center">
           <td>1</td>
           <td> {rearrangedEntryList.PH[0] != null ?rearrangedEntryList.PH[0]: "NA" }</td>
           <td> {rearrangedEntryList.BOD[0]!=null ? rearrangedEntryList.BOD[0]: "NA"}</td>
           <td>{rearrangedEntryList.COD[0] != null? rearrangedEntryList.COD[0] : "NA"}</td>
           <td>{rearrangedEntryList.TSS[0] != null ? rearrangedEntryList.TSS[0] :"NA"}</td>
           <td>{rearrangedEntryList.F_coil[0] != null ? rearrangedEntryList.TSS[0] : "NA"}</td>
         </tr>
           );
    }else{
      <td>No Data</td>
    }
 
   }
}
function mapStateToProps(state) {
  return {
    permission: state.AppState.permission,
  };
}
TreatedWaterReportTemplate.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  permission: object.isRequired
};

// export default withRouter(TreatedWaterReportTemplate);
export default withRouter(
  connect(
    mapStateToProps
  )(TreatedWaterReportTemplate)
);