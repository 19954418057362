import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  navbarActions,
  saveEditSession,
  displayResponseMessage,
  deletePlant
} from "../../../actions/AppStateActions";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Modal
} from "shards-react";
import PageTitle from "../../common/PageTitle";
import ReactTooltip from "react-tooltip";
import MaterialIcon from "material-icons-react";
import { string, object, func } from "prop-types";
import * as userService from "../../../services/UserService";
import * as plantService from "../../../services/PlantService";
import * as organizationService from "../../../services/OrganizationService";
import { getPlantByUserId } from "../../../services/UserPlantsService";
import ResponseMessage from "../../common/ResponseMessage";
import logo from '../../assets/Spinner-1s-200px.gif';


class Manager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // entryList: [
      //   ...this.props.entryList[this.props.collection_name].filter(
      //     e => e.designation && e.designation == "Admin"
      //   ),
      //   ...this.props.entryList[this.props.collection_name].filter(
      //     e => (e.designation && e.designation) != "Admin"
      //   )
      // ],
      entryList: [],
      open: false,
      entry: null,
      isDataLoadedFully: false,
      roleInfo: {
        "admin": "Admin",
      "gold": "Gold",
      "plantStaff": 'Plant Staff',
      "silver": "Silver"
      }

    };
    this.roleInfo = {
      "admin": "Admin",
      "gold": "Gold",
      "plantStaff": 'Plant Staff',
      "silver": "Silver"
      
    }
    this.render_tooltip = this.render_tooltip.bind(this);
    this.setup_search = this.setup_search.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.delete_entry = this.delete_entry.bind(this);
  }

  render_tooltip(toolTips) {
    toolTips = {};
    this.state.entryList.forEach(u => {
      toolTips[u.id] = u.plants.map((p, i) => (
        <p key={i} className="mb-0">
          {i + 1 + ". " + p.plant_name}
        </p>
      ));
    });

    return toolTips;
  }

  componentDidMount() {
    this.setState({
      isDataLoadedFully: true
    })
    const listFunc =
      this.props.collection_name == "users"
        ? userService.getUsers
        : this.props.collection_name == "organizations"
        ? organizationService.getOrganizations
        : plantService.getPlants;

    listFunc().then(entryList => {
      entryList = entryList ? entryList : [];      
      if (this.props.collection_name == "users") {        
        let temp = [];
        entryList.forEach(entry => {
          let tempPlants = [];
          getPlantByUserId(entry.user_id).then(plantRes => {
            if (plantRes && plantRes.length > 0) {
              plantRes.forEach((userPlantMap, j) => {
                plantService
                  .getPlantByMasterId(userPlantMap.plant_id)
                  .then(plant => {                    
                    if (plant) tempPlants.push(plant);
                    if (plantRes.length - 1 == j) {
                      temp.push({ ...entry, plants: tempPlants });
                      this.setState({ entryList: temp });
                      this.setState({
                        isDataLoadedFully: false
                      })
                    }
                  });
              });
            } else {
              temp.push({ ...entry, plants: [] });
              this.setState({ entryList: temp });
              this.setState({
                isDataLoadedFully: false
              })
            }
          });
        });

      } else {
        this.setState({ entryList });
        this.setState({
          isDataLoadedFully: false
        })
      }
    });
    this.props.actions.navbarActions("search_bar_state_visible", true);
  }

  componentWillUnmount() {
    this.props.actions.navbarActions("search_bar_state_visible", false);
  }

  edit_entry(entry) {
    this.props.actions.saveEditSession(entry);
    this.props.history.push(this.props.edit_entry_link);
  }

  toggleModal(entry) {
    this.setState({ entry }, () => {
      this.setState({ open: !this.state.open });
    });
  }

  delete_entry(id) {
    if(this.props.delete_action_type == "DELETE_PLANT"){
      this.props.actions.deletePlant({
        id
      });
    }
    const deleteFunc =
      this.props.collection_name == "users"
        ? userService.deleteUser
        : this.props.collection_name == "organizations"
        ? organizationService.deleteOrganization
        : plantService.deletePlant;

    deleteFunc(id).then(
      () => {
        this.setState(
          { entryList: this.state.entryList.filter(e => e.id != id) },
          () => {
            this.toggleModal(null);
          }
        );
        this.props.actions.displayResponseMessage(
          true,
          "success",
          "Entry deleted successfully"
        );
      },
      () => {
        this.toggleModal();
        this.props.actions.displayResponseMessage(
          true,
          "fail",
          "Sorry, something went wrong"
        );
      }
    );
  }

  render() {
    const isDataLoadedFully = this.state.isDataLoadedFully 
    ? <div className="text-center" >
      <img style={{width:"90px"}} className="rounded mx-auto d-block" src={logo}></img>
    </div>
    : null;
    
    
    let toolTips;
    if (this.props.collection_name == "users") {
      toolTips = this.render_tooltip(toolTips);
    }

    const listItems = this.setup_search(toolTips);

    const headings = Object.keys(this.props.colDef).map((e, i) => {
      return (
        <th key={i} scope="col" className="border-0">
          {e}
        </th>
      );
    });

    const modalWindow = (
      <Modal
        size="lg"
        open={this.state.open}
        style={{ marginTop: "55%" }}
        toggle={() => this.toggleModal(null)}
        className="h-75 d-flex align-items-center justify-content-center"
      >
        <h4 className="text-center p-5 w-100">
          Are you sure you want to delete{" "}
          {this.state.entry
            ? this.props.collection_name == "users"
              ? this.state.entry.first_name + " " + this.state.entry.last_name
              : this.props.collection_name == "organizations"
              ? this.state.entry.organization_name
              : this.state.entry.plant_name
            : ""}
          {" ?"}
        </h4>

        <div className="d-flex w-75 mx-auto justify-content-center">
          <Button
            theme="danger"
            onClick={() => this.toggleModal(null)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.delete_entry(this.state.entry.id)}
            className="col-5 mx-1 mt-2 mb-5 mx-auto"
          >
            Yes
          </Button>
        </div>
      </Modal>
    );

    return (
      <div className=" mh-85" onClick={this.props.navToggle}>
        <ResponseMessage />
        <Container fluid className="main-content-container px-4">
          <Row
            noGutters
            className="page-header py-4 align-items-center justify-content-between"
          >
            <PageTitle
              sm="4"
              title={this.props.page_title}
              subtitle={this.props.page_sub_title}
              className="text-sm-left"
            />

            <Button
              onClick={() => this.props.history.push(this.props.add_entry_link)}
            >
              {this.props.button_title}
            </Button>
          </Row>
          {modalWindow}
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr className="text-center">
                        <th scope="col" className="border-0">
                          #
                        </th>
                        {headings}
                        <th scope="col" className="border-0" />
                      </tr>
                    </thead>
                    <tbody>{listItems}</tbody>
                  </table>
                  {isDataLoadedFully}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  setup_search(toolTips) {
    // console.log(this.props.search_key);

  
    let search_test = {};
    let rearrangedEntryList = [];
    if (this.props.collection_name == "users") {
      this.state.entryList.forEach(entry => {
        if (entry.designation == "Admin") rearrangedEntryList.unshift(entry);
        else rearrangedEntryList.push(entry);
      });
    } else {
      rearrangedEntryList.push(...this.state.entryList);
    }
    return rearrangedEntryList.map((item, i) => {
      search_test[item.id] = JSON.stringify(item);
      // console.log(search_test)
      if (
        this.props.search_key == "" ||
        search_test[item.id].search(new RegExp(this.props.search_key ,"i") ) > 0
      ) {
        return (
          <tr className="text-center" key={i} id={item.id}>
            <td>{i + 1}</td>
            <td>
              {item[this.props.colDef[Object.keys(this.props.colDef)[0]]]}
            </td>
            <td>
              {item[this.props.colDef[Object.keys(this.props.colDef)[1]]]}
            </td>
            <td>
              {item[this.props.colDef[Object.keys(this.props.colDef)[2]]]}
            </td>
        
            {this.props.collection_name == "users" ? (
              <td
                data-tip
                data-for={item.first_name + i}
                className="cursor-pointer"
              >
                <ReactTooltip id={item.first_name + i} type="info">
                  {toolTips[item.id]}
                </ReactTooltip>
                {
                  item[this.props.colDef[Object.keys(this.props.colDef)[3]]]
                    .length
                }
              </td>
            ) : (
              <td>
                {item[this.props.colDef[Object.keys(this.props.colDef)[3]]]}
              </td>
            )}
                <td>
                {this.roleInfo[item[this.props.colDef[Object.keys(this.props.colDef)[4]]]]}
              </td>
            <td>
              <Button
                style={{
                  borderRadius: "50px",
                  backgroundColor: "white",
                  border: "none"
                }}
                className="hover-blue"
                onClick={() => this.toggleModal(item)}
                disabled={item.designation == "Admin"}
              >
                <MaterialIcon icon="delete_outline" />
              </Button>

              <Button
                style={{
                  borderRadius: "50px",
                  backgroundColor: "white",
                  border: "none"
                }}
                onClick={() => this.edit_entry(item)}
                className="hover-blue ml-2"
              >
                <MaterialIcon id={item.id} icon="edit" />
              </Button>
            </td>
          </tr>
        );
      } else {
        return <tr key={i} />;
      }
    });
  }
}

function mapStateToProps(state) {
  return {
    search_key: state.AppState.search_key
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { navbarActions, saveEditSession, displayResponseMessage, deletePlant },
      dispatch
    )
  };
}

Manager.propTypes = {
  page_title: string.isRequired,
  page_sub_title: string.isRequired,
  edit_entry_link: string.isRequired,
  add_entry_link: string.isRequired,
  colDef: object.isRequired,
  entryList: object,
  collection_name: string.isRequired,
  actions: object.isRequired,
  history: object.isRequired,
  delete_action_type: string.isRequired,
  navToggle: func.isRequired,
  button_title: string.isRequired,
  search_key: string.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Manager)
);
