import React, { Component } from "react";
import { number, string, oneOfType, object } from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  FormFeedback,
  Button
} from "shards-react";
import { chain_validations } from "../../common/Validations";
import { connect } from "react-redux";
import { sendResetPasswordLink } from "../../../services/AuthService";
import ResponseMessage from "../../common/ResponseMessage";
import { displayResponseMessage } from "../../../actions/AppStateActions";
import { bindActionCreators } from "redux";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        email: ""
      },
      validations: {
        email: {}
      },
      submitted: false,
      allowValidations: false
    };

    this.onChange = this.onChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  onChange(e) {
    this.setState({ email: { email: e.target.value } }, this.validateForm);
  }

  validateForm = () => {
    const tests = {
      email: chain_validations(["is_empty", "email_valid"], {
        input: this.state.email.email
      })
    };
    this.setState({
      validations: {
        email: {
          invalid:
            this.state.allowValidations &&
            (tests.email.is_empty || !tests.email.email_valid),
          message: "Please enter a valid email"
        }
      }
    });
    let validations = [];
    if (tests.email.is_empty || !tests.email.email_valid) {
      validations.push("email");
    }
    return validations;
  };

  sendEmail(e) {
    e.preventDefault();
    this.setState({ submitted: true, allowValidations: true }, () => {
      if (this.validateForm().length == 0) {
        sendResetPasswordLink({ email: this.state.email.email }).then(
          () => {
            this.props.actions.displayResponseMessage(
              true,
              "success",
              "A password reset link has been sent to the requested email"
            );
            this.props.history.push("/");
          },
          () => {
            this.setState({ submitted: false });
            this.props.actions.displayResponseMessage(
              true,
              "fail",
              "Sorry, something went wrong"
            );
          }
        );
      } else {
        this.setState({ submitted: false });
      }
    });
  }

  render() {
    const button = this.state.submitted ? (
      <img
        className="pull-right"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
    ) : (
      "Send Password Reset Link"
    );
    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center">
        <ResponseMessage />
        <Card className="col-5 mb-5 p-0">
          <CardHeader className="bg-white border-bottom">
            <h4 className="text-dark">Forgot Password</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.sendEmail}>
              <div>
                <label>E-mail</label>
                <FormInput
                  name="email"
                  type="email"
                  value={this.state.email.email}
                  onChange={this.onChange}
                  {...this.state.validations.email}
                />
                <FormFeedback {...this.state.validations.email}>
                  {this.state.validations.email.message}
                </FormFeedback>
              </div>
              <div className="mt-4 col-6 mx-auto d-flex align-items-center justify-content-center">
                <Button className="w-100"> {button} </Button>
              </div>
              <div className="text-right mt-3">
                <a href="/">Go Back To Login</a>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  email: oneOfType([number, string]),
  actions: object,
  history: object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ displayResponseMessage }, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword);
