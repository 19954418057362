// export const AdditionalAnalysis = (
//     ph = "",
//     orp = "",
//     ts = "",
//     bod = "",
//     cod = "",
//     f_coil = "",
//     e_coil = "",
//     nitrates = "",
//     phosphates = "",
//     potassium = "",
//     solid_carbon = "",
//     solid_hydrogen = "",
//     solid_nitrogen = "",
//     solid_oxygen = "",
//     solid_sulphur = "",
//     sludge_moisture = "",
//     sludge_volatiles = "",
//     sludge_fixed_carbon = "",
//     sludge_ash = "",
//     biochar_ash_moisture = "",
//     biochar_fixed_carbon = ""
// ) => {
//     return {
//         ph,
//         orp,
//         ts,
//         bod,
//         cod,
//         f_coil,
//         e_coil,
//         nitrates,
//         phosphates,
//         potassium,
//         solid_carbon,
//         solid_hydrogen,
//         solid_nitrogen,
//         solid_oxygen,
//         solid_sulphur,
//         sludge_moisture,
//         sludge_volatiles,
//         sludge_fixed_carbon,
//         sludge_ash,
//         biochar_ash_moisture,
//         biochar_fixed_carbon
//     };
// };


// export default AdditionalAnalysis(
//     "10",
//     "20",
//     "30"
// );

export const AdditionalAnalysis = (
    key = "",
    value = "",
    comment = "",
    ) => {
    return {
        key,
        value,
        comment
    };
};


export default AdditionalAnalysis(
    "10",
    "20",
    "30"
);