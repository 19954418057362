
export const HoldingTank = (
    ph = "",
    orp = "",
    ts = ""
  ) => {
    return {
      ph,
      orp,
      ts
    };
  };
  
  
  export default HoldingTank(
    "10",
    "20",
    "30"
  );
  